// import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { makeStyles } from "@material-ui/core";
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import {
  GridActionsCellItem,
} from "@mui/x-data-grid";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ManageProducts.css";
import { useSelector, useDispatch } from "react-redux";
import {
  Tooltip,
  Button,
  Stack,
  Alert,
  Snackbar,
  Backdrop,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
} from '@mui/material';
import { DELETE_PRODUCT } from "../../constants";
import DeleteIcon from '@mui/icons-material/Delete';
import allActions from "../../redux/ducks/actions";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CircularProgress from '@mui/material/CircularProgress';
import Images from "../../constants/images";
import { Delete, Edit, TrashCan } from "@carbon/icons-react";
import CustomTableButton from "../../Components/CustomTableButton";

function ProductsTable(props) {
  const editAction = "#b58119";
  const Btncolor = "#2d8f3c";
  const history = useHistory();
  const dispatch = useDispatch();
  // Loading
  const [loading, setLoading] = React.useState(false);

  // Snack Message
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "update",
  });
  const { snackStatus, snackMessageFor } = snackbar;
  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  const { listAllProducts } = useSelector((state) => state.data);
  const [tableRow, setTableRow] = useState([]);
  const [productId, setProductId] = useState(0);
  useEffect(() => {
    setTableRow(listAllProducts);
  }, [listAllProducts]);

  // delete Product Modal
  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: "blur(4px)",
      backgroundColor: "rgba(30,41,51,.45)",
    },
  }));
  const classes = useStyles();
  const [deleteModel, setDeleteModel] = useState(false);
  const handleDeleteModelOpen = () => setDeleteModel(true);
  const handleDeleteModelClose = () => {
    setDeleteModel(false);
  };

  const columns = [
    "Product ID",
    "Product Name",
    "Product Description",
    "Price",
    "Duration",

    {
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box className='districtAction'>
              <Tooltip
                title="Edit Product"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value;
                  history.push(`admin-manage-products/update/${rowId}`);
                }}
              >
                <Box className='customActionIcon'>
                  <Edit size={16} color='#FFF' />
                </Box>
              </Tooltip>

              <Tooltip
                title="Delete"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value;
                  setProductId(rowId);
                  handleDeleteModelOpen();
                }}
              >
                <Box className='customActionIcon'>
                  <TrashCan size={16} color='#FFF' />
                </Box>
              </Tooltip>
            </Box>
          )
        }
      }
    }
  ];


  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>
      {/* Delete Modal */}
      <Dialog
        open={deleteModel}
        keepMounted
        onClose={handleDeleteModelClose}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{
          style: {
            padding: ".7%",
          },
        }}
      >
        <DialogTitle>
          <Box className="AssignmentDeleteHeader">
            Delete Product
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="deleteContent">
            Are you sure you wish to delete this Product ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomTableButton
            isInActive={true}
            buttonLabel={"Cancel"}
            onClickButton={handleDeleteModelClose}
          />
          <CustomTableButton
            isInActive={false}
            buttonLabel={"Ok"}
            onClickButton={() => {
              setLoading(true);
              props.handleLoading(loading)
              dispatch(allActions.deleteProduct(productId, (response) => {
                if (response.MessageID === 0) {
                  setLoading(false);
                  props.handleLoading(loading)
                  handleSnackbarClick("delete");
                } else {
                  console.error(response, "response");
                }
              }));
              handleDeleteModelClose();
            }}
          />
        </DialogActions>
      </Dialog>
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar
          key="SnackBar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackStatus}
          onClose={handleSnackbarClose}
        >
          {snackMessageFor === "delete"
            ? (<Alert
              variant="filled"
              severity="success"
              sx={{
                "&.MuiPaper-root": {
                  background: "#00c853",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {DELETE_PRODUCT}
            </Alert>)
            : null}
        </Snackbar>
      </Stack>
      <MUIDataTable
        title="Products"
        data={tableRow && tableRow.map(data => {
          return [
            data.ProductID,
            data.ProductName,
            data.ProductDesc,
            "$" + data.Price,
            data.Duration,
            data.ProductID,
          ]
        })}
        columns={columns}
        options={options}
      />
    </React.Fragment>
  );
}
export default ProductsTable;
