import React from 'react';
// import Card from './Card';
import { Box, Typography } from '@mui/material';
import { Column, Grid } from '@carbon/react';
import CardDistrict from './CardDistrict';

export default function Districtcard(props) {

    if (props && props.cardType && props.cardType === "SchoolUsageData") {
        return (
            <Grid className='nsAdminPadding'>
                <Column lg={4} md={4} sm={4}>
                    <CardDistrict cardName="Total Users" data={props.data.TotalUsers} />
                </Column>
                <Column lg={4} md={4} sm={4}>
                    <CardDistrict cardName="Total Classes" data={props.data.TotalClasses} />
                </Column>
                <Column lg={4} md={4} sm={4}>
                    <CardDistrict cardName="Total Teachers" data={props.data.TotalTeachers} />
                </Column>
                <Column lg={4} md={4} sm={4}>
                    <CardDistrict cardName="Total Students" data={props.data.TotalStudents} />
                </Column>
            </Grid>
        )
    }

    return (
        <Grid className='nsAdminPadding'>
            <Column lg={4} md={4} sm={4}>
                <CardDistrict cardName="Total Schools" data={props.data.TotalSchools} />
            </Column>
            <Column lg={4} md={4} sm={4}>
                <CardDistrict cardName="Total Classes" data={props.data.TotalClasses} />
            </Column>
            <Column lg={4} md={4} sm={4}>
                <CardDistrict cardName="Total Teachers" data={props.data.TotalTeachers} />
            </Column>
            <Column lg={4} md={4} sm={4}>
                <CardDistrict cardName="Total Students" data={props.data.TotalStudents} />
            </Column>
        </Grid>
    )
}