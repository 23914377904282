import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import CastForEducationRoundedIcon from '@mui/icons-material/CastForEducationRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {
    Button,
    Stack,
    Typography,
    Box,
    CircularProgress,
    Backdrop,
    Autocomplete,
    TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UsersLoginTable from "./UsersLoginTable";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useDispatch, useSelector } from "react-redux";
import "./ManageUsers.css";
import CustomTableButton from '../../Components/CustomTableButton';
import CustomHeader from '../../Components/CustomHeader';
import { Add, Building, Course, Location, Upload } from '@carbon/icons-react';
import allActions from "../../redux/ducks/actions";

function ManageUsers() {
    const history = useHistory(); //History
    const { userInfo, listAllSchools, schoolData, schoolUsageData } = useSelector((state) => state.data);
    const [loading, setLoading] = React.useState(false);
    const [schoolNameOptions, setSchoolNameOptions] = useState({});
    const [value, setValue] = React.useState(null);
    const [tableRow, setTableRow] = useState([]);
    const dispatch = useDispatch();

    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    const defaultData = {
        options: [],
        getOptionLabel: (option) => option,
    };

    useEffect(() => {
        if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
            // dispatch(allActions.getAllUserList(userInfo.SuperGroupID, (response) => {
            //     let rowData = response;
            //     rowData.map((data, index) => {
            //         if (data.ActiveFlag === 1) {
            //             rowData[index].ActiveFlag = "Active";
            //         } else {
            //             rowData[index].ActiveFlag = "Inactive";
            //         }
            //         return null;
            //     })
            //     setTableRow(rowData);
            // }));
        } else {
            if (listAllSchools && listAllSchools.length > 0) {
                let allSchoolNameList = [];
                listAllSchools && listAllSchools.map(data => {
                    allSchoolNameList.push({
                        label: data.Name,
                        id: data.ID
                    });
                    return null;
                })
                let defaultProps = {
                    options: allSchoolNameList,
                    getOptionLabel: (option) => option && option.label && (option.label).trim()
                };
                setSchoolNameOptions(defaultProps);
                if (schoolData) {
                    setValue(schoolData)
                } else {
                    setValue(allSchoolNameList[0]);
                    dispatch(allActions.saveCurrentSchool(allSchoolNameList[0]))
                }
                dispatch(allActions.getAllUserList(allSchoolNameList[0].id, (response) => {
                    let rowData = response;
                    rowData.map((data, index) => {
                        if (data.ActiveFlag === 1) {
                            rowData[index].ActiveFlag = "Active";
                        } else {
                            rowData[index].ActiveFlag = "Inactive";
                        }
                        return null;
                    })
                    setTableRow(rowData);
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
console.log(userInfo, "userInfo")
    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: "99999999"
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>

            <CustomHeader
                licenseStatus={licenseStatus}
                headerLabel={"User Logins"}
            />

            <Box className="customWelcomeCard">
                <Box className="customWelcomePhase1">
                    <Box className='weeklyReportWrapper'>
                        {
                            userInfo && userInfo.UserType === "Supergrouptechadmin" ? (
                                <Box className="customWelcomePhase1">
                                    <Box className="customWelcomeText1">School Name</Box>
                                    <Box className="customWelcomeText2">{userInfo && userInfo.SuperGroupName}</Box>
                                    {/* <Box className="customWelcomeText2">{schoolUsageData && schoolUsageData.schoolName}</Box> */}
                                </Box>
                            ) :
                                listAllSchools && listAllSchools.length > 0 ?
                                    <Autocomplete
                                        {...schoolNameOptions}
                                        value={value}
                                        onChange={(event, newValue) => {
                                            if (!newValue) {
                                                if (listAllSchools && listAllSchools.lenght > 0) {
                                                    const schoolValue = {
                                                        label: listAllSchools[0].Name,
                                                        id: listAllSchools[0].ID
                                                    }
                                                    dispatch(allActions.saveCurrentSchool(schoolValue))
                                                    setValue(schoolValue);
                                                }
                                            } else {
                                                setValue(newValue);
                                                dispatch(allActions.saveCurrentSchool(newValue))
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="School names"
                                                variant="standard"
                                                sx={{
                                                    '& label.Mui-focused': {
                                                        color: "#2d8f3c",
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#2d8f3c',
                                                    },
                                                }}
                                            />
                                        )}
                                    /> :
                                    <Autocomplete
                                        {...defaultData}
                                        id="disable-close-on-select"
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                            <TextField {...params} label="School names" variant="standard" />
                                        )}
                                    />
                        }
                    </Box>
                </Box>
                <Box className="customWelcomePhase2">


                    {
                        userInfo.SourceID !== "" ? null : (
                            <React.Fragment>
                                {
                                    (
                                        userInfo &&
                                        userInfo.UserType === "DistrictAdmin"
                                    ) ?
                                        <CustomTableButton
                                            buttonLabel={"District Admin"}
                                            onClickButton={() => {
                                                history.push("/district/district-user-logins/district-admin-users")

                                            }}
                                            buttonIcon={<Location size={16} />}
                                        /> : null
                                }
                                {
                                    userInfo &&
                                        userInfo.UserType === "SuperGroupAdmin" ? null : (
                                        <CustomTableButton
                                            buttonLabel={"Bulk Upload School"}
                                            onClickButton={() => {
                                                if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                                                    history.push("/school/school-user-logins/school-bulk-upload")
                                                } else {
                                                    history.push("/district/district-user-logins/school-bulk-upload")
                                                }
                                            }}
                                            buttonIcon={<Upload size={16} />}
                                        />
                                    )
                                }
                                {
                                    userInfo &&
                                        userInfo.UserType === "SuperGroupAdmin" ? null : (
                                        <CustomTableButton
                                            buttonLabel={"Bulk Upload Class"}
                                            onClickButton={() => {
                                                if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                                                    history.push("/school/school-user-logins/class-bulk-upload")
                                                } else {
                                                    history.push("/district/district-user-logins/class-bulk-upload")
                                                }
                                            }}
                                            buttonIcon={<Upload size={16} />}
                                        />
                                    )
                                }
                                {
                                    userInfo &&
                                        userInfo.UserType === "SuperGroupAdmin" ? null : (
                                        <CustomTableButton
                                            buttonLabel={"New"}
                                            onClickButton={() => {
                                                if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                                                    history.push("/school/school-user-logins/create")
                                                } else {
                                                    history.push("/district/district-user-logins/create")
                                                }
                                            }}
                                            buttonIcon={<Add size={16} />}
                                        />
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </Box>
            </Box>

            <Box className="nsMainContent">
                <UsersLoginTable selectOption={value} />
            </Box>

        </Box >

    );
}
export default ManageUsers;
