import { call, put } from "redux-saga/effects";
import { LOGIN_FAILED_MESSAGE, LOGOUT_SUCCESS_MESSAGE } from "../../constants";
import Creators from "../ducks/actions";
import { AllService } from "../services";

// NumberSense Starts

// classlink Login Data
export function* classlinkLogin(data) {
  const { sourceId, response } = data;
  const userData = yield call(AllService.classlinkLogin, sourceId);
  if (userData === "TIMEOUT_ERROR") {
    alert("error occurred");
  } else {
    if (userData && userData.UserType) {
      if (userData.UserType === "DistrictAdmin") {
        // setting school data
        let schoolsData = yield call(AllService.getAllSchoolList, userData.DomainID);
        yield put(Creators.setSchoolList(schoolsData.Data));
        // setting class data
        const classLicenseProductData = yield call(AllService.getAllClassLicense);
        yield put(Creators.setClassLicenseList(classLicenseProductData));
        // setting notification data
        if (userData.ISNotification === 1) {
          const Notifications = yield call(AllService.districtAdminNotifications, userData.DomainID);
          yield put(Creators.notificationLength(Notifications.length));
        } else {
          yield put(Creators.notificationLength(0));
        }
      } else if (userData.UserType === "NSTechAdmin") {
        // setting notification data
        if (userData.ISNotification === 1) {
          const newNotifications = yield call(AllService.getNewNotifications);
          yield put(Creators.notificationLength(newNotifications.length));
        } else {
          yield put(Creators.notificationLength(0));
        }
      } else if (userData.UserType === "GroupAdmin") {

      }
      yield put(Creators.userLoginSuccessMessage(userData));
      yield call(response, userData);
    } else {
      yield put(Creators.userLoginFailureMessage(LOGIN_FAILED_MESSAGE));
      yield call(response, userData);
    }
  }
}

// Login Data


export function* userLogin(data) {
  const { username, password, domainname, successFunc, failureFunc } = data;
  const userData = yield call(AllService.userLogin, username, password, domainname);
  if (userData === "TIMEOUT_ERROR") {
    alert("error occurred");
  } else {
    if (userData && userData.Data) {
      if (userData.Data.UserType === "DistrictAdmin") {
        // setting school data
        // let schoolsData = yield call(AllService.getAllSchoolList, userData.Data.DomainID);
        // yield put(Creators.setSchoolList(schoolsData.Data[0].NSSchoolGETALL));
        // // setting class data
        // const classLicenseProductData = yield call(AllService.getAllClassLicense);
        // yield put(Creators.setClassLicenseList(classLicenseProductData));
        // setting notification data
        if (userData.Data.ISNotification === 1) {
          const Notifications = yield call(AllService.districtAdminNotifications, userData.Data.DomainID);
          yield put(Creators.notificationLength(Notifications.length));
        } else {
          yield put(Creators.notificationLength(0));
        }
      } else if (userData.Data.UserType === "NSTechAdmin") {
        // setting notification data
        if (userData.Data.ISNotification === 1) {
          const newNotifications = yield call(AllService.getNewNotifications);
          yield put(Creators.notificationLength(newNotifications.length));
        } else {
          yield put(Creators.notificationLength(0));
        }
      } else if (userData.Data.UserType === "GroupAdmin") {

      }
      if (userData.StatusCode === 400) {
        yield put(Creators.userLoginFailureMessage(LOGIN_FAILED_MESSAGE));
        yield call(failureFunc, userData.Data);
      }
      yield put(Creators.userAuthToken(userData.Token));
      localStorage.setItem("AccessToken", userData.Token.AccessToken);
      localStorage.setItem("RefreshToken", userData.Token.RefreshToken);
      localStorage.setItem("userData", JSON.stringify(userData.Data));
      yield put(Creators.userLoginSuccessMessage(userData.Data));
      yield call(successFunc, userData.Data);
    } else {
      if (userData.StatusCode === 400) {
        yield put(Creators.userLoginFailureMessage(LOGIN_FAILED_MESSAGE));
        yield call(failureFunc, userData);
      }
      else {

        yield put(Creators.userLoginFailureMessage("Server Error, Please try again later"));
        yield call(failureFunc, userData);
      }
    }
  }
}

// class Login Data

export function* classLogin(data) {
  const { code, successFunc, failureFunc } = data;
  const userData = yield call(AllService.classLogin, code);
  if (userData === "TIMEOUT_ERROR") {
    alert("error occurred");
  } else {
    if (userData && userData?.NSUserInfo?.UserType && userData.NSUserInfo.UserType !== "student") {
      if (userData.NSUserInfo.UserType === "DistrictAdmin") {
        // setting school data
        let schoolsData = yield call(AllService.getAllSchoolList, userData.NSUserInfo.DomainID);
        yield put(Creators.setSchoolList(schoolsData.Data));
        // setting class data
        const classLicenseProductData = yield call(AllService.getAllClassLicense);
        yield put(Creators.setClassLicenseList(classLicenseProductData));
        // setting notification data
        if (userData.ISNotification === 1) {
          const Notifications = yield call(AllService.districtAdminNotifications, userData.NSUserInfo.DomainID);
          yield put(Creators.notificationLength(Notifications.length));
        } else {
          yield put(Creators.notificationLength(0));
        }
      } else if (userData.NSUserInfo.UserType === "NSTechAdmin") {
        // setting notification data
        if (userData.ISNotification === 1) {
          const newNotifications = yield call(AllService.getNewNotifications);
          yield put(Creators.notificationLength(newNotifications.length));
        } else {
          yield put(Creators.notificationLength(0));
        }
      } else if (userData.NSUserInfo.UserType === "GroupAdmin") {

      }
      yield put(Creators.userLoginSuccessMessage(userData.NSUserInfo));
      yield call(successFunc, userData.NSUserInfo);
    } else {
      yield put(Creators.userLoginFailureMessage(LOGIN_FAILED_MESSAGE));
      yield call(failureFunc, userData.NSUserInfo);
    }
  }
}

// Number Sense React
export function* getAllDistrictList() {
  const allDomainList = yield call(AllService.getAllDistrictList);
  yield put(Creators.setDistrictList(allDomainList.Data));
}

export function* putDistrict(data) {
  const { districtData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.putDistrict,
    districtData.country,
    districtData.domainName,
    districtData.description,
    districtData.emailId,
    districtData.firstName,
    districtData.lastName,
    districtData.location,
    districtData.password,
    districtData.userName,
    districtData.maxStudents
  );
  yield call(successFunction, serviceResponse);
}
export function* putClasslinkDistrict(data) {
  const { districtData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.putClasslinkDistrict,
    districtData.country,
    districtData.domainName,
    districtData.description,
    districtData.emailId,
    districtData.firstName,
    districtData.lastName,
    districtData.location,
    districtData.password,
    districtData.userName,
    districtData.maxStudents,
    districtData.SourceId,
    districtData.AccessToken,
    districtData.ApplicationID
  );
  yield call(successFunction, serviceResponse);
}

export function* getDistrictByID(data) {
  const { districtId, successFunction } = data;
  const serviceResponse = yield call(AllService.getDistrictByID, districtId);
  yield call(successFunction, serviceResponse.Data);
}

export function* updateClassLinkById(data) {
  const { updateData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.updateClassLinkById,
    updateData.domainId,
    updateData.domainName,
    updateData.description,
    updateData.location,
    updateData.country,
    updateData.userName,
    updateData.emailId,
    updateData.firstName,
    updateData.lastName,
    updateData.password,
    updateData.maxStudents,
    updateData.studentId,
    updateData.accessToken,
    updateData.applicationID,
    updateData.sourceID
  );
  yield call(successFunction, serviceResponse);
}

export function* updateDistrictById(data) {
  const { updateData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.updateDistrictById,
    updateData.domainId,
    updateData.domainName,
    updateData.description,
    updateData.location,
    updateData.country,
    updateData.userName,
    updateData.emailId,
    updateData.firstName,
    updateData.lastName,
    updateData.password,
    updateData.maxStudents,
    updateData.studentId
  );
  yield call(successFunction, serviceResponse);
}

//Manage Projects

export function* getAllProductsList(data) {
  const allProductsList = yield call(AllService.getAllProductsList);
  yield put(Creators.setProductsList(allProductsList.Data[0].GetAllProductsResult));
}

export function* putProducts(data) {
  const { productsData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.putProducts,
    productsData.ProductID,
    productsData.ProductName,
    productsData.ProductDesc,
    productsData.Price,
    productsData.Duration,
    productsData.Modes
  );
  yield call(successFunction, serviceResponse);
}

export function* getProductsByID(data) {
  const { productsId, successFunction } = data;
  const serviceResponse = yield call(AllService.getProductsByID, productsId);
  yield call(successFunction, serviceResponse.Data.GetProductResult);
}

export function* updateProductsById(data) {
  const { updateData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.updateProductsById,
    updateData.ProductID,
    updateData.ProductName,
    updateData.ProductDesc,
    updateData.Price,
    updateData.Duration,
    updateData.Modes
  );
  yield call(successFunction, serviceResponse);
}

// manage class license
export function* getAllClassLicense(data) {
  const { successFunction } = data;
  const classLicenseProductData = yield call(AllService.getAllClassLicense);
  yield call(successFunction, classLicenseProductData.Data);
  yield put(Creators.setClassLicenseList(classLicenseProductData.Data));
}

export function* updateClassSubscription(data) {
  const { updateClassSubscription, successFunction } = data;
  const serviceResponse = yield call(
    AllService.updateClassSubscription,
    updateClassSubscription.DistrictID,
    updateClassSubscription.ProductID,
    updateClassSubscription.SubscriptionDate
  );
  yield call(successFunction, serviceResponse);
}
export function* getAdminData(data) {
  const adminData = yield call(AllService.getAdminData);
  const { successFunction } = data;
  yield call(successFunction, adminData.Data);
}

// logout user
export function* userLogout(data) {
  const { successFn } = data;
  yield put(Creators.setLogoutStatus(LOGOUT_SUCCESS_MESSAGE));
  yield call(successFn);
}

export function* getDistrictDashboardData(data) {
  const { districtId } = data;
  const districtDashboardData = yield call(AllService.getDistrictDashboardData, districtId);
  yield put(Creators.setDistrictDashboardData(districtDashboardData.Data));
}

export function* getAllSchoolList(data) {
  let { districtId } = data;
  let schoolsData = yield call(AllService.getAllSchoolList, districtId);
  yield put(Creators.setSchoolList(schoolsData.Data));
}

export function* putSchools(data) {
  const { schoolsData, successFunction } = data;
  let today = new Date();
  let todayDate = String(today.getDate()).padStart(2, "0");
  let todayMonth = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let todayYear = today.getFullYear();
  let startDate = todayMonth + "/" + todayDate + "/" + todayYear;
  const serviceResponse = yield call(AllService.putSchools, schoolsData.schoolName, schoolsData.description, schoolsData.domainid, 80, startDate);
  yield call(successFunction, serviceResponse);
}

export function* getSchoolsById(data) {
  const { schoolsId, successFunction } = data;
  const serviceResponse = yield call(AllService.getSchoolsById, schoolsId);
  yield call(successFunction, serviceResponse.Data);
}

export function* updateSchoolsById(data) {
  const { updateData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.updateSchoolsById,
    updateData.schoolName,
    updateData.description,
    updateData.domainId,
    updateData.maxStudents,
    updateData.startDate
  );
  yield call(successFunction, serviceResponse);
}

export function* schoolsBulkUpload(data) {
  const { updateData, successFunction } = data;
  const serviceResponse = yield call(AllService.schoolsBulkUpload, updateData.DomainId, updateData.SchoolList);
  yield call(successFunction, serviceResponse);
}

export function* getAllClassList(data) {
  let { schoolId, successFunction } = data;
  let classesData = yield call(AllService.getAllClassList, schoolId);
  yield put(Creators.setClassList(classesData.Data));
  yield call(successFunction, classesData.Data);
}

export function* putClasses(data) {
  const { classesData, successFunction } = data;
  let today = new Date();
  let todayDate = String(today.getDate()).padStart(2, "0");
  let todayMonth = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let todayYear = today.getFullYear();
  let startDate = todayMonth + "/" + todayDate + "/" + todayYear;
  const serviceResponse = yield call(AllService.putClasses, classesData.className, classesData.description, 80, startDate, classesData.schoolId, 1);
  yield call(successFunction, serviceResponse);
}

export function* getClassesById(data) {
  const { classesId, successFunction } = data;
  const serviceResponse = yield call(AllService.getClassesById, classesId);
  yield call(successFunction, serviceResponse.Data);
}

export function* updateClassesById(data) {
  const { updateData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.updateClassesById,
    updateData.classId,
    updateData.className,
    updateData.description,
    updateData.maxStudents,
    updateData.schoolId,
    updateData.startDate
  );
  yield call(successFunction, serviceResponse);
}

export function* classesBulkUpload(data) {
  const { updateData, successFunction } = data;
  const serviceResponse = yield call(AllService.classesBulkUpload, updateData.SchoolID, updateData.ClassList);
  yield call(successFunction, serviceResponse);
}

export function* getClassDetails(data) {
  let { classId, successFunction } = data;
  let classesData = yield call(AllService.getClassDetails, classId);
  yield call(successFunction, classesData);
}

export function* bulkUploadNSTeacherClassMapping(data) {
  const { updateData, successFunction } = data;
  const serviceResponse = yield call(AllService.bulkUploadNSTeacherClassMapping, updateData.SchoolID, updateData.ClassMapingList);
  console.log(serviceResponse);
  yield call(successFunction, serviceResponse);
}

export function* schoolUsageDataSchoolList(data) {
  let { districtId, successFunction } = data;
  let schoolList = yield call(AllService.schoolUsageDataSchoolList, districtId);
  yield call(successFunction, schoolList.Data);
}

export function* schoolUsageDataSchoolDashboard(data) {
  let { schoolId, successFunction } = data;
  let schoolDetails = yield call(AllService.schoolUsageDataSchoolDashboard, schoolId);
  yield call(successFunction, schoolDetails.Data);
  yield put(Creators.setSchoolName(schoolDetails.Data.SchoolName));
}

export function* dailySUD(data) {
  let { districtId, successFunction } = data;
  let schoolDailySummary = yield call(AllService.dailySUD, districtId);
  yield call(successFunction, schoolDailySummary.Data[0].DetailsPerDate);
}

export function* detailsSUD(data) {
  let { districtId, successFunction } = data;
  let schoolActivtiySummary = yield call(AllService.detailsSUD, districtId);
  yield call(successFunction, schoolActivtiySummary.Data[0]);
}

export function* classSUD(data) {
  let { districtId, successFunction } = data;
  let schoolClassSummary = yield call(AllService.classSUD, districtId);
  yield call(successFunction, schoolClassSummary);
}

export function* studentsSummaryCS(data) {
  let { classId, successFunction } = data;
  let studentList = yield call(AllService.studentsSummaryCS, classId);
  yield call(successFunction, studentList.Data.NSStudentPerformance);
}
export function* studentsSummaryDailyAndActivity(data) {
  let { studentId, successFunction } = data;
  let studentDailyAndActivtiySummary = yield call(AllService.studentsSummaryDailyAndActivity, studentId);
  yield call(successFunction, studentDailyAndActivtiySummary.Data);
}
export function* classDailySummaryCS(data) {
  let { classId, successFunction } = data;
  let classActivtiySummary = yield call(AllService.classDailySummaryCS, classId);
  yield call(successFunction, classActivtiySummary.Data[0]);
}
export function* classActivitySummaryCS(data) {
  let { classId, successFunction } = data;
  let classActivitySummary = yield call(AllService.classActivitySummaryCS, classId);
  yield call(successFunction, classActivitySummary.Data[0].Details);
}

//District Admin
export function* getPurchaseHistory(data) {
  const { districtId, successFunction } = data;
  const PurchaseHistory = yield call(AllService.getPurchaseHistory, districtId);
  yield call(successFunction, PurchaseHistory.Data);
}

export function* districtAdminNotifications(data) {
  const { districtId, successFunction } = data;
  const Notifications = yield call(AllService.districtAdminNotifications, districtId);
  yield put(Creators.notificationLength(Notifications.Data.length));
  yield call(successFunction, Notifications.Data);
}

export function* districtAdminViewAllNotif(data) {
  const { districtId, successFunction } = data;
  const viewAllNotifications = yield call(AllService.districtAdminViewAllNotif, districtId);
  yield call(successFunction, viewAllNotifications.Data);
}

//Manage District License
export function* getDistrictLicProductHistory(data) {
  const { districtId, successFunction } = data;
  const serviceResponse = yield call(AllService.getDistrictLicProductHistory, districtId);
  yield call(successFunction, serviceResponse.Data);
}

export function* updateSubscription(data) {
  const { updateSubscription, successFunction } = data;
  const serviceResponse = yield call(
    AllService.updateSubscription,
    updateSubscription.DistrictID,
    updateSubscription.ProductID,
    updateSubscription.SubscriptionDate.replaceAll("/", "-")
  );
  yield call(successFunction, serviceResponse);
}

export function* manageDistrictLicenceSubscription(data) {
  const { subscriptionData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.manageDistrictLicenceSubscription,
    subscriptionData.DistrictID,
    subscriptionData.ProductID,
    subscriptionData.SubscriptionDate.replaceAll("/", "-")
  );
  yield call(successFunction, serviceResponse);
}

//Manage User License
export function* getUserLicenseList(data) {
  const { successFunction } = data;
  const allUserLicenseList = yield call(AllService.getUserLicenseList);
  yield call(successFunction, allUserLicenseList.Data);
  yield put(Creators.setUserLicenseList(allUserLicenseList.Data));
}

export function* getDistrictLicenseList(data) {
  const { successFunction } = data;
  const allDistrictLicenseList = yield call(AllService.getDistrictLicenseList);
  yield call(successFunction, allDistrictLicenseList.Data);
  yield put(Creators.setDistrictLicenseList(allDistrictLicenseList.Data));
}

export function* getUserDistrictProductHistory(data) {
  const { UserId, successFunction } = data;
  const serviceResponse = yield call(AllService.getUserDistrictProductHistory, UserId);
  yield call(successFunction, serviceResponse.Data);
}

export function* userDistrictUpdate(data) {
  const { userDistrictUpdate, successFunction } = data;
  const serviceResponse = yield call(AllService.userDistrictUpdate, userDistrictUpdate.UserID, userDistrictUpdate.ProductID);
  yield call(successFunction, serviceResponse.Data);
}

export function* getAllUserList(data) {
  let { schoolId, successFunction } = data;
  let usersData = yield call(AllService.getAllUserList, schoolId);
  yield call(successFunction, usersData.Data[0].NSUserList);
  yield put(Creators.setUserList(usersData.Data[0].NSUserList));


}

export function* putUser(data) {
  const { userData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.putUser,
    userData.sourceData,
    userData.loginName,
    userData.emailId,
    userData.firstName,
    userData.lastName,
    userData.country,
    userData.age,
    userData.groupIds,
    userData.password,
    userData.schoolId,
    userData.userType
  );
  yield call(successFunction, serviceResponse);
}

export function* getUsersById(data) {
  const { schoolId, successFunction } = data;
  const serviceResponse = yield call(AllService.getUsersById, schoolId);
  yield call(successFunction, serviceResponse.Data[0].Group);
}

//Notifications
export function* getNewNotifications(data) {
  const { successFunction } = data;
  const newNotifications = yield call(AllService.getNewNotifications);
  yield put(Creators.notificationLength(newNotifications.Data.length));
  yield put(Creators.setNewNotifications(newNotifications.Data));
  yield call(successFunction, newNotifications.Data);
}

export function* getAllNotifications(data) {
  const { successFunction } = data;
  const allNotifications = yield call(AllService.getAllNotifications);
  yield put(Creators.setAllNotifications(allNotifications.Data));
  yield call(successFunction, allNotifications.Data);
}

export function* deleteNotifications(data) {
  const { successFunction, notificationIds, userType } = data;
  const deleteNotifications = yield call(AllService.deleteNotifications, notificationIds, userType);
  yield call(successFunction, deleteNotifications);
}

export function* getClassLeaderboardData(data) {
  const { classLeaderboard, successFunction } = data;
  const serviceResponse = yield call(
    AllService.getClassLeaderboardData,
    classLeaderboard.DistrictID,
    classLeaderboard.FromDate,
    classLeaderboard.ToDate
  );
  yield call(successFunction, serviceResponse.Data.GetNSTeacherLeaderBoardReportResult);
}

export function* getStudentLeaderboardData(data) {
  const { schoolLeaderboard, successFunction } = data;
  const serviceResponse = yield call(
    AllService.getStudentLeaderboardData,
    schoolLeaderboard.DistrictID,
    schoolLeaderboard.FromDate,
    schoolLeaderboard.ToDate
  );
  yield call(successFunction, serviceResponse.Data.GetNSDistrictLeaderBoardReportResult);
}

// get school dashboard data
export function* getSchoolDashboardData(data) {
  const { SuperGroupId } = data;
  const schoolDashboardData = yield call(AllService.getSchoolDashboardData, SuperGroupId);
  yield put(Creators.setSchoolDashboardData(schoolDashboardData.Data));
}

export function* activitySAD(data) {
  const { schoolId, successFunction } = data;
  const schoolActiveSummary = yield call(AllService.activitySAD, schoolId);
  yield call(successFunction, schoolActiveSummary.Data[0].DetailsPerDate);
}

export function* detailsSAD(data) {
  const { schoolId, successFunction } = data;
  const schoolDetailsSummary = yield call(AllService.detailsSAD, schoolId);
  yield call(successFunction, schoolDetailsSummary.Data[0].Details);
}

export function* updateUsersById(data) {
  const { updateData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.updateUsersById,
    updateData.imageId,
    updateData.sourceData,
    updateData.loginName,
    updateData.emailId,
    updateData.firstName,
    updateData.lastName,
    updateData.country,
    updateData.age,
    updateData.groupIds,
    updateData.password,
    updateData.schoolId,
    updateData.userType
  );
  yield call(successFunction, serviceResponse);
}

export function* districtWeeklyReport(data) {
  const { weeklyReportData, successFunction } = data;
  try {
    const serviceResponse = yield call(
      AllService.districtWeeklyReport,
      weeklyReportData.districtId,
      weeklyReportData.fromDate,
      weeklyReportData.toDate
    );
    yield call(successFunction, serviceResponse.Data.GetNSDistrictSummaryReportResult);
  } catch (error) {
    yield call(successFunction, error);
  }
}

export function* schoolWeeklyReport(data) {
  const { weeklyReportData, successFunction } = data;
  const serviceResponse = yield call(AllService.schoolWeeklyReport, weeklyReportData.schoolId, weeklyReportData.fromDate, weeklyReportData.toDate);
  yield put(Creators.setClassDetails(weeklyReportData.schoolId, weeklyReportData.classname, serviceResponse.Data.GetNSClassSummaryReportResult));
  yield call(successFunction, serviceResponse.Data.GetNSClassSummaryReportResult);
}

export function* getClassBySchoolId(data) {
  let { schoolId, successFunction } = data;
  let serverResponse = yield call(AllService.getClassBySchoolId, schoolId);
  yield call(successFunction, serverResponse.Data[0].Group);
}

export function* classUsageDataSchoolList(data) {
  let { schoolId, successFunction } = data;
  let classList = yield call(AllService.classUsageDataSchoolList, schoolId);
  yield call(successFunction, classList.Data);
}

export function* classUsageDataSchoolDashboard(data) {
  let { GroupID, successFunction } = data;
  let classDetails = yield call(AllService.classUsageDataSchoolDashboard, GroupID);
  yield call(successFunction, classDetails.Data);
  yield put(Creators.setClassDetails(GroupID, classDetails.Data.ClassName, classDetails.Data));
}

export function* schoolClassDailySummaryCS(data) {
  let { classId, successFunction } = data;
  let classActivtiySummary = yield call(AllService.schoolClassDailySummaryCS, classId);
  yield call(successFunction, classActivtiySummary.Data[0].DetailsPerDate);
}

export function* schoolClassDetailsSummaryCS(data) {
  let { classId, successFunction } = data;
  let classDetailsSummary = yield call(AllService.schoolClassDetailsSummaryCS, classId);
  yield call(successFunction, classDetailsSummary.Data[0].Details);
}

export function* activeAndDeactive(data) {
  let { districtId, status, successFunction } = data;
  let serviceResponse = yield call(AllService.activeAndDeactive, districtId, status);
  yield call(successFunction, serviceResponse);
  const allDomainList = yield call(AllService.getAllDistrictList);
  yield put(Creators.setDistrictList(allDomainList.Data));
}

export function* districtDetails(data) {
  let { districtId, successFunction } = data;
  let serviceResponse = yield call(AllService.districtDetails, districtId);
  yield call(successFunction, serviceResponse.Data);
}

export function* deleteProduct(data) {
  let { productId, successFunction } = data;
  let serviceResponse = yield call(AllService.deleteProduct, productId);
  yield call(successFunction, serviceResponse);
  const allProductsList = yield call(AllService.getAllProductsList);
  yield put(Creators.setProductsList(allProductsList.Data[0].GetAllProductsResult));
}
export function* teacherUsageDataList(data) {
  let { teacherId, successFunction } = data;
  let teacherList = yield call(AllService.teacherUsageDataList, teacherId);
  yield call(successFunction, teacherList.Data);
}

export function* teacherUsageDataDashboard(data) {
  let { GroupID, successFunction } = data;
  let teacherDash = yield call(AllService.teacherUsageDataDashboard, GroupID);
  yield call(successFunction, teacherDash.Data);
  // yield put(Creators.setClassName(teacherDash.SchoolName));
}

// Teacher Admin
export function* getTeacherDashboardData(data) {
  const { LoginID, successFunction } = data;
  const teacherDashboardData = yield call(AllService.getTeacherDashboardData, LoginID);
  yield call(successFunction, teacherDashboardData.Data);
  yield put(Creators.setTeacherDashboardData(teacherDashboardData.Data));
}

export function* teacherClassDailySummaryCS(data) {
  let { ClassID, successFunction } = data;
  let teacherclassActivtiySummary = yield call(AllService.teacherClassDailySummaryCS, ClassID);
  yield call(successFunction, teacherclassActivtiySummary.Data[0].DetailsPerDate);
}

export function* teacherClassDetailsSummaryCS(data) {
  let { ClassID, successFunction } = data;
  let teacherDetailsSummary = yield call(AllService.teacherClassDetailsSummaryCS, ClassID);
  yield call(successFunction, teacherDetailsSummary.Data[0].Details);
}

export function* districtDashboardDailySummary(data) {
  let { districtId, successFunction } = data;
  let responseData = yield call(AllService.districtDashboardDailySummary, districtId);
  yield call(successFunction, responseData.Data[0].DetailsPerDate);
}

export function* districtDashboardActivitySummary(data) {
  let { districtId, successFunction } = data;
  let responseData = yield call(AllService.districtDashboardActivitySummary, districtId);
  yield call(successFunction, responseData.Data[0].Details);
}

export function* getRestrictedAdminUsersList(data) {
  let { districtId, successFunction } = data;
  let serviceResponse = yield call(AllService.getRestrictedAdminUsersList, districtId);
  yield call(successFunction, serviceResponse.Data);
}

export function* userLoginSchoolsBulkUpload(data) {
  const { schoolId, mailNo, userList, successFunction } = data;
  const serviceResponse = yield call(AllService.userLoginSchoolsBulkUpload, schoolId, mailNo, userList);
  yield call(successFunction, serviceResponse);
}

export function* userLoginClassesBulkUpload(data) {
  const { schoolId, classId, mailNo, userList, successFunction } = data;
  const serviceResponse = yield call(AllService.userLoginClassesBulkUpload, schoolId, classId, mailNo, userList);
  yield call(successFunction, serviceResponse);
}

export function* getRestrictedAdminUsersUsersById(data) {
  const { userId, successFunction } = data;
  const serviceResponse = yield call(AllService.getRestrictedAdminUsersUsersById, userId);
  yield call(successFunction, serviceResponse.Data);
}

export function* updateRestrictedAdminUsersById(data) {
  const { updateData, successFunction } = data;
  let groupIds = [];
  updateData.classesList &&
    updateData.classesList.map((data) => {
      if (data.Active === 1) {
        groupIds.push(data.GroupID);
      }
      return null;
    });
  const serviceResponse = yield call(
    AllService.updateRestrictedAdminUsersById,
    updateData.password,
    updateData.emailId,
    updateData.firstName,
    updateData.lastName,
    updateData.country,
    updateData.age,
    updateData.id,
    updateData.isActive,
    groupIds
  );
  yield call(successFunction, serviceResponse);
}

export function* classlinkSchoolUpload(data) {
  let { schoolData, successFunction } = data;
  let responseData = yield call(AllService.classlinkSchoolUpload, schoolData);
  yield call(successFunction, responseData);
}

export function* classlinkClassUpload(data) {
  let { classData, successFunction } = data;
  let responseData = yield call(AllService.classlinkClassUpload, classData);
  yield call(successFunction, responseData);
}
export function* classlinkUserUpload(data) {
  let { userData, successFunction } = data;
  let responseData = yield call(AllService.classlinkUserUpload, userData);
  yield call(successFunction, responseData);
}
export function* classlinkUserMaping(data) {
  let { userMaping, successFunction } = data;
  let responseData = yield call(AllService.classlinkUserMaping, userMaping);
  yield call(successFunction, responseData);
}

export function* classListTeacher(data) {
  let { teacherId, successFunction } = data;
  let serverResponse = yield call(AllService.classListTeacher, teacherId);
  yield put(Creators.setClassList(serverResponse.Data));
  yield call(successFunction, serverResponse.Data);
}

export function* classWeeklyReport(data) {
  const { weeklyReportData, successFunction } = data;
  const serviceResponse = yield call(AllService.classWeeklyReport, weeklyReportData.superGroupId, weeklyReportData.fromDate, weeklyReportData.toDate);
  yield call(successFunction, serviceResponse.Data.GetNSSchoolSummaryReportResult);
}

export function* studentUsageDataList(data) {
  let { classId, successFunction } = data;
  let studentList = yield call(AllService.studentUsageDataList, classId);
  yield call(successFunction, studentList.Data.NSStudentPerformance);
}

export function* studentDailySummaryCS(data) {
  let { studentId, successFunction } = data;
  let studentDailySummary = yield call(AllService.studentDailySummaryCS, studentId);
  yield call(successFunction, studentDailySummary.Data.DetailsPerDate);
}

export function* studentDetailsSummaryCS(data) {
  let { studentId, successFunction } = data;
  let studentDetailsSummary = yield call(AllService.studentDetailsSummaryCS, studentId);
  yield call(successFunction, studentDetailsSummary);
}

export function* getAssignments(data) {
  const { LoginID, GroupID, successFunction } = data;
  const assignmentsData = yield call(AllService.getAssignments, LoginID, GroupID);
  yield call(successFunction, assignmentsData.Data[0].GetNSAssignmentsByTeacherClassResult);
  yield put(Creators.setAssignmentList(assignmentsData.Data[0].GetNSAssignmentsByTeacherClassResult));

}

export function* createAssignment(data) {
  const { assignment, successFunction } = data;
  const serviceResponse = yield call(
    AllService.createAssignment,
    assignment.AssignmentName,
    assignment.Desc,
    assignment.TargetDate,
    assignment.TargetTime,
    assignment.Students,
    assignment.ClassID,
    assignment.ConceptID,
    assignment.SkillID,
    assignment.Tiles,
    assignment.StartingNumber,
    assignment.Order,
    assignment.Layout,
    assignment.ShowNumber,
    assignment.MinRed,
    assignment.MinTime,
    assignment.MinGreen,
    assignment.SuperGroupID,
    assignment.LoginID
  );
  yield call(successFunction, serviceResponse);
}

export function* getConcepts(data) {
  const { domainID, successFunction } = data;
  const conceptData = yield call(AllService.getConcepts, domainID);
  yield call(successFunction, conceptData.Data[0].listConcepts);
}

export function* getSkills(data) {
  const { ConceptID, successFunction } = data;
  const skillsData = yield call(AllService.getSkills, ConceptID);
  yield call(successFunction, skillsData.Data[0].listSkills);
}

export function* getAssignmentDetails(data) {
  const { AssignmentID, LoginID, successFunction } = data;
  const assignmentsDetails = yield call(AllService.getAssignmentDetails, AssignmentID, LoginID);
  yield call(successFunction, assignmentsDetails.Data[0].GetNSAssignmentViewByTeacherResult);
}

export function* getAssignmentReport(data) {
  const { LoginID, AssignmentID, successFunction } = data;
  const assignmentsReport = yield call(AllService.getAssignmentReport, LoginID, AssignmentID);
  yield call(successFunction, assignmentsReport.Data.GetNSAssignmentAnalyticsResult);
}

export function* deleteAssignment(data) {
  const { LoginID, AssignmentID, successFunction } = data;
  const assignment = yield call(AllService.deleteAssignment, LoginID, AssignmentID);
  yield call(successFunction, assignment);
}

export function* getStudentList(data) {
  const { ClassID, successFunction } = data;
  const studentList = yield call(AllService.getStudentList, ClassID);
  yield call(successFunction, studentList.Data);
}

export function* classLbPopup(data) {
  const { classLbData, successFunction } = data;
  const serviceResponse = yield call(AllService.classLbPopup, classLbData.GroupID, classLbData.FromDate, classLbData.ToDate, classLbData.Category);
  yield call(successFunction, serviceResponse.Data);
}

export function* schoolLbPopup(data) {
  const { schoolLbData, successFunction } = data;
  const serviceResponse = yield call(
    AllService.schoolLbPopup,
    schoolLbData.StudentID,
    schoolLbData.FromDate,
    schoolLbData.ToDate,
    schoolLbData.Category
  );
  yield call(successFunction, serviceResponse.Data);
}
