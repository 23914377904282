import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Autocomplete,
  Tooltip,
  Stack,
  Chip
} from '@mui/material';
import allActions from "../../redux/ducks/actions";
import "./ManageUsers.css";
import { GridActionsCellItem, } from "@mui/x-data-grid";
import { Edit } from '@carbon/icons-react';

function UsersLoginTable({ selectOption }) {
  const editAction = "#b58119";
  const history = useHistory();
  const dispatch = useDispatch(); //dispatch
  const { userInfo, listAllSchools, schoolData } = useSelector((state) => state.data);
  const [tableRow, setTableRow] = useState([]);
  const [schoolNameOptions, setSchoolNameOptions] = useState({});

  const [value, setValue] = React.useState(null);

  const defaultData = {
    options: [],
    getOptionLabel: (option) => option,
  };

  useEffect(() => {
    if (userInfo && userInfo.UserType === "DistrictAdmin") {
      dispatch(allActions.getAllSchoolList(userInfo.DomainID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userInfo && (
      userInfo.UserType === "Supergrouptechadmin" ||
      userInfo.UserType === "restrictedschooladmin"
    )) {
      dispatch(allActions.getAllUserList(userInfo.SuperGroupID, (response) => {
        let rowData = response;
        rowData.map((data, index) => {
          if (data.ActiveFlag === 1) {
            rowData[index].ActiveFlag = "Active";
          } else {
            rowData[index].ActiveFlag = "Inactive";
          }
          return null;
        })
        setTableRow(rowData);
      }));
    } else {
      if (listAllSchools && listAllSchools.length > 0) {
        let allSchoolNameList = [];
        listAllSchools && listAllSchools.map(data => {
          allSchoolNameList.push({
            label: data.Name,
            id: data.ID
          });
          return null;
        })
        let defaultProps = {
          options: allSchoolNameList,
          getOptionLabel: (option) => option && option.label && (option.label).trim()
        };
        setSchoolNameOptions(defaultProps);
        if (schoolData) {
          setValue(schoolData)
        } else {
          setValue(allSchoolNameList[0]);
          dispatch(allActions.saveCurrentSchool(allSchoolNameList[0]))
        }
        dispatch(allActions.getAllUserList(allSchoolNameList[0].id, (response) => {
          let rowData = response;
          rowData.map((data, index) => {
            if (data.ActiveFlag === 1) {
              rowData[index].ActiveFlag = "Active";
            } else {
              rowData[index].ActiveFlag = "Inactive";
            }
            return null;
          })
          setTableRow(rowData);
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])

  useEffect(() => {
    if (userInfo && userInfo.UserType === "DistrictAdmin" && selectOption) {
      dispatch(allActions.getAllUserList(selectOption.id, (response) => {
        let rowData = response;
        rowData.map((data, index) => {
          if (data.ActiveFlag === 1) {
            rowData[index].ActiveFlag = "Active";
          } else {
            rowData[index].ActiveFlag = "Inactive";
          }
          return null;
        })
        setTableRow(rowData);
      }));
    }
  }, [selectOption, dispatch, userInfo])

  const columns = [
    {
      label: "User Name",
      options: {
        filter: false,
      }
    },
    {
      label: "First Name",
      options: {
        filter: false,
      }
    },
    {
      label: "Last Name",
      options: {
        filter: false,
      }
    },
    {
      label: "User Type",
    },
    {
      label: "Status",
      options: {
        filter: true,
        filterList: ['Active'],
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                cursor: "pointer",
              }}
            >
              {
                value === "Active" ? <Chip label="Active" color="success" /> :
                  <Chip label="Inactive" color="error" />
              }
            </Stack>
          )
        }
      }
    },
    {
      label: userInfo.UserType === "restricteddistrictadmin" || userInfo.UserType === "restrictedschooladmin" || userInfo.SourceID !== "" ? "" : "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (userInfo.UserType === "restricteddistrictadmin" || userInfo.UserType === "restrictedschooladmin" || userInfo.SourceID !== "") {
            return null;
          }
          return (
            <Box className="actionWrapper">
              {
                (userInfo && userInfo.UserType === "SuperGroupAdmin") ? null : (
                  <Tooltip
                    title="Edit"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          background: "#0e1318",
                        },
                      },
                    }}
                    onClick={() => {
                      let rowId = value;
                      if (userInfo && userInfo.UserType === "Supergrouptechadmin" ||
                        userInfo.UserType === "restrictedschooladmin"
                      ) {
                        history.push(`school-user-logins/update/${rowId}`);
                      } else {
                        history.push(`district-user-logins/update/${rowId}`);
                      }
                    }}
                  >
                    <Box className='customActionIcon'>
                      <Edit size={16} color='#FFF' />
                    </Box>
                  </Tooltip>
                )
              }
            </Box>
          )
        }
      }
    }
  ];
  const options = {
    selectableRows: false,
    filter: true,
    download: false,
    print: false,
    rowsPerPage: 25,
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'success',
        variant: 'outlined',
      };
    },
  };
  return (
    <Box>
      <MUIDataTable
        title={"User Logins"}
        data={tableRow ? tableRow.map(data => {
          return [
            data.UserName,
            data.FName,
            data.LName,
            data.UserType === "GroupAdmin" ? "Teacher"
              : data.UserType === "SuperGroupAdmin" ? "Principal"
                : data.UserType === "Supergrouptechadmin" ? "School Admin"
                  : data.UserType === "restrictedschooladmin" ? "Restricted School Admin"
                    : data.UserType,
            data.ActiveFlag,
            data.UserID,
          ]
        }) : []}
        columns={columns}
        options={options}
      />
    </Box>
  );
}
export default UsersLoginTable;
