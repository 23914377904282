import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../redux/ducks/actions";
import './AdminDashboard.css';
import CardContainer from './Reg&Sub_Section/CardContainer';
import PerformContainer from './Performance_Section/PerformContainer';
import { Typography, Stack, Backdrop, CircularProgress } from '@mui/material';
import Logo from "../../constants/images";
import DashboardIcon from '@mui/icons-material/Dashboard';
import CustomHeader from '../../Components/CustomHeader';
import CustomWelcomeCard from '../../Components/CustomWelcomeCard';
import { formatDateToday } from '../../constants';
import { User } from '@carbon/icons-react';
import { Column, Grid } from '@carbon/react';

//API

const mdTheme = createTheme();
export default function AdminDashboard() {
    const dispatch = useDispatch();
    const [adminData, setAdminData] = useState([]);
    const { userInfo,token } = useSelector((state) => state.data);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if(Object.keys(token).length>0){
            dispatch(allActions.getAdminData((response) => {
                dispatch(allActions.getAllProductsList());
                setAdminData(response);
                setLoading(false);
            }));
        }   
    }, [dispatch,token]);
    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    return (
        <ThemeProvider theme={mdTheme}>
            <Box className='districtStyle'>
                <Backdrop
                    sx={{
                        background: "rgb(0 0 0 / 30%)",
                        color: '#fff',
                        zIndex: "99999999"
                    }}
                    open={loading}
                >
                    <CircularProgress style={{ color: "#008000" }} />
                </Backdrop>
                <CustomHeader
                    hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
                    licenseStatus={licenseStatus}
                    headerLabel={"Admin Dashboard"}
                />

                <Box className='nsDBMainContent'>
                    <Box className="nsDBMainHeader">
                        <Box className="nsDBMainTextHeader">
                            Registrations
                        </Box>
                        <Box className='nsDBPhase1CardWrapper'>
                            <CardContainer name={"Registrations"} data={adminData} />
                        </Box>
                    </Box>
                    <Box className="nsDBMainHeader">
                        <Box className="nsDBMainTextHeader">
                            Subscriptions
                        </Box>
                        <Box className='nsDBPhase1CardWrapper'>
                            <CardContainer name={"Subscriptions"} data={adminData} />
                        </Box>
                    </Box>

                    <Box className="nsDBMainHeader1">
                        <Box className="nsDBMainTextHeader">
                            Performance
                        </Box>
                        <Box className='nsDBPhase1CardWrapper'>
                            <PerformContainer data={adminData} />

                            {/* <Grid className='nsAdminPadding'>
                                <Column lg={4} md={8} sm={4}>
                                    <Box className='nsADBCardWrapper'>
                                        <Box className='nsADBCardText1'>4567</Box>
                                        <Box className='nsADBCardText2'>Minutes Played</Box>
                                    </Box>
                                </Column>
                                <Column lg={4} md={8} sm={4}>
                                    <Box className='nsADBCardWrapper'>
                                        <Box className='nsADBCardText1'>4567</Box>
                                        <Box className='nsADBCardText2'>Minutes Played</Box>
                                    </Box>
                                </Column>
                                <Column lg={4} md={8} sm={4}>
                                    <Box className='nsADBCardWrapper'>
                                        <Box className='nsADBCardText1'>4567</Box>
                                        <Box className='nsADBCardText2'>Minutes Played</Box>
                                    </Box>
                                </Column>
                                <Column lg={4} md={8} sm={4}>
                                    <Box className='nsADBCardWrapper'>
                                        <Box className='nsADBCardText1'>4567</Box>
                                        <Box className='nsADBCardText2'>Minutes Played</Box>
                                    </Box>
                                </Column>
                            </Grid> */}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
