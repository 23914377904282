import { createReducer } from "reduxsauce";
import { ActionTypes } from "./actions";
import { INITIAL_STATE } from "./initialState";
import { LOGIN_SUCCESS_MESSAGE } from "../../constants";


// Number Sense React
export const userAuthToken = (state, { Token }) => {
  console.log(Token, "console Token");
  return {
    ...state,
    token: Token,
  };
};
export const updateToken = (state, { Token }) => {
  console.log(Token, "console Token");
  console.log(state, "console state");
  let newState = { ...state, token: Token };
  return newState;
};
export const userLoginSuccessMessage = (state, { successMessage }) => ({
  ...state,
  userInfo: successMessage,
  logInOutMessage: LOGIN_SUCCESS_MESSAGE,
});

export const userLoginFailureMessage = (state, { errorMessage }) => ({
  ...state,
  userInfo: {},
  logInOutMessage: errorMessage,
});

export const notificationLength = (state, { length }) => ({
  ...state,
  notificationLength: length,
})

export const setDistrictList = (state, { districtList }) => ({
  ...state,
  listAllDistrict: districtList,
});

export const setLogoutStatus = (state, { logoutstatus }) => ({
  ...state,
  userInfo: {},
  logInOutMessage: logoutstatus,
});

export const setDistrictDashboardData = (state, { districtDashboardData }) => ({
  ...state,
  districtDashboardData: districtDashboardData,
});

export const setSchoolList = (state, { schoolList }) => ({
  ...state,
  listAllSchools: schoolList
})

export const setClassList = (state, { classList }) => ({
  ...state,
  listAllClasses: classList
})

export const setAssignmentClassId = (state, { assignmentClassId }) => ({
  ...state,
  assignmentClassId: assignmentClassId
})

export const setAssignmentList = (state, { assignmentList }) => ({
  ...state,
  assignmentList: assignmentList
})

export const setProductsList = (state, { productsList }) => ({
  ...state,
  listAllProducts: productsList,
});

export const setClassId = (state, { classId }) => ({
  ...state,
  schoolUsageData: {
    ...state.schoolUsageData,
    classId
  }
})

export const setSchoolName = (state, { schoolName }) => ({
  ...state,
  schoolUsageData: {
    ...state.schoolUsageData,
    schoolName
  }
})

export const setStudentId = (state, { studentId }) => ({
  ...state,
  schoolUsageData: {
    ...state.schoolUsageData,
    studentId
  }
})

export const setSchoolId = (state, { schoolId }) => ({
  ...state,
  schoolUsageData: {
    ...state.schoolUsageData,
    schoolId
  }
})
export const setUserLicenseList = (state, { userLicenseList }) => ({
  ...state,
  listAllUserLicense: userLicenseList,
});

export const setDistrictLicenseList = (state, { districtLicenseList }) => ({
  ...state,
  listAllDistrictLicense: districtLicenseList,
});

export const setNewNotifications = (state, { newNotifications }) => ({
  ...state,
  listNewNotifications: newNotifications,
});

export const setAllNotifications = (state, { allNotifications }) => ({
  ...state,
  listAllNotifications: allNotifications,
});

export const setClassLicenseList = (state, { classLicenseList }) => ({
  ...state,
  listAllClassLicense: classLicenseList,
});

export const setUserList = (state, { usersList }) => ({
  ...state,
  listAllUsersList: usersList,
});

export const setSchoolDashboardData = (state, { schoolDashboardData }) => ({
  ...state,
  schoolDashboardData: schoolDashboardData,
});

export const setClassDetails = (state, { schoolId, className, serviceResponse }) => ({
  ...state,
  classUsageData: {
    ...state.classUsageData,
    schoolId,
    className,
    classDetails: serviceResponse,
  }
});

export const setTeacherId = (state, { LoginID }) => ({
  ...state,
  schoolUsageData: {
    ...state.schoolUsageData,
    LoginID
  }
})

export const setStudentName = (state, { name }) => ({
  ...state,
  studentUsageData: {
    ...state.studentUsageData,
    studentName: name
  }
});

export const setTeacherDashboardData = (state, { teacherDashboardData }) => ({
  ...state,
  teacherDashboardData: teacherDashboardData,
});

export const saveCurrentSchool = (state, { schoolData }) => ({
  ...state,
  schoolData: schoolData,
});

export const saveCurrentSchoolInClass = (state, { schoolData }) => ({
  ...state,
  classSchoolData: schoolData,
});

export const changeTeacherNumber = (state, { data }) => ({
  ...state,
  teacherNumber: data,
});

export const changeTeacherStatus = (state, { data }) => ({
  ...state,
  teacherStatus: data,
});

export const reducer = createReducer(INITIAL_STATE, {
  [ActionTypes.USER_LOGIN_SUCCESS_MESSAGE]: userLoginSuccessMessage,
  [ActionTypes.USER_LOGIN_FAILURE_MESSAGE]: userLoginFailureMessage,
  [ActionTypes.USER_AUTH_TOKEN]: userAuthToken,
  // Number Sense React
  [ActionTypes.SET_DISTRICT_LIST]: setDistrictList,
  [ActionTypes.SET_LOGOUT_STATUS]: setLogoutStatus,
  [ActionTypes.SET_DISTRICT_DASHBOARD_DATA]: setDistrictDashboardData,
  [ActionTypes.SET_SCHOOL_LIST]: setSchoolList,
  [ActionTypes.SET_CLASS_LIST]: setClassList,
  [ActionTypes.SET_CLASS_ID]: setClassId,
  [ActionTypes.SET_SCHOOL_NAME]: setSchoolName,
  [ActionTypes.SET_STUDENT_ID]: setStudentId,
  [ActionTypes.SET_SCHOOL_ID]: setSchoolId,
  [ActionTypes.SET_USER_LICENSE_LIST]: setUserLicenseList,
  [ActionTypes.SET_NEW_NOTIFICATIONS]: setNewNotifications,
  [ActionTypes.SET_CLASS_LICENSE_LIST]: setClassLicenseList,
  [ActionTypes.SET_PRODUCTS_LIST]: setProductsList,
  [ActionTypes.SET_USER_LIST]: setUserList,
  [ActionTypes.SET_SCHOOL_DASHBOARD_DATA]: setSchoolDashboardData,
  // [ActionTypes.SET_CLASS_NAME]: setClassName,
  [ActionTypes.NOTIFICATION_LENGTH]: notificationLength,
  [ActionTypes.SET_DISTRICT_LICENSE_LIST]: setDistrictLicenseList,
  [ActionTypes.SET_TEACHER_ID]: setTeacherId,
  [ActionTypes.SET_CLASS_DETAILS]: setClassDetails,

  [ActionTypes.SET_TEACHER_DASHBOARD_DATA]: setTeacherDashboardData,
  [ActionTypes.SET_STUDENT_NAME]: setStudentName,
  [ActionTypes.SAVE_CURRENT_SCHOOL]: saveCurrentSchool,
  [ActionTypes.SAVE_CURRENT_SCHOOL_IN_CLASS]: saveCurrentSchoolInClass,
  [ActionTypes.SET_ASSIGNMENT_CLASS_ID]: setAssignmentClassId,
  [ActionTypes.SET_ASSIGNMENT_LIST]: setAssignmentList,

  [ActionTypes.CHANGE_TEACHER_NUMBER]: changeTeacherNumber,
  [ActionTypes.CHANGE_TEACHER_STATUS]: changeTeacherStatus,
  [ActionTypes.UPDATE_TOKEN]: updateToken,
});