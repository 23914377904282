import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Backdrop,
    Breadcrumbs,
    Typography,
    Tooltip,
} from '@mui/material';
import AllActions from "../../redux/ducks/actions";
import { Link, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import Creators from "../../redux/ducks/actions";
import BadgeIcon from '@mui/icons-material/Badge';
import CircularProgress from '@mui/material/CircularProgress';
import CustomHeader from "../../Components/CustomHeader";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Education, User } from "@carbon/icons-react";


function StudentDailySummary(props) {
    const dispatch = useDispatch(); //dispatch
    const { userInfo, studentUsageData, classUsageData } = useSelector((state) => state.data);
    const [tableRow, setTableRow] = useState([]);
    let url = useParams();
    const history = useHistory(); //history
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(AllActions.studentDailySummaryCS(Number(url.id), (response) => {
            console.log(response);
            setTableRow(response);
            dispatch(Creators.setClassId(Number(url.id)));
            setLoading(false);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        "Date",
        "Minutes Played",
        "Problems Solved",
        "Games Completed",
        "Levels Mastered",
    ];

    const options = {
        selectableRows: false,
        filter: false,
        download: false,
        print: false,
        rowsPerPage: 25,
    };

    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    const welcomCardData = []

    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: "99999999"
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            <CustomHeader
                licenseStatus={licenseStatus}
                needBreadCrumb={true}
                previousPageLabel={"Student Usage Data"}
                currentPageLabel={"Student Daily Summary"}
                onClickPrevPageLabel={() => { history.push("/teacher/teacher-student-usage-data") }}
            />
            <CustomWelcomeCard
                welcomCardData={welcomCardData}
                cardLabel={"Student Name"}
                cardValue={`${studentUsageData.studentName}`}
            />

            <Box className="nsMainContent">
                < MUIDataTable
                    title={`Daily Summary`}
                    data={tableRow ? tableRow.map(data => {
                        return [
                            data.date,
                            data.minutes,
                            data.problems,
                            data.games,
                            data.levels,
                        ]
                    }) : []}
                    columns={columns}
                    options={options}
                />
            </Box>
        </Box >
    );
}
export default StudentDailySummary;

