import { Button, Toggle } from "@carbon/react";
import PropTypes from "prop-types";

export default function CustomButton({
    text,
    isDisabled,
    onHandleClick,
    size,
    className,
    kind,
    color,
    background,
    margin,
}) {
    if (kind === "disabled") {
        return (
            <Button
                htmlFor={"toggle-1"}
                kind={
                    kind === "primary" ? "primary"
                        : kind === "secondary" ? "secondary"
                            : kind === "danger" ? "danger"
                                : "ghost"
                }
                className={className || ""}
                size={
                    size === "2xl" ? "2xl" :
                        size === "lg" ? "lg" :
                            size === "md" ? "md" :
                                size === "sm" ? "sm" : "xl"
                }
                disabled={isDisabled}
                onClick={onHandleClick}
            >
                <div className="flex-v-center">
                    <Toggle
                        labelA=""
                        labelB=""
                        id="toggle-1"
                    />
                    <div>{text}</div>
                </div>


            </Button>
        );
    }
    return (
        <Button
            kind={
                kind === "primary" ? "primary"
                    : kind === "secondary" ? "secondary"
                        : kind === "danger" ? "danger"
                            : "ghost"
            }
            className={className || ""}
            size={
                size === "2xl" ? "2xl" :
                    size === "lg" ? "lg" :
                        size === "md" ? "md" :
                            size === "sm" ? "sm" : "xl"
            }
            onClick={onHandleClick}
            disabled={isDisabled}
        >
            {text}
        </Button>
    );
}

CustomButton.propTypes = {
    text: PropTypes.string,
    isDisabled: PropTypes.bool,
    onHandleClick: PropTypes.func,
    color: PropTypes.string,
    size: PropTypes.string,
    background: PropTypes.string,
    margin: PropTypes.string,
}