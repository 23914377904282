import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Stack,
  Breadcrumbs,
  Typography,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import allActions from "../../redux/ducks/actions";
import { Link, useHistory } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useParams } from "react-router-dom";
import SummarizeIcon from '@mui/icons-material/Summarize';
import BadgeIcon from '@mui/icons-material/Badge';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import CustomHeader from "../../Components/CustomHeader";
import { Backdrop } from "@mui/material";
import { ChartBar, ChartLine, Report } from "@carbon/icons-react";

function ClassSummary(props) {
  const dispatch = useDispatch(); //dispatch
  const { userInfo, schoolUsageData } = useSelector((state) => state.data);
  const [tableRow, setTableRow] = useState([]);
  const url = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(allActions.classSUD(Number(url.id), (response) => {
      setTableRow(response);
      setLoading(false);
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])


  const columns = [
    "Class Name",
    "Games Completed",
    "Levels Mastered",
    "Minutes Played",
    "Problems Solved",
    {
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box className="actionWrapper">
              <Tooltip
                title="Student List"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value;
                  dispatch(allActions.setClassId(rowId));
                  history.push(`/district/district-school-usage-data/student-list/${rowId}`);
                }}
              >
                <Box className='customActionIcon'>
                  <Report size={16} color='#FFF' />
                </Box>
              </Tooltip>

              <Tooltip
                title="Class Daily Summary"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value;
                  dispatch(allActions.setClassId(rowId));
                  history.push(`/district/district-school-usage-data/class-daily-summary/${rowId}`);
                }}
              >
                <Box className='customActionIcon'>
                  <ChartBar size={16} color='#FFF' />
                </Box>
              </Tooltip>

              <Tooltip
                title="Class Activity Summary"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value;
                  dispatch(allActions.setClassId(rowId));
                  history.push(`/district/district-school-usage-data/class-activity-summary/${rowId}`);
                }}
              >
                <Box className='customActionIcon'>
                  <ChartLine size={16} color='#FFF' />
                </Box>
              </Tooltip>
            </Box>
          )
        }
      }
    }
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };
  return (
    <Box className='districtStyle'>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: "99999999"
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>
      <CustomHeader
        licenseStatus={licenseStatus}
        needBreadCrumb={true}
        previousPageLabel={`${schoolUsageData.schoolName} Dashboard`}
        currentPageLabel={"Class Summary"}
        onClickPrevPageLabel={() => {
          let navigateTo = `/district/district-school-usage-data/school-details/${Number(url.id)}`;
          history.push(navigateTo);
        }}
      />

      <CustomWelcomeCard
        welcomCardData={[]}
        cardLabel={"School Name"}
        cardValue={schoolUsageData.schoolName}
      />

      <Box className="nsMainContent">
        <MUIDataTable
          title={"Class Summary"}
          data={tableRow ? tableRow.map(data => {
            return [
              data.GroupName,
              data.LevelsAttempted,
              data.LevelsMastered,
              data.MinutesPlayed,
              data.ProblemsCompleted,
              data.GroupID,
            ]
          }) : []}
          columns={columns}
          options={options}
        />
      </Box>
    </Box >
  );
}
export default ClassSummary;
