import * as React from 'react';
import Logo from "../constants/images";
import { CaretLeft, CaretRight } from '@carbon/icons-react';

export default function CustomBrandName({
    layoutName,
    isMenuOpen,
    onMenuToggle
}) {
    return (
        <div className='customBrandWrapper'>
            {
                isMenuOpen ? (
                    <React.Fragment>
                        <img alt="logo" src={Logo.logo} className="newLogoStyle" />
                        <div className='brandWrapper'>
                            <div className='brandText1'>{layoutName}</div>
                            <div className='brandText2'>Console</div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <img alt="logo" src={Logo.logo} className="newLogoStyleMobile" />
                    </React.Fragment>
                )
            }

            <div className='menuToggle' onClick={onMenuToggle}>
                {isMenuOpen ? <CaretLeft size={24} /> : <CaretRight size={24} />}
            </div>
        </div>
    );
}