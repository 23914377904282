import { takeLatest, all } from "redux-saga/effects";
import { ActionTypes } from "../ducks/actions";
import {
  // Number Sense React
  classlinkLogin,
  userLogin,
  classLogin,
  userLogout,
  getAllDistrictList,
  putDistrict,
  putClasslinkDistrict,
  getDistrictByID,
  updateDistrictById,
  updateClassLinkById,
  getAllProductsList,
  putProducts,
  getProductsByID,
  updateProductsById,
  getAllClassLicense,
  updateClassSubscription,
  getAdminData,
  getDistrictDashboardData,
  getAllSchoolList,
  putSchools,
  getSchoolsById,
  updateSchoolsById,
  schoolsBulkUpload,
  getAllClassList,
  putClasses,
  getClassesById,
  updateClassesById,
  classesBulkUpload,
  getClassDetails,
  bulkUploadNSTeacherClassMapping,
  schoolUsageDataSchoolList,
  schoolUsageDataSchoolDashboard,
  dailySUD,
  detailsSUD,
  classSUD,
  studentsSummaryCS,
  studentsSummaryDailyAndActivity,
  classDailySummaryCS,
  classActivitySummaryCS,
  getDistrictLicProductHistory,
  updateSubscription,
  manageDistrictLicenceSubscription,
  getUserLicenseList,
  getDistrictLicenseList,
  getUserDistrictProductHistory,
  userDistrictUpdate,
  getNewNotifications,
  getAllNotifications,
  getStudentLeaderboardData,
  getClassLeaderboardData,
  getAllUserList,
  getClassBySchoolId,
  putUser,
  getUsersById,
  updateUsersById,
  districtWeeklyReport,
  schoolWeeklyReport,
  getSchoolDashboardData,
  activitySAD,
  detailsSAD,
  classUsageDataSchoolList,
  classUsageDataSchoolDashboard,
  schoolClassDailySummaryCS,
  schoolClassDetailsSummaryCS,
  deleteNotifications,
  activeAndDeactive,
  districtDetails,
  deleteProduct,
  getPurchaseHistory,
  districtAdminNotifications,
  districtAdminViewAllNotif,
  teacherUsageDataList,
  teacherUsageDataDashboard,
  getTeacherDashboardData,
  districtDashboardDailySummary,
  districtDashboardActivitySummary,
  getRestrictedAdminUsersList,
  userLoginSchoolsBulkUpload,
  userLoginClassesBulkUpload,
  getRestrictedAdminUsersUsersById,
  updateRestrictedAdminUsersById,
  classlinkSchoolUpload,
  classlinkClassUpload,
  classlinkUserUpload,
  classlinkUserMaping,
  teacherClassDailySummaryCS,
  teacherClassDetailsSummaryCS,
  classListTeacher,
  classWeeklyReport,
  studentUsageDataList,
  studentDailySummaryCS,
  studentDetailsSummaryCS,
  getAssignments,
  getConcepts,
  getSkills,
  getAssignmentDetails,
  getAssignmentReport,
  deleteAssignment,
  createAssignment,
  getStudentList,
  classLbPopup,
  schoolLbPopup,
} from "./allSagaFunctions";

export default function* rootSaga() {
  yield all([
    // Number Sense React
    takeLatest(ActionTypes.CLASSLINK_LOGIN, classlinkLogin),
    takeLatest(ActionTypes.USER_LOGIN, userLogin),
    takeLatest(ActionTypes.CLASS_LOGIN, classLogin),
    takeLatest(ActionTypes.USER_LOGOUT, userLogout),
    takeLatest(ActionTypes.GET_ALL_DISTRICT_LIST, getAllDistrictList),
    takeLatest(ActionTypes.PUT_DISTRICT, putDistrict),
    takeLatest(ActionTypes.PUT_CLASSLINK_DISTRICT, putClasslinkDistrict),
    
    takeLatest(ActionTypes.GET_DISTRICT_BY_ID, getDistrictByID),
    takeLatest(ActionTypes.UPDATE_DISTRICT_BY_ID, updateDistrictById),
    takeLatest(ActionTypes.UPDATE_CLASS_LINK_BY_ID, updateClassLinkById),
    
    takeLatest(ActionTypes.GET_ALL_PRODUCTS_LIST, getAllProductsList),
    takeLatest(ActionTypes.PUT_PRODUCTS, putProducts),
    takeLatest(ActionTypes.GET_PRODUCTS_BY_ID, getProductsByID),
    takeLatest(ActionTypes.UPDATE_PRODUCTS_BY_ID, updateProductsById),
    takeLatest(ActionTypes.GET_ALL_CLASS_LICENSE, getAllClassLicense),
    takeLatest(ActionTypes.UPDATE_CLASS_SUBSCRIPTION, updateClassSubscription),
    takeLatest(ActionTypes.GET_ADMIN_DATA, getAdminData),
    takeLatest(ActionTypes.GET_DISTRICT_DASHBOARD_DATA, getDistrictDashboardData),
    takeLatest(ActionTypes.GET_ALL_SCHOOL_LIST, getAllSchoolList),
    takeLatest(ActionTypes.PUT_SCHOOLS, putSchools),
    takeLatest(ActionTypes.GET_SCHOOLS_BY_ID, getSchoolsById),
    takeLatest(ActionTypes.UPDATE_SCHOOLS_BY_ID, updateSchoolsById),
    takeLatest(ActionTypes.SCHOOLS_BULK_UPLOAD, schoolsBulkUpload),
    takeLatest(ActionTypes.GET_ALL_CLASS_LIST, getAllClassList),
    takeLatest(ActionTypes.PUT_CLASSES, putClasses),
    takeLatest(ActionTypes.GET_CLASSES_BY_ID, getClassesById),
    takeLatest(ActionTypes.UPDATE_CLASSES_BY_ID, updateClassesById),
    takeLatest(ActionTypes.CLASSES_BULK_UPLOAD, classesBulkUpload),
    takeLatest(ActionTypes.GET_CLASS_DETAILS, getClassDetails),
    takeLatest(ActionTypes.BULK_UPLOAD_NS_TEACHER_CLASS_MAPPING, bulkUploadNSTeacherClassMapping),
    takeLatest(ActionTypes.SCHOOL_USAGE_DATA_SCHOOL_LIST, schoolUsageDataSchoolList),
    takeLatest(ActionTypes.SCHOOL_USAGE_DATA_SCHOOL_DASHBOARD, schoolUsageDataSchoolDashboard),
    takeLatest(ActionTypes.DAILY_SUD, dailySUD),
    takeLatest(ActionTypes.DETAILS_SUD, detailsSUD),
    takeLatest(ActionTypes.CLASS_SUD, classSUD),
    takeLatest(ActionTypes.STUDENTS_SUMMARY_CS, studentsSummaryCS),
    takeLatest(ActionTypes.STUDENTS_SUMMARY_DAILY_AND_ACTIVITY, studentsSummaryDailyAndActivity),
    takeLatest(ActionTypes.CLASS_DAILY_SUMMARY_CS, classDailySummaryCS),
    takeLatest(ActionTypes.CLASS_ACTIVITY_SUMMARY_CS, classActivitySummaryCS),
    takeLatest(ActionTypes.BULK_UPLOAD_NS_TEACHER_CLASS_MAPPING, bulkUploadNSTeacherClassMapping),
    takeLatest(ActionTypes.GET_DISTRICT_LIC_PRODUCT_HISTORY, getDistrictLicProductHistory),
    takeLatest(ActionTypes.UPDATE_SUBSCRIPTION, updateSubscription),
    takeLatest(ActionTypes.GET_USER_LICENSE_LIST, getUserLicenseList),
    takeLatest(ActionTypes.GET_DISTRICT_LICENSE_LIST, getDistrictLicenseList),
    takeLatest(ActionTypes.GET_USER_DISTRICT_PRODUCT_HISTORY, getUserDistrictProductHistory),
    takeLatest(ActionTypes.USER_DISTRICT_UPDATE, userDistrictUpdate),
    takeLatest(ActionTypes.GET_NEW_NOTIFICATIONS, getNewNotifications),
    takeLatest(ActionTypes.GET_ALL_NOTIFICATIONS, getAllNotifications),
    takeLatest(ActionTypes.GET_STUDENT_LEADERBOARD_DATA, getStudentLeaderboardData),
    takeLatest(ActionTypes.GET_CLASS_LEADERBOARD_DATA, getClassLeaderboardData),
    takeLatest(ActionTypes.GET_ALL_USER_LIST, getAllUserList),
    takeLatest(ActionTypes.GET_CLASS_BY_SCHOOL_ID, getClassBySchoolId),
    takeLatest(ActionTypes.PUT_USER, putUser),
    takeLatest(ActionTypes.GET_USERS_BY_ID, getUsersById),
    takeLatest(ActionTypes.UPDATE_USERS_BY_ID, updateUsersById),
    takeLatest(ActionTypes.GET_SCHOOL_DASHBOARD_DATA, getSchoolDashboardData),
    takeLatest(ActionTypes.ACTIVITY_SAD, activitySAD),
    takeLatest(ActionTypes.DETAILS_SAD, detailsSAD),
    takeLatest(ActionTypes.CLASS_USAGE_DATA_SCHOOL_LIST, classUsageDataSchoolList),
    takeLatest(ActionTypes.CLASS_USAGE_DATA_SCHOOL_DASHBOARD, classUsageDataSchoolDashboard),
    takeLatest(ActionTypes.SCHOOL_CLASS_DAILY_SUMMARY_CS, schoolClassDailySummaryCS),
    takeLatest(ActionTypes.SCHOOL_CLASS_DETAILS_SUMMARY_CS, schoolClassDetailsSummaryCS),
    takeLatest(ActionTypes.DISTRICT_WEEKLY_REPORT, districtWeeklyReport),
    takeLatest(ActionTypes.SCHOOL_WEEKLY_REPORT, schoolWeeklyReport),
    takeLatest(ActionTypes.DELETE_NOTIFICATIONS, deleteNotifications),
    takeLatest(ActionTypes.MANAGE_DISTRICT_LICENCE_SUBSCRIPTION, manageDistrictLicenceSubscription),
    takeLatest(ActionTypes.ACTIVE_AND_DEACTIVE, activeAndDeactive),
    takeLatest(ActionTypes.DISTRICT_DETAILS, districtDetails),
    takeLatest(ActionTypes.DELETE_PRODUCT, deleteProduct),
    takeLatest(ActionTypes.GET_PURCHASE_HISTORY, getPurchaseHistory),
    takeLatest(ActionTypes.DISTRICT_ADMIN_NOTIFICATIONS, districtAdminNotifications),
    takeLatest(ActionTypes.DISTRICT_ADMIN_VIEW_ALL_NOTIF, districtAdminViewAllNotif),
    takeLatest(ActionTypes.TEACHER_USAGE_DATA_LIST, teacherUsageDataList),
    takeLatest(ActionTypes.TEACHER_USAGE_DATA_DASHBOARD, teacherUsageDataDashboard),
    takeLatest(ActionTypes.GET_TEACHER_DASHBOARD_DATA, getTeacherDashboardData),
    takeLatest(ActionTypes.TEACHER_CLASS_DAILY_SUMMARY_CS, teacherClassDailySummaryCS),
    takeLatest(ActionTypes.TEACHER_CLASS_DETAILS_SUMMARY_CS, teacherClassDetailsSummaryCS),


    takeLatest(ActionTypes.DISTRICT_DASHBOARD_DAILY_SUMMARY, districtDashboardDailySummary),
    takeLatest(ActionTypes.DISTRICT_DASHBOARD_ACTIVITY_SUMMARY, districtDashboardActivitySummary),
    takeLatest(ActionTypes.GET_RESTRICTED_ADMIN_USERS_LIST, getRestrictedAdminUsersList),
    takeLatest(ActionTypes.USER_LOGIN_SCHOOLS_BULK_UPLOAD, userLoginSchoolsBulkUpload),
    takeLatest(ActionTypes.USER_LOGIN_CLASSES_BULK_UPLOAD, userLoginClassesBulkUpload),
    takeLatest(ActionTypes.GET_RESTRICTED_ADMIN_USERS_USERS_BY_ID, getRestrictedAdminUsersUsersById),
    takeLatest(ActionTypes.UPDATE_RESTRICTED_ADMIN_USERS_BY_ID, updateRestrictedAdminUsersById),
    takeLatest(ActionTypes.CLASSLINK_SCHOOL_UPLOAD, classlinkSchoolUpload),
    takeLatest(ActionTypes.CLASSLINK_CLASS_UPLOAD, classlinkClassUpload),
    takeLatest(ActionTypes.CLASSLINK_USER_UPLOAD, classlinkUserUpload),
    takeLatest(ActionTypes.CLASSLINK_USER_MAPING, classlinkUserMaping),


  
    
    takeLatest(ActionTypes.CLASS_LIST_TEACHER, classListTeacher),

    takeLatest(ActionTypes.CLASS_WEEKLY_REPORT, classWeeklyReport),
    takeLatest(ActionTypes.STUDENT_USAGE_DATA_LIST, studentUsageDataList),
    takeLatest(ActionTypes.STUDENT_DAILY_SUMMARY_CS, studentDailySummaryCS),
    takeLatest(ActionTypes.STUDENT_DETAILS_SUMMARY_CS, studentDetailsSummaryCS),
    takeLatest(ActionTypes.GET_ASSIGNMENTS, getAssignments),
    takeLatest(ActionTypes.GET_CONCEPTS, getConcepts),
    takeLatest(ActionTypes.GET_SKILLS, getSkills),
    takeLatest(ActionTypes.GET_ASSIGNMENT_DETAILS, getAssignmentDetails),
    takeLatest(ActionTypes.GET_ASSIGNMENT_REPORT, getAssignmentReport),
    takeLatest(ActionTypes.DELETE_ASSIGNMENT, deleteAssignment),
    takeLatest(ActionTypes.CREATE_ASSIGNMENT, createAssignment),
    takeLatest(ActionTypes.GET_STUDENT_LIST, getStudentList),
    takeLatest(ActionTypes.CLASS_LB_POPUP, classLbPopup),
    takeLatest(ActionTypes.SCHOOL_LB_POPUP, schoolLbPopup),
    
  ]);

}
