import { create } from "apisauce";
import { Config as rootAPIURL } from "./api";
import configStore from "../configStore";
import Creators from "../ducks/actions";
import { message } from "antd";
import { createBrowserHistory } from "history";



const userApiClient = create({
  baseURL: rootAPIURL.PROD_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 2500000,
});
let isRefreshing = false; // Flag to prevent multiple reloads

userApiClient.addAsyncRequestTransform(async (request) => {
  // Wait for the refresh process to complete if it's ongoing
  if (refreshTokenPromise) {
    try {
      await refreshTokenPromise; // Wait for the new token to be available
    } catch (error) {
      console.error("Error during token refresh in request transform:", error);
      return; // Optionally handle the error, such as redirecting to login
    }
  }

  // Get the latest token from localStorage
  const AccessToken = localStorage.getItem("AccessToken");
  if (AccessToken) {
    request.headers["Authorization"] = `Bearer ${AccessToken}`;
  }
});

let refreshTokenPromise = null;
userApiClient.addAsyncResponseTransform(async (response) => {
  const history = createBrowserHistory();

  if (response.data.StatusCode === 401) {
    // Check if a refresh is already in progress
    if (!refreshTokenPromise) {
      isRefreshing = true;

      refreshTokenPromise = new Promise(async (resolve, reject) => {
        try {
          const refreshToken = localStorage.getItem("RefreshToken");
          let LoginID = JSON.parse(localStorage.getItem("userData")).LoginID;
          const payload = {
            UserID: LoginID,
            RefreshToken: refreshToken,
          };

          const retryApiClient = create({
            baseURL: response.config.baseURL,
            timeout: response.config.timeout,
          });

          const refreshResponse = await retryApiClient.post("RefreshToken", payload);

          if (refreshResponse && refreshResponse.data.StatusCode === 201) {
            const newAccessToken = refreshResponse.data.Token.AccessToken;
            const newRefreshToken = refreshResponse.data.Token.RefreshToken;

            // Update tokens in localStorage
            localStorage.setItem("AccessToken", newAccessToken);
            localStorage.setItem("RefreshToken", newRefreshToken);

            // Resolve the refresh promise
            resolve();
          } else {
            // Handle logout if refresh token fails
            // store.dispatch(
            //   Creators.userLogout(() => {
            //     history.push("/login?messageID=7");
            //     resolve();
            //   })
            // );
            redirectToLogin(resolve);
          }
        } catch (error) {
          console.error("Error refreshing token:", error);
          reject(error);
        } finally {
          isRefreshing = false;
          refreshTokenPromise = null;
        }
      });
    }

    try {
      // Wait for the token refresh process to complete
      await refreshTokenPromise;
    } catch (error) {
      console.error("Error waiting for token refresh:", error);
      return;
    }

    // Retry the original request with the new token
    const newAccessToken = localStorage.getItem("AccessToken");
    const retryApiClient = create({
      baseURL: response.config.baseURL,
      headers: {
        ...response.config.headers,
        Authorization: `Bearer ${newAccessToken}`,
      },
      timeout: response.config.timeout,
    });

    const retryRequest =
      response.config.method === "get"
        ? retryApiClient.get(response.config.url, response.config.params) // Use params for GET
        : retryApiClient.post(response.config.url, response.config.data);

    response.data = await retryRequest.then((data) => data.data);
  }

  if (response.problem === "SERVER_ERROR" && response.config.url !== "/AuthenticateAdminPortal") {
    // Handle server error
    // console.error("Server error occurred:", response.problem);
    redirectToLogin();
  }
});

// userApiClient.addAsyncResponseTransform(async (response) => {
//   const history = createBrowserHistory();

//   if (response.data.StatusCode == 401 && !isReloading) {
//     isReloading = true; // Set the flag to prevent further reloads
//     const retryPromise = new Promise((resolve) => {
//       try {
//         const { store } = configStore();
//         const unsubscribe = store.subscribe(() => {
//           let refreshToken = localStorage.getItem("RefreshToken")
//           console.log(refreshToken, "refreshToken");
//           const state = store.getState();
//           const payload = {
//             UserID: state.data.userInfo.LoginID,
//             RefreshToken: refreshToken,
//           };

//           const retryApiClient = create({
//             baseURL: response.config.baseURL,
//             timeout: response.config.timeout,
//           });

//           retryApiClient.post("RefreshToken", { ...payload }).then(async (response1) => {
//             if (response1 && response1.data.StatusCode === 201) {
//               let newToken = response1.data.Token.AccessToken;

//               // Update token in the store
//               // await store.dispatch(Creators.updateToken(response1.data.Token));
//               // window.location.reload();
//               // Retry the original request
//               localStorage.setItem("AccessToken", response1.data.Token.AccessToken);
//               localStorage.setItem("RefreshToken", response1.data.Token.RefreshToken);
//               const retryApiClient2 = create({
//                 baseURL: response.config.baseURL,
//                 headers: {
//                   ...response.config.headers,
//                   Authorization: `Bearer ${newToken}`,
//                 },
//                 timeout: response.config.timeout,
//               });

//               const retryRequest =
//                 response.config.method === "get"
//                   ? retryApiClient2.get(response.config.url, response.config.data)
//                   : retryApiClient2.post(response.config.url, response.config.data);

//               retryRequest
//                 .then((data) => {
//                   unsubscribe();
//                   response.data = data.data;
//                   resolve();
//                 })
//                 .catch((error) => {
//                   console.error("Error making request with new token:", error);
//                   // redirectToLogin(resolve);
//                   unsubscribe();
//                   // history.push("/login?messageID=5");
//                   // window.location.reload();
//                 });
//             } else {
//               // Handle logout if refresh token fails
//               store.dispatch(
//                 Creators.userLogout(() => {
//                   history.push("/login?messageID=7");
//                   // window.location.reload();
//                   resolve();
//                 })
//               );
//             }
//           });
//         });
//       } catch (exception) {
//         // redirectToLogin(resolve);
//       }
//     });

//     await retryPromise;
//     isReloading = false; // Reset the flag after completion
//   }

//   if (response.problem === "SERVER_ERROR" && response.config.url !== "/AuthenticateAdminPortal") {
//     //  redirectToLogin();
//   }
// });
function redirectToLogin(resolve = null) {
  // const { store } = configStore();
  const history = createBrowserHistory();
  localStorage.setItem("AccessToken", "");
  localStorage.setItem("RefreshToken", "");
  localStorage.setItem("userData", "");
  window.location.href = "/login?messageID=6";
  localStorage.clear();
  // history.push("/login?messageID=5");
  if (resolve) {
    resolve();
  }

}

// function getAuthToken(callback) {

//   const { store } = configStore();

//   // Subscribe to state changes
//   const unsubscribe = store.subscribe(() => {
//     const state = store.getState();
//     const authToken = state.data.token && state.data.token.AccessToken;
//     console.log(authToken, "authToken")
//     if (authToken) {
//       // Call the callback with the token value
//       callback(authToken);

//       // Unsubscribe after getting the token value
//       unsubscribe();
//     }
//   });
// }


/* The Below code is implementing an asynchronous request transform in a userApiClient object. When a
request is made, the code checks if the request URL is not "AuthenticateLogin". If it's not, the
code then checks if a token promise is null or resolved. If it is, a new token retrieval process is
initiated by calling the getAuthToken function. */
let tokenPromise = null;
// userApiClient.addAsyncRequestTransform((request) => async () => {
//   if (request.url !== "/AuthenticateAdminPortal") {
//     // if (!tokenPromise || tokenPromise._settled) {
//     //   tokenPromise = new Promise((resolve) => {
//     //     getAuthToken((token) => {
//     //       resolve(token);
//     //     });
//     //   });
//     // }

//     try {
//       const AccessToken = localStorage.getItem("AccessToken");
//       const RefreshToken = localStorage.getItem("RefreshToken");
//       if (AccessToken) {
//         userApiClient.setHeader("Authorization", "Bearer " + AccessToken);
//         request.headers["Authorization"] = "Bearer " + AccessToken; // change the current request too
//       }
//     } catch (error) {
//       console.error("Error retrieving token:", error);
//     }
//   }
// });


function getAdminData() {
  return userApiClient.get("/GetNSSuperAdminDashboard").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

// Lessonbot

// Login Data

function userLogin(username, password, schoolDistrict) {
  return userApiClient
    .post("/AuthenticateAdminPortal", {
      LoginName: username,
      Password: password,
      DistrictName: schoolDistrict,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classlinkLogin(sourceid) {
  userApiClient.setHeader("SourceID", sourceid);
  return userApiClient
    .get("/AuthenticateNSClassLink")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}
let sampleRes = {
  AdditionalServiceResponseContent: null,
  ErrorMessage: null,
  NSUserInfo: {
    DomainID: 6481,
    EmailAddress: "samchinnu001@gmail.com",
    FName: "sam",
    Grade: 0,
    ISNotification: 0,
    LName: "chinnu",
    LicenseExpiryDate: "08/05/2023",
    LicenseName: "IBTrial",
    LicenseSubscribedDate: "07/06/2023",
    Password: "T30",
    SourceID: "",
    SuperGroupID: 6648,
    SuperGroupName: "Teach21                                                                                             ",
    UserID: 60602,
    UserName: "Teach21",
    UserType: "GroupAdmin",
  },
};

//class link old
function classLogin(code) {
  return userApiClient
    .post("/AuthenticateClassLink", {
      code: code,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}
// class link new
// function classLogin(code) {
//   return userApiClient
//     .post("/AuthenticateClassLink", {
//       code: code,
//     })
//     .then((response) => {
//       if (response.status === 200) {
//         return response.data ? response.data : code === "c1707828140779eeaa2b373fb7eda15be049b08423bdcd" ? sampleRes : 404;
//       } else if (response.problem === "TIMEOUT_ERROR") {
//         return code === "c1707828140779eeaa2b373fb7eda15be049b08423bdcd" ? sampleRes : 404;
//       } else if (response.status !== 200) {
//         return code === "c1707828140779eeaa2b373fb7eda15be049b08423bdcd" ? sampleRes : 404;
//       } else {
//         return code === "c1707828140779eeaa2b373fb7eda15be049b08423bdcd" ? sampleRes : 404;
//       }
//     });
// }

// Manage Products

function getAllProductsList() {
  return userApiClient.get("/GetAllProducts").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

function putProducts(ProductID, ProductName, ProductDesc, Price, Duration, Modes) {
  return userApiClient
    .post("/CreateProduct", {
      ProductID: ProductID,
      ProductName: ProductName,
      ProductDesc: ProductDesc,
      Modes: Modes.toString(),
      Price: Price,
      Duration: Duration,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data.CreateProductResult;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getProductsByID(id) {
  userApiClient.setHeader("productId", id);
  return userApiClient
    .get("/GetProduct")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function updateProductsById(ProductID, ProductName, ProductDesc, Price, Duration, Modes) {
  return userApiClient
    .post("/UpdateProduct", {
      ProductID: ProductID,
      ProductName: ProductName,
      ProductDesc: ProductDesc,
      Modes: Modes.toString(),
      Price: Price,
      Duration: Duration,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data.UpdateProductResult;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

// Manage Class License
function getAllClassLicense() {
  return userApiClient.get("/GetNSPortalDistrictProductTransactions").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}
function updateClassSubscription(DistrictID, ProductID, SubscriptionDate) {
  return userApiClient
    .post("./NSUpdateDistrictProductTransaction", {
      DomainID: DistrictID,
      ProductID: ProductID,
      SubscriptionDate: SubscriptionDate,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

// Number Sense React
function getAllDistrictList() {
  return userApiClient.get("/NSDistrictGETALL").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

function putDistrict(country, domainName, description, emailId, firstName, lastName, location, password, userName, maxStudents) {
  return userApiClient
    .post("./NSDistrictCreate", {
      Country: country,
      Name: domainName,
      Desc: description,
      EmailID: emailId,
      Location: location,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      LoginName: userName,
      MaxStudents: maxStudents,
    })
    .then((response) => {
      console.log(response)
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}
function putClasslinkDistrict(
  country,
  domainName,
  description,
  emailId,
  firstName,
  lastName,
  location,
  password,
  userName,
  maxStudents,
  SourceId,
  AccessToken,
  ApplicationID
) {
  return userApiClient
    .post("./DistrictCreate", {
      Country: country,
      Name: domainName,
      Desc: description,
      EmailID: emailId,
      Location: location,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      LoginName: userName,
      MaxStudents: maxStudents,
      SourceID: SourceId,
      AccessToken: AccessToken,
      ApplicationID: ApplicationID,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getDistrictByID(id) {
  userApiClient.setHeader("DistrictID", id);
  return userApiClient
    .get("./NSDistrictGET",)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function updateDistrictById(
  domainId,
  domainName,
  description,
  location,
  country,
  userName,
  emailId,
  firstName,
  lastName,
  password,
  maxStudents,
  studentId
) {
  return userApiClient
    .post("./NSDistrictUpdate", {
      ID: domainId,
      Name: domainName,
      Desc: description,
      Location: location,
      Country: country,
      LoginName: userName,
      EmailID: emailId,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      MaxStudents: maxStudents,
      StudentID: studentId,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function updateClassLinkById(
  domainId,
  domainName,
  description,
  location,
  country,
  userName,
  emailId,
  firstName,
  lastName,
  password,
  maxStudents,
  studentId,
  accessToken,
  applicationID,
  sourceID
) {
  return userApiClient
    .post("./NSClassLinkDistrictUPDATE", {
      ID: domainId,
      Name: domainName,
      Desc: description,
      Location: location,
      Country: country,
      LoginName: userName,
      EmailID: emailId,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      MaxStudents: maxStudents,
      StudentID: studentId,
      AccessToken: accessToken,
      ApplicationID: applicationID,
      SourceID: sourceID,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getDistrictDashboardData(DistrictID) {
  userApiClient.setHeader("DistrictID", DistrictID);
  return userApiClient
    .get("/GetNSDistrictDashboardByDistrict")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getAllSchoolList(id) {
  userApiClient.setHeader("DistrictID", id);
  return userApiClient
    .get("/NSSchoolGETALL")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function putSchools(schoolName, description, domainid, maxStudent, startDate) {
  return userApiClient
    .post("./NSSchoolCreate", {
      Name: schoolName,
      Desc: description,
      DistrictID: domainid,
      StartDate: startDate,
      MaxStudents: maxStudent
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getSchoolsById(id) {
  userApiClient.setHeader("SchoolID", id);
  return userApiClient
    .get("./NSSchoolGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function updateSchoolsById(name, description, domainId, maxStudents, startDate) {
  return userApiClient
    .post("./NSSchoolUpdate", {
      Name: name,
      Desc: description,
      DistrictID: domainId,
      MaxStudents: maxStudents,
      StartDate: startDate,
      ID: domainId,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function schoolsBulkUpload(domainId, schoolList) {
  return userApiClient
    .post("./NSSchoolBulkUpload", {
      DistrictID: domainId,
      SchoolList: schoolList,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getAllClassList(id) {
  userApiClient.setHeader("SchoolID", id);
  return userApiClient
    .get("/NSClassGETALL")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function putClasses(className, description, maxStudents, startDate, schoolId, allowParentAccess) {
  return userApiClient
    .post("./NSClassCreate", {
      Name: className,
      Desc: description,
      MaxStudents: maxStudents,
      StartDate: startDate,
      SchoolID: schoolId,
      AllowParentAccess: allowParentAccess,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getClassesById(id) {
  userApiClient.setHeader("classid", id);
  return userApiClient
    .get("./NSClassGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function updateClassesById(classId, className, description, maxStudents, schoolId, startDate) {
  return userApiClient
    .post("./NSClassUpdate", {
      ID: classId,
      Name: className,
      Desc: description,
      MaxStudents: maxStudents,
      SchoolID: schoolId,
      StartDate: startDate,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classesBulkUpload(schoolId, classList) {
  return userApiClient
    .post("./NSClassBulkUpload", {
      SchoolID: schoolId,
      ClassList: classList,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getClassDetails(id) {
  userApiClient.setHeader("GroupID", id);
  return userApiClient
    .get("/NSClassGETDetails")
    .then((response) => {
      if (response.status === 200) {
        return response.data.Data.GroupMemberDetails;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function bulkUploadNSTeacherClassMapping(schoolId, classmapping) {
  return userApiClient
    .post("./BulkUploadNSTeacherClassMapping", {
      SchoolID: schoolId,
      ClassMapingList: classmapping,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function schoolUsageDataSchoolList(id) {
  userApiClient.setHeader("DistrictID", id);
  return userApiClient
    .get("/NSSchoolGETALL")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function schoolUsageDataSchoolDashboard(id) {
  userApiClient.setHeader("SuperGroupID", id);
  return userApiClient
    .get("./GetNSSchoolDashboardBySchool")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getDistrictLicProductHistory(id) {
  userApiClient.setHeader("DomainID", id);
  return userApiClient.get("/GetNSDistrictProductTransactionHistory").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

function dailySUD(id) {
  userApiClient.setHeader("DistrictId", id);
  return userApiClient
    .get("./NSDistrictDailyGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function detailsSUD(id) {
  userApiClient.setHeader("DistrictId", id);
  return userApiClient
    .get("./NSDistrictActivityDetailsGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data.Details;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classSUD(id) {
  userApiClient.setHeader("SchoolID", id);
  return userApiClient
    .get("./GetNSSchoolClassSummaryReport")
    .then((response) => {
      if (response.status === 200) {
        return response.data.GetNSSchoolClassSummaryReportResult;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function studentsSummaryCS(id) {
  userApiClient.setHeader("ClassID", id);
  return userApiClient
    .get("./NSStudentsListForAClass")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function studentsSummaryDailyAndActivity(id) {
  userApiClient.setHeader("StudentID", id);
  return userApiClient
    .get("./NSStudentSummaryGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

//District Admin Purchase History

function getPurchaseHistory(id) {
  userApiClient.setHeader("DomainID", id);
  return userApiClient
    .get("/GetNSDistrictProductTransactionHistory")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function districtAdminNotifications(id) {
  userApiClient.setHeader("DistrictID", id);
  userApiClient.setHeader("ViewType", "New");
  return userApiClient
    .get("/GETAllStudentExceededNotificationsByDistrict")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function districtAdminViewAllNotif(id) {
  userApiClient.setHeader("DistrictID", id);
  userApiClient.setHeader("ViewType", "All");
  return userApiClient
    .get("/GETAllStudentExceededNotificationsByDistrict")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

//UpdateSUb
function updateSubscription(DistrictID, ProductID, SubscriptionDate) {
  return userApiClient
    .post("./NSUpdateDistrictProductTransaction", {
      DomainID: DistrictID,
      ProductID: ProductID,
      SubscriptionDate: SubscriptionDate,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function manageDistrictLicenceSubscription(DistrictID, ProductID, SubscriptionDate) {
  return userApiClient
    .post("./NSCreateDistrictProductTransaction", {
      DomainID: DistrictID,
      ProductID: ProductID,
      SubscriptionDate: SubscriptionDate,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getUserLicenseList() {
  return userApiClient.get("/GetNSUserProductTransactions").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

function getDistrictLicenseList() {
  return userApiClient.get("/GetNSDistrictProductTransactions").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

function getUserDistrictProductHistory(id) {
  userApiClient.setHeader("UserId", id);
  return userApiClient.get("/GetNSUserProductTransactionHistory").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

function userDistrictUpdate(UserID, ProductID) {
  return userApiClient
    .post("./MapUsersToProduct", {
      UserID: UserID,
      ProductID: ProductID,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

//Notifications
function getNewNotifications() {
  userApiClient.setHeader("ViewType", "New");
  return userApiClient.get("/GETAllStudentExceededNotifications").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

function getAllNotifications() {
  userApiClient.setHeader("ViewType", "All");
  return userApiClient.get("/GETAllStudentExceededNotifications").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

function deleteNotifications(notificationIds, userType) {
  return userApiClient
    .post("/NSUpdateStudentExceededNotification", {
      StudentExceedNotificationIDs: notificationIds,
      UpdateType: userType,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getClassLeaderboardData(DistrictID, FromDate, ToDate) {
  userApiClient.setHeader("DistrictID", DistrictID);
  userApiClient.setHeader("FromDate", FromDate);
  userApiClient.setHeader("ToDate", ToDate);
  return userApiClient
    .get("./GetNSTeacherLeaderBoardReport")
    .then((response) => {
      if (response.status === 400) {
        return response.data;
      } else if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getStudentLeaderboardData(DistrictID, FromDate, ToDate) {
  userApiClient.setHeader("DistrictID", DistrictID);
  userApiClient.setHeader("FromDate", FromDate);
  userApiClient.setHeader("ToDate", ToDate);
  return userApiClient
    .get("./GetNSDistrictLeaderBoardReport")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classDailySummaryCS(id) {
  userApiClient.setHeader("ClassID", id);
  return userApiClient
    .get("./NSClassDailyGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classActivitySummaryCS(id) {
  userApiClient.setHeader("ClassID", id);
  return userApiClient
    .get("./NSClassDetailsGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getAllUserList(id) {
  userApiClient.setHeader("SuperGroupID", id);
  return userApiClient
    .get("/NSUsersGetAllForASchool")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function putUser(sourceData, loginName, emailId, firstName, lastName, country, age, groupIds, password, schoolId, userType) {
  return userApiClient
    .post("./CreateNSUser", {
      ImageID: "",
      Source: sourceData,
      LoginName: loginName,
      EmailID: emailId,
      FName: firstName,
      LName: lastName,
      Country: country,
      Age: age,
      GroupID: groupIds,
      Password: password,
      SuperGroupID: schoolId,
      UserType: userType,
    })
    .then((response) => {
      console.log(response);
      if (response.status === 201) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getUsersById(id) {
  userApiClient.setHeader("SuperGroupID", id);
  return userApiClient
    .get("./GetAllGroupsForASuperGroupID")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function districtWeeklyReport(DistrictID, FromDate, ToDate) {
  userApiClient.setHeader("DistrictID", DistrictID);
  userApiClient.setHeader("FromDate", FromDate);
  userApiClient.setHeader("ToDate", ToDate);
  return userApiClient
    .get("/GetNSDistrictSummaryReport")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function schoolWeeklyReport(schoolId, fromDate, toDate) {
  userApiClient.setHeader("GroupID", schoolId);
  userApiClient.setHeader("FromDate", fromDate);
  userApiClient.setHeader("ToDate", toDate);
  return userApiClient
    .get("/GetNSClassSummaryReport")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function updateUsersById(imageId, sourceData, loginName, emailId, firstName, lastName, country, age, groupIds, password, schoolId, userType) {
  return userApiClient
    .post("./updateCreateNSUser", {
      ImageID: imageId,
      Source: sourceData,
      LoginName: loginName,
      EmailID: emailId,
      FName: firstName,
      LName: lastName,
      Country: country,
      Age: age,
      GroupIDs: groupIds,
      Pwd: password,
      SuperGroupID: schoolId,
      UserType: userType,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getClassBySchoolId(id) {
  userApiClient.setHeader("SuperGroupID", id);
  return userApiClient
    .get("/GetAllGroupsForASuperGroupID")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getSchoolDashboardData(SuperGroupID) {

  userApiClient.setHeader("SuperGroupID", SuperGroupID);
  return userApiClient
    .get("/GetNSSchoolDashboardBySchool")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function activitySAD(schoolId) {
  userApiClient.setHeader("SchoolID", schoolId);
  return userApiClient
    .get("./NSSchoolDailyGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function detailsSAD(schoolId) {
  userApiClient.setHeader("SchoolID", schoolId);
  return userApiClient
    .get("./NSSchoolDetailsGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classUsageDataSchoolList(schoolId) {
  userApiClient.setHeader("SchoolID", schoolId);
  return userApiClient
    .get("./NSClassGETALL")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classUsageDataSchoolDashboard(id) {
  userApiClient.setHeader("GroupID", id);
  return userApiClient
    .get("./GetNSClassDashboardByClass")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function schoolClassDailySummaryCS(id) {
  userApiClient.setHeader("ClassID", id);
  return userApiClient
    .get("./NSClassDailyGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function schoolClassDetailsSummaryCS(id) {
  userApiClient.setHeader("ClassID", id);
  return userApiClient
    .get("./NSClassDetailsGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function teacherUsageDataList(Id) {
  userApiClient.setHeader("SuperGroupID", Id);
  return userApiClient
    .get("./GETAllTeachersBySchool")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function teacherUsageDataDashboard(ID) {
  userApiClient.setHeader("LoginID", ID);
  return userApiClient
    .get("./GetNSTeacherDashboardByTeacher")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function teacherClassDailySummaryCS(id) {
  userApiClient.setHeader("ClassID", id);
  return userApiClient
    .get("./NSClassDailyGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function teacherClassDetailsSummaryCS(id) {
  userApiClient.setHeader("ClassID", id);
  return userApiClient
    .get("./NSClassDetailsGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function activeAndDeactive(districtId, status) {
  return userApiClient
    .post("./NSDistrictActivateORDeactivate", {
      ID: districtId,
      Status: status,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function districtDetails(districtId) {
  userApiClient.setHeader("DistrictID", districtId);
  return userApiClient
    .get("./GetNSDistrictDashboardByDistrict")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function deleteProduct(productId) {
  return userApiClient
    .post("./DeleteProduct", {
      ProductID: productId,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data.DeleteProductResult;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getTeacherDashboardData(ID) {
  userApiClient.setHeader("LoginID", ID);
  return userApiClient
    .get("/GetNSTeacherDashboardByTeacher")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function districtDashboardDailySummary(id) {
  userApiClient.setHeader("DistrictID", id);
  return userApiClient
    .get("./NSDistrictDailyGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function districtDashboardActivitySummary(id) {
  userApiClient.setHeader("DistrictID", id);
  return userApiClient
    .get("./NSDistrictActivityDetailsGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getRestrictedAdminUsersList(id) {
  userApiClient.setHeader("DistrictID", id);
  return userApiClient
    .get("/NSUsersGetAllForADistrict")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function userLoginSchoolsBulkUpload(schoolId, mailNo, userList) {
  return userApiClient
    .post("./BulkUploadCreateNSUserForSchool", {
      SuperGroupID: schoolId,
      SendEmailToAll: mailNo,
      listNSUsers: userList,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function userLoginClassesBulkUpload(schoolId, classId, mailNo, userList) {
  return userApiClient
    .post("./BulkUploadCreateNSUser", {
      GroupID: classId,
      SuperGroupID: schoolId,
      SendEmailToAll: mailNo,
      listNSUsers: userList,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getRestrictedAdminUsersUsersById(id) {
  userApiClient.setHeader("UserID", id);
  return userApiClient
    .get("./GetNSUser")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function updateRestrictedAdminUsersById(password, emailId, firstName, lastName, country, age, id, isActive, groupIds) {
  return userApiClient
    .post("./EditNSUser", {
      Pwd: password,
      EmailID: emailId,
      FName: firstName,
      LName: lastName,
      Country: country,
      Age: age,
      ID: id,
      ActiveFlag: isActive,
      GroupIDs: groupIds,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 400) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classlinkSchoolUpload(data) {
  return userApiClient
    .post("./NSClasslinkCreateSchool", {
      orgs: data.orgs,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}
function classlinkClassUpload(data) {
  return userApiClient
    .post("./NSClasslinkCreateClass", {
      classes: data.classes,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}
function classlinkUserUpload(data) {
  return userApiClient
    .post("./NSClasslinkCreateUser", {
      users: data.users,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}
function classlinkUserMaping(data) {
  return userApiClient
    .post("./NSClasslinkUserMapping", {
      data,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classListTeacher(id) {
  userApiClient.setHeader("TeacherID", id);
  return userApiClient
    .get("./GETAllGroupsByTeacher")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}
function classWeeklyReport(superGroupId, fromDate, toDate) {
  userApiClient.setHeader("SuperGroupID", superGroupId);
  userApiClient.setHeader("FromDate", fromDate);
  userApiClient.setHeader("ToDate", toDate);
  return userApiClient
    .get("/GetNSSchoolSummaryReport")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function studentUsageDataList(id) {
  userApiClient.setHeader("ClassID", id);
  return userApiClient
    .get("./NSStudentsListForAClass")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function studentDailySummaryCS(id) {
  userApiClient.setHeader("StudentID", id);
  return userApiClient
    .get("./NSStudentSummaryGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function studentDetailsSummaryCS(id) {
  userApiClient.setHeader("StudentID", id);
  return userApiClient
    .get("./NSStudentSummaryGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data.Data.Details;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getAssignments(LoginID, GroupID) {
  userApiClient.setHeader("LoginID", LoginID);
  userApiClient.setHeader("GroupID", GroupID);
  return userApiClient
    .get("/GetNSAssignmentsByTeacherClass")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function createAssignment(
  AssignmentName,
  Desc,
  TargetDate,
  TargetTime,
  Students,
  ClassID,
  ConceptID,
  SkillID,
  Tiles,
  StartingNumber,
  Order,
  Layout,
  ShowNumber,
  MinRed,
  MinTime,
  MinGreen,
  SuperGroupID,
  LoginID
) {
  return userApiClient
    .post("./CreateNSAssignment", {
      AssignmentName,
      Desc,
      TargetDate,
      TargetTime,
      Students,
      ClassID,
      ConceptID,
      SkillID,
      Tiles,
      StartingNumber,
      Order,
      Layout,
      ShowNumber,
      MinRed,
      MinTime,
      MinGreen,
      SuperGroupID,
      LoginID,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getConcepts(domainID) {
  userApiClient.setHeader("DomainID", domainID);
  return userApiClient.get("/NSConceptsByDomain").then((response) => {
    if (response.status === 200) {
      return response.data;
    } else if (response.problem === "TIMEOUT_ERROR") {
      return response.problem;
    } else if (response.status !== 200) {
      return response && response.data ? response.data : response.problem;
    } else {
      return response.data;
    }
  });
}

function getSkills(ConceptID) {
  userApiClient.setHeader("ConceptID", ConceptID);
  return userApiClient
    .get("/NSSkillsGET")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getAssignmentDetails(AssignmentID, LoginID) {
  userApiClient.setHeader("AssignmentID", AssignmentID);
  userApiClient.setHeader("LoginID", LoginID);
  return userApiClient
    .get("/GetNSAssignmentViewByTeacher")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function getAssignmentReport(LoginID, AssignmentID) {
  userApiClient.setHeader("AssignmentID", AssignmentID);
  userApiClient.setHeader("LoginID", LoginID);
  return userApiClient
    .get("/GetNSAssignmentAnalytics")
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function deleteAssignment(LoginID, AssignmentID) {
  userApiClient.setHeader("AssignmentID", AssignmentID);
  userApiClient.setHeader("LoginID", LoginID);
  return userApiClient
    .post("/DeleteNSAssignment", {
      LoginID: LoginID,
      AssignmentID: AssignmentID,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function classLbPopup(id, FromDate, ToDate, category) {
  userApiClient.setHeader("GroupID", id);
  userApiClient.setHeader("FromDate", FromDate);
  userApiClient.setHeader("ToDate", ToDate);
  userApiClient.setHeader("Category", category);
  return userApiClient
    .get("./GetNSTeacherLeaderBoardReportDetails")
    .then((response) => {
      if (response.status === 200) {
        return response.data.GetNSTeacherLeaderBoardReportDetailsResult;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

function schoolLbPopup(id, FromDate, ToDate, category) {
  userApiClient.setHeader("StudentID", id);
  userApiClient.setHeader("FromDate", FromDate);
  userApiClient.setHeader("ToDate", ToDate);
  userApiClient.setHeader("Category", category);
  return userApiClient
    .get("./GetNSDistrictLeaderBoardReportDetails")
    .then((response) => {
      if (response.status === 200) {
        return response.data.GetNSDistrictLeaderBoardReportDetailsResult;
      } else if (response.problem === "TIMEOUT_ERROR") {
        return response.problem;
      } else if (response.status !== 200) {
        return response && response.data ? response.data : response.problem;
      } else {
        return response.data;
      }
    });
}

export const AllService = {
  // Number Sense React
  userLogin, // login
  classlinkLogin,
  classLogin,
  getAllDistrictList,
  putDistrict,
  putClasslinkDistrict,
  getDistrictByID,
  updateDistrictById,
  updateClassLinkById,
  // Manage Products
  getAllProductsList,
  putProducts,
  getProductsByID,
  updateProductsById,

  // Manage Class License
  getAllClassLicense,
  updateClassSubscription,
  //getClassLicenseTrans,

  getAdminData,
  getDistrictDashboardData,
  getAllSchoolList,
  putSchools,
  getSchoolsById,
  updateSchoolsById,
  schoolsBulkUpload,
  getAllClassList,
  putClasses,
  getClassesById,
  updateClassesById,
  classesBulkUpload,
  getClassDetails,
  bulkUploadNSTeacherClassMapping,
  schoolUsageDataSchoolList,
  schoolUsageDataSchoolDashboard,
  dailySUD,
  detailsSUD,
  classSUD,
  studentsSummaryCS,
  studentsSummaryDailyAndActivity,
  classDailySummaryCS,
  classActivitySummaryCS,
  getDistrictLicProductHistory,
  updateSubscription,
  manageDistrictLicenceSubscription,
  getUserLicenseList,
  getDistrictLicenseList,
  getUserDistrictProductHistory,
  userDistrictUpdate,
  getNewNotifications,
  getAllNotifications,
  //Leaderboard
  getStudentLeaderboardData,
  getClassLeaderboardData,
  getClassBySchoolId,
  putUser,
  getAllUserList,
  getUsersById,
  updateUsersById,
  getSchoolDashboardData,
  activitySAD,
  detailsSAD,
  classUsageDataSchoolList,
  classUsageDataSchoolDashboard,
  schoolClassDailySummaryCS,
  schoolClassDetailsSummaryCS,
  districtWeeklyReport,
  schoolWeeklyReport,
  deleteNotifications,
  activeAndDeactive,
  districtDetails,
  deleteProduct,
  getPurchaseHistory,
  districtAdminNotifications,
  districtAdminViewAllNotif,
  teacherUsageDataList,
  teacherUsageDataDashboard,
  getTeacherDashboardData,
  districtDashboardDailySummary,
  districtDashboardActivitySummary,
  getRestrictedAdminUsersList,
  userLoginSchoolsBulkUpload,
  userLoginClassesBulkUpload,
  getRestrictedAdminUsersUsersById,
  updateRestrictedAdminUsersById,
  classlinkSchoolUpload,
  classlinkClassUpload,
  classlinkUserUpload,
  classlinkUserMaping,
  teacherClassDailySummaryCS,
  teacherClassDetailsSummaryCS,
  classListTeacher,
  classWeeklyReport,
  studentUsageDataList,
  studentDailySummaryCS,
  studentDetailsSummaryCS,
  getAssignments,
  createAssignment,
  getConcepts,
  getSkills,
  getAssignmentDetails,
  getAssignmentReport,
  deleteAssignment,
  classLbPopup,
  schoolLbPopup,
};
