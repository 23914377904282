
export const SetSession = (userName, password, domainName, isRemember) => {
    if (isRemember) {
        sessionStorage.setItem("userName", userName);
        sessionStorage.setItem("password", password);
        sessionStorage.setItem("domainName", domainName);
        sessionStorage.setItem("rememberme", isRemember);
    } else {
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("password");
        sessionStorage.removeItem("domainName");
        sessionStorage.removeItem("rememberme");
    }
    return true;
}

export const GetSession = (loginForm, setLoginForm, setIsRemember) => {
    const rememberme = sessionStorage.getItem("rememberme");
    if (rememberme) {
        const userName = sessionStorage.getItem("userName");
        const password = sessionStorage.getItem("password");
        const domainName = sessionStorage.getItem("domainName");
        loginForm.map(data => {
            if (data.name === "userName") {
                setLoginForm(current =>
                    current.map(obj => {
                        if (obj.name === data.name) return { ...obj, value: userName }
                        return obj;
                    })
                );
                return false;
            } else if (data.name === "password") {
                setLoginForm(current =>
                    current.map(obj => {
                        if (obj.name === data.name) return { ...obj, value: password }
                        return obj;
                    })
                );
                return false;
            } else if (data.name === "domainName") {
                setLoginForm(current =>
                    current.map(obj => {
                        if (obj.name === data.name) return { ...obj, value: domainName }
                        return obj;
                    })
                );
                return false;
            }
            return true;
        });
        setIsRemember(true);
    }
    return true;
}
