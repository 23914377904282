import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box    // Toolbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from "react-router-dom";
import { MENU } from "../constants";
import ROUTES from "../routes";
// import Dashboard from '../Dashboard/Dashboard';
import "./LayoutStyle.css";
import CustomBrandName from './CustomBrandName';
import CustomMenuList from './CustomMenuList';
import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import LogoNS from "../constants/images/logo.png"
import tourImage from "../constants/images/tourImage.png"
import Cookies from 'universal-cookie';
import Creators from "../redux/ducks/actions";
const cookies = new Cookies();

// route config
const getUserRoutes = (routes) => {
    return routes.map((prop, key) => {
        if (prop.layout === "/teacher") {
            return (
                <Route
                    exact
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                />
            );
        } else {
            return null;
        }
    });
};

const drawerWidth = 280;

const mdTheme = createTheme();

export default function TeacherLayout() {
    const { userInfo, teacherNumber, teacherStatus } = useSelector((state) => state.data);
    // Menu
    const history = useHistory(); //   HIstory
    const { url } = useRouteMatch(); //   Route
    const location = useLocation();
    const CurrentURL = location.pathname.split("/")[2];
    const [open, setOpen] = React.useState(true);
    // const [showTour, setShowTour] = useState(true);
    // const [showTour, setShowTour] = useState(cookies.get("teacher_admin") === undefined);
    // const [tourIndex, setTourIndex] = useState(0);
    const dispatch = useDispatch();

    React.useEffect(() => {
        const currentPath = location && location.pathname.split("/")[2];
        switch (currentPath) {
            case 'teacher-dashboard': document.title = "Number Sense - Teacher Dashboard"
                break;
            case 'teacher-student-usage-data': document.title = "Number Sense - Student Usage Data"
                break;
            case 'teacher-weekly-report': document.title = "Number Sense - Weekly Reports"
                break;
            case 'teacher-leaderboard': document.title = "Number Sense - Leaderboard"
                break;
            case 'teacher-assignments': document.title = "Number Sense - Assignments"
                break;
            default: document.title = "Number Sense - Teacher Console";
                break;
        }
    }, [url, location])

    React.useEffect(() => {
        if (userInfo.UserType !== "GroupAdmin") {
            if (userInfo.UserType === "NSTechAdmin") {
                history.push(`/admin`);
            } else if (userInfo.UserType === "DistrictAdmin") {
                history.push(`/district`);
            } else if (userInfo.UserType === "Supergrouptechadmin") {
                history.push(`/school`);
            } else {
                history.push(`/login`);
            }
        }
    })


    const toggleDrawer = () => {
        setOpen(!open);
    };

    const [darwerLeft, setDarwerLeft] = React.useState(false);

    const toggleMobileDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDarwerLeft(open);
    };


    const webView = "17rem";
    const mobileView = "4rem";

    const onClickMenuItem = (layout, menuURL) => {
        history.push(layout + "/" + menuURL)
    }

    const onChangeTour = (tour) => {
        const { action, index, type } = tour;
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

        if (action === 'close') {
            dispatch(Creators.changeTeacherStatus(false));
            setTimeout(() => {
                dispatch(Creators.changeTeacherNumber(0));
            }, 800)
        }

        if (action === ACTIONS.CLOSE || type === EVENTS.TOUR_END) {
            dispatch(Creators.changeTeacherStatus(false));
            dispatch(Creators.changeTeacherNumber(0));
        }
        if ([EVENTS.STEP_AFTER, EVENTS.CLOSE, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            if (teacherNumber === 4 && action === "prev") {
                history.push("teacher/teacher-dashboard")
                setTimeout(() => {
                    dispatch(Creators.changeTeacherNumber(3));
                }, 500)
            } else {
                setTimeout(() => {
                    // setTourIndex(nextStepIndex);
                    dispatch(Creators.changeTeacherNumber(nextStepIndex));
                }, 500)
            }
        }
    }

    useEffect(() => {
        // cookies.get("teacher_admin") === undefined
        if (cookies.get("teacher_admin") === undefined) {
            cookies.set("teacher_admin", true);
            dispatch(Creators.changeTeacherNumber(0));
            dispatch(Creators.changeTeacherStatus(true));
        }
    }, [])

    useEffect(() => {
        if (teacherStatus === false) {
            dispatch(Creators.changeTeacherNumber(0));
        }
    }, [teacherStatus])

    const steps = [
        {
            target: '.layoutMenuList',
            // title: 'Introduction',
            content: (
                <div className='JRModalWrapper'>
                    <div className='JRHeaderWrapper'>
                        <img className='JRLogo' src={LogoNS} alt='NSlogo' />
                        <div className='JRHeaderText'>Teacher Console</div>
                    </div>
                    <div className='JRContentWrapper'>
                        <div className='JRContentTitle'>
                            Welcome to NumberSense <br />
                            Teacher Console Walkthrough
                        </div>
                        <section>
                            <div className='JRContentBody mb-125'>
                                This walkthrough will guide you through the Teachers’ Administrative Console for NumberSense app.
                            </div>
                            <div className='JRContentBody'>
                                Teacher Console is a tool that can be used to administrate and organize the students in a classroom and monitor their performance while recording statistics collected through the NumberSense app. It can also used to set up Assignments inside the NumberSense app to keep students engaged and competitive inside the classroom while developing their mathematical skills.
                            </div>
                        </section>
                    </div>
                </div >
            ),
            placement: 'center',
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            showSkipButton: true,
            locale: {
                next: 'Lets Start!',
            }
        },
        {
            title: "Main Menu",
            target: '.layoutMenuList',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        The main menu helps you navigate through to different pages of the Teacher Console.
                    </div>
                    <div className='MMLogoWrapper'>
                        <img className='MMLogo' src={tourImage} alt='NSlogo' />
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 0
        },
        {
            title: "Dashboard Introduction",
            target: '#teacher-dashboard-1',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        Teacher Console dashboard shows you the summary statistics of your classes and students. In here you can also access individual classes to obtain in depth information about your classes performance.
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 0
        },
        {
            title: "Dashboard Introduction",
            target: '.customDBCard',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        These are your assigned classes. At a glance you can get statistics about your class details and performances. Try clicking on a class card to get more details.
                    </div>
                </div >
            ),
            placement: 'top',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 5,
            spotlightClicks: true,
            hideFooter: true,
            hideCloseButton: true,
        },
        {
            title: "Classes",
            target: '.nsMainContent',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        This is a class that you are assigned to. You can take a detailed look on how your students have performed on the NumberSense app here.
                    </div>
                </div >
            ),
            placement: 'bottom',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 5,
        },
        {
            title: "Classes",
            target: '#class-buttons-id',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        Also you can access different types of automatically generated reports based on your student performances through these buttons.
                    </div>
                </div >
            ),
            placement: 'bottom',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 5,
        },
        {
            title: "Student Usage Data",
            target: '#teacher-student-usage-data-1',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        This is the Student Usage Data page. Here you can access statistics and automatically generate reports on each individual student.
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 0
        },
        {
            title: "Weekly Reports",
            target: '#teacher-weekly-report-1',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        This is the Weekly Reports page. From here you can view both Weekly reports and Student Usage reports for a specific time period of your liking to get an overview on how your students are doing. Try clicking on the menu.
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 0,
            spotlightClicks: true,
            hideFooter: true,
            hideCloseButton: true,
        },
        {
            title: "Weekly Reports",
            target: '#download-button',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        Also you have the option to download a digital copy of the generated reports by clicking this button.
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 0
        },
        {
            title: "Leaderboard",
            target: '#teacher-leaderboard-1',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        This is the Leaderboard screen where you can get an insight on the top scoring classes or students in your entire school district. Try clicking on the menu.
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 0,
            spotlightClicks: true,
            hideFooter: true,
            hideCloseButton: true,
        },
        {
            title: "Leaderboard",
            target: '#leaderboard-tab-id',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        Use these buttons to switch between top performing classes in the Class Leaderboard section or the top performing student in the Student leaderboard section.
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 5
        },
        {
            title: "Leaderboard",
            target: '#leaderboard-buttons-id',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        You can also sort the leaderboard list according to minutes played, problems solved or levels mastered in the NumberSense app.
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 5
        },
        {
            title: "Assignments",
            target: '#teacher-assignments-1',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        In the Assignments screen you can view all the assignments you have created for the student to be completed on the NumberSense app.  Try clicking on the menu.
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 0,
            spotlightClicks: true,
            hideFooter: true,
            hideCloseButton: true,
        },
        {
            title: "Assignments",
            target: '#assignment-id',
            content: (
                <div className='mainMenuWrapper'>
                    <div className='MMText'>
                        Use this button to create new assignments.
                    </div>
                </div >
            ),
            placement: 'right',
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightPadding: 5
        },
    ]

    return (
        <ThemeProvider theme={mdTheme}>
            <Joyride
                steps={steps}
                run={teacherStatus}
                stepIndex={teacherNumber}
                callback={onChangeTour}
                continuous
                locale={{
                    back: 'Back',
                    close: 'Close',
                    last: 'Done',
                    next: 'Next',
                    open: 'Open the dialog',
                    skip: 'Skip'
                }}
            />
            <Box className='layoutWrapper'>
                <Box
                    className='layoutMenuList'
                    style={{
                        width: open ? webView : mobileView,
                    }}
                >
                    <CustomBrandName
                        layoutName="Teacher"
                        isMenuOpen={open}
                        onMenuToggle={toggleDrawer}
                    />
                    <CustomMenuList
                        isMenuOpen={open}
                        menuList={MENU}
                        currentRole={url}
                        currentMenuURL={CurrentURL}
                        onMenuClick={onClickMenuItem}
                    />
                </Box>
                <Box className='layoutMainContent'
                    style={{
                        width: open ? `calc(100% - ${webView})` : `calc(100% - ${mobileView})`
                    }}
                >
                    <Switch>
                        {getUserRoutes(ROUTES)}
                        <Redirect from="*" to="/teacher/teacher-dashboard" />
                    </Switch>
                </Box>

                {/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip
                            title="Profile"
                            arrow
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        background: "#0e1318",
                                    },
                                },
                            }}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={openProfile ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openProfile ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32, background: "#e0faef", color: "#00864e" }}>
                                    {
                                        userInfo &&
                                        userInfo.FName &&
                                        userInfo.FName !== "" &&
                                        userInfo.FName.charAt(0)
                                    }
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box> */}
                {/* <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={openProfile}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem>
                            <Avatar alt={userInfo.FName} src={Images.man} /> {userInfo.FName}
                        </MenuItem>
                        <MenuItem onClick={() => {
                            dispatch(
                                allActions.userLogout(() => {
                                    sessionStorage.Username = "";
                                    sessionStorage.Password = "";
                                    sessionStorage.userDomain = "";
                                    sessionStorage.Type = "";
                                    history.push("/");
                                })
                            );
                        }}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu> */}
                {/* </Box> */}
                {/* <Box className='SideMainStyle'> */}
                {/* <React.Fragment key={"left"}>
                        <Drawer
                            open={darwerLeft}
                            onClose={toggleMobileDrawer(false)}
                        >
                            {DrawerListItems()}
                        </Drawer>
                    </React.Fragment> */}

                {/* Menu */}
                {/* <Box className="menuList" sx={{ display: 'flex' }}>
                        <MDrawer
                            variant="permanent"
                            open={open}
                        >
                            {DrawerListItems()}
                        </MDrawer>
                    </Box> */}

                {/* Main Content */}
                {/* <Box className='rightMainMenu'>
                        <Switch>
                            {getUserRoutes(ROUTES)}
                            <Redirect from="*" to="/teacher/teacher-dashboard" />
                        </Switch>
                    </Box> */}
                {/* </Box> */}
            </Box>
        </ThemeProvider>
    );
}