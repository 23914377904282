import { useState, useEffect } from "react";

export const UseFilterSearch = ({ searchValue, retrieve }) => {

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        setFilteredData(retrieve);
    }, [retrieve]);

    useEffect(() => {
        const redistributeData = retrieve;
        if (searchValue) {
            const newFilteredData = redistributeData?.map(data => {
                const allValues = Object.values(data).join(" ");
                if (allValues.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
                    return data;
                return null;
            });
            setFilteredData(newFilteredData?.filter(value => {
                return value !== null
            }))
        } else {
            setFilteredData(redistributeData);
        }
    }, [searchValue]);

    return { filteredData };
};
