import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import Notfound from "./NotFoundScreen/NotFoundScreen";
import configStore from "../redux/configStore";
import LoginScreen from "./LoginScreen/LoginScreen";
import ClasslinkLogin from "./LoginScreen/ClasslinkLogin";

import AdminLayout from "../Layout/AdminLayout";
import TeacherLayout from "../Layout/TeacherLayout";
import SchoolLayout from "../Layout/SchoolLayout";
import DistrictLayout from "../Layout/DistrictLayout";

import "./index.scss";

const { store, persistor } = configStore();

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Switch>
            <Redirect
              exact
              from="/"
              to="/login"
              key={window.location.pathname}
            />
            <Route
              exact
              path="/login"
              component={LoginScreen}
              key={window.location.pathname}
            />
             <Route
              exact
              path="/cllogin"
              component={ClasslinkLogin}
              key={window.location.pathname}
            />
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            <Route
              path="/teacher"
              render={(props) => <TeacherLayout {...props} />}
            />
            <Route
              path="/school"
              render={(props) => <SchoolLayout {...props} />}
            />
            <Route
              path="/district"
              render={(props) => <DistrictLayout {...props} />}
            />
              <Route
              exact
              path="/classlinklogin"
              component={LoginScreen}
              key={window.location.pathname}
            />
            <Route component={Notfound} />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
