import React, {
    useEffect,
    useState
} from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Typography,
    Tooltip,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import "./SchoolDashboard.css";
import Logo from "../../constants/images";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import AllActions from "../../redux/ducks/actions";
import { useHistory } from "react-router-dom";
// Icons
import SchoolIcon from '@mui/icons-material/School';
import ClassIcon from '@mui/icons-material/Class';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import { ChartBar, ChartLine } from "@carbon/icons-react";
import Districtcard from "../../Admin_District/DistrictDashboard/Districtcard";
import CustomClassCard from "../../Components/CustomClassCard";
import { Grid, Column } from "@carbon/react";
import { FormatNumbers } from "../../constants";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    margin: "10px",
}));
const mdTheme = createTheme();

export default function SchoolDashboard() {
    const history = useHistory(); //history
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    // const url = useParams();
    const {
        schoolDashboardData,
        userInfo
    } = useSelector(state => state.data);

    useEffect(() => {
        dispatch(AllActions.getSchoolDashboardData(userInfo.SuperGroupID));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (schoolDashboardData && schoolDashboardData.ExpirationDate) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = schoolDashboardData.ExpirationDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolDashboardData, schoolDashboardData])

    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>

            <CustomHeader
                licenseStatus={licenseStatus}
                headerLabel={
                    userInfo.UserType === "restrictedschooladmin" ? "Restricted School Admin Dashboard" :
                        userInfo.UserType === "SuperGroupAdmin" ? "Principal Dashboard" :
                            `${schoolDashboardData && schoolDashboardData.District} District`
                }
            />

            <Box className="customWelcomeCard">
                <Box className="customWelcomePhase1">
                    <Box className="customWelcomePhase1">
                        <Box className="customWelcomeText1">School Name</Box>
                        <Box className="customWelcomeText2">{schoolDashboardData && schoolDashboardData.SchoolName}</Box>
                    </Box>
                </Box>
                <Box className="customWelcomePhase2">
                    {
                        userInfo.SourceID !== "" ? null : (
                            <React.Fragment>
                                <CustomTableButton
                                    buttonLabel={"Daily Summary"}
                                    onClickButton={() => history.push(`/school/school-dashboard/school-detail-activity/${userInfo.SuperGroupID}`)}
                                    buttonIcon={<ChartLine size={16} />}
                                />
                                <CustomTableButton
                                    buttonLabel={"Activity Summary"}
                                    onClickButton={() => history.push(`/school/school-dashboard/school-daily-activity/${userInfo.SuperGroupID}`)}
                                    buttonIcon={<ChartBar size={16} />}
                                />
                            </React.Fragment>
                        )
                    }
                </Box>
            </Box>

            <Box className='nsMainContent'>

                <Box className="nsMainHeader">
                    <Box className="nsMainTextHeader">
                        School Dashboard
                    </Box>
                </Box>

                <Districtcard cardType="SchoolUsageData" data={schoolDashboardData} />

                <Box
                    className="gridWrapper"
                    style={{
                        padding: "0.75rem"
                    }}
                >
                    <Grid>
                        <Column sm={4} md={4} lg={4}>
                            <CustomClassCard
                                cardLabel={FormatNumbers(schoolDashboardData && schoolDashboardData.Minutes)}
                                cardValue={"Minutes Played"}
                                backgroundColor={"linear-gradient(225deg, #D896FF 0%, #F57373 100%)"}
                            />
                        </Column>
                        <Column sm={4} md={4} lg={4}>
                            <CustomClassCard
                                cardLabel={FormatNumbers(schoolDashboardData && schoolDashboardData.ProblemsCompleted)}
                                cardValue={"Problems Solved"}
                                backgroundColor={"linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.00) 96.41%), linear-gradient(132deg, #667EEA 6.78%, #764BA2 93.86%)"}
                            />
                        </Column>
                        <Column sm={4} md={4} lg={4}>
                            <CustomClassCard
                                cardLabel={FormatNumbers(schoolDashboardData && schoolDashboardData.LevelsAttempted)}
                                cardValue={"Games Completed"}
                                backgroundColor={"linear-gradient(225deg, #41A7C4 0%, #3E72E8 100%)"}
                            />
                        </Column>
                        <Column sm={4} md={4} lg={4}>
                            <CustomClassCard
                                cardLabel={FormatNumbers(schoolDashboardData && schoolDashboardData.LevelsMastered)}
                                cardValue={"Levels Mastered"}
                                backgroundColor={"linear-gradient(225deg, #95CC4E 0%, #2ED8F7 100%)"}
                            />
                        </Column>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
