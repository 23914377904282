import React from 'react';
// import Card from './Card';
import { Box, Typography } from '@mui/material';
import { Column, Grid } from '@carbon/react';
import CardDistrict from './CardDistrict';

export default function Schoolcard(props) {

    return (
        <Grid className='nsAdminPadding'>
            <Column lg={8} md={4} sm={4}>
                <CardDistrict cardName="Total Teachers" data={props.data.TotalTeachers} />
            </Column>
            <Column lg={8} md={4} sm={4}>
                <CardDistrict cardName="Total Students" data={props.data.TotalStudents} />
            </Column>
        </Grid>
    )
}