import SmartToyIcon from '@mui/icons-material/SmartToy';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import { FormatNumbers } from '../../../constants';

export default function PerformCard(props) {
    return (
        <Box className={`nsADBCardWrapper ${props.styleName}`}>
            <Box className='nsADBCardText1'>{FormatNumbers(props.data)}</Box>
            <Box className='nsADBCardText2'>{props.cardName}</Box>
        </Box>
    )
}