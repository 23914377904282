/*----------------------------------Teacher Role----------------------------------*/
import TeacherDashboard from "./Admin_Group/TeacherDashboard/TeacherDashboard";
import TeacherClassUsageDataList from "./Admin_Group/TeacherClassUsageData/TeacherClassUsageDataList";
import TeacherClassUsageDataDashboard from "./Admin_Group/TeacherClassUsageData/TeacherClassUsageDataDashboard"
import TeacherClassDailySummary from "./Admin_Group/TeacherClassUsageData/TeacherClassDailySummary";
import TeacherClassDetailsSummary from "./Admin_Group/TeacherClassUsageData/TeacherClassDetailsSummary";
import ClassList from "./Admin_Group/TeacherWeeklyReports/ClassList";
import StudentUsageDataList from "./Admin_Group/StudentUsageData/StudentUsageDataList";
import StudentDailySummary from "./Admin_Group/StudentUsageData/StudentDailySummary";
import StudentDetailsSummary from "./Admin_Group/StudentUsageData/StudentDetailsSummary";
/*----------------------------------School Role----------------------------------*/
import SchoolDashboard from "./Admin_School/SchoolDashboard/SchoolDashboard";
import SchoolActivitySummary from "./Admin_School/SchoolActivity/SchoolActivitySummary";
import SchoolSummary from "./Admin_School/SchoolActivity/SchoolDetailSummary";
import ClassUsageDataSchoolList from "./Admin_School/ClassUsageData/ClassUsageDataSchoolList";
import ClassUsageDataDashboard from "./Admin_School/ClassUsageData/ClassUsageDataDashboard";
import SchoolClassDailySummary from "./Admin_School/ClassUsageData/SchoolClassDailySummary"
import SchoolClassDetailsSummary from "./Admin_School/ClassUsageData/SchoolClassDetailsSummary"
import TeacherUsageDataList from "./Admin_School/TeacherUsageData/TeacherUsageDataList"
import TeacherUsageDataDashboard from "./Admin_School/TeacherUsageData/TeacherUsageDataDashboard"
/*----------------------------------Admin Role----------------------------------*/
import AdminDashboard from "./Admin_Super/AdminDashboard/AdminDashboard";
import CreateDomain from "./Admin_Super/ManageDistrict/CreateDomain";
import CreateclasslinkDomain from "./Admin_Super/ManageDistrict/CreateclasslinkDomain";
import ManageDistrict from "./Admin_Super/ManageDistrict/ManageDistrict";
import UpdateDomain from "./Admin_Super/ManageDistrict/UpdateDomain";
import ManageProducts from "./Admin_Super/ManageProducts/ManageProducts";
import CreateProducts from "./Admin_Super/ManageProducts/CreateProducts";
import UpdateProduct from "./Admin_Super/ManageProducts/UpdateProducts";
import ManageClassLicense from "./Admin_Super/ManageClassLicense/ManageClassLicense"
import ClassLicensePurchaseHistory from "./Admin_Super/ManageClassLicense/ClassLicensePurchaseHistory";
import EditClassSubscription from "./Admin_Super/ManageClassLicense/ClassLicenseEdit";
import ManageDistrictLicense from "./Admin_Super/ManageDistrictLicense/ManageDistrictLicense";
import EditSubscription from "./Admin_Super/ManageDistrictLicense/EditSubscription";
import PurchaseHistory from "./Admin_Super/ManageDistrictLicense/PurchaseHistory";
import ManageUserLicense from "./Admin_Super/ManageUserLicense/ManageUserLicense";
import MUPurchaseHistory from "./Admin_Super/ManageUserLicense/MUPurchaseHistory";
import MUEditSubscription from "./Admin_Super/ManageUserLicense/MUEditSubcription";
import Notifications from "./Admin_Super/Notifications/Notifications";
import ViewAllNotifications from "./Admin_Super/Notifications/ViewAllNotifications";
/*----------------------------------District Role----------------------------------*/
import DistrictDashboard from "./Admin_District/DistrictDashboard/DistrictDashboard";
import ManageSchools from "./Admin_District/ManageSchools/ManageSchools";
import CreateSchools from "./Admin_District/ManageSchools/CreateSchools";
import UpdateSchools from "./Admin_District/ManageSchools/UpdateSchools";
import ManageClasses from "./Admin_District/ManageClasses/ManageClasses";
import CreateClasses from "./Admin_District/ManageClasses/CreateClasses";
import UpdateClasses from "./Admin_District/ManageClasses/UpdateClasses";
import SchoolBulkUpload from "./Admin_District/ManageSchools/SchoolBulkUpload";
import ClassDetails from "./Admin_District/ManageClasses/ClassDetails";
import ClassBulkUpload from "./Admin_District/ManageClasses/ClassBulkUpload";
import TeacherToClassMapping from "./Admin_District/ManageClasses/TeacherToClassMapping";
import SchoolUsageDataSchoolList from "./Admin_District/SchoolUsageData/SchoolUsageDataSchoolList";
import SchoolUsageDataDashboard from "./Admin_District/SchoolUsageData/SchoolUsageDataDashboard";
import ClassSummary from "./Admin_District/SchoolUsageData/ClassSummary";
import SchoolDetailsSummary from "./Admin_District/SchoolUsageData/SchoolDetailsSummary";
import StudentList from "./Admin_District/SchoolUsageData/ClassSummary/StudentList";
import ClassDailySummary from "./Admin_District/SchoolUsageData/ClassSummary/ClassDailySummary";
import ClassActivitySummary from "./Admin_District/SchoolUsageData/ClassSummary/ClassActivitySummary";
import SchoolDailySummary from "./Admin_District/SchoolUsageData/SchoolDailySummary";
import DailySummarySL from "./Admin_District/SchoolUsageData/ClassSummary/StudentList/DailySummarySL";
import ActivitySummarySL from "./Admin_District/SchoolUsageData/ClassSummary/StudentList/ActivitySummarySL";
import SchoolLeaderboard from "./Admin_District/SchoolLeaderboard/SchoolLeaderboard";
import ClassLeaderboard from "./Admin_District/ClassLeaderboard/ClassLeaderboard";
import ManageUsers from "./Admin_District/UserLogins/ManageUsers";
import CreateUsers from "./Admin_District/UserLogins/CreateUsers";
import WeeklyReports from "./Admin_District/WeeklyReports/WeeklyReports";
import MDLSubscribe from "./Admin_Super/ManageDistrictLicense/MDLSubscribe";
import DistrictDetails from "./Admin_Super/ManageDistrict/DistrictDetails";
import DistrictPurchaseHistory from "./Admin_District/Purchase History/DistrictPurchaseHistory";
import districtAdminNotifications from "./Admin_District/Notifications/DistrictAdminNotifications";
import DistrictAdminViewAllNotif from "./Admin_District/Notifications/DistrictAdminViewAllNotif";
import Assignments from "./Admin_Group/Assigments/Assignments";
import CreateAssignment from "./Admin_Group/Assigments/CreateAssignment";
import AssignmentReport from "./Admin_Group/Assigments/AssignmentReport";
import UpdateUsers from "./Admin_District/UserLogins/UpdateUsers";
import DistrictAdmin from "./Admin_District/UserLogins/DistrictAdmin";
import UserLoginsClassBulkUpload from "./Admin_District/UserLogins/UserLoginsClassBulkUpload";
import UserLoginsSchoolBulkUpload from "./Admin_District/UserLogins/UserLoginsSchoolBulkUpload";
import DistrictDailySummary from "./Admin_District/DistrictDashboard/DistrictDailySummary";
import DistrictDetailsSummary from "./Admin_District/DistrictDashboard/DistrictDetailsSummary";
import UpdateClassLink from "./Admin_Super/ManageDistrict/UpdateClassLink";

var ROUTES = [
  /*----------------------------------Teacher Role----------------------------------*/
  // => Teacher Dashboard
  {
    path: "/teacher-dashboard",
    component: TeacherDashboard,
    layout: "/teacher",
  },
  // {
  //   path: "/teacher-class-leaderboard",
  //   component: ClassLeaderboard,
  //   layout: "/teacher",
  // },
  {
    path: "/teacher-leaderboard",
    component: SchoolLeaderboard,
    layout: "/teacher",
  },
  {
    path: "/teacher-assignments",
    component: Assignments,
    layout: "/teacher",
  },
  {
    path: "/teacher-assignments/create",
    component: CreateAssignment,
    layout: "/teacher",
  },
  {
    path: "/teacher-assignments/report/:AssignmentID",
    component: AssignmentReport,
    layout: "/teacher",
  },
  {
    path: "/teacher-dashboard/class-details/:id",
    component: TeacherClassUsageDataDashboard,
    layout: "/teacher",
  },

  {
    path: "/teacher-dashboard/class-daily-summary/:id",
    component: TeacherClassDailySummary,
    layout: "/teacher",
  },

  {
    path: "/teacher-dashboard/class-details-summary/:id",
    component: TeacherClassDetailsSummary,
    layout: "/teacher",
  },

  {
    path: "/teacher-dashboard/student-list/:id",
    component: StudentList,
    layout: "/teacher",
  },
  {
    path: "/teacher-dashboard/studentlist-daily-summary/:id",
    component: DailySummarySL,
    layout: "/teacher",
  },
  {
    path: "/teacher-dashboard/studentlist-activity-summary/:id",
    component: ActivitySummarySL,
    layout: "/teacher",
  },
  {
    path: "/teacher-dashboard/class-weekly-report/:id",
    component: WeeklyReports,
    layout: "/teacher",
  },

  // {
  //   path: "/teacher-weekly-report",
  //   component: WeeklyReports,
  //   layout: "/teacher",
  // },
  {
    path: "/teacher-weekly-report",
    component: WeeklyReports,
    layout: "/teacher",
  },

  {
    path: "/teacher-student-usage-data",
    component: StudentUsageDataList,
    layout: "/teacher",
  },

  {
    path: "/teacher-student-usage-data/student-daily-summary/:id",
    component: StudentDailySummary,
    layout: "/teacher",
  },

  {
    path: "/teacher-student-usage-data/student-details-summary/:id",
    component: StudentDetailsSummary,
    layout: "/teacher",
  },
  /*----------------------------------School Role----------------------------------*/
  // => School Dashboard
  {
    path: "/school-dashboard",
    component: SchoolDashboard,
    layout: "/school",
  },

  {
    path: "/school-dashboard/school-daily-activity/:id",
    component: SchoolActivitySummary,
    layout: "/school",
  },

  {
    path: "/school-dashboard/school-detail-activity/:id",
    component: SchoolSummary,
    layout: "/school",
  },

  // => Manage Classses
  {
    path: "/school-manage-classes",
    component: ManageClasses,
    layout: "/school",
  },
  {
    path: "/school-manage-classes/create",
    component: CreateClasses,
    layout: "/school",
  },
  {
    path: "/school-manage-classes/update/:id",
    component: UpdateClasses,
    layout: "/school",
  },
  {
    path: "/school-manage-classes/class-details/:id",
    component: ClassDetails,
    layout: "/school",
  },
  {
    path: "/school-manage-classes/bulk-upload",
    component: ClassBulkUpload,
    layout: "/school",
  },
  {
    path: "/school-manage-classes/teacher-to-class-mapping",
    component: TeacherToClassMapping,
    layout: "/school",
  },

  // => School Usage Data
  {
    path: "/school-class-usage-data",
    component: ClassUsageDataSchoolList,
    layout: "/school",
  },
  {
    path: "/school-class-usage-data/class-details/:id",
    component: ClassUsageDataDashboard,
    layout: "/school",
  },

  {
    path: "/school-class-usage-data/class-daily-summary/:id",
    component: SchoolClassDailySummary,
    layout: "/school",
  },

  {
    path: "/school-class-usage-data/class-details-summary/:id",
    component: SchoolClassDetailsSummary,
    layout: "/school",
  },

  {
    path: "/school-class-usage-data/student-list/:id",
    component: StudentList,
    layout: "/school",
  },
  {
    path: "/school-class-usage-data/studentlist-daily-summary/:id",
    component: DailySummarySL,
    layout: "/school",
  },
  {
    path: "/school-class-usage-data/studentlist-activity-summary/:id",
    component: ActivitySummarySL,
    layout: "/school",
  },
  {
    path: "/school-class-leaderboard",
    component: ClassLeaderboard,
    layout: "/school",
  },
  {
    path: "/school-student-leaderboard",
    component: SchoolLeaderboard,
    layout: "/school",
  },

  // => Teacher Usage Data
  {
    path: "/school-teacher-usage-data",
    component: TeacherUsageDataList,
    layout: "/school",
  },
  {
    path: "/school-teacher-usage-data/teacher-details/:id",
    component: TeacherUsageDataDashboard,
    layout: "/school",
  },
  {
    path: "/school-teacher-usage-data/school-daily-summary/:id",
    component: SchoolDailySummary,
    layout: "/school",
  },
  {
    path: "/school-teacher-usage-data/school-details-summary/:id",
    component: SchoolDetailsSummary,
    layout: "/school",
  },

  // Weekly reports
  {
    path: "/school-weekly-report",
    component: WeeklyReports,
    layout: "/school",
  },

  // => User Logins
  {
    path: "/school-user-logins",
    component: ManageUsers,
    layout: "/school",
  },
  {
    path: "/school-user-logins/create",
    component: CreateUsers,
    layout: "/school",
  },
  {
    path: "/school-user-logins/update/:id",
    component: UpdateUsers,
    layout: "/school",
  },
  {
    path: "/school-user-logins/class-bulk-upload",
    component: UserLoginsClassBulkUpload,
    layout: "/school",
  },
  {
    path: "/school-user-logins/school-bulk-upload",
    component: UserLoginsSchoolBulkUpload,
    layout: "/school",
  },

  /*----------------------------------Admin Role----------------------------------*/
  // => Admin Dashboard
  {
    path: "/admin-dashboard",
    component: AdminDashboard,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district",
    component: ManageDistrict,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district/create",
    component: CreateDomain,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district/createclasslink",
    component: CreateclasslinkDomain,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district/update/:id",
    component: UpdateDomain,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district/classlink-update/:id",
    component: UpdateClassLink,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district/details/:id",
    component: DistrictDetails,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district-license",
    component: ManageDistrictLicense,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district-license/edit_subscription/:DistrictID",
    component: EditSubscription,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district-license/district_license_history/:id",
    component: PurchaseHistory,
    layout: "/admin",
  },
  {
    path: "/admin-manage-district-license/create_subscription/:DistrictID",
    component: MDLSubscribe,
    layout: "/admin",
  },
  {
    path: "/admin-manage-user-license",
    component: ManageUserLicense,
    layout: "/admin",
  },
  {
    path: "/admin-manage-user-license/user_license_history/:id",
    component: MUPurchaseHistory,
    layout: "/admin",
  },
  {
    path: "/admin-manage-user-license/edit_subscription/:UserID/:ProductID",
    component: MUEditSubscription,
    layout: "/admin",
  },
  {
    path: "/admin-notification",
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/admin-notification/viewAll",
    component: ViewAllNotifications,
    layout: "/admin",
  },
  {
    path: "/admin-manage-products",
    component: ManageProducts,
    layout: "/admin",
  },
  {
    path: "/admin-manage-products/create",
    component: CreateProducts,
    layout: "/admin",
  },
  {
    path: "/admin-manage-products/update/:id",
    component: UpdateProduct,
    layout: "/admin",
  },
  // => Manage Class License
  {
    path: "/admin-manage-class-license",
    component: ManageClassLicense,
    layout: "/admin",
  },
  {
    path: "/admin-manage-class-license/class-license-history/:id",
    component: ClassLicensePurchaseHistory,
    layout: "/admin",
  },

  {
    path: "/admin-manage-class-license/edit_class_subscription/:DistrictID/:ProductID/:SubscriptionDate",
    component: EditClassSubscription,
    layout: "/admin",
  },
  /*----------------------------------District Role----------------------------------*/
  // => District Dashboard
  {
    path: "/district-dashboard",
    component: DistrictDashboard,
    layout: "/district",
  },
  {
    path: "/district-dashboard/daily-summary/:id",
    component: DistrictDailySummary,
    layout: "/district",
  },
  {
    path: "/district-dashboard/details-summary/:id",
    component: DistrictDetailsSummary,
    layout: "/district",
  },
  // => Manage Schools
  {
    path: "/district-manage-schools",
    component: ManageSchools,
    layout: "/district",
  },
  {
    path: "/district-manage-schools/create",
    component: CreateSchools,
    layout: "/district",
  },
  {
    path: "/district-manage-schools/update/:id",
    component: UpdateSchools,
    layout: "/district",
  },
  {
    path: "/district-manage-schools/bulk-upload",
    component: SchoolBulkUpload,
    layout: "/district",
  },
  // => Manage Classses
  {
    path: "/district-manage-classes",
    component: ManageClasses,
    layout: "/district",
  },
  {
    path: "/district-manage-classes/create",
    component: CreateClasses,
    layout: "/district",
  },
  {
    path: "/district-manage-classes/update/:id",
    component: UpdateClasses,
    layout: "/district",
  },
  {
    path: "/district-manage-classes/class-details/:id",
    component: ClassDetails,
    layout: "/district",
  },
  {
    path: "/district-manage-classes/bulk-upload",
    component: ClassBulkUpload,
    layout: "/district",
  },
  {
    path: "/district-manage-classes/teacher-to-class-mapping",
    component: TeacherToClassMapping,
    layout: "/district",
  },
  // => Purchase History
  {
    path: "/district-purchase-History",
    component: DistrictPurchaseHistory,
    layout: "/district",
  },

  {
    path: "/district-notification",
    component: districtAdminNotifications,
    layout: "/district",
  },

  {
    path: "/district-notification/viewAll",
    component: DistrictAdminViewAllNotif,
    layout: "/district",
  },


  // => School Usage Data
  {
    path: "/district-school-usage-data",
    component: SchoolUsageDataSchoolList,
    layout: "/district",
  },
  {
    path: "/district-school-usage-data/school-details/:id",
    component: SchoolUsageDataDashboard,
    layout: "/district",
  },
  {
    path: "/district-school-usage-data/school-daily-summary/:id",
    component: SchoolDailySummary,
    layout: "/district",
  },
  {
    path: "/district-school-usage-data/school-details-summary/:id",
    component: SchoolDetailsSummary,
    layout: "/district",
  },
  {
    path: "/district-school-usage-data/class-summary/:id",
    component: ClassSummary,
    layout: "/district",
  },
  {
    path: "/district-school-usage-data/student-list/:id",
    component: StudentList,
    layout: "/district",
  },
  {
    path: "/district-school-usage-data/class-daily-summary/:id",
    component: ClassDailySummary,
    layout: "/district",
  },
  {
    path: "/district-school-usage-data/class-activity-summary/:id",
    component: ClassActivitySummary,
    layout: "/district",
  },
  {
    path: "/district-school-usage-data/studentlist-daily-summary/:id",
    component: DailySummarySL,
    layout: "/district",
  },
  {
    path: "/district-school-usage-data/studentlist-activity-summary/:id",
    component: ActivitySummarySL,
    layout: "/district",
  },
  {
    path: "/district-class-leaderboard",
    component: ClassLeaderboard,
    layout: "/district",
  },
  {
    path: "/district-student-leaderboard",
    component: SchoolLeaderboard,
    layout: "/district",
  },
  // => User Logins
  {
    path: "/district-user-logins",
    component: ManageUsers,
    layout: "/district",
  },
  {
    path: "/district-user-logins/create",
    component: CreateUsers,
    layout: "/district",
  },
  {
    path: "/district-user-logins/update/:id",
    component: UpdateUsers,
    layout: "/district",
  },
  {
    path: "/district-user-logins/district-admin-users",
    component: DistrictAdmin,
    layout: "/district",
  },
  {
    path: "/district-user-logins/restricted-admin/update/:id",
    component: UpdateUsers,
    layout: "/district",
  },
  {
    path: "/district-user-logins/class-bulk-upload",
    component: UserLoginsClassBulkUpload,
    layout: "/district",
  },
  {
    path: "/district-user-logins/school-bulk-upload",
    component: UserLoginsSchoolBulkUpload,
    layout: "/district",
  },
  // Weekly reports
  {
    path: "/district-weekly-report",
    component: WeeklyReports,
    layout: "/district",
  },
];

export default ROUTES;
