import React from 'react';
import Card from './Card';
import { Box, Typography } from '@mui/material';
import { Column, Grid } from '@carbon/react';

export default function CardContainer(props) {

    let displayData;
    switch (props.name) {
        case "Registrations":
            displayData =
                <Grid className='nsAdminPadding'>
                    <Column lg={4} md={4} sm={4}>
                        <Card cardName="Total Districts" data={props.data.TotalDistricts} />
                    </Column>
                    <Column lg={4} md={4} sm={4}>
                        <Card cardName="Total Schools" data={props.data.TotalSchools} />
                    </Column>
                    <Column lg={4} md={4} sm={4}>
                        <Card cardName="Total Classes" data={props.data.TotalClasses} />
                    </Column>
                    <Column lg={4} md={4} sm={4}>
                        <Card cardName="Total Users" data={props.data.TotalUsers} />
                    </Column>
                </Grid>
            break;
        case "Subscriptions":
            displayData =
                <Grid className='nsAdminPadding'>
                    <Column lg={4} md={4} sm={4}>
                        <Card cardName="Total Districts" data={props.data.TotalSubscribedDistricts} />
                    </Column>
                    <Column lg={4} md={4} sm={4}>
                        <Card cardName="Total Schools" data={props.data.TotalSubscribedSchools} />
                    </Column>
                    <Column lg={4} md={4} sm={4}>
                        <Card cardName="Total Classes" data={props.data.TotalSubscribedClasses} />
                    </Column>
                    <Column lg={4} md={4} sm={4}>
                        <Card cardName="Total Users" data={props.data.TotalSubscribedUsers} />
                    </Column>
                </Grid>
            break;
        default:
            displayData =
                <Column lg={4} md={4} sm={4}>
                    <Card cardName="Total Districts" data={"N/A"} />
                    <Card cardName="Total Schools" data={"N/A"} />
                    <Card cardName="Total Classes" data={"N/A"} />
                    <Card cardName="Total Users" data={"N/A"} />
                </Column>
    }
    return (
        <React.Fragment>
            {displayData}
        </React.Fragment>
    )
}