import React, { useEffect, useState } from "react";
import "./LoginCarousel.css";
import { ChevronLeft, ChevronRight } from "@carbon/icons-react";
import Image1 from "../../constants/images/Group 2581.png";
import Image2 from "../../constants/images/Group 2582.png";
import Image3 from "../../constants/images/Group 2583.png";

export default function LoginCarousel() {
    const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
    const onBoardingMessage = [
        "Practice and Play",
        "Get to know your students’ progress",
        "Engage them in friendly competitions",
    ];
    const subOnBordingMessage = [
        "Allow your students to practice and master their math skills and encourage them to keep playing to enhance critical thinking",
        "Help your students develop their problem solving skills while always being up to date on their progress",
        "Let your students challenge their friends and play against them while earning exciting rewards",
    ]

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNextSlide();
        }, 4000);

        return () => {
            clearInterval(intervalId);
        };
    }, [currentSlideNumber]);

    const handleNextSlide = () => {
        setCurrentSlideNumber((prevSlideNumber) =>
            prevSlideNumber < 2 ? prevSlideNumber + 1 : 0
        );
    };

    const handlePrevSlide = () => {
        setCurrentSlideNumber((prevSlideNumber) =>
            prevSlideNumber > 0 ? prevSlideNumber - 1 : 2
        );
    };

    return (
        <React.Fragment>
            <div className="logo-frame">
                <div className="knovo-text">NumberSense</div>
                <div className="knovo-console">Admin_Console<span className="version-text">V-1.00FE</span></div>
            </div>
            <div className="carosuelFrame">
                <div className="carosuel">
                    <div className="carosuelBottom">
                        {
                            currentSlideNumber === 0
                                ? (
                                    <img
                                        src={Image1}
                                        alt="Group-1"
                                        className="lottieParent"
                                    />
                                )
                                : currentSlideNumber === 1
                                    ? (
                                        <img
                                            src={Image2}
                                            alt="Group-1"
                                            className="lottieParent"
                                        />
                                    )
                                    : (
                                        <img
                                            src={Image3}
                                            alt="Group-1"
                                            className="lottieParent"
                                        />
                                    )
                        }
                    </div>
                    <div className="carosuelTop">
                        <span className="carosuelText">
                            {onBoardingMessage[currentSlideNumber]}
                        </span>
                        <span className="carosuelSubText">
                            {subOnBordingMessage[currentSlideNumber]}
                        </span>
                    </div>
                </div>
                <div
                    className="caroselIcons boarding-left-arrow"
                    onClick={handlePrevSlide}
                >
                    <ChevronLeft className="carouselArrow" />
                </div>
                <div
                    className="caroselIcons boarding-right-arrow"
                    onClick={handleNextSlide}
                >
                    <ChevronRight className="carouselArrow" />
                </div>
                <div className="boarding-pagination-container">
                    {
                        onBoardingMessage.map((item, index) => {
                            return (
                                <div
                                    key={index.toString()}
                                    className="pagination-dot"
                                    style={{
                                        backgroundColor:
                                            currentSlideNumber === index
                                                ? "#000000"
                                                : "rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}