import {
    Alert,
    Box,
    Typography,
    Button,
    Snackbar,
    Stack,
    Backdrop
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
    SCHOOL_NAME_UPLOAD_SUCCESS,
    INVALID_DATA,
    SCHOOL_LIST_EMPTY,
    INVALID_COLUMN_DATA,
    NO_DATA,
} from "../../constants";
import Logo from "../../constants/images";
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import XLSX from "xlsx";
import Creators from "../../redux/ducks/actions";
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import { Close, Download, Upload } from "@carbon/icons-react";

function SchoolBulkUpload() {
    const [xlsxfile, setxlsxFile] = React.useState(null);
    const [duplicateCheck, setDuplicateCheck] = React.useState(true);
    const [duplicateRowsInFile, setDuplicateRowsInFile] = React.useState("");
    const [fileData, setFileData] = React.useState(null);
    const history = useHistory();
    const dispatch = useDispatch(); // Dispatch
    const { userInfo } = useSelector(state => state.data);
    // colors
    const Btncolor = "#2d8f3c";

    // Loading
    const [loading, setLoading] = React.useState(false);
    //   const handleLoadingClose = () => {
    //     setLoading(false);
    //   };

    // Action Status
    const [snackbar, setSnackbar] = React.useState({
        snackStatus: false,
        snackMessageFor: "update",
    });
    const { snackStatus, snackMessageFor } = snackbar;

    const handleSnackbarClick = (msg) => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: true,
            snackMessageFor: msg,
        }));
    };

    const handleSnackbarClose = () => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: false,
            snackMessageFor: "",
        }));
    };

    React.useEffect(() => {
        if (fileData) {
            setDuplicateCheck(false);
        }
    }, [fileData])

    React.useEffect(() => {
        setTimeout(() => {
            let msgBox1 = document.getElementsByClassName("sc-hKMtZM");
            let msgBox2 = document.getElementsByClassName("cXBrtO");
            if (msgBox1 && (
                msgBox1[0].innerText !== "Upload or drop a file right here" ||
                msgBox1[0].innerText === "Uploaded Successfully!. Upload another?"
            )) {
                msgBox1[0].innerHTML = xlsxfile && xlsxfile.name + " - Uploaded Successfully!.<br/> Click or drop a file right here to replace file.";
            } else if (msgBox2 && (
                msgBox2[0].innerText !== "Upload or drop a file right here" ||
                msgBox2[0].innerText === "Uploaded Successfully!. Upload another?"
            )) {
                msgBox2[0].innerHTML = xlsxfile && xlsxfile.name + " - Uploaded Successfully!. <br/>Click or drop a file right here to replace file.";
            }
        }, 700)
    }, [xlsxfile])

    // For Image
    const fileTypes = ["XLSX"];

    // handle xsls file
    const handleChange = (file) => {
        /* Boilerplate to set up FileReader */
        setxlsxFile(file)
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            setFileData(data);
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    const uploadSchoolList = () => {
        setLoading(true);
        if (!fileData || fileData.length === 0) {
            setLoading(false);
            handleSnackbarClick("noFile");
            return null;
        }
        let isColumnMatch = 0;
        let columnNames = [
            "School Name",
            "School Description",
        ];
        let allSchoolDetails = [];
        for (let sample of fileData) {
            if (sample.length !== 0 && sample[0]) {
                let dataObject = {
                    SchoolName: sample[0],
                    SchoolDescrption: sample[1]
                }
                allSchoolDetails.push(dataObject);
            }
        }
        // checking column names
        if (allSchoolDetails && allSchoolDetails.length > 1) {
            const columnCheck = Object.values(allSchoolDetails[0])
            columnCheck.map(data => {
                if (!columnNames.includes(data)) {
                    isColumnMatch = 1;
                }
                return null
            })
        } else {
            isColumnMatch = 2;
        }
        if (isColumnMatch === 2) {
            setLoading(false);
            handleSnackbarClick("noData");
        } else if (isColumnMatch === 1) {
            setLoading(false);
            handleSnackbarClick("inValidColumnName");
        } else {
            let userList = allSchoolDetails.filter((data, index) => index !== 0 && data);
            let updateData = {
                DomainId: userInfo.DomainID,
                SchoolList: userList
            }
            dispatch(
                Creators.schoolsBulkUpload(updateData, (response) => {
                    if (response.MessageID === 4) {
                        setLoading(false);
                        // School name already exist Error
                        let duplicateSchoolNames = "";
                        if (response.Messages.length > 0) {
                            response.Messages.map(data => {
                                // eslint-disable-next-line no-useless-concat
                                duplicateSchoolNames = duplicateSchoolNames + (`Row number ${data.RowNumber} has ${data.Message}` + "\n");
                                return null;
                            })
                        }
                        setDuplicateRowsInFile(duplicateSchoolNames);
                        handleSnackbarClick("duplicateError");
                        return null;
                    } else if (response.MessageID === 3) {
                        setLoading(false);
                        // Invalida data
                        handleSnackbarClick("invalidData");
                        return null;
                    } else if (response.MessageID === 1) {
                        setLoading(false);
                        // Invalida data
                        handleSnackbarClick("schoolListEmpty");
                        return null;
                    } else if (response.MessageID === 0) {
                        setLoading(false);
                        // School Update Success Message
                        handleSnackbarClick("update");
                        setTimeout(() => {
                            history.push("/district/district-manage-schools");
                        }, 1000);
                    } else if (response.MessageID === 5) {
                        setLoading(false);
                        // Invalida data
                        handleSnackbarClick("duplicatedata");
                        return null;
                    } else {
                        setLoading(false);
                        console.error(response, "response");
                    }
                })
            );
        }
    }


    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])


    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            <Stack spacing={2} sx={{ maxWidth: 1000 }}>
                <Snackbar
                    key="SnackBar"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackStatus}
                    onClose={handleSnackbarClose}
                >
                    {snackMessageFor === "update" ? (
                        <Alert
                            variant="filled"
                            severity="success"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#00c853",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {SCHOOL_NAME_UPLOAD_SUCCESS}
                        </Alert>
                    ) : (
                        <Alert
                            variant="filled"
                            severity="warning"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#ffc107",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {snackMessageFor === "duplicateError"
                                ? duplicateRowsInFile
                                : snackMessageFor === "inValidColumnName"
                                    ? INVALID_COLUMN_DATA
                                    : snackMessageFor === "noData"
                                        ? NO_DATA
                                        : snackMessageFor === "invalidData"
                                            ? INVALID_DATA
                                            : snackMessageFor === "schoolListEmpty"
                                                ? SCHOOL_LIST_EMPTY : snackMessageFor === "noFile" ? "Please upload a file" : snackMessageFor === "duplicatedata" ? "Duplicate data found"
                                                    : ""}
                        </Alert>

                    )}
                </Snackbar>
            </Stack>
            <Box className='districtStyle'>
                <CustomHeader
                    licenseStatus={licenseStatus}
                    needBreadCrumb={true}
                    previousPageLabel={"Manage School"}
                    currentPageLabel={"Bulk Upload School"}
                    onClickPrevPageLabel={() => { history.push("/district/district-manage-schools") }}
                />

                <Box className="customWelcomeCard">
                    <Box className="customWelcomePhase1">
                        <Box className='weeklyReportWrapper'>
                            {/* <Box className="customWelcomePhase1">
                                <Box className="customWelcomeText1">School Name</Box>
                                <Box className="customWelcomeText2">{"schoolUsageData && schoolUsageData.schoolName"}</Box>
                            </Box> */}
                        </Box>
                    </Box>
                    <Box className="customWelcomePhase2">
                        <a
                            className="anchorStyle"
                            style={{ textDecoration: "none" }}
                            href={require("./Sample_Bulkupload_Schools.xlsx")}
                            download="Sample_Bulkupload_Schools"
                        >
                            <Download size={16} />
                            Download Sample file
                        </a>
                    </Box>
                </Box>

                <Box className='nsMainContent'>
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            Bulk Upload
                        </Box>
                        <Box className="customHeaderButton">
                        </Box>
                    </Box>

                    <Box className="gridWrapper bulkUploadHead fileuploadStyle" style={{ padding: "0.75rem" }}>
                        <FileUploader handleChange={handleChange} name="file" types={fileTypes} className='bulkupLoadSchool' />
                        <Box className="bulkUploadPhase2">
                            <Box></Box>
                            <Box className="createButtonParent">
                                <Box className="createButtonChild">
                                    <CustomTableButton
                                        isInActive={true}
                                        buttonLabel={"Cancel"}
                                        onClickButton={() => {
                                            history.push("/district/district-manage-schools")
                                        }}
                                        buttonIcon={<Close size={16} />}
                                    />
                                </Box>
                                <Box className="createButtonChild">
                                    <CustomTableButton
                                        isInActive={false}
                                        disabled={duplicateCheck}
                                        buttonLabel={"Upload"}
                                        onClickButton={uploadSchoolList}
                                        buttonIcon={<Upload size={16} />}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SchoolBulkUpload;