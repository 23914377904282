import PropTypes from "prop-types";
import { Invalid_Email, maxRequired, minRequired, This_Field_Required } from "../../constants";
// import { Close } from "react-icons/ai";
import React, { useEffect } from 'react';
import CustomCheckBox from "./CustomCheckbox";
import { Close, View, ViewOff } from "@carbon/icons-react";
export default function CustomInput({
    hideLabel,
    name,
    type,
    label,
    refValue,
    options,
    value,
    notes,
    isRequired,
    showPassword,
    min,
    max,
    allowNegativeValue,
    onHandleChange,
    onHandleBlur,
    disabled,
    error,
    errorMessage,
    handleBlurState,
    needErrorCheck,
    hide,
}) {

    function checkError(value, type, name) {
        const emailPattern = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
        let fieldError = {
            isError: false,
            Message: "",
            fieldname: "",
        };;
        if (isRequired && value === "") {
            // Required Validation
            fieldError = {
                isError: true,
                Message: This_Field_Required,
                fieldname: name,
            };
        } else if (min && value.length < min) {
            // Min length Validation
            fieldError = {
                isError: true,
                Message: minRequired(min),
                fieldname: name,
            };
        } else if (max && value.length > max) {
            // max length Validation
            fieldError = {
                isError: true,
                Message: maxRequired(max),
                fieldname: name,
            };
        } else if (type === "email" && !value.match(emailPattern)) {
            // Email Validation
            fieldError = {
                isError: true,
                Message: Invalid_Email,
                fieldname: name,
            };
        }
        onHandleChange(current =>
            current.map(object => {
                if (object.name === name) {
                    return {
                        ...object,
                        error: fieldError.isError,
                        errorMessage: fieldError.Message,
                        value: value
                    }
                }
                return object;
            })
            , value);
    }

    function handleErrors(event) {
        const { target: { value, type, name } } = event;
        checkError(value, type, name)
    }

    function handleSelectErrors(value) {
        let fieldError = {
            isError: false,
            Message: "",
            fieldname: "",
        };;
        if (isRequired && value === "") {
            fieldError = {
                isError: true,
                Message: This_Field_Required,
                fieldname: name,
            };
        }
        onHandleChange(current =>
            current.map(object => {
                if (object.name === name) {
                    return {
                        ...object,
                        error: fieldError.isError,
                        errorMessage: fieldError.Message,
                        value: value
                    }
                }
                return object;
            })
        );
    }

    function handleBlurSelectErrors(value) {
        let fieldError = {
            isError: false,
            Message: "",
            fieldname: "",
        };;
        if (!options.includes(value)) {
            fieldError = {
                isError: true,
                Message: This_Field_Required,
                fieldname: name,
            };
        }
        onHandleChange(current =>
            current.map(object => {
                if (object.name === name) {
                    return {
                        ...object,
                        error: fieldError.isError,
                        errorMessage: fieldError.Message,
                        value: !options.includes(value) ? "" : value
                    }
                }
                return object;
            })
        );
        handleBlurState && handleBlurState(name, value);
    }

    function handleShowPassword(value) {
        onHandleChange(current =>
            current.map(object => {
                if (object.name === name && object.type === "password") {
                    return {
                        ...object,
                        showPassword: value
                    }
                }
                return object;
            })
        );
    }

    useEffect(() => {
        if (needErrorCheck) {
            checkError(value, type, name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needErrorCheck, value])

    if (type === "textarea") {
        return (
            <div className="custom-input-main">
                <label className="custom-input-label" htmlFor={name}>
                    {label}<span className="optional"> {isRequired ? "" : "(Optional)"}</span>
                </label>
                <textarea
                    id={name}
                    name={name}
                    rows="4"
                    disabled={disabled}
                    className="custom-input"
                    placeholder={"Type " + label}
                    ref={refValue}
                    value={value}
                    style={{
                        border: error ? "2px solid #ff4d4f" : "",
                    }}
                    onChange={(e) => {
                        handleErrors(e)
                    }}
                    onBlur={(e) => {
                        handleErrors(e)
                        onHandleBlur && onHandleBlur(e)
                    }}
                    maxLength={max}
                />
                <span
                    className={error ? "required" : "not-required"}>
                    <Close />{errorMessage}
                </span>
            </div>
        );
    } else if (type === "check") {
        return (
            <div
                className="custom-input-main"
                style={{
                    display: hide ? "none" : undefined
                }}
            >
                <label className="custom-input-label" htmlFor={name}>
                    {label}<span className="optional"> {isRequired ? "" : "(Optional)"}</span>
                </label>
                <CustomCheckBox
                    name={name}
                    text={label}
                    isChecked={value}
                    onHandleClick={(value) => checkError(value, type, name)}
                />
                <span
                    className={error ? "required" : "not-required"}>
                    <Close />{errorMessage}
                </span>
            </div>
        );
    }

    return (
        <div className="custom-input-main">
            {
                hideLabel ? null : (
                    <label className="custom-input-label" htmlFor={name}>
                        {label}<span className="optional"> {isRequired ? "" : "(Optional)"}</span>
                    </label>
                )
            }
            <input
                id={name}
                name={name}
                className="custom-input"
                type={type === "password" && showPassword ? "text" : type}
                onKeyDown={(e) => (
                    (type === "number" && e.key === "e") ||
                    (type === "number" && !allowNegativeValue &&
                        (e.key === "-" || e.key === "+" || e.key === ".")
                    )
                ) && e.preventDefault()}
                placeholder={hideLabel ? label : "Type " + label}
                ref={refValue}
                value={value}
                style={{
                    border: error ? "2px solid #ff4d4f" : "",
                }}
                onChange={(e) => {
                    handleErrors(e)
                }}
                onBlur={(e) => {
                    handleErrors(e)
                    onHandleBlur && onHandleBlur(e)
                }}
                disabled={disabled}
                minLength={min}
                maxLength={max}
            />
            {type === "password" ?
                <span
                    className="show-password"
                    // style={{
                    //     top: error ? "29%" : "42%",
                    // }}
                >
                    <CustomCheckBox
                        name="showPassword"
                        text={showPassword ? <ViewOff /> : <View />}
                        isChecked={showPassword}
                        size="small"
                        hideCheckBox={true}
                        onHandleClick={value => handleShowPassword(value)}
                    />
                </span>
                : null
            }
            <span
                className={error ? "required" : "not-required"}>
                <Close />{errorMessage}
            </span>
            {notes ? <span className="input-note">Note: {notes}</span>
                : null
            }

        </div>
    );
}
CustomInput.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    refValue: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    isRequired: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    options: PropTypes.array,
    notes: PropTypes.string,
    showPassword: PropTypes.bool,
    allowNegativeValue: PropTypes.bool,
    onHandleChange: PropTypes.func.isRequired,
    onHandleBlur: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    handleBlurState: PropTypes.func,
    disabled: PropTypes.bool,
    needErrorCheck: PropTypes.bool,
}

CustomInput.defaultProps = {
    options: [],
    needErrorCheck: false
}