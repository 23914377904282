import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Autocomplete,
  Tooltip
} from '@mui/material';
import allActions from "../../redux/ducks/actions";
import "./ManageClasses.css";
import { GridActionsCellItem, } from "@mui/x-data-grid";
import InfoIcon from '@mui/icons-material/Info';
import { Edit } from '@carbon/icons-react';

function ClassesTable({ selectValue }) {
  const editAction = "#b58119";
  const purchaseAction = "#034c96";

  const history = useHistory();
  const dispatch = useDispatch(); //dispatch
  const { userInfo, listAllSchools, classSchoolData } = useSelector((state) => state.data);
  const [tableRow, setTableRow] = useState([]);
  const [schoolNameOptions, setSchoolNameOptions] = useState({});
  const defaultData = {
    options: [],
    getOptionLabel: (option) => option,
  };
  const [value, setValue] = React.useState(null);
  useEffect(() => {
    if (userInfo && (
      userInfo.UserType === "Supergrouptechadmin" ||
      userInfo.UserType === "SuperGroupAdmin"
    )
    ) {
      dispatch(allActions.getAllClassList(userInfo.SuperGroupID, (response) => {
        setTableRow(response);
      }));
    } else if (listAllSchools && listAllSchools.length > 0) {
      let allSchoolNameList = [];
      listAllSchools && listAllSchools.map(data => {
        allSchoolNameList.push({
          label: data && data.Name && (data.Name).trim(),
          id: data.ID
        });
        return null;
      })
      let defaultProps = {
        options: allSchoolNameList,
        getOptionLabel: (option) => option.label
      };
      setSchoolNameOptions(defaultProps);
      if (classSchoolData) {
        setValue(classSchoolData)
      } else {
        setValue(allSchoolNameList[0]);
        dispatch(allActions.saveCurrentSchoolInClass(allSchoolNameList[0]))
      }
      dispatch(allActions.getAllClassList(allSchoolNameList[0].id, (response) => {
        setTableRow(response);
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
      dispatch(allActions.getAllClassList(userInfo.SuperGroupID, (response) => {
        setTableRow(response);
      }));
    } else {
      if (selectValue) {
        dispatch(allActions.getAllClassList(selectValue.ID, (response) => {
          setTableRow(response);
        }));
      }
    }
  }, [selectValue, dispatch, userInfo])

  const columns = [
    "Name",
    "Description",
    "Total Students",
    "Start Date",
    {
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box className="actionWrapper">
              <Tooltip
                title="Class Details"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value;
                  if (
                    userInfo && (
                      userInfo.UserType === "Supergrouptechadmin" ||
                      userInfo.UserType === "SuperGroupAdmin"
                    )) {
                    history.push(`school-manage-classes/class-details/${rowId}`);
                  } else {
                    history.push(`district-manage-classes/class-details/${rowId}`);
                  }
                }}
              >
                <Box className='customActionIcon'>
                  <InfoIcon size={16} color='#FFF' />
                </Box>
              </Tooltip>

              {
                userInfo && (
                  userInfo.UserType === "SuperGroupAdmin" ||
                  userInfo.UserType === "restricteddistrictadmin" ||
                  userInfo.UserType === "restrictedschooladmin"
                ) ? null :
                  userInfo.SourceID !== "" ? null :
                    <Tooltip
                      title="Edit"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            background: "#0e1318",
                          },
                        },
                      }}
                      onClick={() => {
                        let rowId = value;
                        if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                          history.push(`school-manage-classes/update/${rowId}`);
                        } else {
                          history.push(`district-manage-classes/update/${rowId}`);
                        }
                      }}
                    >
                      <Box className='customActionIcon'>
                        <Edit size={16} color='#FFF' />
                      </Box>
                    </Tooltip>
              }
            </Box>
          )
        }
      }
    }
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };
  return (
    <Box>
      <MUIDataTable
        title={"Class List"}
        data={tableRow ? tableRow.map(data => {
          return [
            data.Name,
            data.Desc,
            data.TotalStudents,
            data.StartDate,
            data.ID,
          ]
        }) : []}
        columns={columns}
        options={options}
      />
    </Box>
  );
}
export default ClassesTable;
