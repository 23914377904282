// import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import {
  Box,
  Tooltip,
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ManageSchools.css";
import { useSelector } from "react-redux";
import { GridActionsCellItem, } from "@mui/x-data-grid";
import { Edit } from '@carbon/icons-react';

function SchoolsTable(props) {
  const editAction = "#b58119";
  const history = useHistory();
  const { userInfo, listAllSchools } = useSelector((state) => state.data);
  const [tableRow, setTableRow] = useState([]);

  useEffect(() => {
    console.log(listAllSchools,"listAllSchools");
    setTableRow(listAllSchools);
  }, [listAllSchools]);

  const columns = [
    "Name",
    "Description",
    "Start Date",
    "Total Students",
    "Total Teachers",
    "District Name",
    {
      label: userInfo.UserType === "restricteddistrictadmin" || userInfo.SourceID !== "" ? "" : "Action",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (userInfo.UserType === "restricteddistrictadmin" || userInfo.SourceID !== "") {
            return null;
          }
          return (
            <Box className="actionWrapper">
              <Tooltip
                title="Edit"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value;
                  history.push(`district-manage-schools/update/${rowId}`);
                }}
              >
                <Box className='customActionIcon'>
                  <Edit size={16} color='#FFF' />
                </Box>
              </Tooltip>
            </Box>
          )
        }
      }
    }
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };

  return (
    <MUIDataTable
      title={"School"}
      data={tableRow && tableRow.map(data => {
        return [
          data.Name,
          data.Desc,
          data.StartDate,
          data.TotalStudents,
          data.TotalTeachers,
          data.DistrictName,
          data.ID
        ]
      })}
      columns={columns}
      options={options}
    />
  );
}
export default SchoolsTable;
