import React, { useEffect, useState } from "react";
import Creators from "../../redux/ducks/actions";
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumbs, Stack, Typography, Box, Button, Snackbar, Alert, Backdrop, Autocomplete, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SUBSCRIPTION_UPDATED_SUCCESS, INVALID_PRODUCT } from "../../constants";
import "../ManageDistrict/ManageDistrict.css";
import "../ManageDistrictLicense/ManageDistrictLicense.css";
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import CancelIcon from '@mui/icons-material/Cancel';
import { Close, Edit } from "@carbon/icons-react";

export default function MUEditSubscription() {
    const dispatch = useDispatch();
    const url = useParams();
    const history = useHistory();
    const Btncolor = "#2d8f3c";

    const { userInfo, listAllProducts, listAllUserLicense } = useSelector((state) => state.data);

    const [form, setForm] = useState({
        UserID: " ",
        ProductID: " ",
    });

    let defaultProps = {
        options: listAllProducts,
        getOptionLabel: (option) => option.ProductName + " - (" + option.Duration + " Months)"
    };
    const [value, setValue] = React.useState(null);

    // Action Status
    const [snackbar, setSnackbar] = React.useState({
        snackStatus: false,
        snackMessageFor: "update",
    });
    const { snackStatus, snackMessageFor } = snackbar;

    const handleSnackbarClick = (msg) => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: true,
            snackMessageFor: msg,
        }));
    };

    const handleSnackbarClose = () => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: false,
            snackMessageFor: "",
        }));
    };

    // Loading
    const [loading, setLoading] = React.useState(false);


    useEffect(() => {
        if (url) {
            listAllUserLicense.map(data => {
                if (Number(data.UserID) === Number(url.UserID)) {
                    setForm({
                        ...form,
                        UserID: data.UserID,
                        ProductID: data.ProductID,
                    })
                    listAllProducts.map(item => {
                        if (item.ProductID === data.ProductID) {
                            setValue(item);
                        }
                        return null;
                    })
                }
                return null;
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateClick = () => {
        setLoading(true);
        form.ProductID = value.ProductName;
        dispatch(
            Creators.userDistrictUpdate(form, (response) => {
                if (response.MessageID === 2) {
                    handleSnackbarClick("invalidData");
                    setLoading(false);
                    return null;
                } else if (response.MessageID === 0) {
                    // Course Update Success Message
                    setLoading(false);
                    handleSnackbarClick("update");
                    setTimeout(() => {
                        history.push(`/admin/admin-manage-user-license`);
                    }, 1000);
                } else {
                    setLoading(false);
                    console.error(response, "response");
                }
            })
        );
    }



    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])


    return (
        <React.Fragment>

            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            <Stack spacing={2} sx={{ maxWidth: 1000 }}>
                <Snackbar
                    key="SnackBar"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackStatus}
                    onClose={handleSnackbarClose}
                >
                    {snackMessageFor === "update"
                        ? (
                            <Alert
                                variant="filled"
                                severity="success"
                                sx={{
                                    "&.MuiPaper-root": {
                                        background: "#00c853",
                                        color: "#fff",
                                    },
                                }}
                                action={
                                    <CloseRoundedIcon
                                        sx={{ cursor: "pointer", color: "#fff" }}
                                        onClick={() => handleSnackbarClose()}
                                        color="inherit"
                                        size="small"
                                    />
                                }
                            >
                                {SUBSCRIPTION_UPDATED_SUCCESS}
                            </Alert>)
                        : (
                            <Alert
                                variant="filled"
                                severity="warning"
                                sx={{
                                    "&.MuiPaper-root": {
                                        background: "#ffc107",
                                        color: "#fff",
                                    },
                                }}
                                action={
                                    <CloseRoundedIcon
                                        sx={{ cursor: "pointer", color: "#fff" }}
                                        onClick={() => handleSnackbarClose()}
                                        color="inherit"
                                        size="small"
                                    />
                                }
                            >
                                {snackMessageFor === "invalidData"
                                    ? INVALID_PRODUCT
                                    : ""}
                            </Alert>
                        )}
                </Snackbar>
            </Stack>
            <Box className='districtStyle'>
                <CustomHeader
                    hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
                    licenseStatus={licenseStatus}
                    needBreadCrumb={true}
                    previousPageLabel={"Manage User License"}
                    currentPageLabel={"Edit User Subscription"}
                    onClickPrevPageLabel={() => { history.push("/admin/admin-manage-user-license") }}
                />
                <Box className="noHeaderButton" />
                <Box className='nsMainContent'>
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            Edit User Subscription
                        </Box>
                        <Box className="customHeaderButton" />
                    </Box>
                    <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
                        <Box className="contentSection">
                            <Box className="fieldParentCU">
                                <Box className="fieldChildCU">
                                    <Box className="fieldLabel">License</Box>
                                    <Autocomplete
                                        {...defaultProps}
                                        value={value}
                                        onChange={(event, newValue) => {
                                            if (!newValue) {
                                                setValue(listAllProducts[0]);
                                            } else {
                                                setValue(newValue);
                                            }
                                        }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "50%",
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                size="small"
                                                label=""
                                                variant="outlined"
                                                sx={{
                                                    '& label.Mui-focused': {
                                                        color: "#2d8f3c",
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#2d8f3c',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box className="createButtonParent" style={{ height: "10%" }}>
                                <Box className="createButtonChild">
                                    <CustomTableButton
                                        isInActive={true}
                                        buttonLabel={"Cancel"}
                                        onClickButton={() => {
                                            history.push("/admin/admin-manage-user-license");
                                        }}
                                        buttonIcon={<Close size={16} />}
                                    />
                                </Box>
                                <Box className="createButtonChild">
                                    <CustomTableButton
                                        isInActive={false}
                                        buttonLabel={"Update"}
                                        onClickButton={handleUpdateClick}
                                        buttonIcon={<Edit size={16} />}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}