import ClassIcon from '@mui/icons-material/Class';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import CastForEducationRoundedIcon from '@mui/icons-material/CastForEducationRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {
  Button,
  Stack,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
  TextField,
  Autocomplete
} from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ClassesTable from "./ClassesTable";
import { useDispatch, useSelector } from "react-redux";
import "./ManageClasses.css";
import CustomHeader from '../../Components/CustomHeader';
import CustomTableButton from '../../Components/CustomTableButton';
import allActions from "../../redux/ducks/actions";
import { Add, Roadmap, Upload } from '@carbon/icons-react';

function ManageClasses() {
  const history = useHistory(); //History
  const dispatch = useDispatch();
  const { userInfo, listAllSchools, classSchoolData } = useSelector((state) => state.data);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(null);

  useEffect(() => {
    if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
      // dispatch(allActions.getAllClassList(userInfo.SuperGroupID, (response) => {
      //   setTableRow(response);
      // }));
    } else if (userInfo && userInfo.UserType === "DistrictAdmin") {
      let allSchoolNameList = [];
      listAllSchools && listAllSchools.map(data => {
        allSchoolNameList.push(data);
        return null;
      })
      setValue(allSchoolNameList[0]);
      dispatch(allActions.saveCurrentSchoolInClass(allSchoolNameList[0]))
    } else if (listAllSchools && listAllSchools.length > 0) {
      let allSchoolNameList = [];
      listAllSchools && listAllSchools.map(data => {
        allSchoolNameList.push({
          label: data && data.Name && (data.Name).trim(),
          id: data.ID
        });
        return null;
      })
      if (classSchoolData) {
        setValue(classSchoolData)
      } else {
        setValue(allSchoolNameList[0]);
        dispatch(allActions.saveCurrentSchoolInClass(allSchoolNameList[0]))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [licenseStatus, setLicenseStatus] = React.useState(false);
  React.useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  let defaultProps = {
    options: listAllSchools,
    getOptionLabel: (option) => option && option.Name && (option.Name).trim()
  };

  return (
    <Box className='districtStyle'>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: "99999999"
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>

      <CustomHeader
        licenseStatus={licenseStatus}
        headerLabel={"Manage Classes"}
      />

      <Box className="customWelcomeCard">
        <Box className="customWelcomePhase1">
          <Box className='weeklyReportWrapper'>
            {
              userInfo && userInfo.UserType === "DistrictAdmin" ? (
                listAllSchools && listAllSchools.length > 0 ?
                  <Autocomplete
                    {...defaultProps}
                    value={value}
                    onChange={(_, newValue) => {
                      if (!newValue) {
                        if (listAllSchools && listAllSchools.lenght > 0) {
                          setValue({
                            label: listAllSchools[0].Name,
                            id: listAllSchools[0].ID
                          });
                          dispatch(allActions.saveCurrentSchoolInClass(listAllSchools[0].ID));
                        }
                      } else {
                        dispatch(allActions.saveCurrentSchoolInClass(newValue));
                        setValue(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="School names"
                        variant="standard"
                        sx={{
                          '& label.Mui-focused': {
                            color: "#15135E",
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#15135E',
                          },
                        }}
                      />
                    )}
                  /> : (
                    <TextField
                      label="School names"
                      variant="standard"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#15135E",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#15135E',
                        },
                      }}
                      value={""}
                    />
                  )
              ) : (
                <Box className="customWelcomePhase1">
                  <Box className="customWelcomeText1">School Name</Box>
                  <Box className="customWelcomeText2">{userInfo && userInfo.SuperGroupName}</Box>
                </Box>
              )
            }
          </Box>
        </Box>
        <Box className="customWelcomePhase2">
          {
            userInfo &&
              userInfo.SourceID !== "" ? null : (
              <React.Fragment>
                <CustomTableButton
                  buttonLabel={"Teacher to class mapping"}
                  onClickButton={() => {
                    if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                      history.push("/school/school-manage-classes/teacher-to-class-mapping")
                    } else {
                      history.push("/district/district-manage-classes/teacher-to-class-mapping")
                    }
                  }}
                  buttonIcon={<Roadmap size={16} />}
                />
                <CustomTableButton
                  buttonLabel={"Bulk Upload Class"}
                  onClickButton={() => {
                    if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                      history.push("/school/school-manage-classes/bulk-upload")
                    } else {
                      history.push("/district/district-manage-classes/bulk-upload")
                    }
                  }}
                  buttonIcon={<Upload size={16} />}
                />
                <CustomTableButton
                  buttonLabel={"New"}
                  onClickButton={() => {
                    if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                      history.push("/school/school-manage-classes/create")
                    } else {
                      history.push("/district/district-manage-classes/create")
                    }
                  }}
                  buttonIcon={<Add size={16} />}
                />

              </React.Fragment>
            )
          }
        </Box>
      </Box>

      <Box className="nsMainContent">
        <ClassesTable selectValue={value} />
      </Box>

    </Box>
  );
}
export default ManageClasses;
