import CreateIcon from "@mui/icons-material/Create";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Breadcrumbs,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
  Autocomplete,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Checkbox,
  FormControlLabel,
  Backdrop,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from "react-redux";
import {
  COUNTRIES,
  COUNTRY_EMPTY,
  AGE_EMPTY,
  EMAIL_EMPTY,
  FIRST_NAME_EMPTY,
  LAST_NAME_EMPTY,
  PASSWORD_EMPTY,
  USER_TYPE_EMPTY,
  USER_NAME_EMPTY,
  EMAIL_INVALID,
  USER_NAME_EXIST,
  CLASS_EMPTY,
  VALID_AGE,
  USER_CREATED_SUCCESS,
} from "../../constants";
import Creators from "../../redux/ducks/actions";
import "./ManageUsers.css";
import { useHistory } from "react-router-dom";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import { Close, Pen } from "@carbon/icons-react";

function CreateUsers(props) {
  const dispatch = useDispatch(); //dispatch
  const { userInfo, listAllSchools } = useSelector(state => state.data); //state data
  const history = useHistory(); //history
  const [emailValidation, setEmailValidation] = useState("");
  const [countryData, setCountryData] = useState({
    code: "",
    label: "",
    phone: "",
  });
  // Snack Message
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "",
  });
  const { snackStatus, snackMessageFor } = snackbar;
  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  const [form, setForm] = useState({
    loginName: "",
    emailId: "",
    firstName: "",
    lastName: "",
    country: "",
    userType: "",
    schoolId: "",
    groupIds: "",
    age: "",
    password: "",
    sourceData: "Private",
  });
  const [emptyError, setEmptyError] = useState({
    loginName: false,
    emailId: false,
    firstName: false,
    lastName: false,
    country: false,
    userType: false,
    schoolId: false,
    groupIds: false,
    age: false,
    password: false,
    sourceData: false,
  });

  const [classNamesList, setClassNamesList] = useState([]);

  const [schoolNameOptions, setSchoolNameOptions] = useState([]);
  const [value, setValue] = React.useState(null);
  const [groupIdData, setGroupIdData] = React.useState([]);


  // Loading
  const [loading, setLoading] = React.useState(false);

  // Autocomplete for school name search
  const defaultProps = {
    options: schoolNameOptions,
    getOptionLabel: (option) => option && option.label && (option.label).trim()
  };
  React.useEffect(() => {
    if (userInfo && userInfo.UserType !== "Supergrouptechadmin") {
      if (listAllSchools && listAllSchools.length > 0) {
        let allSchoolNameList = [];
        listAllSchools && listAllSchools.map(data => {
          allSchoolNameList.push({
            label: data.Name,
            id: data.ID
          });
          return null;
        })
        setSchoolNameOptions(allSchoolNameList);
        setValue(allSchoolNameList[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAllSchools]);

  //   create Button
  const handleCreateClick = () => {
    setLoading(true)
    let newForm = { ...form };
    const checkEmptyValue = { ...emptyError };
    let reg = /^\d+$/; // validation for age (only numbers)
    let submit = true;
    let checkAge = form.age;
    if (!checkAge.match(reg)) {
      submit = false;
      // handleSnackbarClick("validAge");
      setLoading(false)
    }
    // Checking empty data
    for (let items in form) {
      if (
        (
          userInfo.UserType === "Supergrouptechadmin" &&
          form.userType === "Student" &&
          items !== "schoolId" &&
          form[items] === ""
        ) ||
        (userInfo.UserType === "Supergrouptechadmin" &&
          form.userType === "Teacher" &&
          items !== "schoolId" &&
          items !== "groupIds" &&
          form[items] === ""
        ) ||
        (userInfo.UserType !== "Supergrouptechadmin" && items !== "groupIds" && form[items] === "")
      ) {
        submit = false;
        checkEmptyValue[items] = true;
      }
    }
    if (emailValidation !== "") {
      submit = false;
    }
    if (form.userType === "Teacher") {
      newForm.userType = "GroupAdmin";
    } else if (form.userType === "Student") {
      newForm.userType = "Student";
    } else if (form.userType === "District Admin") {
      newForm.userType = "DistrictAdmin";
    } else if (form.userType === "School Admin") {
      newForm.userType = "Supergrouptechadmin";
    } else if (form.userType === "Principal") {
      newForm.userType = "SuperGroupAdmin";
    } else if (form.userType === "Restricted School Admin") {
      newForm.userType = "restrictedschooladmin";
    } else if (form.userType === "Restricted District Admin") {
      newForm.userType = "restricteddistrictadmin";
    }
    if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
      newForm.schoolId = userInfo.SuperGroupID;
    } else if (form.userType === "Restricted District Admin") {
      newForm.schoolId = userInfo.DomainID;
    } else {
      newForm.schoolId = value.id;
    }
    if (form.userType === "Student") {
      newForm.groupIds = [parseInt(form.groupIds)];
    } else if (form.userType === "Teacher") {
      newForm.groupIds = groupIdData;
    } else {
      newForm.groupIds = [];
    }
    // atleast on class should be selected for Student 
    if (form.userType === "Student" && form.groupIds === "") {
      submit = false;
      handleSnackbarClick("groupIdMissing");
    }
    // atleast on class should be selected for Teacher 
    if (form.userType === "Teacher" && groupIdData.length < 1) {
      submit = false;
      handleSnackbarClick("groupIdMissing");
    }
    if (!submit) {
      setEmptyError(checkEmptyValue);
      setLoading(false)
    } else {
      dispatch(
        Creators.putUser(newForm, (response) => {
          console.log(response);


          if (response) {
            if (response.MessageID === 0) {
              // Class Success Message
             
              setLoading(false)
              handleSnackbarClick("create");
              if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                setTimeout(() => {
                  handleCancelButton();
                  history.push("/school/school-user-logins");
                }, 1000);
              } else {
                setTimeout(() => {
                  handleCancelButton();
                  history.push("/district/district-user-logins");
                }, 1000);
              }
            } else if (response.MessageID === 2) {
              // Class Duplicate Error
              setLoading(false)
              handleSnackbarClick("duplicateError");
              return null;
            } else if (response.MessageID === 1) {
              // Class Duplicate Error
              setLoading(false)
              handleSnackbarClick("duplicateError");
              return null;
            } else {
              setLoading(false)
              console.error(response, "response");
            }
          } else {
            setLoading(false)
            console.error(response, "response");
          }
        })
      );
    }
  };

  useEffect(() => {
    if (userInfo.UserType === "Supergrouptechadmin") {
      dispatch(
        Creators.getClassBySchoolId(userInfo.SuperGroupID, (response) => {
          setClassNamesList(response);
        })
      );
    } else if (value) {
      dispatch(
        Creators.getClassBySchoolId(value.id, (response) => {
          setClassNamesList(response);
        })
      );
      setForm((state) => ({
        ...state,
        schoolId: value.id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  // cancel Button
  const handleCancelButton = () => {
    setForm((state) => ({
      ...state,
      loginName: "",
      emailId: "",
      firstName: "",
      lastName: "",
      country: "",
      userType: "",
      schoolId: "",
      groupIds: "",
      age: "",
      password: "",
      sourceData: "Private",
    }));
    setEmptyError((state) => ({
      ...state,
      loginName: false,
      emailId: false,
      firstName: false,
      lastName: false,
      country: false,
      userType: false,
      schoolId: false,
      groupIds: false,
      age: false,
      password: false,
      sourceData: false,
    }));
    setCountryData({
      code: "",
      label: "",
      phone: "",
    });
  };
  // handle Create User
  const handleOnChangeCreateUser = (e) => {
    if (e.target.id === "age" && e.target.value < 0) {
      e.preventDefault()
    } else {
      if (e.target.value !== "") {
        setEmptyError((state) => ({
          ...state,
          [e.target.id]: false,
        }));
      }
      if (e.target.name === "groupIds") {
        setForm((state) => ({
          ...state,
          [e.target.name]: e.target.value,
        }));
        
      } else {
        setForm((state) => ({
          ...state,
          [e.target.id]: e.target.value,
        }));
      }

      if (e.target.id === "emailId" && e.target.value !== "") {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
          setEmailValidation("");
          setForm((state) => ({
            ...state,
            [e.target.id]: e.target.value,
          }));
        } else {
          setEmptyError((state) => ({
            ...state,
            [e.target.id]: true,
          }));

          setEmailValidation(EMAIL_INVALID);
          return null;
        }
      }
    }
  };

  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <>
      {/* Action Status */}
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar
          key="SnackBar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackStatus}
          onClose={handleSnackbarClose}
        >
          {snackMessageFor === "duplicateError" ? (
            <Alert
              variant="filled"
              severity="warning"
              sx={{
                "&.MuiPaper-root": {
                  background: "#ffc107",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {USER_NAME_EXIST}
            </Alert>
          ) : snackMessageFor === "validAge" ? (
            <Alert
              variant="filled"
              severity="warning"
              sx={{
                "&.MuiPaper-root": {
                  background: "#ffc107",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {VALID_AGE}
            </Alert>
          ) : snackMessageFor === "groupIdMissing" ? (
            <Alert
              variant="filled"
              severity="warning"
              sx={{
                "&.MuiPaper-root": {
                  background: "#ffc107",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {CLASS_EMPTY}
            </Alert>
          ) : snackMessageFor === "create" ? (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                "&.MuiPaper-root": {
                  background: "#00c853",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {USER_CREATED_SUCCESS}
            </Alert>
          ) : null}
        </Snackbar>
      </Stack>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>
      <Box className='districtStyle'>
        <CustomHeader
          licenseStatus={licenseStatus}
          needBreadCrumb={true}
          previousPageLabel={"User Logins"}
          currentPageLabel={"Create User"}
          onClickPrevPageLabel={() => {
            const navigateTo = userInfo && userInfo.UserType === "Supergrouptechadmin" ? "/school/school-user-logins" : "/district/district-user-logins"
            history.push(navigateTo)
          }}
        />
        <Box className="noHeaderButton" />
        <Box className='nsMainContent'>
          <Box className="nsMainHeader">
            <Box className="nsMainTextHeader">
              Create User
            </Box>
            <Box className="customHeaderButton" />
          </Box>
          <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
            <Box className="createDomainParent">
              <Box className="LessonBotContent">
                <Box className="fieldParentCU">
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">User Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.loginName ? "#ff0000" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="loginName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.loginName}
                      onChange={handleOnChangeCreateUser}
                      error={emptyError.loginName}
                      helperText={emptyError.loginName ? USER_NAME_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Password</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.password ? "#ff0000" : '#2d8f3c',
                        },
                      }}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      size="small"
                      id="password"
                      label=""
                      type="text"
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.password}
                      onChange={handleOnChangeCreateUser}
                      error={emptyError.password}
                      helperText={emptyError.password ? PASSWORD_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Email</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.emailId ? "#ff0000" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="emailId"
                      label=""
                      type="email"
                      className="fieldStyle"
                      value={form.emailId}
                      onChange={handleOnChangeCreateUser}
                      error={emptyError.emailId}
                      helperText={
                        emptyError.emailId
                          ? emailValidation !== ""
                            ? EMAIL_INVALID
                            : EMAIL_EMPTY
                          : ""
                      }
                    />
                  </Box>
                </Box>
                <Box className="fieldParentCU">
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">First Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.firstName ? "#ff0000" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="firstName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.firstName}
                      onChange={handleOnChangeCreateUser}
                      error={emptyError.firstName}
                      helperText={emptyError.firstName ? FIRST_NAME_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Last Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.lastName ? "#ff0000" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="lastName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.lastName}
                      onChange={handleOnChangeCreateUser}
                      error={emptyError.lastName}
                      helperText={emptyError.lastName ? LAST_NAME_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Age</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.age ? "#ff0000" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="age"
                      label=""
                      type="number"
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.age}
                      onKeyDown={(e) => {
                        let charCode = (e.which) ? e.which : e.keyCode;
                        if (charCode === 37 || charCode === 39) {
                          return;
                        } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault()
                        }
                      }}
                      onChange={handleOnChangeCreateUser}
                      error={emptyError.age}
                      helperText={emptyError.age ? AGE_EMPTY : ""}
                    />
                  </Box>
                </Box>
                <Box className="fieldParentCU">
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Country</Box>
                    <Autocomplete
                      size="small"
                      id="country"
                      options={COUNTRIES}
                      value={countryData}
                      onChange={(_, y) => setCountryData(y)}
                      getOptionLabel={(option) => option.label}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          const present = COUNTRIES.some(
                            (item) => item.label === e.target.value
                          );
                          if (present) {
                            setEmptyError((state) => ({
                              ...state,
                              country: false,
                            }));
                            setForm((state) => ({
                              ...state,
                              country: e.target.value,
                            }));
                          } else {
                            setForm((state) => ({
                              ...state,
                              country: "",
                            }));
                          }
                        } else {
                          setForm((state) => ({
                            ...state,
                            country: "",
                          }));
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          required
                          variant="outlined"
                          sx={{
                            '& label.Mui-focused': {
                              color: "#2d8f3c",
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: emptyError.country ? "#ff0000" : '#2d8f3c',
                            },
                          }}
                          size="small"
                          {...params}
                          label=""
                          value={form.country}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          error={emptyError.country}
                          helperText={emptyError.country ? COUNTRY_EMPTY : ""}
                        />
                      )}
                    />
                  </Box>
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">User Type</Box>
                    <Autocomplete
                      options={
                        userInfo &&
                          userInfo.UserType === "Supergrouptechadmin" ? //school
                          ["Student", "Teacher"]
                          : userInfo.UserType === "GroupAdmin" ? //teacher
                            ["Student"]
                            : userInfo.UserType === "DistrictAdmin" ? //district
                              ["Student", "Teacher", "Principal", "School Admin", "Restricted School Admin", "Restricted District Admin"]
                              : userInfo.UserType === "NSTechAdmin" ? //admin
                                ["Student", "Teacher", "Principal", "School Admin", "District Admin"]
                                : []
                      }
                      id="userType"
                      value={form.userType}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setEmptyError((state) => ({
                            ...state,
                            userType: false,
                          }));
                        }
                        setForm({
                          ...form,
                          userType: newValue,
                        })
                        // setting teacher and student value null
                        setGroupIdData([])
                        setForm((state) => ({
                          ...state,
                          groupIds: [],
                        }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label=""
                          variant="outlined"
                          size="small"
                          sx={{
                            '& label.Mui-focused': {
                              color: "#2d8f3c",
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: emptyError.userType ? "#ff0000" : '#2d8f3c',
                            },
                          }}
                          error={emptyError.userType}
                          helperText={emptyError.userType ? USER_TYPE_EMPTY : ""}
                        />
                      )}
                    />
                  </Box>
                  {
                    userInfo && userInfo.UserType === "Supergrouptechadmin" ? null
                      : form.userType !== "Restricted District Admin" ?
                        (<Box className="fieldChildCUClass">
                          <Box className="fieldLabel">School names</Box>
                          <Autocomplete
                            {...defaultProps}
                            value={value}
                            onChange={(event, newValue) => {
                              if (!newValue) {
                                setValue(schoolNameOptions[0]);
                              } else {
                                setValue(newValue);
                              }
                              // setting teacher and student value null
                              setGroupIdData([])
                              setForm((state) => ({
                                ...state,
                                groupIds: [],
                              }));
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label=""
                                variant="outlined"
                                size="small"
                                sx={{
                                  '& label.Mui-focused': {
                                    color: "#2d8f3c",
                                  },
                                  '& .MuiInput-underline:after': {
                                    borderBottomColor: '#2d8f3c',
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>) : null
                  }
                </Box>
                <Box className="fieldParentCURadioButton">
                  <Box className="fieldChildCURadioButton">
                    {form.userType === "Student" ?
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel
                          id="Class"
                          color="success"
                          sx={{
                            width: "100%"
                          }}
                        >
                          Class *
                        </FormLabel>
                        <RadioGroup
                          size="small"
                          name="groupIds"
                          className="fieldStyle"
                          value={form.groupIds}
                          onChange={handleOnChangeCreateUser}
                          sx={{
                            flexDirection: "row",
                            width: "100%",
                            height: "100%",
                            overflow: "auto",
                          }}
                        >
                          {classNamesList &&
                            classNamesList.length > 0 &&
                            classNamesList.map(item =>
                              <FormControlLabel
                                sx={{
                                  margin: 0,
                                  width: "20%",
                                }}
                                key={item.GroupID}
                                value={item.GroupID}
                                control={<Radio />}
                                label={item.GroupName}
                              />
                            )}
                        </RadioGroup>
                      </FormControl>
                      : form.userType === "Teacher" ?
                        <React.Fragment>
                          <FormLabel
                            id="Class"
                            color="success"
                            sx={{
                              width: "100%"
                            }}
                          >
                            Class *
                          </FormLabel>
                          {
                            classNamesList &&
                            classNamesList.length > 0 &&
                            classNamesList.map(item =>
                              <FormControlLabel
                                label={item.GroupName}
                                sx={{
                                  margin: 0,
                                  width: "20%",
                                }}
                                control={
                                  <Checkbox
                                    checked={groupIdData.includes(item.GroupID)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        let allGroupIdData = groupIdData;
                                        allGroupIdData.push(item.GroupID);
                                        let finalGroupId = allGroupIdData.filter(data => data)
                                        setGroupIdData(finalGroupId);
                                      } else {
                                        let allGroupIdData = groupIdData;
                                        let finalGroupId = allGroupIdData.filter(data => data !== item.GroupID)
                                        setGroupIdData(finalGroupId);
                                      }
                                    }}
                                  />
                                }
                              />)
                          }
                        </React.Fragment>
                        : null
                    }
                  </Box>
                </Box>
                <Box className="createButtonParent" style={{ marginRight: "3%" }}>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={true}
                      buttonLabel={"Cancel"}
                      onClickButton={() => {
                        if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                          history.push("/school/school-user-logins");
                        } else {
                          history.push("/district/district-user-logins");
                        }
                      }}
                      buttonIcon={<Close size={16} />}
                    />
                  </Box>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={false}
                      buttonLabel={"Create"}
                      onClickButton={handleCreateClick}
                      buttonIcon={<Pen size={16} />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default CreateUsers;
