import CreateIcon from "@mui/icons-material/Create";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Breadcrumbs,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
  Autocomplete,
} from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DESCRIPTION_EMPTY,
  CLASS_NAME_EMPTY,
  CLASS_NAME_DUPLICATE,
  CLASS_ADD,
} from "../../constants";
import Creators from "../../redux/ducks/actions";
import "./ManageClasses.css";
import { useHistory } from "react-router-dom";
import CustomTableButton from "../../Components/CustomTableButton";
import CustomHeader from "../../Components/CustomHeader";
import { Close, Pen } from "@carbon/icons-react";

function CreateClasses(props) {
  const dispatch = useDispatch(); //dispatch
  const { userInfo, listAllSchools } = useSelector(state => state.data); //state data
  const history = useHistory(); //history
  // Snack Message
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "update",
  });
  const { snackStatus, snackMessageFor } = snackbar;
  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  const [form, setForm] = useState({
    className: "",
    description: "",
  });
  const [emptyError, setEmptyError] = useState({
    className: false,
    description: false,
  });

  const [schoolNameOptions, setSchoolNameOptions] = useState([]);
  const [value, setValue] = React.useState(null);
  // Autocomplete for school name search
  const defaultProps = {
    options: schoolNameOptions,
    getOptionLabel: (option) => option && option.label && (option.label).trim()
  };
  React.useEffect(() => {
    let allSchoolNameList = [];
    listAllSchools && listAllSchools.map(data => {
      allSchoolNameList.push({
        label: data.Name,
        id: data.ID
      });
      return null;
    })
    setSchoolNameOptions(allSchoolNameList);
    setValue(allSchoolNameList[0]);
  }, [listAllSchools]);

  //   create Button
  const [createLoading, setCreateLoading] = useState(false);
  const handleCreateClick = () => {
    setCreateLoading(true);
    const checkEmptyValue = { ...emptyError };
    let submit = true;
    // Checking empty data
    for (let items in form) {
      if (form[items] === "") {
        submit = false;
        checkEmptyValue[items] = true;
      }
    }
    if (!submit) {
      setEmptyError(checkEmptyValue);
      setCreateLoading(false);
    } else {
      setCreateLoading(false);
      if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
        form.schoolId = userInfo.SuperGroupID;
      } else {
        form.schoolId = value.id;
      }
      dispatch(
        Creators.putClasses(form, (response) => {
          if (response.MessageID === 1 || response.MessageID === 5) { 
            // Class Duplicate Error
            setCreateLoading(false);
            handleSnackbarClick("duplicateError");
            return null;
          } else if (response.MessageID === 0) {
            // Class Success Message
            handleCancelButton();
            handleSnackbarClick("create");
            if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
              setTimeout(() => {
                setCreateLoading(false);
                history.push("/school/school-manage-classes");
              }, 1000);
            } else {
              setTimeout(() => {
                setCreateLoading(false);
                history.push("/district/district-manage-classes");
              }, 1000);
            }
          } else {
            console.error(response, "response");
          }
        })
      );
    }
  };

  // cancel Button
  const handleCancelButton = () => {
    setForm((state) => ({
      ...state,
      className: "",
      description: "",
    }));
    setEmptyError((state) => ({
      ...state,
      className: false,
      description: false,
    }));
  };
  // handle Create Classes
  const handleOnChangeCreateDistrict = (e) => {
    if (e.target.value !== "") {
      setEmptyError((state) => ({
        ...state,
        [e.target.id]: false,
      }));
    }

    setForm((state) => ({
      ...state,
      [e.target.id]: e.target.value,
    }));

  };



  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <>
      {/* Action Status */}
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar
          key="SnackBar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackStatus}
          onClose={handleSnackbarClose}
        >
          {
            snackMessageFor === "duplicateError" ? (
              <Alert
                variant="filled"
                severity="warning"
                sx={{
                  "&.MuiPaper-root": {
                    background: "#ffc107",
                    color: "#fff",
                  },
                }}
                action={
                  <CloseRoundedIcon
                    sx={{ cursor: "pointer", color: "#fff" }}
                    onClick={() => handleSnackbarClose()}
                    color="inherit"
                    size="small"
                  />
                }
              >
                {CLASS_NAME_DUPLICATE}
              </Alert>
            ) : snackMessageFor === "create" ? (
              <Alert
                variant="filled"
                severity="success"
                sx={{
                  "&.MuiPaper-root": {
                    background: "#00c853",
                    color: "#fff",
                  },
                }}
                action={
                  <CloseRoundedIcon
                    sx={{ cursor: "pointer", color: "#fff" }}
                    onClick={() => handleSnackbarClose()}
                    color="inherit"
                    size="small"
                  />
                }
              >
                {CLASS_ADD}
              </Alert>
            )
              : null}
        </Snackbar>
      </Stack>
      <Box className='districtStyle'>
        <CustomHeader
          licenseStatus={licenseStatus}
          needBreadCrumb={true}
          previousPageLabel={"Manage Classes"}
          currentPageLabel={"Create Class"}
          onClickPrevPageLabel={() => {
            const navigateTo = userInfo && userInfo.UserType === "Supergrouptechadmin" ? "/school/school-manage-classes" : "/district/district-manage-classes"
            history.push(navigateTo);
          }}
        />
        <Box className="noHeaderButton" />
        <Box className='nsMainContent'>
          <Box className="nsMainHeader">
            <Box className="nsMainTextHeader">
              Create Class
            </Box>
            <Box className="customHeaderButton" />
          </Box>
          <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
            <Box className="createDomainParent">
              <Box className="LessonBotContent">
                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Class Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.className ? "#ff000" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="className"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.className}
                      onChange={handleOnChangeCreateDistrict}
                      error={emptyError.className}
                      helperText={emptyError.className ? CLASS_NAME_EMPTY : ""}
                    />
                  </Box>
                  {
                    (userInfo && userInfo.UserType === "Supergrouptechadmin")
                      ? <Box className="fieldChild">
                        <Box className="fieldLabel">Class Description</Box>
                        <TextField
                          required
                          variant="outlined"
                          sx={{
                            width: "100%",
                            '& label.Mui-focused': {
                              color: "#2d8f3c",
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#2d8f3c',
                            },
                          }}
                          size="small"
                          id="description"
                          label=""
                          multiline
                          rows={1}
                          value={form.description}
                          onChange={handleOnChangeCreateDistrict}
                          error={emptyError.description}
                          helperText={emptyError.description ? DESCRIPTION_EMPTY : ""}
                        />
                      </Box>
                      : <Box className="fieldChild">
                        <Box className="fieldLabel">School names</Box>
                        <Autocomplete
                          {...defaultProps}
                          value={value}
                          onChange={(event, newValue) => {
                            if (!newValue) {
                              setValue(schoolNameOptions[0]);
                            } else {
                              setValue(newValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label=""
                              size="small"
                              variant="outlined"
                              sx={{
                                '& label.Mui-focused': {
                                  color: "#2d8f3c",
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#2d8f3c',
                                },
                              }}
                            />
                          )}
                        />
                      </Box>
                  }
                </Box>
                {
                  (userInfo && userInfo.UserType === "Supergrouptechadmin") ? null :
                    <Box className="fieldParent">
                      <Box className="fieldChild">
                        <Box className="fieldLabel">Class Description</Box>
                        <TextField
                          required
                          variant="outlined"
                          sx={{
                            width: "100%",
                            '& label.Mui-focused': {
                              color: "#2d8f3c",
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#2d8f3c',
                            },
                          }}
                          size="small"
                          id="description"
                          label=""
                          multiline
                          rows={1}
                          value={form.description}
                          onChange={handleOnChangeCreateDistrict}
                          error={emptyError.description}
                          helperText={emptyError.description ? DESCRIPTION_EMPTY : ""}
                        />
                      </Box>
                    </Box>
                }

                <Box className="createButtonParent" style={{ height: "auto", padding: "2%" }}>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={true}
                      buttonLabel={"Cancel"}
                      onClickButton={() => {
                        if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                          history.push("/school/school-manage-classes");
                        } else {
                          history.push("/district/district-manage-classes");
                        }
                      }}
                      buttonIcon={<Close size={16} />}
                    />
                  </Box>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={false}
                      buttonLabel={"Create"}
                      onClickButton={handleCreateClick}
                      buttonIcon={<Pen size={16} />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >
    </>
  );
}
export default CreateClasses;
