import {
    Box,
    Typography,
    FormControl,
    Backdrop,
    Select,
    MenuItem,
    Paper,
    Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Creators from "../../redux/ducks/actions";
import Logo from "../../constants/images";
import "./ClassUsageData.css";
import { useHistory } from "react-router-dom";
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import CircularProgress from '@mui/material/CircularProgress';

export default function ClassUsageDataSchoolList() {
    const dispatch = useDispatch(); //dispatch
    const history = useHistory(); //history
    const { userInfo } = useSelector(state => state.data); //dispatch
    const [classList, setClassList] = useState([]);
    const [classId, setClassId] = useState(-1);
    const [loading, setLoading] = React.useState(false);

    const handleSchoolList = (event) => {
        setClassId(event.target.value);
    };

    useEffect(() => {
        setLoading(true);
        dispatch(Creators.classUsageDataSchoolList(userInfo.SuperGroupID, (response) => {
            if (response.length > 0) {
                setClassList(response);
                setClassId(response.length > 0 ? response[0].ID : -1)
                setLoading(false);
                history.push(`school-class-usage-data/class-details/${response[0].ID}`)
            } else {
                history.push(`school-class-usage-data/class-details/-1}`)
            }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null;
}