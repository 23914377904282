import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Stack,
    Breadcrumbs,
    Typography,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import allActions from "../../../../redux/ducks/actions";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useParams } from "react-router-dom";
import SummarizeIcon from '@mui/icons-material/Summarize';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import BadgeIcon from '@mui/icons-material/Badge';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import { Backdrop } from "@mui/material";
import CustomHeader from "../../../../Components/CustomHeader";
import CustomWelcomeCard from "../../../../Components/CustomWelcomeCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Education, User } from "@carbon/icons-react";

function ActivitySummarySL(props) {
    const dispatch = useDispatch(); //dispatch
    const { userInfo, schoolUsageData, classUsageData } = useSelector((state) => state.data);
    const [tableRow, setTableRow] = useState([]);
    const url = useParams();
    const history = useHistory(); //history
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(allActions.studentsSummaryDailyAndActivity(Number(url.id), (response) => {
            setTableRow(response.Details);
            setLoading(false);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            name: "Skill",
            options: {
                filter: true,
            }
        },
        {
            name: "Games Completed",
            options: {
                filter: true,
            }
        },
        {
            name: "Beginner,Advanced,Sensei",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (value, tableMeta, updateValue) => {
                    return (
                        <th className="MuiTableCell-head MuiTableCell-root MuiTableCell-sizeMedium th-border-style">
                            <tr className="headerTrStyle">
                                <th colSpan={3}>Levels Mastered</th>
                            </tr>
                            <tr className="headerTrStyle">
                                <th className="headerThStyle">Beginner</th>
                                <th className="headerThStyle">Advanced</th>
                                <th className="headerThStyle">Sensei</th>
                            </tr>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box className="bodyBoxStyle">
                            <Box className="bodyinnerBoxStyle">{value.Beg}</Box>
                            <Box className="bodyinnerBoxStyle">{value.Adv}</Box>
                            <Box className="bodyinnerBoxStyle">{value.Sen}</Box>
                        </Box>
                    );
                }
            }
        },
    ];

    const options = {
        selectableRows: false,
        filter: false,
        download: false,
        print: false,
        rowsPerPage: 25,
    };


    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    const welcomCardData = [
        {
            label: "Total Students",
            icon: <User className="welcomeCardIcons" />,
            value: classUsageData && classUsageData.classDetails && classUsageData.classDetails.TotalStudents ? classUsageData.classDetails.TotalStudents : 0
        },
        {
            label: "Total Teachers",
            icon: <Education className="welcomeCardIcons" />,
            value: classUsageData && classUsageData.classDetails && classUsageData.classDetails.TotalTeachers ? classUsageData.classDetails.TotalTeachers : 0
        },
    ]


    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: "99999999"
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            {
                userInfo && (userInfo.UserType === "Supergrouptechadmin") ? (
                    <CustomHeader
                        licenseStatus={licenseStatus}
                        needBreadCrumb={true}
                        previousPageLabel={
                            userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "GroupAdmin") ?
                                `${classUsageData.className} Dashboard` : "Class Summary"
                        }
                        previousPageLabel1={"Student List"}
                        currentPageLabel={"Daily Summary"}
                        onClickPrevPageLabel={() => {
                            const navigateTo = userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "SuperGroupAdmin" || userInfo.UserType === "restrictedschooladmin") ?
                                `/school/school-class-usage-data/class-details/${classUsageData.schoolId}` :
                                userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin") ?
                                    `/district/district-school-usage-data/class-summary/${schoolUsageData.schoolId}` :
                                    userInfo && userInfo.UserType === "GroupAdmin" ?
                                        `/teacher/teacher-dashboard/class-details/${classUsageData.schoolId}` :
                                        null
                            history.push(navigateTo);
                        }}
                        onClickPrevPageLabel1={() => {
                            const navigateTo = userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "SuperGroupAdmin" || userInfo.UserType === "restrictedschooladmin") ?
                                `/school/school-class-usage-data/student-list/${classUsageData.schoolId}` :
                                userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin") ?
                                    `/district/district-school-usage-data/student-list/${schoolUsageData.classId}` :
                                    userInfo && userInfo.UserType === "GroupAdmin" ?
                                        `/teacher/teacher-dashboard/student-list/${classUsageData.schoolId}` :
                                        null
                            history.push(navigateTo)
                        }}
                    />
                ) : (
                    <CustomHeader
                        licenseStatus={licenseStatus}
                        needBreadCrumb={true}
                        previousPageLabel={
                            userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "GroupAdmin") ?
                                "Class Usage Data" : `${schoolUsageData.schoolName} Dashboard`
                        }
                        previousPageLabel1={
                            userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "GroupAdmin") ?
                                `${classUsageData.className} Dashboard` : "Class Summary"
                        }
                        previousPageLabel2={"Student List"}
                        currentPageLabel={"Student Activity Summary"}
                        onClickPrevPageLabel={() => {
                            const navigateTo = userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "SuperGroupAdmin" || userInfo.UserType === "restrictedschooladmin") ?
                                `/school/school-class-usage-data` :
                                userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin") ?
                                    `/district/district-school-usage-data/school-details/${schoolUsageData.schoolId}` :
                                    userInfo && userInfo.UserType === "GroupAdmin" ?
                                        `/teacher/teacher-dashboard` :
                                        null
                            history.push(navigateTo);
                        }}
                        onClickPrevPageLabel1={() => {
                            const navigateTo = userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "SuperGroupAdmin" || userInfo.UserType === "restrictedschooladmin") ?
                                `/school/school-class-usage-data/class-details/${classUsageData.schoolId}` :
                                userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin") ?
                                    `/district/district-school-usage-data/class-summary/${schoolUsageData.schoolId}` :
                                    userInfo && userInfo.UserType === "GroupAdmin" ?
                                        `/teacher/teacher-dashboard/class-details/${classUsageData.schoolId}` :
                                        null
                            history.push(navigateTo);
                        }}
                        onClickPrevPageLabel2={() => {
                            const navigateTo = userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "SuperGroupAdmin" || userInfo.UserType === "restrictedschooladmin") ?
                                `/school/school-class-usage-data/student-list/${classUsageData.schoolId}` :
                                userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin") ?
                                    `/district/district-school-usage-data/student-list/${schoolUsageData.classId}` :
                                    userInfo && userInfo.UserType === "GroupAdmin" ?
                                        `/teacher/teacher-dashboard/student-list/${classUsageData.schoolId}` :
                                        null
                            history.push(navigateTo)
                        }}
                    />
                )
            }
            <CustomWelcomeCard
                welcomCardData={welcomCardData}
                cardLabel={"Class Name"}
                cardValue={`${classUsageData.className}`}
            />

            <Box className="nsMainContent">
                <MUIDataTable
                    title={"Student Activity Summary"}
                    data={tableRow ? tableRow.map(data => {
                        const levelsMastered = {
                            Beg: data.Beg,
                            Adv: data.Adv,
                            Sen: data.Sen,
                        }
                        return [
                            data.skill,
                            data.Games,
                            levelsMastered
                        ]
                    }) : []}
                    columns={columns}
                    options={options}
                />
            </Box>
        </Box >
    );
}
export default ActivitySummarySL;
