import {
    Box,
    Typography,
    FormControl,
    Backdrop,
    Select,
    MenuItem,
    Paper,
    Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Creators from "../../redux/ducks/actions";
import Logo from "../../constants/images";
import "./TeacherUsageData.css";
import { useHistory } from "react-router-dom";
import GroupsIcon from '@mui/icons-material/Groups';
import CircularProgress from '@mui/material/CircularProgress';

export default function TeacherUsageDataList() {
    const dispatch = useDispatch(); //dispatch
    const history = useHistory(); //history
    const { userInfo } = useSelector(state => state.data); //dispatch
    const [TeacherList, setTeacherList] = useState([]);
    const [TeacherId, setTeacherId] = useState(-1);
    const [loading, setLoading] = React.useState(false);

    const handleSchoolList = (event) => {
        setTeacherId(event.target.value);
    };

    useEffect(() => {
        setLoading(true);
        dispatch(Creators.teacherUsageDataList(userInfo.SuperGroupID, (response) => {
            if (response.length > 0) {
                setTeacherList(response);
                setTeacherId(response.length > 0 ? response[0].ID : -1)
                history.push(`school-teacher-usage-data/teacher-details/${response[0].ID}`)
                setLoading(false);
            } else {
                history.push(`school-teacher-usage-data/teacher-details/-1`)
            }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const mdTheme = createTheme();

    return (
        < ThemeProvider theme={mdTheme} >
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            {/* <Box className='districtStyle'>
                <Box className='districtTitleBox HeaderBoxResponsive'>
                    <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        sx={{
                            "&.MuiTypography-h6": {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: 700,
                            },
                        }}
                    >
                        <GroupsIcon
                            fontSize="large"
                            sx={{
                                marginRight: "5px",
                                color: "#2d8f3c",
                                display: "flex",
                                alignSelf: "center",
                            }}
                        />
                        Teacher Usage Data
                    </Typography>
                    <Box>
                        <img alt="logo" src={Logo.logo} className="logoStyle" />
                    </Box>
                </Box>
                <Box className='schoolUsageListMain'>
                    <Paper
                        elevation={3}
                        className="schoolUsageListHead"
                    >
                        <Box className='SchoolListDetails'>
                            <Box sx={{ height: "40%" }}>
                                <Box className="numberStyle">Select Teacher</Box>
                                <Box className="LineStyle" sx={{ background: "#2d8f3c" }} />
                            </Box>
                            <FormControl variant="standard" className="SchoolListSelect">
                                <Select
                                    labelId="TeacherList"
                                    variant="standard"
                                    value={TeacherId}
                                    onChange={handleSchoolList}
                                    label="Teacher List"
                                    color="success"
                                >
                                    {TeacherList.map((data, index) => {
                                        return (
                                            <MenuItem key={`Teacher-list-${index}`} value={data.ID}>
                                                {data.LoginName}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <Button
                                sx={{
                                    "&.MuiButton-root": {
                                        background: "#2d8f3c",
                                        color: "#fff",
                                    },
                                    "&:hover": {
                                        color: "#fff",
                                        boxShadow: "0px 0px 0px 0px #2d8f3c, 0px 0px 0px 0px #2d8f3c, 0px 0px 3px 1px #2d8f3c"
                                    }
                                }}
                                onClick={() => {
                                    history.push(`school-teacher-usage-data/teacher-details/${TeacherId}`)
                                }}
                            >
                                Ok
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </Box> */}
        </ThemeProvider >
    );
}