import React, { useState, useEffect } from "react";
import "./ClassUsageData.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Typography,
    Tooltip,
    Breadcrumbs,
    Backdrop,
    CircularProgress,
    TextField,
    Autocomplete,
} from '@mui/material';
import Logo from "../../constants/images";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AllActions from "../../redux/ducks/actions";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// Icons
import SchoolIcon from '@mui/icons-material/School';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import Creators from "../../redux/ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SchoolPerformContainer from "./Performance_Section/SchoolPerformContainer";
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import CustomHeader from "../../Components/CustomHeader";
import CustomClassCard from "../../Components/CustomClassCard";
import { Grid, Column } from "@carbon/react";
import { ChartBar, ChartLine } from "@carbon/icons-react";
import CustomTableButton from "../../Components/CustomTableButton";
import Districtcard from "../../Admin_District/DistrictDashboard/Districtcard";
import { FormatNumbers } from "../../constants";
import Schoolcard from "../../Admin_District/DistrictDashboard/SchoolCard";

export default function ClassUsageDataDashboard() {
    const history = useHistory(); //history
    const dispatch = useDispatch();
    const [classUsageDashboardData, setClassUsageDashboardData] = useState([]);
    const [expirationDate, setExpirationDate] = useState("");
    const url = useParams();
    const { userInfo } = useSelector(state => state.data); //state data
    const [classList, setClassList] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(null);

    const getClassDBData = (classId) => {
        dispatch(AllActions.classUsageDataSchoolDashboard(classId, (response) => {
            setClassUsageDashboardData(response);
            setExpirationDate(userInfo.LicenseExpiryDate)
        }));
    }

    useEffect(() => {
        dispatch(AllActions.classUsageDataSchoolList(userInfo.SuperGroupID, (response) => {
            if (response.length > 0) {
                setClassList(response);
                const newData = response && response.length > 0 ? response.filter(data => data.ID === Number(url.id)) : [{ id: -1, label: "" }]
                setValue(newData[0]);
            } else {
                setClassList([]);
            }
        }))
        getClassDBData(Number(url.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (url.id !== -1) {
            getClassDBData(Number(url.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url.id]);

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (expirationDate) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = expirationDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classUsageDashboardData, expirationDate])

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        margin: "10px",
    }));

    const mdTheme = createTheme();

    let defaultProps = {
        options: classList,
        getOptionLabel: (option) => option && option.Name && (option.Name).trim()
    };

    return (
        <ThemeProvider theme={mdTheme}>
            <Box className='districtStyle'>
                <Backdrop
                    sx={{
                        background: "rgb(0 0 0 / 30%)",
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={loading}
                >
                    <CircularProgress style={{ color: "#008000" }} />
                </Backdrop>

                <CustomHeader
                    licenseStatus={licenseStatus}
                    headerLabel={`Class Dashboard`}
                />

                <Box className="customWelcomeCard">
                    <Box className="customWelcomePhase1">
                        <Box className="customWelcomePhase1">
                            {
                                classList && classList.length > 0 ?
                                    <Autocomplete
                                        {...defaultProps}
                                        value={value}
                                        onChange={(_, newValue) => {
                                            if (!newValue) {
                                                if (classList && classList.lenght > 0) {
                                                    setValue({
                                                        label: classList[0].Name,
                                                        id: classList[0].ID
                                                    });
                                                }
                                                history.push(`/school/school-class-usage-data/class-details/${classList[0].ID}`)
                                            } else {
                                                setValue(newValue);
                                                history.push(`/school/school-class-usage-data/class-details/${newValue.ID}`)
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Class names"
                                                variant="standard"
                                                sx={{
                                                    '& label.Mui-focused': {
                                                        color: "#15135E",
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#15135E',
                                                    },
                                                }}
                                            />
                                        )}
                                    /> : null
                            }
                        </Box>
                    </Box>
                    <Box className="customWelcomePhase2">
                        {
                            userInfo.SourceID !== "" ? null : (
                                <React.Fragment>
                                    <CustomTableButton
                                        buttonLabel={"Daily Summary"}
                                        onClickButton={() => history.push(`/school/school-class-usage-data/class-daily-summary/${Number(url.id)}`)}
                                        buttonIcon={<ChartLine size={16} />}
                                    />
                                    <CustomTableButton
                                        buttonLabel={"Activity Summary"}
                                        onClickButton={() => history.push(`/school/school-class-usage-data/class-details-summary/${Number(url.id)}`)}
                                        buttonIcon={<ChartBar size={16} />}
                                    />
                                    <CustomTableButton
                                        buttonLabel={"Student List"}
                                        onClickButton={() => history.push(`/school/school-class-usage-data/student-list/${Number(url.id)}`)}
                                        buttonIcon={<ChartBar size={16} />}
                                    />
                                </React.Fragment>
                            )
                        }
                    </Box>
                </Box>

                <Box className='nsMainContent'>

                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            Class Dashboard
                        </Box>
                    </Box>

                    <Schoolcard data={classUsageDashboardData} />

                    <Box
                        className="gridWrapper"
                        style={{
                            padding: "0.75rem"
                        }}
                    >
                        <Grid>
                            <Column sm={4} md={4} lg={4}>
                                <CustomClassCard
                                    cardLabel={FormatNumbers(classUsageDashboardData && classUsageDashboardData.Minutes)}
                                    cardValue={"Minutes Played"}
                                    backgroundColor={"linear-gradient(225deg, #D896FF 0%, #F57373 100%)"}
                                />
                            </Column>
                            <Column sm={4} md={4} lg={4}>
                                <CustomClassCard
                                    cardLabel={FormatNumbers(classUsageDashboardData && classUsageDashboardData.ProblemsCompleted)}
                                    cardValue={"Problems Solved"}
                                    backgroundColor={"linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.00) 96.41%), linear-gradient(132deg, #667EEA 6.78%, #764BA2 93.86%)"}
                                />
                            </Column>
                            <Column sm={4} md={4} lg={4}>
                                <CustomClassCard
                                    cardLabel={FormatNumbers(classUsageDashboardData && classUsageDashboardData.LevelsAttempted)}
                                    cardValue={"Games Completed"}
                                    backgroundColor={"linear-gradient(225deg, #41A7C4 0%, #3E72E8 100%)"}
                                />
                            </Column>
                            <Column sm={4} md={4} lg={4}>
                                <CustomClassCard
                                    cardLabel={FormatNumbers(classUsageDashboardData && classUsageDashboardData.LevelsMastered)}
                                    cardValue={"Levels Mastered"}
                                    backgroundColor={"linear-gradient(225deg, #95CC4E 0%, #2ED8F7 100%)"}
                                />
                            </Column>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}