import React, { useEffect, useState } from "react";
import { Button, Stack, Typography, Box, Snackbar, Alert, Backdrop } from "@mui/material";
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import NotificationsIcon from '@mui/icons-material/Notifications';
import "../ManageDistrict/ManageDistrict.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../redux/ducks/actions";
import MUIDataTable from "mui-datatables";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import CircularProgress from '@mui/material/CircularProgress';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { NOTIFICATION_DELETED_SUCCESS, } from "../../constants";
import { Add, Notification } from "@carbon/icons-react";
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";

export default function Notifications() {

  const Btncolor = "#2d8f3c";
  const dispatch = useDispatch();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const { userInfo } = useSelector((state) => state.data);

  // Action Status
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "",
  });
  const { snackStatus, snackMessageFor } = snackbar;

  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  // Loading
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    dispatch(allActions.getNewNotifications((response) => {
      setNotifications(response)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    "Notification message",
    "District name",
    "School name",
    "Created date",
    "Max allowed student",
    "Current no of student",
  ]

  const options = {
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    onRowsDelete: (items) => {
      setLoading(true);
      let allIndex = [];
      let deleteIds = [];
      items.data.map((indexData) => {
        allIndex.push(indexData.index)
        return null;
      })
      notifications.map((data, index) => {
        if (allIndex.includes(index)) {
          deleteIds.push(data.StudentExceedNotificationID)
        }
        return null;
      })
      let ids = deleteIds.toString();
      dispatch(allActions.deleteNotifications(ids, "Nsadmin", (response) => {
        if (response.MessageID === 0) {
          setLoading(false);
          handleSnackbarClick("delete")
          dispatch(allActions.getNewNotifications((response) => {
            setNotifications(response)
          }));
        } else {
          setLoading(false);
          console.error("response", response);
        }
      }));
    },
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };


  const [licenseStatus, setLicenseStatus] = React.useState(false);
  React.useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])


  return (
    <Box className='districtStyle'>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>

      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        {
          snackMessageFor === "delete" ?
            <Snackbar
              key="SnackBar"
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackStatus}
              onClose={handleSnackbarClose}
            >
              <Alert
                variant="filled"
                severity="success"
                sx={{
                  "&.MuiPaper-root": {
                    background: "#00c853",
                    color: "#fff",
                  },
                }}
                action={
                  <CloseRoundedIcon
                    sx={{ cursor: "pointer", color: "#fff" }}
                    onClick={() => handleSnackbarClose()}
                    color="inherit"
                    size="small"
                  />
                }
              >
                {NOTIFICATION_DELETED_SUCCESS}
              </Alert>
            </Snackbar>
            : null
        }
      </Stack>

      <CustomHeader
        hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
        licenseStatus={licenseStatus}
        headerLabel={"Notifications"}
      />

      <Box className="customWelcomeCard">
        <Box className="customWelcomePhase1">
          <Box className='weeklyReportWrapper' />
        </Box>
        <Box className="customWelcomePhase2">
          <CustomTableButton
            buttonLabel={"All Notifications"}
            onClickButton={e => history.push(`admin-notification/viewAll`)}
            buttonIcon={<Notification size={16} />}
          />
        </Box>
      </Box>
      <Box className="nsMainContent">
        <MUIDataTable
          title="Notifications"
          data={notifications && notifications.map(data => {
            return [
              data.NotificationMessage,
              data.DistrictName,
              data.SchoolName,
              data.CreatedDate,
              data.MaxStudents,
              data.CurrentStudents
            ]
          })}
          columns={columns}
          options={options}
        />
      </Box>

    </Box>
  );
}