import React, { useState, useEffect } from "react";
import "./TeacherUsageData.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Typography,
    Tooltip,
    Breadcrumbs,
    Autocomplete,
    Backdrop,
    CircularProgress,
    TextField,
} from '@mui/material';
import Logo from "../../constants/images";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AllActions from "../../redux/ducks/actions";
import { Link } from "react-router-dom";
import GroupsIcon from '@mui/icons-material/Groups';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// Icons
import SchoolIcon from '@mui/icons-material/School';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Creators from "../../redux/ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import CustomHeader from "../../Components/CustomHeader";
import { PenFountain, Search, User } from "@carbon/icons-react";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import { UseFilterSearch } from "../../Components/UseFilterSearch";
import CustomDashboardCard from "../../Components/CustomDashboardCard";
import { Grid, Column } from "@carbon/react";

export default function TeacherUsageDataDashboard() {
    const history = useHistory(); //history
    const dispatch = useDispatch();
    const [teacherUsageDashboardData, setTeacherUsageDashboardData] = useState([]);
    const [expirationDate, setExpirationDate] = useState("");
    const url = useParams();
    const [teacherList, setTeacherList] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const { userInfo } = useSelector(state => state.data); //state data
    const [searchValue, setSearchValue] = useState("");

    const getTeacherDBData = (classId) => {
        setLoading(true);
        dispatch(AllActions.teacherUsageDataDashboard(Number(url.id), (response) => {
            setLoading(false);
            setTeacherUsageDashboardData(response);
            setExpirationDate(response.ExpirationDate)
            dispatch(Creators.setTeacherId(Number(url.id)));
        }));
    }

    useEffect(() => {
        setLoading(true);
        dispatch(AllActions.teacherUsageDataList(userInfo.SuperGroupID, (response) => {
            setLoading(false);
            if (response.length > 0) {
                setTeacherList(response);
                const newData = response && response.length > 0 ? response.filter(data => data.ID === Number(url.id)) : [{ id: -1, label: "" }]
                setValue(newData[0]);
                setLoading(false);
            } else {
                setTeacherList([]);
                // history.push(`school-teacher-usage-data/teacher-details/-1`)
            }
        }))
        getTeacherDBData(Number(url.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (url.id !== -1) {
            getTeacherDBData(Number(url.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url.id]);

    const { filteredData } = UseFilterSearch({
        searchValue,
        retrieve: teacherUsageDashboardData &&
            teacherUsageDashboardData.Class &&
            Array.isArray(teacherUsageDashboardData.Class)
            ? teacherUsageDashboardData.Class : [],
    });

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (expirationDate) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = expirationDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teacherUsageDashboardData, expirationDate])

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        margin: "10px",
    }));

    const mdTheme = createTheme();

    let defaultProps = {
        options: teacherList,
        getOptionLabel: (option) => option && option.LoginName && (option.LoginName).trim()
    };

    const welcomCardData = [
        {
            label: "Total Classes",
            icon: <PenFountain className="welcomeCardIcons" />,
            value: teacherUsageDashboardData.TotalClasses
        },
        {
            label: "Total Students",
            icon: <User className="welcomeCardIcons" />,
            value: teacherUsageDashboardData.TotalStudents
        },
    ]

    return (
        <ThemeProvider theme={mdTheme}>
            <Box className='districtStyle'>
                <Backdrop
                    sx={{
                        background: "rgb(0 0 0 / 30%)",
                        color: '#fff',
                        zIndex: "99999999"
                    }}
                    open={loading}
                >
                    <CircularProgress style={{ color: "#008000" }} />
                </Backdrop>
                <CustomHeader
                    licenseStatus={licenseStatus}
                    headerLabel={`Dashboard`}
                />
                <CustomWelcomeCard
                    welcomCardData={welcomCardData}
                    cardLabel={"Teacher Name"}
                    cardValue={`${teacherUsageDashboardData.LoginName ? teacherUsageDashboardData.LoginName : ""}`}
                />
                <Box className="customWelcomeCard">
                    <Box className="customWelcomePhase1">
                        <Box className="customWelcomePhase1">
                            {
                                teacherList && teacherList.length > 0 ?
                                    <Autocomplete
                                        {...defaultProps}
                                        value={value}
                                        onChange={(_, newValue) => {
                                            if (!newValue) {
                                                if (teacherList && teacherList.lenght > 0) {
                                                    setValue({
                                                        label: teacherList[0].LoginName,
                                                        id: teacherList[0].ID
                                                    });
                                                }
                                                history.push(`/school/school-teacher-usage-data/teacher-details/${teacherList[0].ID}`)
                                            } else {
                                                setValue(newValue);
                                                history.push(`/school/school-teacher-usage-data/teacher-details/${newValue.ID}`)
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Teacher names"
                                                variant="standard"
                                                sx={{
                                                    '& label.Mui-focused': {
                                                        color: "#15135E",
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#15135E',
                                                    },
                                                }}
                                            />
                                        )}
                                    /> : null
                            }
                        </Box>
                    </Box>
                    <Box className="customWelcomePhase2">
                    </Box>
                </Box>

                <Box className='nsMainContent'>
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            Classes
                        </Box>
                        <Box>
                            <span className="searchWrapper">
                                <input
                                    className="searchInput"
                                    type="search"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <Search className="searchIcon" />
                            </span>
                        </Box>
                    </Box>
                    <Box className="gridWrapper">
                        <Grid>
                            {
                                filteredData.map((data, index) => {
                                    return (
                                        <Column sm={4} md={8} lg={8}>
                                            <CustomDashboardCard
                                                key={`custom-dashboard-card${index}`}
                                                index={index}
                                                onClickCard={() => history.push(`/school/school-class-usage-data/class-details/${data.GroupID}`)}
                                                teacherClass={data.ClassName}
                                                TotalStudents={data.TotalStudents}
                                                Minutes={data.Minutes}
                                                ProblemsCompleted={data.ProblemsCompleted}
                                                LevelsAttempted={data.LevelsAttempted}
                                                LevelsMastered={data.LevelsMastered}
                                            />
                                        </Column>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}