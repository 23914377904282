import { makeStyles } from "@material-ui/core";
import InfoIcon from '@mui/icons-material/Info';
import { GridActionsCellItem, } from "@mui/x-data-grid";
import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../redux/ducks/actions";
import { useHistory } from "react-router-dom";
import {
    Tooltip,
    Backdrop,
    TextField,
    Autocomplete,
    Box,
    Button,
    Stack,
    Typography,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Alert,
    Chip
} from '@mui/material';
import "../TeacherDashboard/TeacherDashboard.css";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Images from "../../constants/images";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { DELETE_ASSIGNMENT_MESSAGE } from "../../constants";
import CustomHeader from "../../Components/CustomHeader";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import CustomTableButton from "../../Components/CustomTableButton";
import { ChartBar, CloseFilled, CloseOutline } from "@carbon/icons-react";
import { Column, Grid } from "@carbon/react";

export default function Assignments() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { userInfo, listAllClasses, assignmentList, assignmentClassId } = useSelector(state => state.data);
    const [open, setOpen] = useState(false)
    const [detailData, setDetailData] = useState([]);
    const [value, setValue] = React.useState(null);
    const [assignmentId, setAssignmentId] = useState(0);
    // Loading
    const [loading, setLoading] = React.useState(false);

    // delete Assignment Modal
    const useStyles = makeStyles(() => ({
        backDrop: {
            backdropFilter: "blur(4px)",
            backgroundColor: "rgba(30,41,51,.45)",
        },
    }));
    const classes = useStyles();
    const [deleteModel, setDeleteModel] = useState(false);
    const handleDeleteModelOpen = () => setDeleteModel(true);
    const handleDeleteModelClose = () => {
        setDeleteModel(false);
    };

    // Snack Message
    const [snackbar, setSnackbar] = React.useState({
        snackStatus: false,
        snackMessageFor: "",
    });
    const { snackStatus, snackMessageFor } = snackbar;
    const handleSnackbarClick = (msg) => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: true,
            snackMessageFor: msg,
        }));
    };
    const handleSnackbarClose = () => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: false,
            snackMessageFor: "",
        }));
    };

    let defaultProps = {
        options: listAllClasses,
        getOptionLabel: (option) => option && option.Name && (option.Name).trim()
    };

    const getAssignmentData = () => {
        dispatch(allActions.classListTeacher(userInfo.LoginID, (response) => {
            setValue(response.length > 0 ? response[0] : -1);
            dispatch(allActions.setAssignmentClassId(response.length > 0 ? response[0] : -1));
            dispatch(allActions.getAssignments(userInfo.LoginID, response.length > 0 ? response[0].GroupID : -1, (response) => {
                setData(response);
                setLoading(false)
            }));
        }));
    }

    useEffect(() => {
        setLoading(true)
        getAssignmentData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (value) {
            setLoading(true)
            dispatch(allActions.getAssignments(userInfo.LoginID, value.GroupID, (response) => {
                setData(response);
                setLoading(false)
            }));
        }
    }, [value, dispatch, userInfo])


    const columns = [
        {
            label: "Name",
            options: {
                filter: false,
            }
        },
        {
            label: "Description",
            options: {
                filter: false,
            }
        },
        {
            label: "Created Date",
            options: {
                filter: false,
            }
        },
        {
            label: "Due Date",
            options: {
                filter: false,
            }
        },
        {
            label: "Status",
            options: {
                filter: true,
                filterList: ['Active'],
                customBodyRender: (value, tableMeta, tableData) => {
                    return (
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            {
                                value === "Active" ? <Chip label="Active" color="success" /> :
                                    <Chip label="Inactive" color="error" />
                            }
                        </Stack>
                    )
                }
            }
        },
        {
            label: "Action",
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box className="actionWrapper">
                            <Tooltip
                                title="Details"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            background: "#0e1318",
                                        },
                                    },
                                }}
                                onClick={() => {
                                    let AssignmentID = value;
                                    dispatch(allActions.getAssignmentDetails(AssignmentID, userInfo.LoginID, (response) => {
                                        setDetailData(response);
                                        setOpen(true)
                                    }));
                                }}
                            >
                                <Box className='customActionIcon'>
                                    <InfoIcon size={16} color='#FFF' />
                                </Box>
                            </Tooltip>
                            <Tooltip
                                title="Report"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            background: "#0e1318",
                                        },
                                    },
                                }}
                                onClick={() => {
                                    let AssignmentID = value;
                                    history.push(`/teacher/teacher-assignments/report/${AssignmentID}`);
                                }}
                            >
                                <Box className='customActionIcon'>
                                    <ReceiptLongIcon size={16} color='#FFF' />
                                </Box>
                            </Tooltip>

                            {
                                tableMeta.rowData[4] === "Active" ?
                                    <Tooltip
                                        title="Delete"
                                        arrow
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    background: "#0e1318",
                                                },
                                            },
                                        }}
                                        onClick={() => {
                                            let AssignmentID = value;
                                            setAssignmentId(AssignmentID);
                                            handleDeleteModelOpen();
                                        }}
                                    >
                                        <Box className='customActionIcon'>
                                            <DeleteIcon size={16} color='#FFF' />
                                        </Box>
                                    </Tooltip>
                                    : null
                            }
                        </Box>
                    )
                }
            }
        }
    ];

    const options = {
        selectableRows: false,
        filter: true,
        download: false,
        print: false,
        rowsPerPage: 25,
        setFilterChipProps: (colIndex, colName, data) => {
            return {
                color: 'success',
                variant: 'outlined',
            };
        }
    };

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: "99999999"
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>

            <Stack spacing={2} sx={{ maxWidth: 1000 }}>
                <Snackbar
                    key="SnackBar"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackStatus}
                    onClose={handleSnackbarClose}
                >
                    {snackMessageFor === "delete"
                        ? (<Alert
                            variant="filled"
                            severity="success"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#00c853",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {DELETE_ASSIGNMENT_MESSAGE}
                        </Alert>)
                        : null}
                </Snackbar>
            </Stack>
            {/* Delete Modal */}
            <Dialog
                open={deleteModel}
                keepMounted
                onClose={handleDeleteModelClose}
                aria-describedby="alert-dialog-slide-description"
                BackdropProps={{
                    classes: {
                        root: classes.backDrop,
                    },
                }}
                PaperProps={{
                    style: {
                        padding: "1.5rem",
                    },
                }}
            >
                <DialogTitle>
                    <Box className="AssignmentDeleteHeader">
                        Delete Assignment
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="deleteContent">
                        Do you want to Deactivate the Assignment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomTableButton
                        isInActive={true}
                        buttonLabel={"Cancel"}
                        onClickButton={handleDeleteModelClose}
                    />
                    <CustomTableButton
                        isInActive={false}
                        buttonLabel={"ok"}
                        onClickButton={() => {
                            setLoading(true);
                            dispatch(allActions.deleteAssignment(userInfo.LoginID, assignmentId, (response) => {
                                if (response.MessageID === 0) {
                                    getAssignmentData();
                                    handleSnackbarClick("delete");
                                } else {
                                    console.error(response, "response");
                                }
                            }));
                            handleDeleteModelClose();
                        }}
                    />
                </DialogActions>
            </Dialog>
            {/* Report Model */}
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"md"}
                keepMounted
                onClose={() => setOpen(false)}
            >
                <DialogTitle className="assignmentDetailsHeader">
                    <Box className="assignmentDetailsHeaderText">
                        Assignment Details
                    </Box>
                    <CloseOutline
                        color="#0d7fd2"
                        size={32}
                        onClick={() => setOpen(false)}
                        style={{ cursor: "pointer" }}
                    />
                </DialogTitle>
                <DialogContent sx={{ padding: "2rem!important" }}>
                    <Grid>
                        <Column lg={6} md={4} sm={4} className="ADPhase1">
                            <Box className="ADTextTitle">Name</Box>
                            <Box className="ADTextContent">{detailData && detailData.Name}</Box>
                        </Column>
                        <Column lg={6} md={4} sm={4} className="ADPhase1">
                            <Box className="ADTextTitle">Description</Box>
                            <Box className="ADTextContent">{detailData && detailData.Desc}</Box>
                        </Column>
                        <Column lg={6} md={4} sm={4} className="ADPhase1">
                            <Box className="ADTextTitle">Concept</Box>
                            <Box className="ADTextContent">{detailData && detailData.ConceptName}</Box>
                        </Column>
                        <Column lg={6} md={4} sm={4} className="ADPhase1">
                            <Box className="ADTextTitle">Skill</Box>
                            <Box className="ADTextContent">{detailData && detailData.SkillName}</Box>
                        </Column>
                    </Grid>
                    <Box className="divider1"></Box>
                    <Box className="ADSectionWrapper">
                        <Box className="ADLSec">
                            <Grid>
                                <Column lg={8} md={4} sm={4} className="ADPhase1">
                                    <Box className="ADTextTitle">No. of Tiles</Box>
                                    <Box className="ADTextContent">{detailData && detailData.Tiles}</Box>
                                </Column>
                                <Column lg={8} md={4} sm={4} className="ADPhase1">
                                    <Box className="ADTextTitle">Starting Number</Box>
                                    <Box className="ADTextContent">{detailData && detailData.StartingNumber}</Box>
                                </Column>
                                <Column lg={8} md={4} sm={4} className="ADPhase1">
                                    <Box className="ADTextTitle">Min. Green</Box>
                                    <Box className="ADTextContent">{detailData && detailData.MinGreen}</Box>
                                </Column>
                                <Column lg={8} md={4} sm={4} className="ADPhase1">
                                    <Box className="ADTextTitle">Max. Red</Box>
                                    <Box className="ADTextContent">{detailData && detailData.MinRed}</Box>
                                </Column>
                            </Grid>
                        </Box>
                        <Box className="verticalDivider">
                            <Box className="divider2"></Box>
                        </Box>
                        <Box className="ADRSec">
                            <Grid>
                                <Column lg={8} md={4} sm={4} className="ADPhase1">
                                    <Box className="ADTextTitle">Order</Box>
                                    <Box className="ADTextContent">
                                        {
                                            detailData &&
                                                detailData.AssignmentOrder ?
                                                <img
                                                    src={
                                                        detailData.AssignmentOrder === "ru" ? Images.ruB :
                                                            detailData.AssignmentOrder === "ur" ? Images.urB :
                                                                detailData.AssignmentOrder === "rd" ? Images.rdB :
                                                                    detailData.AssignmentOrder === "lu" ? Images.luB :
                                                                        null
                                                    }
                                                    alt={
                                                        detailData.AssignmentOrder === "ru" ? "ru" :
                                                            detailData.AssignmentOrder === "ur" ? "ur" :
                                                                detailData.AssignmentOrder === "rd" ? "rd" :
                                                                    detailData.AssignmentOrder === "lu" ? "lu" :
                                                                        ""
                                                    }
                                                    className="imgStyleLayout"
                                                />
                                                : null
                                        }
                                    </Box>
                                </Column>
                                <Column lg={8} md={4} sm={4} className="ADPhase1">
                                    <Box className="ADTextTitle">Layout</Box>
                                    <Box className="ADTextContent">
                                        {
                                            detailData &&
                                                detailData.Layout ?
                                                <img
                                                    src={
                                                        detailData.Layout === "normal" ? Images.basicB :
                                                            detailData.Layout === "zigzag" ? Images.zigzagB :
                                                                detailData.Layout === "spiral" ? Images.spiralB :
                                                                    null
                                                    }
                                                    alt={
                                                        detailData.Layout === "normal" ? "normal" :
                                                            detailData.Layout === "zigzag" ? "zigzag" :
                                                                detailData.Layout === "spiral" ? "spiral" :
                                                                    ""
                                                    }
                                                    className="imgStyleLayout"
                                                />
                                                : null
                                        }
                                    </Box>
                                </Column>
                                <Column lg={8} md={4} sm={4} className="ADPhase1">
                                    <Box className="ADTextTitle">Min. Time</Box>
                                    <Box className="ADTextContent">{detailData && detailData.MinTime}</Box>
                                </Column>
                                <Column lg={8} md={4} sm={4} className="ADPhase1">
                                    <Box className="ADTextTitle">Show Number</Box>
                                    <Box className="ADTextContent">{detailData.ShowNumber ? "True" : "False"}</Box>
                                </Column>
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>

            <CustomHeader
                licenseStatus={licenseStatus}
                headerLabel={"Assignments"}
            />

            <Box className="customWelcomeCard">
                <Box className="customWelcomePhase1">
                    <Box className='weeklyReportWrapper'>
                        {
                            userInfo && userInfo.UserType === "Supergrouptechadmin" ? "Users" :
                                listAllClasses && listAllClasses.length > 0 ?
                                    <Autocomplete
                                        {...defaultProps}
                                        value={value}
                                        onChange={(_, newValue) => {
                                            if (!newValue) {
                                                if (listAllClasses && listAllClasses.lenght > 0) {
                                                    setValue({
                                                        label: listAllClasses[0].Name,
                                                        id: listAllClasses[0].GroupID
                                                    });
                                                    dispatch(allActions.setAssignmentClassId(listAllClasses[0].GroupID));
                                                }
                                            } else {
                                                dispatch(allActions.setAssignmentClassId(newValue));
                                                setValue(newValue);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Class names"
                                                variant="standard"
                                                sx={{
                                                    '& label.Mui-focused': {
                                                        color: "#15135E",
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#15135E',
                                                    },
                                                }}
                                            />
                                        )}
                                    /> : null
                        }
                    </Box>
                </Box>
                <Box className="customWelcomePhase2">
                    <CustomTableButton
                        id="assignment-id"
                        buttonLabel={"Create Assignment"}
                        onClickButton={() => {
                            history.push(`teacher-assignments/create`)
                        }}
                        buttonIcon={<ChartBar size={16} />}
                    />
                </Box>
            </Box>

            <Box className="nsMainContent">
                <MUIDataTable
                    title={"Assignments"}
                    data={data && data.map(data => {
                        return [
                            data.Name,
                            data.Desc,
                            data.CreatedDate,
                            data.TargetDate,
                            data.Status,
                            data.ID
                        ]
                    })}
                    columns={columns}
                    options={options}
                />
            </Box>

        </Box>

    );
}