import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Tooltip,
    Typography,
    Stack,
    Breadcrumbs,
    Button,
    Chip,
} from '@mui/material';
import allActions from "../../redux/ducks/actions";
import CastForEducationRoundedIcon from '@mui/icons-material/CastForEducationRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Logo from "../../constants/images";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import "./ManageUsers.css";
import { GridActionsCellItem, } from "@mui/x-data-grid";
import CustomTableButton from '../../Components/CustomTableButton';
import CustomHeader from '../../Components/CustomHeader';
import { Add, Edit } from '@carbon/icons-react';

function DistrictAdmin(props) {
    const editAction = "#b58119";
    const history = useHistory();
    const dispatch = useDispatch(); //dispatch
    const { userInfo } = useSelector((state) => state.data);
    const [tableRow, setTableRow] = useState([]);
    const Btncolor = "#2d8f3c";

    useEffect(() => {
        dispatch(allActions.getRestrictedAdminUsersList(userInfo.DomainID, (response) => {
            setTableRow(response);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        "User Name",
        "First Name",
        "Last Name",
        "User Type",
        {
            label: "Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Stack direction="row" spacing={1}>
                            {
                                value === 1 ? <Chip label="Active" color="success" /> :
                                    <Chip label="InActive" color="error" />
                            }
                        </Stack>
                    )
                }
            }
        },
        {
            label: "Action",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box className="actionWrapper">
                            <Tooltip
                                title="Edit"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            background: "#0e1318",
                                        },
                                    },
                                }}
                                onClick={() => {
                                    let rowId = value;
                                    if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                                        history.push(`/school/school-user-logins/update/${rowId}`);
                                    } else {
                                        history.push(`/district/district-user-logins/restricted-admin/update/${rowId}`);
                                    }
                                }}
                            >
                                <Box className='customActionIcon'>
                                    <Edit size={16} color='#FFF' />
                                </Box>
                            </Tooltip>
                        </Box>
                    )
                }
            }
        }
    ];

    const options = {
        selectableRows: false,
        filter: false,
        download: false,
        print: false,
        rowsPerPage: 25,
    };

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    return (
        <Box className='districtStyle'>
            <CustomHeader
                licenseStatus={licenseStatus}
                needBreadCrumb={true}
                previousPageLabel={"User Logins"}
                currentPageLabel={"District Admin"}
                onClickPrevPageLabel={() => {
                    const navigateTo = userInfo && userInfo.UserType === "Supergrouptechadmin" ? "/school/school-user-logins" : "/district/district-user-logins"
                    history.push(navigateTo);
                }}
            />

            <Box className="customWelcomeCard">
                <Box className="customWelcomePhase1">
                    <Box className='weeklyReportWrapper' />
                </Box>
                <Box className="customWelcomePhase2">
                    <CustomTableButton
                        buttonLabel={"New User"}
                        onClickButton={() => {
                            if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                                history.push("/school/school-user-logins/create")
                            } else {
                                history.push("/district/district-user-logins/create")
                            }
                        }}
                        buttonIcon={<Add size={16} />}
                    />
                </Box>
            </Box>
            <Box className="nsMainContent">
                <MUIDataTable
                    title={"Users"}
                    data={tableRow ? tableRow.map(data => {
                        return [
                            data.UserName,
                            data.FName,
                            data.LName,
                            data.UserType === "DistrictAdmin" ? "District Admin"
                                : data.UserType === "restricteddistrictadmin" ? "Restricted District Admin"
                                    : data.UserType,
                            data.ActiveFlag,
                            data.UserID,
                        ]
                    }) : []}
                    columns={columns}
                    options={options}
                />
            </Box>
        </Box>
    );
}
export default DistrictAdmin;
