import * as React from 'react';
import {
    Box,
} from '@mui/material';
import { PenFountain, User } from '@carbon/icons-react';

export default function CustomWelcomeCard({
    isSelectCard,
    selectComponent,
    welcomCardData,
    cardLabel,
    cardValue,
}) {

    if (isSelectCard) {
        return (
            <Box className="customWelcomeCard">
                <Box className="customWelcomePhase1">
                    {selectComponent}
                </Box>
                <Box className="customWelcomePhase2">
                    {
                        welcomCardData &&
                        Array.isArray(welcomCardData) &&
                        welcomCardData.map((data, index) => {
                            return (
                                <Box className="customDashboardCardWrapper" key={`${data.label}-${index}`}>
                                    <Box className="customDashboardCardCount">
                                        {data.icon}
                                        {data.value}
                                    </Box>
                                    <Box className="customDashboardCardLabel">
                                        {data.label}
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        );
    }

    return (
        <Box className="customWelcomeCard">
            <Box className="customWelcomePhase1">
                <Box className="customWelcomeText1">{cardLabel}</Box>
                <Box className="customWelcomeText2">{cardValue}</Box>
            </Box>
            <Box className="customWelcomePhase2">
                {
                    welcomCardData &&
                    Array.isArray(welcomCardData) &&
                    welcomCardData.map((data, index) => {
                        return (
                            <Box className="customDashboardCardWrapper" key={`${data.label}-${index}`}>
                                <Box className="customDashboardCardCount">
                                    {data.icon}
                                    {data.value}
                                </Box>
                                <Box className="customDashboardCardLabel">
                                    {data.label}
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    );
} 