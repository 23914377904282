import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {
  Button,
  Stack,
  Typography,
  Box
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../redux/ducks/actions";
import { useHistory } from "react-router-dom";
import ProductsTable from "./ProductsTable";
import "./ManageProducts.css";
import CustomHeader from '../../Components/CustomHeader';
import CustomTableButton from '../../Components/CustomTableButton';
import { Add } from '@carbon/icons-react';

function ManageProducts() {
  const history = useHistory(); //History
  const { userInfo } = useSelector(state => state.data);
  const [headerIndex, setHeaderIndex] = useState(false)

  const handleLoading = (data) => {
    setHeaderIndex(data)
  }

  // colors
  const Btncolor = "#2d8f3c";

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allActions.getAllProductsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [licenseStatus, setLicenseStatus] = React.useState(false);
  React.useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])


  return (
    <Box className='districtStyle'>
      <CustomHeader
        hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
        licenseStatus={licenseStatus}
        headerLabel={"Manage Products"}
      />

      <Box className="customWelcomeCard">
        <Box className="customWelcomePhase1">
          <Box className='weeklyReportWrapper' />
        </Box>
        <Box className="customWelcomePhase2">
          <CustomTableButton
            buttonLabel={"New Product"}
            onClickButton={() => history.push("/admin/admin-manage-products/create")}
            buttonIcon={<Add size={16} />}
          />
        </Box>
      </Box>
      <Box className="nsMainContent">
        <ProductsTable handleLoading={handleLoading} />
      </Box>
    </Box>
  );
}
export default ManageProducts;
