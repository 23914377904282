import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  // Number Sense React
  // Classlink Login user
  classlinkLogin: [
    "sourceId",
    "response",
  ],
  // Login user
  userLogin: [
    "username",
    "password",
    "domainname",
    "successFunc",
    "failureFunc",
  ],
  // ClassLinkLogin
  classLogin: [
    "code",
    "successFunc",
    "failureFunc",
  ],
  userAuthToken: ["Token"],
  updateToken: ["Token"],

  // The login success response
  userLoginSuccessMessage: ["successMessage"],
  // The login error response
  userLoginFailureMessage: ["errorMessage"],
  notificationLength: ["length"],
  getAllDistrictList: [],
  setDistrictList: ["districtList"],
  putDistrict: ["districtData", "successFunction"],
  putClasslinkDistrict: ["districtData", "successFunction"],
  getDistrictByID: ["districtId", "successFunction"],
  updateDistrictById: ["updateData", "successFunction"],
  updateClassLinkById: ["updateData", "successFunction"],

  // Manage Product
  getAllProductsList: [],
  setProductsList: ["productsList"],
  putProducts: ["productsData", "successFunction"],
  getProductsByID: ["productsId", "successFunction"],
  updateProductsById: ["updateData", "successFunction"],

  // Manage Class License
  setClassLicenseList: ["classLicenseList"],
  getAllClassLicense: ["successFunction"],
  updateClassSubscription: ["updateClassSubscription", "successFunction"],
  //getClassLicenseTrans: ["successFunction"],

  // setAdminData: ["adminData"],
  getAdminData: ["successFunction"],
  // Logout user
  userLogout: ["successFn"],
  // Logout Status
  setLogoutStatus: ["logoutstatus"],
  // get district dashboard data
  getDistrictDashboardData: ["districtId"],
  setDistrictDashboardData: ["districtDashboardData"],
  activeAndDeactive: ["districtId", "status", "successFunction"],
  districtDetails: ["districtId", "successFunction"],
  deleteProduct: ["productId", "successFunction"],
  // get and set schoolList
  getAllSchoolList: ["districtId"],
  setSchoolList: ["schoolList"],
  putSchools: ["schoolsData", "successFunction"],
  getSchoolsById: ["schoolsId", "successFunction"],
  updateSchoolsById: ["updateData", "successFunction"],
  schoolsBulkUpload: ["updateData", "successFunction"],
  // get and set classList
  getAllClassList: ["schoolId", "successFunction"],
  setClassList: ["classList"],
  putClasses: ["classesData", "successFunction"],
  getClassesById: ["classesId", "successFunction"],
  updateClassesById: ["updateData", "successFunction"],
  classesBulkUpload: ["updateData", "successFunction"],
  getClassDetails: ["classId", "successFunction"],
  bulkUploadNSTeacherClassMapping: ["updateData", "successFunction"],
  // school usage data
  schoolUsageDataSchoolList: ["districtId", "successFunction"],
  schoolUsageDataSchoolDashboard: ["schoolId", "successFunction"],
  dailySUD: ["districtId", "successFunction"],
  detailsSUD: ["districtId", "successFunction"],
  classSUD: ["districtId", "successFunction"],
  setClassId: ["classId"],
  setSchoolName: ["schoolName"],
  setSchoolId: ["schoolId"],
  setStudentId: ["studentId"],
  studentsSummaryCS: ["classId", "successFunction"],
  studentsSummaryDailyAndActivity: ["studentId", "successFunction"],
  classDailySummaryCS: ["classId", "successFunction"],
  classActivitySummaryCS: ["classId", "successFunction"],

  //District Admin
  getPurchaseHistory: ["districtId", "successFunction"],
  districtAdminNotifications: ["districtId", "successFunction"],
  districtAdminViewAllNotif: ["districtId", "successFunction"],

  setTeacherId: ["LoginID"],
  //Manage District License
  updateSubscription: ["updateSubscription", "successFunction"],
  getDistrictLicProductHistory: ["districtId", "successFunction"],
  manageDistrictLicenceSubscription: ["subscriptionData", "successFunction"],

  //Manage User License
  setUserLicenseList: ["userLicenseList"],
  getUserLicenseList: ["successFunction"],
  getUserDistrictProductHistory: ["UserId", "successFunction"],
  userDistrictUpdate: ["userDistrictUpdate", "successFunction"],

  // Manage District LicenseList
  setDistrictLicenseList: ["districtLicenseList"],
  getDistrictLicenseList: ["successFunction"],

  //notifications
  getNewNotifications: ["successFunction"],
  setNewNotifications: ["newNotifications"],
  getAllNotifications: ["successFunction"],
  setAllNotifications: ["allNotifications"],
  deleteNotifications: ["notificationIds", "userType", "successFunction"],

  //Leaderboard
  getStudentLeaderboardData: ["schoolLeaderboard", "successFunction"],
  getClassLeaderboardData: ["classLeaderboard", "successFunction"],

  // User Logins
  getAllUserList: ["schoolId", "successFunction"],
  setUserList: ["usersList"],
  putUser: ["userData", "successFunction"],
  getClassBySchoolId: ["schoolId", "successFunction"],
  getUsersById: ["schoolId", "successFunction"],
  updateUsersById: ["updateData", "successFunction"],
  districtWeeklyReport: ["weeklyReportData", "successFunction"],
  saveCurrentSchool: ["schoolData"],
  saveCurrentSchoolInClass: ["schoolData"],

  // get school dashboard data
  getSchoolDashboardData: ["SuperGroupId"],
  setSchoolDashboardData: ["schoolDashboardData"],
  activitySAD: ["schoolId", "successFunction"],
  detailsSAD: ["schoolId", "successFunction"],

  // class usage data
  setClassDetails: ["schoolId", "className", "serviceResponse"],
  classUsageDataSchoolList: ["schoolId", "successFunction"],
  classUsageDataSchoolDashboard: ["GroupID", "successFunction"],
  schoolClassDailySummaryCS: ["classId", "successFunction"],
  schoolClassDetailsSummaryCS: ["classId", "successFunction"],

  // Teacher Usage Data
  teacherUsageDataList: ["teacherId", "successFunction"],
  teacherUsageDataDashboard: ["GroupID", "successFunction"],
  getTeacherDashboardData: ["LoginID", "successFunction"],
  setTeacherDashboardData: ["teacherDashboardData"],
  teacherClassDailySummaryCS: ["ClassID", "successFunction"],
  teacherClassDetailsSummaryCS: ["ClassID", "successFunction"],

  // Weekly Report School
  schoolWeeklyReport: ["weeklyReportData", "successFunction", "classname"],
  setStudentName: ["name"],

  districtDashboardDailySummary: ["districtId", "successFunction"],
  districtDashboardActivitySummary: ["districtId", "successFunction"],
  getRestrictedAdminUsersList: ["districtId", "successFunction"],
  userLoginSchoolsBulkUpload: ["schoolId", "mailNo", "userList", "successFunction"],
  userLoginClassesBulkUpload: ["schoolId", "classId", "mailNo", "userList", "successFunction"],
  getRestrictedAdminUsersUsersById: ["userId", "successFunction"],
  updateRestrictedAdminUsersById: ["updateData", "successFunction"],

  //Classlink Integration
  classlinkSchoolUpload: ["schoolData", "successFunction"],
  classlinkClassUpload: ["classData", "successFunction"],
  classlinkUserUpload: ["userData", "successFunction"],
  classlinkUserMaping: ["userMaping", "successFunction"],

  // Teacher reports
  classListTeacher: ["teacherId", "successFunction"],
  classWeeklyReport: ["weeklyReportData", "successFunction"],
  studentUsageDataList: ["classId", "successFunction"],
  studentDailySummaryCS: ["studentId", "successFunction"],
  studentDetailsSummaryCS: ["studentId", "successFunction"],

  //Teacher-Assignments
  getAssignments: ["LoginID", "GroupID", "successFunction"],
  createAssignment: ["assignment", "successFunction"],
  getConcepts: ["domainID", "successFunction"],
  getSkills: ["ConceptID", "successFunction"],
  getAssignmentDetails: ["AssignmentID", "LoginID", "successFunction"],
  getAssignmentReport: ["LoginID", "AssignmentID", "successFunction"],
  deleteAssignment: ["LoginID", "AssignmentID", "successFunction"],
  getStudentList: ["ClassID", "successFunction"],

  classLbPopup: ["classLbData", "successFunction"],
  schoolLbPopup: ["schoolLbData", "successFunction"],

  setAssignmentClassId: ["assignmentClassId"],
  setAssignmentList: ["assignmentList"],

  changeTeacherNumber: ["data"],
  changeTeacherStatus: ["data"],
});
export const ActionTypes = Types;
export default Creators;

