import {
    useEffect, useState,
} from "react";
import { createTheme } from '@mui/material/styles';
import {
    Box,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import AllActions from "../../redux/ducks/actions";
// Icons
import { useParams } from "react-router-dom";
import CustomHeader from "../../Components/CustomHeader";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import CustomClassCard from "../../Components/CustomClassCard";
import { Grid, Column } from "@carbon/react";
import { FormatNumbers } from "../../constants";
import Districtcard from "../../Admin_District/DistrictDashboard/Districtcard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function DistrictDetails() {
    const dispatch = useDispatch();
    const history = useHistory(); //history
    const url = useParams();
    const [districtDetails, setDistrictDetails] = useState({});
    const [licenseStatus, setLicenseStatus] = useState(false);
    // Loading
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(AllActions.districtDetails(url.id, (response) => {
            setDistrictDetails(response);
            setLoading(false);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (districtDetails && districtDetails.ExpirationDate && districtDetails.length !== 0) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = districtDetails.ExpirationDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
    }, [districtDetails])

    return (
        <Box className='districtStyle'>

            <CustomHeader
                hidelicense={true}
                licenseStatus={licenseStatus}
                needBreadCrumb={true}
                previousPageLabel={"Manage Districts"}
                currentPageLabel={"District Summary"}
                onClickPrevPageLabel={() => { history.push("/admin/admin-manage-district") }}
            />

            <Box className="customWelcomeCard">
                <Box className="customWelcomePhase1Wrapper">
                    <Box className="customWelcomePhase1">
                        <Box className="customWelcomeText1">District Name</Box>
                        <Box className="customWelcomeText2">{districtDetails && districtDetails.District}</Box>
                    </Box>
                    <Box className="customWelcomePhase1">
                        <Box className="customWelcomeText1">License Status</Box>
                        <Box className={licenseStatus ? "customWelcomeText2License" : "customWelcomeText2LicenseR"}>
                            {licenseStatus ? "Active" : "Inactive"}
                        </Box>
                    </Box>
                </Box>
                <Box className="customWelcomePhase2">
                </Box>
            </Box>

            <Box className='nsMainContent'>

                <Box className="nsMainHeader">
                    <Box className="nsMainTextHeader">
                        District Summary
                    </Box>
                </Box>

                <Districtcard data={districtDetails} />

                <Box
                    className="gridWrapper"
                    style={{
                        padding: "0.75rem"
                    }}
                >
                    <Grid>
                        <Column sm={4} md={4} lg={4}>
                            <CustomClassCard
                                cardLabel={FormatNumbers(districtDetails && districtDetails.Minutes)}
                                cardValue={"Minutes Played"}
                                backgroundColor={"linear-gradient(225deg, #D896FF 0%, #F57373 100%)"}
                            />
                        </Column>
                        <Column sm={4} md={4} lg={4}>
                            <CustomClassCard
                                cardLabel={FormatNumbers(districtDetails && districtDetails.ProblemsCompleted)}
                                cardValue={"Problems Solved"}
                                backgroundColor={"linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.00) 96.41%), linear-gradient(132deg, #667EEA 6.78%, #764BA2 93.86%)"}
                            />
                        </Column>
                        <Column sm={4} md={4} lg={4}>
                            <CustomClassCard
                                cardLabel={FormatNumbers(districtDetails && districtDetails.LevelsAttempted)}
                                cardValue={"Games Completed"}
                                backgroundColor={"linear-gradient(225deg, #41A7C4 0%, #3E72E8 100%)"}
                            />
                        </Column>
                        <Column sm={4} md={4} lg={4}>
                            <CustomClassCard
                                cardLabel={FormatNumbers(districtDetails && districtDetails.LevelsMastered)}
                                cardValue={"Levels Mastered"}
                                backgroundColor={"linear-gradient(225deg, #95CC4E 0%, #2ED8F7 100%)"}
                            />
                        </Column>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
