import React from 'react';
import {
    Chart as ChartJSBar,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import "./WeeklyReports.css";

export default function NSPieChart(params) {
    ChartJSBar.register(
        ArcElement,
        Title,
        Tooltip,
        Legend
    );

    let delayed;
    const data = {
        labels: params.name,
        datasets: [
            {
                data: params.value,
                backgroundColor: [
                    "#A10035", // red
                    "#FF7F3F", // orange
                    "#ffc800", // yellow
                    "#224B0C", // green
                    "#277BC0", // blue
                    "#8758FF", // purple
                    "#E80F88", // pink
                    "#003865", // Navy
                    "#472D2D", // Brown
                    "#748DA6", // Gray
                ],
                borderColor: [
                    '#FA9494', // red
                    "#ffce86", // orange
                    "#FFDE00", // yellow
                    "#CFE8A9", // green
                    "#89CFFD", // blue
                    "#B1B2FF", // purple
                    "#FF8FB1", // pink
                    "#DFF6FF", // Navy
                    "#A77979", // Brown
                    "#F1EEE9", // Gray
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        animation: {
            onComplete: () => {
                delayed = true;
            },
            delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 300 + context.datasetIndex * 100;
                }
                return delay;
            },
        },
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    color: "#0e1318",
                    family: "sans-serif",
                }
            },
            datalabels: {
                display: false,
                clip: true,
                align: 'center',
                anchor: 'center',
                font: {
                    weight: 'bold',
                },
                color: '#ffffff',
            }
        },
    };

    return (
        <Pie data={data} options={options} />
    );
}