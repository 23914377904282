import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
// import MenuIcon from '@mui/icons-material/Menu';
import ShoppingBagRoundedIcon from '@mui/icons-material/ShoppingBagRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
    Avatar,
    Box,
    // Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Menu,
    MenuItem,
    Badge,
    IconButton,
    Tooltip,
    Drawer
    // Toolbar,
} from "@mui/material";
import Logout from '@mui/icons-material/Logout';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import allActions from "../redux/ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import Images from "../constants/images";
// import { mainListItems, secondaryListItems } from './listItems';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';
// import MenuList from './MenuList';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from "react-router-dom";
import { MENU } from "../constants";
import ROUTES from "../routes";
// import Dashboard from '../Dashboard/Dashboard';
import "./LayoutStyle.css";
import CustomBrandName from './CustomBrandName';
import CustomMenuList from './CustomMenuList';

// route config
const getUserRoutes = (routes) => {
    return routes.map((prop, key) => {
        if (prop.layout === "/admin") {
            return (
                <Route
                    exact
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                />
            );
        } else {
            return null;
        }
    });
};

const drawerWidth = 280;


const MDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            overflow: "auto",
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

export default function AdminLayout() {
    const { userInfo, notificationLength } = useSelector((state) => state.data);
    const history = useHistory(); //   HIstory
    const { url } = useRouteMatch(); //   Route
    const location = useLocation();
    const CurrentURL = location.pathname.split("/")[2];
    const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch(); // dispatch

    React.useEffect(() => {
        const currentPath = location && location.pathname.split("/")[2];
        switch (currentPath) {
            case 'admin-dashboard': document.title = "Number Sense - Admin Dashboard"
                break;
            case 'admin-manage-district': document.title = "Number Sense - Manage Districts"
                break;
            case 'admin-manage-products': document.title = "Number Sense - Manage Products"
                break;
            case 'admin-manage-district-license': document.title = "Number Sense - Manage District License"
                break;
            case 'admin-manage-class-license': document.title = "Number Sense - Manage Class License"
                break;
            case 'admin-manage-user-license': document.title = "Number Sense - Manage User License"
                break;
            case 'admin-notification': document.title = "Number Sense - Notifications"
                break;
            default: document.title = "Number Sense - Admin Console";
                break;
        }
    }, [url, location])

    React.useEffect(() => {
        if (userInfo.UserType !== "NSTechAdmin") {
            if (userInfo.UserType === "DistrictAdmin") {
                history.push(`/district`);
            } else if (userInfo.UserType === "GroupAdmin") {
                history.push(`/teacher`);
            } else if (userInfo.UserType === "Supergrouptechadmin") {
                history.push(`/school`);
            } else {
                history.push(`/login`);
            }
        }
    })

    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openProfile = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // 
    const [darwerLeft, setDarwerLeft] = React.useState(false);

    const toggleMobileDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDarwerLeft(open);
    };

    const DrawerListItems = () => (
        <List component="nav">
            {MENU.map((item, index) =>
                item.layout === url ? (
                    <Tooltip
                        key={"menu" + index}
                        title={open ? "" : item.menuName}
                        arrow
                        placement="right"
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    background: "#0e1318",
                                },
                            },
                        }}>
                        <ListItem
                            id={index}
                            key={index}
                            sx={{
                                "&.MuiListItem-root": {
                                    background:
                                        item.menuURL === CurrentURL ? "#e2f6e5" : undefined,
                                    borderRadius: "12px",
                                },
                                "&.MuiListItem-root:hover": {
                                    // background: "#e2f6e5",
                                    transform: "scale(1.05)",
                                    boxShadow: "0px 15px 28px rgb(0 0 0 / 10%), 0px 5px 12px rgb(0 0 0 / 8%)",
                                    transition: "all 300ms ease",
                                },
                            }}
                            button
                            onClick={() => history.push(item.layout + "/" + item.menuURL)}
                        >
                            <ListItemIcon>
                                <Avatar
                                    sx={{
                                        overflow: "inherit",
                                        borderRadius: "0",
                                        background: "transparent",
                                        color: item.menuURL === CurrentURL ? "#0e1318" : "#000000",
                                    }}
                                >
                                    {item.menuName === "Dashboard" ? (
                                        <DashboardRoundedIcon
                                            sx={{
                                                color:
                                                    item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                            }}
                                        />
                                    ) : item.menuName === "Manage Districts" ? (
                                        <LocationOnRoundedIcon
                                            sx={{
                                                color:
                                                    item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                            }}
                                        />
                                    ) : item.menuName === "Manage Products" ? (
                                        <ShoppingBagRoundedIcon
                                            sx={{
                                                color:
                                                    item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                            }}
                                        />
                                    ) : item.menuName === "Manage District License" ? (
                                        <AddLocationAltRoundedIcon
                                            sx={{
                                                color:
                                                    item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                            }}
                                        />
                                    ) : item.menuName === "Manage Class License" ? (
                                        <ClassRoundedIcon
                                            sx={{
                                                color:
                                                    item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                            }}
                                        />
                                    ) : item.menuName === "Manage User License" ? (
                                        <AccountCircleRoundedIcon
                                            sx={{
                                                color:
                                                    item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                            }}
                                        />
                                    ) : item.menuName === "Notifications" ?
                                        userInfo.ISNotification === 1 ? (
                                            <Badge badgeContent={notificationLength} color="error">
                                                <NotificationsActiveRoundedIcon
                                                    sx={{
                                                        color:
                                                            item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                    }}
                                                />
                                            </Badge>
                                        ) : (
                                            <NotificationsIcon
                                                sx={{
                                                    color:
                                                        item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                }}
                                            />
                                        )
                                        : null}
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText
                                primary={item.menuName}
                                disableTypography
                                sx={{
                                    color: item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                    fontWeight: item.menuURL === CurrentURL ? 700 : undefined,
                                }}
                            />
                        </ListItem>
                    </Tooltip>
                ) : null
            )}
        </List>
    );

    const webView = "17rem";
    const mobileView = "4rem";

    const onClickMenuItem = (layout, menuURL) => {
        history.push(layout + "/" + menuURL)
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Box className='layoutWrapper'>
                <Box
                    className='layoutMenuList'
                    style={{
                        width: open ? webView : mobileView,
                    }}
                >
                    <CustomBrandName
                        layoutName="Admin"
                        isMenuOpen={open}
                        onMenuToggle={toggleDrawer}
                    />
                    <CustomMenuList
                        isMenuOpen={open}
                        menuList={MENU}
                        currentRole={url}
                        currentMenuURL={CurrentURL}
                        onMenuClick={onClickMenuItem}
                    />
                </Box>
                <Box className='layoutMainContent'
                    style={{
                        width: open ? `calc(100% - ${webView})` : `calc(100% - ${mobileView})`
                    }}
                >
                    <Switch>
                        {getUserRoutes(ROUTES)}
                        <Redirect from="*" to="/admin/admin-dashboard" />
                    </Switch>
                </Box>
            </Box>

            {/* <Box className='sb-topnav navbar navbar-expand' id="NavStyle">
                    <Box className='NavBrandMenuStyle'>
                        <Typography className='brandname navbar-brand'>
                            NumberSense Admin Console
                        </Typography>
                        <Box className='menuIconStyle'>
                            <MenuRoundedIcon onClick={toggleDrawer} className="menuStyle" />
                        </Box>
                        <Box className='MobileViewDraweer'>
                            <MenuRoundedIcon onClick={toggleMobileDrawer(true)} className="menuStyle" />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip
                            title="Profile"
                            arrow
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        background: "#0e1318",
                                    },
                                },
                            }}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={openProfile ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openProfile ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32, background: "#e0faef", color: "#00864e" }}>
                                    {
                                        userInfo &&
                                        userInfo.FName &&
                                        userInfo.FName !== "" &&
                                        userInfo.FName.charAt(0)
                                    }
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={openProfile}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem>
                            <Avatar alt={userInfo.FName} src={Images.man} /> {userInfo.FName}
                        </MenuItem>
                        <MenuItem onClick={() => {
                            dispatch(
                                allActions.userLogout(() => {
                                    sessionStorage.Username = "";
                                    sessionStorage.Password = "";
                                    sessionStorage.userDomain = "";
                                    sessionStorage.Type = "";
                                    history.push("/");
                                })
                            );
                        }}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Box> 
                 <Box className='SideMainStyle'>
                    <React.Fragment key={"left"}>
                        <Drawer
                            open={darwerLeft}
                            onClose={toggleMobileDrawer(false)}
                        >
                            {DrawerListItems()}
                        </Drawer>
                    </React.Fragment>
                    <Box className="menuList" sx={{ display: 'flex' }}>
                        <MDrawer
                            variant="permanent"
                            open={open}
                        // onMouseLeave={closeDrawer}
                        >
                            {DrawerListItems()}
                        </MDrawer>
                    </Box>
                    <Box className='rightMainMenu'>
                        <Switch>
                            {getUserRoutes(ROUTES)}
                            <Redirect from="*" to="/admin/admin-dashboard" />
                        </Switch>
                    </Box>
                </Box> */}
        </ThemeProvider>
    );
}