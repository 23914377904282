import React, { useState, useEffect } from "react";
import "./TeacherDashboard.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Backdrop,
} from '@mui/material';
import AllActions from "../../redux/ducks/actions";
// Icons
import { useParams, useHistory } from "react-router-dom";
import Creators from "../../redux/ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomHeader from "../../Components/CustomHeader";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import { PenFountain, Search, User } from "@carbon/icons-react";
import { Grid, Column } from "@carbon/react";
import { UseFilterSearch } from "../../Components/UseFilterSearch";
import CustomDashboardCard from "../../Components/CustomDashboardCard";
import { formatDateToday } from "../../constants";
import CircularProgress from '@mui/material/CircularProgress';

export default function TeacherDashboard() {
    const dispatch = useDispatch();
    const [teacherDashboardData, setTeacherDashboardData] = useState([]);

    const url = useParams();
    const history = useHistory(); //history
    const { userInfo, teacherNumber, teacherStatus } = useSelector((state) => state.data);
    const [expirationDate, setExpirationDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const { filteredData } = UseFilterSearch({
        searchValue,
        retrieve: teacherDashboardData &&
            teacherDashboardData.Class &&
            Array.isArray(teacherDashboardData.Class)
            ? teacherDashboardData.Class : [],
    });
    useEffect(() => {
        setLoading(true);
        dispatch(AllActions.getTeacherDashboardData(userInfo.LoginID, (response) => {
            setTeacherDashboardData(response);
            setExpirationDate(response.ExpirationDate)
            dispatch(AllActions.classListTeacher(userInfo.LoginID, (response) => {
                dispatch(Creators.setTeacherId(Number(url.id)));
            }));
            
           
            setLoading(false);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    const [licenseStatus, setLicenseStatus] = React.useState(false);

    useEffect(() => {
        if (expirationDate && expirationDate !== "" && teacherDashboardData.length !== 0) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = teacherDashboardData.ExpirationDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teacherDashboardData, expirationDate])

    const mdTheme = createTheme();

    const welcomCardData = [
        {
            label: "Total Classes",
            icon: <PenFountain className="welcomeCardIcons" />,
            value: teacherDashboardData.TotalClasses
        },
        {
            label: "Total Students",
            icon: <User className="welcomeCardIcons" />,
            value: teacherDashboardData.TotalStudents
        },
    ]

    return (
        <ThemeProvider theme={mdTheme}>
            <Box className='districtStyle'>
                <Backdrop
                    sx={{
                        background: "rgb(0 0 0 / 30%)",
                        color: '#fff',
                        zIndex: "99999999"
                    }}
                    open={loading}
                >
                    <CircularProgress style={{ color: "#008000" }} />
                </Backdrop>
                <CustomHeader
                    licenseStatus={licenseStatus}
                    headerLabel={"Dashboard"}
                    needHelp={true}
                />
                <CustomWelcomeCard
                    welcomCardData={welcomCardData}
                    cardLabel={formatDateToday()}
                    cardValue={`Welcome back ${teacherDashboardData.LoginName ? teacherDashboardData.LoginName : ""}!`}
                />

                <Box className='nsMainContent'>
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            Classes
                        </Box>
                        <Box>
                            <span className="searchWrapper">
                                <input
                                    className="searchInput"
                                    type="search"
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <Search className="searchIcon" />
                            </span>
                        </Box>
                    </Box>
                    <Box className="gridWrapper">
                        <Grid>
                            {
                                filteredData.map((data, index) => {
                                    return (
                                        <Column key={`keys-tdb-${index}`} sm={4} md={8} lg={8}>
                                            <CustomDashboardCard
                                                key={`custom-dashboard-card${index}`}
                                                index={index}
                                                onClickCard={() => {
                                                    history.push(`/teacher/teacher-dashboard/class-details/${data.GroupID}`)
                                                    if (teacherStatus) {
                                                        dispatch(Creators.changeTeacherNumber(teacherNumber + 1));
                                                    }
                                                }}
                                                teacherClass={data.ClassName}
                                                TotalStudents={data.TotalStudents}
                                                Minutes={data.Minutes}
                                                ProblemsCompleted={data.ProblemsCompleted}
                                                LevelsAttempted={data.LevelsAttempted}
                                                LevelsMastered={data.LevelsMastered}
                                            />
                                        </Column>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}