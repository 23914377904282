import StoreIcon from '@mui/icons-material/Store';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { GridActionsCellItem, } from "@mui/x-data-grid";
import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import allActions from "../../redux/ducks/actions";
import { useHistory } from "react-router-dom";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Tooltip, Skeleton, Box } from '@mui/material';
import { Edit, ShoppingCatalog } from '@carbon/icons-react';

export default function ManageDistrictLicenseTable() {
  const editAction = "#b58119";
  const purchaseAction = "#6829ba";
  const history = useHistory();
  const dispatch = useDispatch();
  const [districtProductData, setDistrictProductData] = useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true)
    dispatch(allActions.getDistrictLicenseList((response) => {
      setDistrictProductData(response);
      setLoading(false)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    "Name",
    "Admin Name",
    "License Name",
    "Start Date",
    "End Date",
    {
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value.status === 1) {
            return (
              <Box className='districtAction'>

                <Tooltip
                  title="Edit Subscription"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        background: "#0e1318",
                      },
                    },
                  }}
                  onClick={() => {
                    let districtID = value.districtId;
                    history.push(`/admin/admin-manage-district-license/edit_subscription/${districtID}`);
                  }}
                >
                  <Box className='customActionIcon'>
                    <Edit size={16} color='#FFF' />
                  </Box>
                </Tooltip>

                <Tooltip
                  title="Purchase History"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        background: "#0e1318",
                      },
                    },
                  }}
                  onClick={() => {
                    let rowId = value.districtId;
                    history.push(`/admin/admin-manage-district-license/district_license_history/${rowId}`);
                  }}
                >
                  <Box className='customActionIcon'>
                    <ShoppingCatalog size={16} color='#FFF' />
                  </Box>
                </Tooltip>

              </Box>
            )
          }
          else {
            return (
              <Tooltip title="Subscribe">
                <GridActionsCellItem
                  sx={{
                    backgroundColor: "#3fc495", width: "55%", borderRadius: "3px",
                    "&:hover": {
                      color: "#fff",
                      background: "#129667",
                      borderColor: "#129667",
                    },
                  }}
                  icon={<AddShoppingCartIcon sx={{ color: "white" }} />}
                  label="Subscribe"
                  onClick={() => {
                    let districtID = value.districtId;
                    history.push(`/admin/admin-manage-district-license/create_subscription/${districtID}`);
                  }}
                />
              </Tooltip>
            )
          }
        }
      }
    }
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };

  return (
    <React.Fragment>
      {
        loading
          ? <Skeleton
            variant="rectangular"
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          />
          :
          <MUIDataTable
            sx={{ margin: "10px" }}
            title="Districts License"
            data={districtProductData && districtProductData.map(data => {
              const TableValue = {
                status: data.Status,
                districtId: data.DistrictID,
              }
              return [
                data.Name,
                data.FullName,
                data.ProductID,
                data.SubscriptionDate,
                data.ExpirationDate,
                TableValue,
              ]
            })}
            columns={columns}
            options={options}
          />
      }
    </React.Fragment>
  );
}




