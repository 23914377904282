import {
    Box,
    Breadcrumbs,
    Stack,
    Typography,
    Tooltip,
    Backdrop,
    CircularProgress,
    TextField,
    Autocomplete,
} from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReportPageCard from "./ReportPageCard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import allActions from "../../redux/ducks/actions";
import MUIDataTable from "mui-datatables";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CustomHeader from "../../Components/CustomHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomTableButton from "../../Components/CustomTableButton";
import { ChartBar } from "@carbon/icons-react";
import { Column, Grid } from "@carbon/react";
import PerformCard from "../../Admin_Super/AdminDashboard/Performance_Section/PerformCard";
import AssignmentCard from "../../Admin_Super/AdminDashboard/Performance_Section/AssignmentCard";
import { FormatNumbers } from "../../constants";

export default function AssignmentReport() {

    const [data, setData] = useState([]);
    const [assignmentName, setAssignmentName] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory(); //history
    const url = useParams();
    const { userInfo, assignmentList } = useSelector((state) => state.data);
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(null);

    useEffect(() => {
        setLoading(true);
        dispatch(allActions.getAssignmentReport(
            userInfo.LoginID,
            Number(url.AssignmentID),
            (response) => {
                const CurrentAssignmentData = assignmentList.filter(data => data.ID === Number(url.AssignmentID))[0];
                setValue(CurrentAssignmentData);
                setData(response);
                setLoading(false);
            }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (value) {
            setLoading(true)
            dispatch(allActions.getAssignmentReport(
                userInfo.LoginID,
                Number(value.ID),
                (response) => {
                    setData(response);
                    setLoading(false);
                }));
        }
    }, [value, dispatch, userInfo])



    const columns = [
        "Student Name",
        `Minutes Played(${data && data.MinTime ? data.MinTime : 0})`,
        `Min Green(${data && data.MinGreen ? data.MinGreen : 0})`,
        `Max Red(${data && data.MinRed ? data.MinRed : 0})`,
        "Completed",
        "Target Achieved",
        "On Time"
    ]

    const options = {
        selectableRows: false,
        filter: false,
        download: false,
        print: false,
        rowsPerPage: 25,
    };

    const showStatus = (status) => {
        if (status === true) {
            let iconCSS = { height: '4vh', width: '4vh', color: "green" };
            return (<CheckIcon sx={iconCSS} />)
        }
        else if (status === false) {
            let iconCSS = { height: '4vh', width: '4vh', color: "red" };
            return (<CloseIcon sx={iconCSS} />)
        }
    }

    let defaultProps = {
        options: assignmentList,
        getOptionLabel: (option) => option && option.Name && (option.Name).trim()
    };

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])


    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: "99999999"
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>

            <CustomHeader
                licenseStatus={licenseStatus}
                needBreadCrumb={true}
                previousPageLabel={"Assignments"}
                currentPageLabel={"Assignment Report"}
                onClickPrevPageLabel={() => { history.push("/teacher/teacher-assignments") }}
            />

            <Box className="AssignmentReportWrapper">
                <Box className="customWelcomeARCard">
                    <Box className="customWelcomePhase1">
                        <Box className='weeklyReportWrapper'>
                            <Autocomplete
                                {...defaultProps}
                                value={value}
                                onChange={(_, newValue) => {
                                    if (!newValue) {
                                        if (assignmentList && assignmentList.lenght > 0) {
                                            setValue({
                                                label: assignmentList[0].Name,
                                                id: assignmentList[0].ID
                                            });
                                        }
                                    } else {
                                        setValue(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Assignment Name"
                                        variant="standard"
                                        sx={{
                                            '& label.Mui-focused': {
                                                color: "#15135E",
                                            },
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: '#15135E',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    <Box className="customWelcomePhase2">
                        <CustomTableButton
                            buttonLabel={"Create Assignment"}
                            onClickButton={() => {
                                history.push(`/teacher/teacher-assignments/create`)
                            }}
                            buttonIcon={<ChartBar size={16} />}
                        />
                    </Box>
                </Box>

                <Box className="nsAssignmentReport">
                    <Box className="nsDBMainTextHeader">
                        Performance
                    </Box>
                    <Box className='nsARPhase1CardWrapper'>
                        <Grid className='nsAdminPadding ARWrapper'>
                            <Column lg={3} md={4} sm={4}>
                                <AssignmentCard cardName="Assigned" data={FormatNumbers(data.Assigned)} styleName={"MinutesPlayedColor"} />
                            </Column>
                            <Column lg={3} md={4} sm={4}>
                                <AssignmentCard cardName="Completed" data={FormatNumbers(data.Completed)} styleName={"ProblemsSolvedColor"} />
                            </Column>
                            <Column lg={3} md={4} sm={4}>
                                <AssignmentCard cardName="Target Achieved" data={FormatNumbers(data.TargetAchieved)} styleName={"GamesCompletedColor"} />
                            </Column>
                            <Column lg={3} md={4} sm={4}>
                                <AssignmentCard cardName="Minutes Played" data={FormatNumbers(data.MinutesPlayed)} styleName={"LevelsMasteredColor"} />
                            </Column>
                            <Column lg={3} md={4} sm={4}>
                                <AssignmentCard cardName="Problems Solved" data={FormatNumbers(data.ProblemSolved)} styleName={"MinutesPlayedColor"} />
                            </Column>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            {data ?
                <Box className="ASfieldChild">
                    <MUIDataTable
                        title={"Report"}
                        sx={{ margin: "10px" }}
                        data={data && data.Students && data.Students.map(data => {
                            return [
                                data.FullName,
                                data.MinutesPlayed,
                                data.GreenCount,
                                data.RedCount,
                                showStatus(data.Complete),
                                showStatus(data.TargetAchieved),
                                showStatus(data.OnTime),
                            ]
                        })}
                        columns={columns}
                        options={options}
                    />
                </Box> : <Box>
                    No data found
                </Box>
            }
        </Box>
    )
}