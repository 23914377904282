import { Tooltip } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Creators from '../redux/ducks/actions';

export default function CustomMenuList({
    isMenuOpen,
    menuList,
    currentRole,
    currentMenuURL,
    onMenuClick,
}) {

    const { teacherNumber, teacherStatus } = useSelector((state) => state.data);
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            {
                menuList.map((item, index) => {
                    if (item.layout === currentRole) {
                        return (
                            <Tooltip
                                key={`menu-list-${item.menuName}`}
                                title={isMenuOpen ? "" : item.menuName}
                                arrow
                                placement="right"
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            background: "#0e1318",
                                        },
                                    },
                                }}
                            >
                                <div
                                    id={`${item.menuURL}-${1}`}
                                    key={`menu-list-${index}`}
                                    className='customMenuStyle'
                                    style={{
                                        background: item.menuURL === currentMenuURL ? "#FFB900" : "transparent"
                                    }}
                                    onClick={() => {
                                        onMenuClick(item.layout, item.menuURL)
                                        if (teacherStatus) {
                                            dispatch(Creators.changeTeacherNumber(teacherNumber + 1));
                                        }
                                    }}
                                >
                                    {
                                        item.menuURL === currentMenuURL ? (
                                            <React.Fragment>
                                                <div className='currentMenuIconStyle'>
                                                    {item.icon}
                                                </div>
                                                <div className='currentMenuItemStyle'>
                                                    {item.menuName}
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <div className='customMenuIconStyle'>
                                                    {item.icon}
                                                </div>
                                                <div className='menuItemStyle'>
                                                    {item.menuName}
                                                </div>
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                            </Tooltip>
                        )
                    }
                    return null
                })
            }
        </React.Fragment>
    );
}