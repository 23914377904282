import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Stack,
  Breadcrumbs,
  Typography,
  Backdrop,
  Tooltip,
} from '@mui/material';
import allActions from "../../../redux/ducks/actions";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SummarizeIcon from '@mui/icons-material/Summarize';
import BadgeIcon from '@mui/icons-material/Badge';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import CircularProgress from '@mui/material/CircularProgress';
import CustomHeader from "../../../Components/CustomHeader";
import CustomWelcomeCard from "../../../Components/CustomWelcomeCard";
import { ChartBar, ChartLine, Education, User } from "@carbon/icons-react";
import CustomTableButton from "../../../Components/CustomTableButton";

function StudentList(props) {
  const dispatch = useDispatch(); //dispatch
  const [tableRow, setTableRow] = useState([]);
  const url = useParams();
  const { userInfo, schoolUsageData, classUsageData } = useSelector((state) => state.data);
  const history = useHistory(); //history
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(allActions.studentsSummaryCS(Number(url.id), (response) => {
      setLoading(false);
      setTableRow(response);
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    "Student Name",
    "Games Completed",
    "Levels Mastered",
    "Minutes Played",
    "Problems Solved",
    {
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box className="actionWrapper">
              <Tooltip
                title="Student Daily Summary"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value;
                  if (userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "SuperGroupAdmin" || userInfo.UserType === "restrictedschooladmin")) {
                    history.push(`/school/school-class-usage-data/studentlist-daily-summary/${rowId}`);
                  } else if (userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin")) {
                    history.push(`/district/district-school-usage-data/studentlist-daily-summary/${rowId}`);
                  } else if (userInfo && userInfo.UserType === "GroupAdmin") {
                    history.push(`/teacher/teacher-dashboard/studentlist-daily-summary/${rowId}`);
                  }
                }}
              >
                <Box className='customActionIcon'>
                  <ChartBar size={16} color='#FFF' />
                </Box>
              </Tooltip>
              <Tooltip
                title="Student Activity Summary"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value;
                  if (userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "SuperGroupAdmin" || userInfo.UserType === "restrictedschooladmin")) {
                    history.push(`/school/school-class-usage-data/studentlist-activity-summary/${rowId}`);
                  } else if (userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin")) {
                    history.push(`/district/district-school-usage-data/studentlist-activity-summary/${rowId}`);
                  } else if (userInfo && userInfo.UserType === "GroupAdmin") {
                    history.push(`/teacher/teacher-dashboard/studentlist-activity-summary/${rowId}`);
                  }
                }}
              >
                <Box className='customActionIcon'>
                  <ChartLine size={16} color='#FFF' />
                </Box>
              </Tooltip>
            </Box>
          )
        }
      }
    }
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };

  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  const welcomCardData =
    userInfo && (userInfo.UserType === "DistrictAdmin") ? [
      {
        label: "Total Students",
        icon: <User className="welcomeCardIcons" />,
        value: tableRow ?
          tableRow.length : 0
      },
    ] : [
      {
        label: "Total Students",
        icon: <User className="welcomeCardIcons" />,
        value: classUsageData && classUsageData.classDetails && classUsageData.classDetails.TotalStudents ? classUsageData.classDetails.TotalStudents : 0
      },
      {
        label: "Total Teachers",
        icon: <Education className="welcomeCardIcons" />,
        value: classUsageData && classUsageData.classDetails && classUsageData.classDetails.TotalTeachers ? classUsageData.classDetails.TotalTeachers : 0
      },
    ]

  return (
    <Box className='districtStyle'>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: "99999999"
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>

      {
        userInfo && (userInfo.UserType === "Supergrouptechadmin") ? (
          <CustomHeader
            licenseStatus={licenseStatus}
            needBreadCrumb={true}
            previousPageLabel={
              userInfo && userInfo.UserType === "Supergrouptechadmin" ?
                `${classUsageData.className} Dashboard` :
                "Class Summary"
            }
            currentPageLabel={"Student List"}
            onClickPrevPageLabel={() => {
              const navigateTo =
                (userInfo && userInfo.UserType === "Supergrouptechadmin") ?
                  `/school/school-class-usage-data/class-details/${classUsageData.schoolId}` :
                  null
              history.push(navigateTo)
            }}
          />
        ) : (
          <CustomHeader
            licenseStatus={licenseStatus}
            needBreadCrumb={true}
            previousPageLabel={
              userInfo && userInfo.UserType === "GroupAdmin" ? "Dashboard" :
                `${schoolUsageData.schoolName} Dashboard`
            }
            previousPageLabel1={
              userInfo && userInfo.UserType === "GroupAdmin" ?
                `${classUsageData.className} Dashboard` :
                "Class Summary"
            }
            currentPageLabel={"Student List"}
            onClickPrevPageLabel={() => {
              const navigateTo =
                (userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "SuperGroupAdmin" || userInfo.UserType === "restrictedschooladmin")) ?
                  `/school/school-class-usage-data` :
                  userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin") ?
                    `/district/district-school-usage-data/school-details/${schoolUsageData.schoolId}` :
                    userInfo && userInfo.UserType === "GroupAdmin" ?
                      `/teacher/teacher-dashboard` :
                      null;
              history.push(navigateTo)
            }}
            onClickPrevPageLabel1={() => {
              const navigateTo =
                (userInfo && (userInfo.UserType === "SuperGroupAdmin" || userInfo.UserType === "restrictedschooladmin")) ?
                  `/school/school-class-usage-data/class-details/${classUsageData.schoolId}` :
                  userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin") ?
                    `/district/district-school-usage-data/class-summary/${schoolUsageData.schoolId}` :
                    userInfo && userInfo.UserType === "GroupAdmin" ?
                      `/teacher/teacher-dashboard/class-details/${classUsageData.schoolId}` :
                      null
              history.push(navigateTo)
            }}
          />
        )
      }

      <CustomWelcomeCard
        welcomCardData={welcomCardData}
        cardLabel={"Class Name"}
        cardValue={`${classUsageData.className}`}
      />

      <Box className="nsMainContent">
        <MUIDataTable
          title={"Student List"}
          data={tableRow ? tableRow.map(data => {
            return [
              data.StudentName,
              data.GamesCompleted,
              data.LevelsMastered,
              data.MinutesPlayed,
              data.ProblemsSolved,
              data.StudentID,
            ]
          }) : []}
          columns={columns}
          options={options}
        />
      </Box>
    </Box >
  );
}
export default StudentList;
