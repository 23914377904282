import SchoolIcon from '@mui/icons-material/School';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {
  Button,
  Stack,
  Typography,
  Box
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../redux/ducks/actions";
import { useHistory } from "react-router-dom";
import SchoolsTable from "./SchoolsTable";
import "./ManageSchools.css";
import CustomHeader from '../../Components/CustomHeader';
import CustomTableButton from '../../Components/CustomTableButton';
import { Add, Upload } from '@carbon/icons-react';

function ManageSchools() {
  const history = useHistory(); //History
  const { userInfo } = useSelector(state => state.data);
  const [loading, setLoading] = React.useState(false);

  // colors
  const Btncolor = "#2d8f3c";

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allActions.getAllSchoolList(userInfo.DomainID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [licenseStatus, setLicenseStatus] = React.useState(false);
  React.useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <React.Fragment>
      <Box className='districtStyle'>

        <CustomHeader
          licenseStatus={licenseStatus}
          headerLabel={"Manage School"}
        />

        <Box className="customWelcomeCard">
          <Box className="customWelcomePhase1">
          </Box>
          <Box className="customWelcomePhase2">
            {
              userInfo.SourceID !== "" ? null : (
                <React.Fragment>
                  <CustomTableButton
                    buttonLabel={"Bulk Upload School"}
                    onClickButton={() => history.push("/district/district-manage-schools/bulk-upload")}
                    buttonIcon={<Upload size={16} />}
                  />
                  <CustomTableButton
                    buttonLabel={"New"}
                    onClickButton={() => history.push("/district/district-manage-schools/create")}
                    buttonIcon={<Add size={16} />}
                  />
                </React.Fragment>
              )
            }
          </Box>
        </Box>

        <Box className="nsMainContent">
          <SchoolsTable />
        </Box>

      </Box>
    </React.Fragment>
  );
}
export default ManageSchools;
