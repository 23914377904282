import * as React from 'react';
import {
    Box,
    Typography,
    Tooltip,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Logo from "../constants/images";
import { CaretLeft, Logout, UserProfile, Help } from '@carbon/icons-react';
import { NumberSenseLogOut } from '../constants';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import allActions from "../redux/ducks/actions";

export default function CustomHeader({
    needHelp,
    hidelicense,
    licenseStatus,
    headerLabel,
    needBreadCrumb,
    previousPageLabel,
    previousPageLabel1,
    previousPageLabel2,
    currentPageLabel,
    onClickPrevPageLabel,
    onClickPrevPageLabel1,
    onClickPrevPageLabel2,
}) {

    const dispatch = useDispatch();
    const history = useHistory(); //   HIstory

    const nsLogout = () => {
        dispatch(
            allActions.userLogout(() => {
                sessionStorage.Username = "";
                sessionStorage.Password = "";
                sessionStorage.userDomain = "";
                sessionStorage.Type = "";
                localStorage.removeItem("AccessToken");
                localStorage.removeItem("RefreshToken");
                localStorage.removeItem("userData");
                history.push("/");
            })
        );
    }

    return (
        <Box className='districtTitleBox HeaderBoxResponsive'>
            {
                needBreadCrumb ? (
                    <Box className='breadCrumbWrapper'>
                        {
                            previousPageLabel ? (
                                <React.Fragment>
                                    <Box
                                        className='previousPageLabel'
                                        onClick={onClickPrevPageLabel}
                                    >
                                        {previousPageLabel}
                                    </Box>
                                    <img src={Logo.backImg} alt="back" />
                                </React.Fragment>
                            ) : null
                        }
                        {
                            previousPageLabel1 ? (
                                <React.Fragment>
                                    <Box
                                        className='previousPageLabel'
                                        onClick={onClickPrevPageLabel1}
                                    >
                                        {previousPageLabel1}
                                    </Box>
                                    <img src={Logo.backImg} alt="back" />
                                </React.Fragment>
                            ) : null
                        }
                        {
                            previousPageLabel2 ? (
                                <React.Fragment>
                                    <Box
                                        className='previousPageLabel'
                                        onClick={onClickPrevPageLabel2}
                                    >
                                        {previousPageLabel2}
                                    </Box>
                                    <img src={Logo.backImg} alt="back" />
                                </React.Fragment>
                            ) : null
                        }

                        <Box className='currentPageLabel'>
                            {currentPageLabel}
                        </Box>
                    </Box>
                ) : (
                    <Typography
                        variant="h6"
                        gutterBottom
                        component="div"
                        className='titleCustomHeader'
                    >
                        {headerLabel}
                    </Typography>
                )
            }

            <Box className='iconWrapperCustomHeader'>
                {
                    needHelp ? (
                        <Box
                            className='iconCustomHeader'
                            onClick={() => {
                                dispatch(allActions.changeTeacherStatus(true));
                            }}
                        >
                            <Help size={32} color='#FFF' />
                        </Box>
                    ) : null
                }
                <Box className='iconCustomHeader'>
                    {
                        hidelicense ? (null) : (
                            licenseStatus ? (
                                <Tooltip
                                    title="License Active"
                                    arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                background: "#0e1318",
                                            },
                                        },
                                    }}
                                >
                                    <Box className='statusCustomHeader activeStatus'></Box>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title="License Inactive"
                                    arrow
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                background: "#0e1318",
                                            },
                                        },
                                    }}
                                >
                                    <Box className='statusCustomHeader notActiveStatus'></Box>
                                </Tooltip>
                            )
                        )
                    }
                    <UserProfile size={32} color='#FFF' />
                </Box>
                <Box className='iconCustomHeader' onClick={nsLogout}>
                    <Logout size={32} color='#FFF' />
                </Box>
            </Box>
        </Box>
    );
} 