import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded';
import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../redux/ducks/actions";
import { Skeleton, Box, Typography, Stack } from '@mui/material';
import Logo from "../../constants/images";
import "../ManageSchools/ManageSchools.css"
import CustomHeader from '../../Components/CustomHeader';

export default function DistrictPurchaseHistory() {

  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.data);
  const [districtAdminPurchase, setDistrictAdminPurchase] = useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true)
    dispatch(allActions.getPurchaseHistory(userInfo.DomainID, (response) => {
      setDistrictAdminPurchase(response);
      setLoading(false)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    "Product ID",
    "Start Date",
    "End Date"
  ]

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };



  const [licenseStatus, setLicenseStatus] = React.useState(false);
  React.useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <React.Fragment>
      {
        loading
          ? <Skeleton
            variant="rectangular"
            sx={{
              position: "relative",
              width: "100%",
              height: "100%"
            }}
          />
          :
          <Box className='districtStyle'>
            <CustomHeader
              licenseStatus={licenseStatus}
              headerLabel={"Districts License"}
            />

            <Box className="noHeaderButton" />

            <Box className="nsMainContent">
              <MUIDataTable
                sx={{ margin: "10px" }}
                title="Purchase History"
                data={districtAdminPurchase && districtAdminPurchase.map(data => {
                  return [
                    data.ProductID,
                    data.SubscriptionDate,
                    data.ExpirationDate,
                  ]
                })}
                columns={columns}
                options={options}
              />
            </Box>
          </Box>
      }
    </React.Fragment>
  );
}