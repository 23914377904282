/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import "../ManageSchools/ManageSchools.css"
import {
    Stack,
    Typography,
    Box,
    Backdrop,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CircularProgress from '@mui/material/CircularProgress';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import "./Leaderboard.css";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Creators from "../../redux/ducks/actions";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Slide from '@mui/material/Slide';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NSPieChart from '../WeeklyReports/NSPieChart';
import CustomHeader from '../../Components/CustomHeader';
import { ChartBar, CloseOutline, List } from '@carbon/icons-react';
import CustomTableButton from '../../Components/CustomTableButton';
import { Column, Grid } from '@carbon/react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function SchoolLeaderboard() {

    const [districtProductData, setDistrictProductData] = useState([]);
    let iconCSS = { height: '5vh', width: '5vh', margin: "0% 1%", marginBottom: "3px" };

    const getFromDate = () => {
        let todaysDate = new Date();
        let date = todaysDate.getDate();
        let month = todaysDate.getMonth();
        let year = todaysDate.getFullYear();
        month = month + 1;
        return (year - 1)
            + "-"
            + ((month).toString().length === 1 ? (0 + month.toString()) : month)
            + "-"
            + ((date).toString().length === 1 ? (0 + date.toString()) : date)
    }
    const getToDate = () => {
        let todaysDate = new Date();
        let date = todaysDate.getDate();
        let month = todaysDate.getMonth();
        let year = todaysDate.getFullYear();
        month = month + 1;
        return (year)
            + "-"
            + ((month).toString().length === 1 ? (0 + month.toString()) : month)
            + "-"
            + ((date).toString().length === 1 ? (0 + date.toString()) : date)
    }

    const dispatch = useDispatch();
    const [dataResult, setDataResult] = useState("minutes")
    const [fromDate, setFromDate] = React.useState(getFromDate());
    const [toDate, setToDate] = React.useState(getToDate());
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [minDate, setMinDate] = React.useState("");
    const [maxDate, setMaxDate] = React.useState("");
    const { userInfo } = useSelector(state => state.data);
    const [loading, setLoading] = React.useState(false);
    const [isSchoolLeaderboard, setIsSchoolLeaderboard] = React.useState(false);

    // Popup
    const [openPopup, setOpenPopup] = React.useState(false);
    const [popupData, setPopupData] = React.useState(false);

    const handleClickOpen = () => {
        setOpenPopup(true);
    };

    const handleClose = () => {
        setOpenPopup(false);
    };

    const handleFromDate = (date) => {
        setMinDate(date)
        try {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const finaldate = `${year}-${month}-${day}`;
            setFromDate(finaldate)
        }
        catch (err) {
            console.error(err, "error")
        }
    }

    const handleToDate = (date) => {
        // Get the current date
        const currentDate = new Date();

        // Check if the selected date is greater than the current date
        if (
            date.getFullYear() > currentDate.getFullYear() ||
            (date.getFullYear() === currentDate.getFullYear() &&
                date.getMonth() > currentDate.getMonth()) ||
            (date.getFullYear() === currentDate.getFullYear() &&
                date.getMonth() === currentDate.getMonth() &&
                date.getDate() > currentDate.getDate())
        ) {
            // If the selected date is in the future, set it to the current date
            date = currentDate;
        }

        setMaxDate(date)
        try {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const finaldate = `${year}-${month}-${day}`;
            setToDate(finaldate)
        }
        catch (err) {
            console.error(err, "error")
        }
    }
    const getDateLB = () => {
        setLoading(true);
        let form = {
            DistrictID: userInfo.DomainID,
            FromDate: fromDate,
            ToDate: toDate,
        }
        if (dataResult === "minutes") {
            if (isSchoolLeaderboard) {
                dispatch(Creators.getStudentLeaderboardData(
                    form,
                    (response) => {
                        setLoading(false);
                        if (response) {
                            setLeaderboardData(response.MinutesPlayed)
                        }
                    }
                ));
            } else {
                dispatch(Creators.getClassLeaderboardData(
                    form,
                    (response) => {
                        setLoading(false);
                        if (response) {
                            setLeaderboardData(response.MinutesPlayed)
                        }
                    }
                ));
            }
        }
        else if (dataResult === "problems") {
            if (isSchoolLeaderboard) {
                dispatch(Creators.getStudentLeaderboardData(
                    form,
                    (response) => {
                        setLoading(false);
                        if (response) {
                            setLeaderboardData(response.ProblemsSolved)
                        }
                    }
                ));
            } else {
                dispatch(Creators.getClassLeaderboardData(
                    form,
                    (response) => {
                        setLoading(false);
                        if (response) {
                            setLeaderboardData(response.ProblemsSolved)
                        }
                    }
                ));
            }
        }
        else if (dataResult === "levels") {
            if (isSchoolLeaderboard) {
                dispatch(Creators.getStudentLeaderboardData(form,
                    (response) => {
                        setLoading(false);
                        if (response) {
                            setLeaderboardData(response.GamesCompleted)
                        }
                    }
                ));
            } else {
                dispatch(Creators.getClassLeaderboardData(form,
                    (response) => {
                        setLoading(false);
                        if (response) {
                            setLeaderboardData(response.GamesCompleted)
                        }
                    }
                ));
            }
        }
    }

    React.useEffect(() => {
        // Setting min and max date
        let currentDate = new Date();
        setMaxDate(currentDate)
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let date = currentDate.getDate();
        var prevDate = new Date(year, month, date);
        prevDate.setFullYear(prevDate.getFullYear() - 1);
        setMinDate(prevDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        getDateLB();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate, fromDate, dataResult, isSchoolLeaderboard])

    useEffect(() => {
        dispatch(Creators.getDistrictLicenseList((response) => {
            setDistrictProductData(response);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setLicenseDate = (date) => {
        setFromDate(date);
    }

    useEffect(() => {
        const result = districtProductData?.filter(data => data.DistrictID === userInfo.DomainID);
        if (result.length !== 0) {
            const { SubscriptionDate } = result[0];
            const Date = SubscriptionDate.split(" ")[0];
            const getDate = Date.split("/");
            const purchaseFromDate = getDate[2] + "-" + getDate[0] + "-" + getDate[1]
            if (purchaseFromDate !== fromDate) {
                setLicenseDate(purchaseFromDate);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [districtProductData])

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])


    return (
        <React.Fragment>
            <Box className='districtStyle'>
                <Backdrop
                    sx={{
                        background: "rgb(0 0 0 / 30%)",
                        color: '#fff',
                        zIndex: "99999999"
                    }}
                    open={loading}
                >
                    <CircularProgress style={{ color: "#008000" }} />
                </Backdrop>
                {/* Popup */}
                <Dialog
                    maxWidth={`xl`}
                    open={openPopup}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle
                        sx={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <Box className="assignmentDetailsHeaderText">
                            Details
                        </Box>
                        <CloseOutline
                            color="#0d7fd2"
                            size={32}
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box className="ADSectionWrapper">
                                <Box className="ADLSec">
                                    <Grid>
                                        <Column lg={16} md={8} sm={4} className="ADPhase1">
                                            <Box className="ADTextTitle">School Name</Box>
                                            <Box className="ADTextContent">{popupData && popupData.SchoolName}</Box>
                                        </Column>
                                        <Column lg={16} md={8} sm={4} className="ADPhase1">
                                            <Box className="ADTextTitle">Class Name</Box>
                                            <Box className="ADTextContent">{popupData && popupData.ClassName}</Box>
                                        </Column>
                                    </Grid>
                                </Box>
                                <Box className="verticalDivider">
                                    <Box className="divider2"></Box>
                                </Box>
                                <Box className="ADRSec">
                                    <Grid>
                                        <Column lg={16} md={8} sm={4} className="ADPhase1">
                                            <Box className="ADTextTitle">{
                                                isSchoolLeaderboard ? "Student Name" : "Teacher Name"
                                            }</Box>
                                            <Box className="ADTextContent">{isSchoolLeaderboard ? popupData && popupData.FullName : popupData?.TeachersName}</Box>
                                        </Column>
                                        <Column lg={16} md={8} sm={4} className="ADPhase1">
                                            <Box className="ADTextTitle">Total</Box>
                                            <Box className="ADTextContent">
                                                {
                                                    popupData &&
                                                    (dataResult === "minutes" ? `${popupData.CategoryTotal} Minutes Played` :
                                                        dataResult === "problems" ? `${popupData.CategoryTotal} Problems Solved` :
                                                            dataResult === "levels" ? `${popupData.CategoryTotal} Levels Mastered` :
                                                                null)
                                                }
                                            </Box>
                                        </Column>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box className="divider1"></Box>
                            {popupData && popupData.Skills &&
                                (
                                    <React.Fragment>
                                        <Box
                                            className='chartStylePieHead'
                                        >
                                            <Box className='chartStylePie'>
                                                <NSPieChart
                                                    value={popupData.ConceptData}
                                                    name={popupData.ConceptName}
                                                />
                                            </Box>
                                        </Box>

                                        <Box className="dividersub1"></Box>

                                        <Box className='ConceptTable'>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Skill</TableCell>
                                                        <TableCell align="left">Concept</TableCell>
                                                        <TableCell align="left">
                                                            {dataResult === "minutes" ? `Minutes Played` :
                                                                dataResult === "problems" ? `Problems Solved` :
                                                                    dataResult === "levels" ? `Levels Mastered` :
                                                                        null}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {popupData.Skills.map((row) => (
                                                        <TableRow
                                                            key={row.SkillName}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">{row.SkillName}</TableCell>
                                                            <TableCell align="left">{row.ConceptName}</TableCell>
                                                            <TableCell align="left">{row.SkillValue}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </React.Fragment>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <CustomHeader
                    licenseStatus={licenseStatus}
                    headerLabel={"Leaderboard"}
                />

                <Box className="customWelcomeCard">
                    <Grid>
                        <Column lg={8} md={8} sm={4}>
                            <Box className="leaderBoard" id={`leaderboard-tab-id`}>
                                <Box
                                    className={!isSchoolLeaderboard ? 'LBTabPrimary' : 'LBTabSecondary'}
                                    onClick={() => {
                                        setIsSchoolLeaderboard(!isSchoolLeaderboard);
                                    }}
                                >
                                    <ChartBar size={16} />
                                    Class Leaderboard
                                </Box>
                                <Box
                                    className={isSchoolLeaderboard ? 'LBTabPrimary' : 'LBTabSecondary'}
                                    onClick={() => {
                                        setIsSchoolLeaderboard(!isSchoolLeaderboard);
                                    }}
                                >
                                    <ChartBar size={16} />
                                    Student Leaderboard
                                </Box>
                            </Box>
                        </Column>
                        <Column lg={8} md={8} sm={4} className="ADPhase1">
                            <Box className="customWelcomePhase2">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="From Date"
                                        inputFormat="dd/MM/yyyy"
                                        value={fromDate}
                                        onChange={handleFromDate}
                                        maxDate={maxDate}
                                        renderInput={(params) =>
                                            <TextField
                                                required
                                                sx={{ width: "90%" }}
                                                onKeyDown={e => e.preventDefault()}
                                                {...params}
                                                variant="standard"
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        className='toDate'
                                        label="To Date"
                                        inputFormat="dd/MM/yyyy"
                                        value={toDate}
                                        onChange={handleToDate}
                                        minDate={minDate}
                                        renderInput={(params) =>
                                            <TextField
                                                required
                                                sx={{ width: "90%" }}
                                                onKeyDown={e => e.preventDefault()}
                                                {...params}
                                                variant="standard"
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Column>
                    </Grid>
                </Box>

                <Box className='nsMainContent'>
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            {
                                isSchoolLeaderboard ? "Student Leaderboard" : "Class Leaderboard"
                            }
                        </Box>
                        <Box className="customHeaderButton" id={`leaderboard-buttons-id`}>
                            <CustomTableButton
                                isInActive={dataResult !== "minutes"}
                                buttonLabel={"Minutes Played"}
                                onClickButton={() => {
                                    setDataResult("minutes");
                                }}
                                buttonIcon={<List size={16} />}
                            />
                            <CustomTableButton
                                isInActive={dataResult !== "problems"}
                                buttonLabel={"Problems Solved"}
                                onClickButton={() => {
                                    setDataResult("problems");
                                }}
                                buttonIcon={<List size={16} />}
                            />
                            <CustomTableButton
                                isInActive={dataResult !== "levels"}
                                buttonLabel={"Levels Mastered"}
                                onClickButton={() => {
                                    setDataResult("levels");
                                }}
                                buttonIcon={<List size={16} />}
                            />
                        </Box>
                    </Box>

                    <Box
                        className="gridWrapper"
                        style={{
                            padding: "0.75rem"
                        }}
                    >
                        {
                            !loading && leaderboardData && leaderboardData.map((row, index) => (
                                <Box
                                    key={index + "lbd"}
                                    className="lbBox"
                                    onClick={() => {
                                        if (isSchoolLeaderboard) {
                                            let form = {
                                                StudentID: row.StudentID,
                                                FromDate: fromDate,
                                                ToDate: toDate,
                                                Category:
                                                    dataResult === "minutes" ? "mp" :
                                                        dataResult === "problems" ? `ps` :
                                                            dataResult === "levels" ? `lm` :
                                                                null
                                            }
                                            dispatch(Creators.schoolLbPopup(form, (response) => {
                                                handleClickOpen()
                                                setPopupData(response);
                                                console.log(response, "response");
                                            }
                                            ));
                                        } else {
                                            let form = {
                                                GroupID: row.GroupID,
                                                FromDate: fromDate,
                                                ToDate: toDate,
                                                Category:
                                                    dataResult === "minutes" ? "mp" :
                                                        dataResult === "problems" ? `ps` :
                                                            dataResult === "levels" ? `lm` :
                                                                null
                                            }
                                            dispatch(Creators.classLbPopup(form, (response) => {
                                                handleClickOpen()
                                                setPopupData(response);
                                            }
                                            ));
                                        }
                                    }}
                                >
                                    <Box className='lbphase1'>
                                        {
                                            index === 0 ? <Box className='numberlbBox'>
                                                {index + 1}
                                            </Box> :
                                                index === 1 ? <Box className='numberlbBox'>
                                                    {index + 1}
                                                </Box> :
                                                    index === 2 ? <Box className='numberlbBox'>
                                                        {index + 1}
                                                    </Box> :
                                                        <Box className='numberlbBoxNotActive'>
                                                            {index + 1}
                                                        </Box>
                                        }
                                    </Box>
                                    <Box className='lbphase2'>
                                        @{isSchoolLeaderboard ? row.FName : row?.TeachersName}
                                    </Box>
                                    <Box className='lbphase3'>
                                        <Box className='lbTextTitle'>
                                            {row.SchoolName}
                                        </Box>
                                        <Box className='lbTextSubTitle'>
                                            {row.ClassName}
                                        </Box>
                                    </Box>
                                    <Box
                                        className='totalDetails'
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "10%"
                                        }}>
                                        <Box>
                                            {
                                                dataResult === "minutes" ? ` Minutes` :
                                                    dataResult === "problems" ? ` Problems` :
                                                        dataResult === "levels" ? ` Levels` :
                                                            null
                                            }
                                        </Box>
                                        <Box>
                                            {
                                                dataResult === "minutes" ? `${row.MinutesPlayed}` :
                                                    dataResult === "problems" ? `${row.ProblemsSolved}` :
                                                        dataResult === "levels" ? `${row.GamesCompleted}` :
                                                            null
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            ))
                        }
                        {
                            !loading &&
                                (
                                    leaderboardData === null ||
                                    (leaderboardData && leaderboardData.length === 0)
                                ) ?
                                <Box sx={{ padding: "2%" }}>
                                    There is no activity for the selected date range.Please change the date range.
                                </Box> :
                                ""
                        }
                    </Box>
                </Box>
            </Box>
        </React.Fragment >
    )
}