import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Breadcrumbs,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DESCRIPTION_EMPTY,
  SCHOOL_NAME_EMPTY,
  SCHOOL_NAME_EXIST,
  SCHOOL_UPDATE,
} from "../../constants";
import CancelIcon from '@mui/icons-material/Cancel';
import Creators from "../../redux/ducks/actions";
import "./ManageSchools.css";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CustomTableButton from "../../Components/CustomTableButton";
import CustomHeader from "../../Components/CustomHeader";
import { Close, Edit } from "@carbon/icons-react";

function UpdateSchools(props) {
  const dispatch = useDispatch(); // Dispatch
  const history = useHistory();
  const { userInfo } = useSelector(state => state.data); //dispatch

  // Snack Message
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "update",
  });
  const { snackStatus, snackMessageFor } = snackbar;
  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  const url = useParams(); //URl

  const [form, setForm] = useState({
    schoolName: "",
    description: "",
  });
  const [emptyError, setEmptyError] = useState({
    schoolName: false,
    description: false,
  });

  //   update Button
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateClick = () => {
    setUpdateLoading(true);
    const checkEmptyValue = { ...emptyError };
    let submit = true;
    // Checking empty data
    for (let items in form) {
      if (form[items] === "") {
        submit = false;
        checkEmptyValue[items] = true;
      }
    }
    if (!submit) {
      setEmptyError(checkEmptyValue);
      setUpdateLoading(false);
    } else {
      dispatch(
        Creators.updateSchoolsById(form, (response) => {
          if (response.MessageID === 1 || response.MessageID === 2) {
            // School name already exist Error
            setUpdateLoading(false);
            handleSnackbarClick("duplicateError");
            return null;
          } else if (response.MessageID === 0) {
            // School Update Success Message
            handleSnackbarClick("update");
            setTimeout(() => {
              setUpdateLoading(false);
              history.push("/district/district-manage-schools");
            }, 1000);
          } else {
            console.error(response, "response");
          }
        })
      );
    }
  };

  // handle update Domain
  const handleOnChangeUpdateSchools = (e) => {
    if (e.target.value !== "") {
      setEmptyError((state) => ({
        ...state,
        [e.target.id]: false,
      }));
    }

    setForm((state) => ({
      ...state,
      [e.target.id]: e.target.value,
    }));
  };

  //   setting data in field
  useEffect(() => {
    dispatch(
      Creators.getSchoolsById(Number(url.id), (response) => {
        let rowData = response;
        setForm((state) => ({
          ...state,
          schoolName: rowData && rowData.Name && (rowData.Name).trim(),
          description: rowData.Desc,
          domainId: rowData.ID,
          maxStudents: rowData.MaxStudents,
          startDate: rowData.StartDate,
        }));
      })
    );
  }, [url, dispatch]);

  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <>
      {/* Action Status */}
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar
          key="SnackBar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackStatus}
          onClose={handleSnackbarClose}
        >
          {snackMessageFor === "duplicateError" ? (
            <Alert
              variant="filled"
              severity="warning"
              sx={{
                "&.MuiPaper-root": {
                  background: "#ffc107",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {SCHOOL_NAME_EXIST}
            </Alert>
          ) : snackMessageFor === "update" ? (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                "&.MuiPaper-root": {
                  background: "#00c853",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {SCHOOL_UPDATE}
            </Alert>
          ) : null}
        </Snackbar>
      </Stack>
      <Box className='districtStyle'>
        <CustomHeader
          licenseStatus={licenseStatus}
          needBreadCrumb={true}
          previousPageLabel={"Manage School"}
          currentPageLabel={"Update School"}
          onClickPrevPageLabel={() => { history.push("/district/district-manage-schools") }}
        />
        <Box className="noHeaderButton" />
        <Box className='nsMainContent'>
          <Box className="nsMainHeader">
            <Box className="nsMainTextHeader">
              Update School
            </Box>
            <Box className="customHeaderButton" />
          </Box>
          <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
            <Box className="createDomainParent">
              <Box className="LessonBotContent">
                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">School Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="schoolName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.schoolName}
                      onChange={handleOnChangeUpdateSchools}
                      error={emptyError.schoolName}
                      helperText={emptyError.schoolName ? SCHOOL_NAME_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">School Description</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        width: "100%",
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="description"
                      label=""
                      multiline
                      rows={1}
                      value={form.description}
                      onChange={handleOnChangeUpdateSchools}
                      error={emptyError.description}
                      helperText={emptyError.description ? DESCRIPTION_EMPTY : ""}
                    />
                  </Box>
                </Box>
                <Box className="createButtonParent" style={{ height: "auto", padding: "2%" }}>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={true}
                      buttonLabel={"Cancel"}
                      onClickButton={() => {
                        history.push("/district/district-manage-schools");
                      }}
                      buttonIcon={<Close size={16} />}
                    />
                  </Box>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={false}
                      buttonLabel={"Update"}
                      onClickButton={handleUpdateClick}
                      buttonIcon={<Edit size={16} />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default UpdateSchools;
