const Images = {
  knom_logo: require("./knom_logo.png"),
  man: require("./man.png"),
  logo: require("./logo.png"),
  background: require("./background.jpg"),
  smart_logo: require("./favicon.jpeg"),
  basicB: require("./basicBlack.png"),
  basicW: require("./basicWhite.png"),
  spiralB: require("./spiralBlack.png"),
  spiralW: require("./spiralWhite.png"),
  zigzagB: require("./zigzagBlack.png"),
  zigzagW: require("./zigzagWhite.png"),
  luB: require("./luBlack.png"),
  luW: require("./luWhite.png"),
  rdB: require("./rdBlack.png"),
  rdW: require("./rdWhite.png"),
  ruB: require("./ruBlack.png"),
  ruW: require("./ruWhite.png"),
  urB: require("./urBlack.png"),
  urW: require("./urWhite.png"),
  DBImage: require("./DBImage.png"),
  DBImage1: require("./DBImage2.png"),
  DBImage2: require("./DBImage3.png"),
  backImg: require("./backImg.png"),
  tourImage: require("./tourImage.png"),
};
export default Images;
