import CreateIcon from "@mui/icons-material/Create";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
    Backdrop,
    Box,
    Breadcrumbs,
    Stack,
    TextField,
    Typography,
    Alert,
    Snackbar,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    FormLabel,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    DESCRIPTION_EMPTY,
    DOMAIN_NAME_EXIST,
    MAX_STUDENTS_VALIDATION,
    ADD_ASSIGNMENT_MESSAGE,
    ASSIGNMENT_NAME_EMPTY,
    SELECT_EMPTY,
    NO_OF_TILES,
    STARTING_NUMBER,
    TIME_FORMAT,
} from "../../constants";
import Creators from "../../redux/ducks/actions";
import { useHistory } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import allActions from "../../redux/ducks/actions";
import FormHelperText from '@mui/material/FormHelperText';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Images from "../../constants/images";
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import { Close, Pen } from "@carbon/icons-react";

function CreateAssignment(props) {
    const dispatch = useDispatch(); //dispatch
    const history = useHistory(); //history
    const [classList, setClassList] = React.useState([]);
    const [conceptList, setConceptList] = React.useState([]);
    const [studentList, setStudentList] = React.useState([]);
    const [skillList, setSkillList] = React.useState([]);
    const [order, setOrder] = useState("ru");
    const [layout, setLayout] = useState("normal");
    const { userInfo } = useSelector((state) => state.data);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [errorStudent, setErrorStudent] = useState(false);

    const [form, setForm] = useState({
        assignmentName: "",
        description: "",
        targetdate: "",
        targetTime: "12:00",
        students: [],
        classId: "",
        conceptId: 0,
        skillId: 0,
        noOfTiles: 10,
        startingNumber: 1,
        showNumber: false,
        maxRed: 2,
        minGreen: 2,
        minTime: 1,
        ampmStatus: "am",
    });
    const [emptyError, setEmptyError] = useState({
        assignmentName: false,
        description: false,
        targetdate: false,
        classId: false,
    });

    // Loading
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(allActions.classListTeacher(userInfo.LoginID, (response) => {
            setClassList(response);
            setForm((state) => ({
                ...state,
                classId: response.length > 0 ? response[0].GroupID : -1,
            }));
        }));

        dispatch(allActions.getConcepts(userInfo.DomainID, (response) => {
            const filteredConcepts = response.filter(concept => concept.ConceptID !== 1);
            setConceptList(filteredConcepts);
            setLoading(false);

            setForm((state) => ({
                ...state,
                conceptId: filteredConcepts.length > 0 ? filteredConcepts[0].ConceptID : -1,
            }));
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (form.conceptId) {
            setLoading(true);
            dispatch(allActions.getSkills(form.conceptId, (response) => {
                setSkillList(response);
                if (response.length > 0) {
                    // setLoading(false);

                    setForm((state) => ({
                        ...state,
                        skillId: response.length > 0 ? response[0].SkillID : -1,
                    }));
                }

            }));
        }
        if (form.classId) {
            setLoading(true);
            dispatch(allActions.studentUsageDataList(form.classId, (response) => {
                setLoading(false);
                setStudentList(response);
            }));
        }
        else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.conceptId, form.classId])

    // Select and deSelect
    useEffect(() => {
        if (isSelectAll) {
            let allStudentsId = [];
            studentList.map(data => {
                allStudentsId.push(data.StudentID);
                return null;
            });
            setForm({
                ...form,
                students: allStudentsId,
            })
        } else {
            setForm({
                ...form,
                students: [],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectAll])

    // Snack Message
    const [snackbar, setSnackbar] = React.useState({
        snackStatus: false,
        snackMessageFor: "update",
    });
    const { snackStatus, snackMessageFor } = snackbar;
    const handleSnackbarClick = (msg) => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: true,
            snackMessageFor: msg,
        }));
    };
    const handleSnackbarClose = () => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: false,
            snackMessageFor: "",
        }));
    };

    const getDateDate = (param) => {
        let data = param;
        let newMonth = Number(data.getMonth()) + 1;
        let newDate = Number(data.getDate());
        let monthLength = newMonth.toString().length;
        let dateLength = newDate.toString().length;
        let date = dateLength === 1 ? "0" + newDate : newDate;
        let month = monthLength === 1 ? "0" + newMonth : newMonth;
        let year = data.getFullYear();
        return year + '-' + month + '-' + date;
    }

    //   create Button
    const handleCreateClick = () => {
        setLoading(true);
        const checkEmptyValue = { ...emptyError };
        let submit = true;
        let minTimeCheck = false;
        // Checking empty data
        for (let items in form) {
            if (items === "targetdate") {
                continue;
            } else if (form[items] === "") {
                submit = false;
                checkEmptyValue[items] = true;
            }
        }
        if (form.students.length === 0) {
            submit = false;
            setErrorStudent(true);
        }
        if (Number(form.minTime) < 1) {
            minTimeCheck = true;
            submit = false;
        }
        if (!submit) {
            if (minTimeCheck) {
                alert("Min Time(mins) should be greater than 0.")
            }
            setEmptyError(checkEmptyValue);
            setLoading(false);
        } else {
            let newTargetdate = "";
            let newTargetTime = form.targetTime;
            // Time Validation
            if (form.ampmStatus === "am") {
                let timeArray = (form.targetTime).split(":");
                if (timeArray[0] === "12") {
                    let newHour = "00";
                    let newMin = timeArray[1];
                    newTargetTime = newHour + ":" + newMin
                } else {
                    newTargetTime = form.targetTime;
                }
            } else if (form.ampmStatus === "pm") {
                let timeArray = (form.targetTime).split(":");
                if (timeArray[0] === "12" && timeArray[1] === "00") {
                    newTargetTime = "00:00";
                } else if (timeArray[0] !== "12") {
                    let newHour = Number(timeArray[0]) + 12;
                    let newMin = timeArray[1];
                    newTargetTime = newHour + ":" + newMin
                } else if (timeArray[0] === "12") {
                    let newHour = "12";
                    let newMin = timeArray[1];
                    newTargetTime = newHour + ":" + newMin
                }
            }
            // Date Validation
            if (form.targetdate === "") {
                newTargetdate = getDateDate(new Date());
            } else {
                newTargetdate = getDateDate(form.targetdate);
            }

            // is previous time
            let time = new Date().toLocaleTimeString();
            let currentDate = getDateDate(new Date());
            let isUpdate = false;
            if (currentDate === newTargetdate && time) {
                let currentTimeArray = time.split(":");
                let currentHour = Number(currentTimeArray[0]);
                let currentMin = Number(currentTimeArray[1]);
                let setTimeArray = newTargetTime.split(":");
                let setHour = Number(setTimeArray[0]);
                let setMin = Number(setTimeArray[1]);
                if (currentHour > setHour) {
                    isUpdate = false;
                } else if (currentHour >= setHour && currentMin > setMin) {
                    isUpdate = false;
                } else {
                    isUpdate = true;
                }
            } else {
                isUpdate = true;
            }
            let assignmentData = {
                AssignmentName: form.assignmentName,
                Desc: form.description,
                TargetDate: newTargetdate,
                TargetTime: newTargetTime,
                Students: form.students.join(),
                ClassID: Number(form.classId),
                ConceptID: form.conceptId,
                SkillID: form.skillId,
                Tiles: form.noOfTiles,
                StartingNumber: form.startingNumber,
                Order: order,
                Layout: layout,
                ShowNumber: form.showNumber,
                MinRed: form.maxRed,
                MinTime: form.minTime,
                MinGreen: form.minGreen,
                SuperGroupID: userInfo.SuperGroupID,
                LoginID: userInfo.LoginID,
            }
            setLoading(false);
            if (!isUpdate) {
                alert("Invalid date or time");
            } else {
                dispatch(
                    Creators.createAssignment(assignmentData, (response) => {
                        if (response) {
                            if (response.MessageID === 3) {
                                setLoading(false);
                                handleSnackbarClick("Exception");
                            } else if (response.MessageID === 0) {
                                // Assignment Success Message
                                setLoading(false);
                                handleSnackbarClick("create");
                                setTimeout(() => {
                                    history.push("/teacher/teacher-assignments");
                                }, 1000);
                            }
                        } else {
                            setLoading(false);
                            console.error(response, "response");
                        }
                    })
                );
            }
        }
    };

    const handleTimeChange = (data) => {
        setForm((state) => ({
            ...state,
            targetdate: data,
        }));
    }


    // handle Create District
    const handleOnChangeCreateDistrict = (e) => {
        if (e.target.value !== "") {
            if (emptyError && emptyError[e.target.name]) {
                setEmptyError((state) => ({
                    ...state,
                    [e.target.name]: false,
                }));
            }
        }
        if (e.target.name === "classId") {
            setIsSelectAll(false);
        }
        setForm((state) => ({
            ...state,
            [e.target.name]: e.target.value,
        }));
    }

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])


    return (
        <>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            {/* Action Status */}
            <Stack spacing={2} sx={{ maxWidth: 1000 }}>
                <Snackbar
                    key="SnackBar"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackStatus}
                    onClose={handleSnackbarClose}
                >
                    {snackMessageFor === "duplicateError" ? (
                        <Alert
                            variant="filled"
                            severity="warning"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#ffc107",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {DOMAIN_NAME_EXIST}
                        </Alert>
                    ) : snackMessageFor === "validAge" ? (
                        <Alert
                            variant="filled"
                            severity="warning"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#ffc107",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {MAX_STUDENTS_VALIDATION}
                        </Alert>
                    ) : (
                        <Alert
                            variant="filled"
                            severity="success"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#00c853",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {snackMessageFor === "Exception"
                                ? "Exception"
                                : snackMessageFor === "create"
                                    ? ADD_ASSIGNMENT_MESSAGE
                                    : ""}
                        </Alert>
                    )}
                </Snackbar>
            </Stack>

            <Box className='districtStyle'>
                <CustomHeader
                    licenseStatus={licenseStatus}
                    needBreadCrumb={true}
                    previousPageLabel={"Assignments"}
                    currentPageLabel={"Create Assignment"}
                    onClickPrevPageLabel={() => { history.push("/teacher/teacher-assignments") }}
                />

                <Box className="noHeaderButton" />
                <Box className='nsMainContent'>
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            Create Assignment
                        </Box>
                        <Box className="customHeaderButton" />
                    </Box>
                    <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
                        <Box className="createDomainParent">
                            <Box className="LessonBotContent">
                                <Box className="fieldParent">
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Name</Box>
                                        <TextField
                                            required
                                            variant="outlined"
                                            sx={{
                                                '& label.Mui-focused': {
                                                    color: "#15135E",
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: emptyError.assignmentName ? "#f00" : '#15135E',
                                                },
                                            }}
                                            size="small"
                                            name="assignmentName"
                                            label=""
                                            type="text"
                                            className="fieldStyle"
                                            value={form.assignmentName}
                                            onChange={handleOnChangeCreateDistrict}
                                            error={emptyError.assignmentName}
                                            helperText={emptyError.assignmentName ? ASSIGNMENT_NAME_EMPTY : ""}
                                        />
                                    </Box>
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Description</Box>
                                        <TextField
                                            required
                                            variant="outlined"
                                            sx={{
                                                '& label.Mui-focused': {
                                                    color: "#15135E",
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: emptyError.description ? "#f00" : '#15135E',
                                                },
                                            }}
                                            size="small"
                                            name="description"
                                            label=""
                                            type="text"
                                            className="fieldStyle"
                                            value={form.description}
                                            onChange={handleOnChangeCreateDistrict}
                                            error={emptyError.description}
                                            helperText={emptyError.description ? DESCRIPTION_EMPTY : ""}
                                        />
                                    </Box>
                                </Box>
                                <Box className="fieldParent">
                                    <Box className="fieldChild">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker
                                                label="Target Date"
                                                minDate={new Date()}
                                                inputFormat="dd/MM/yyyy"
                                                name="targetdate"
                                                value={form.targetdate === "" ? new Date() : form.targetdate}
                                                onChange={handleTimeChange}
                                                renderInput={
                                                    (params) =>
                                                        <TextField
                                                            required
                                                            sx={{ width: "100%" }}
                                                            variant="standard"
                                                            onKeyDown={(evt) => evt && evt.preventDefault()}
                                                            {...params}
                                                        />
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box className="fieldChild" sx={{ width: "100%" }}>
                                        <FormControl
                                            required
                                            variant="standard"
                                            sx={{ width: "75%" }}
                                        >
                                            <InputLabel id="targetTime">Target Time</InputLabel>
                                            <Select
                                                name="targetTime"
                                                labelId="targetTime"
                                                value={form.targetTime}
                                                onChange={handleOnChangeCreateDistrict}
                                            >
                                                {TIME_FORMAT && TIME_FORMAT.map(index => {
                                                    return <MenuItem key={index + "time"} value={index}>{index}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl
                                            variant="standard"
                                            sx={{ width: "25%" }}
                                        >
                                            <InputLabel id="ampmStatus"></InputLabel>
                                            <Select
                                                name="ampmStatus"
                                                labelId="ampmStatus"
                                                onChange={handleOnChangeCreateDistrict}
                                                value={form.ampmStatus}
                                            >
                                                <MenuItem key={"am"} value={"am"}>AM</MenuItem>
                                                <MenuItem key={"pm"} value={"pm"}>PM</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box className="fieldParent">
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Concept</Box>
                                        <FormControl
                                            required
                                            variant="outlined"
                                            fullWidth
                                        >
                                            {/* <InputLabel></InputLabel> */}
                                            <Select
                                                size="small"
                                                name="conceptId"
                                                label="Concept"
                                                value={form.conceptId}
                                                onChange={handleOnChangeCreateDistrict}
                                            >
                                                {conceptList && conceptList.length > 0 && conceptList.map(index => {
                                                    return <MenuItem value={index.ConceptID}>{index.ConceptName}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Skill</Box>
                                        <FormControl
                                            required
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <Select
                                                size="small"
                                                name="skillId"
                                                value={form.skillId}
                                                onChange={handleOnChangeCreateDistrict}
                                            >
                                                {skillList && skillList.length > 0 && skillList.map(index => {
                                                    return <MenuItem value={index.SkillID}>{index.SkillName}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Number of Tiles</Box>
                                        <FormControl
                                            required
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <Select
                                                size="small"
                                                value={form.noOfTiles}
                                                name="noOfTiles"
                                                onChange={e => {
                                                    let minGreenMaxRed = (Number(e.target.value) * 2) / 10;
                                                    setForm({
                                                        ...form,
                                                        noOfTiles: e.target.value,
                                                        minGreen: minGreenMaxRed,
                                                        maxRed: minGreenMaxRed,
                                                    })
                                                }}>
                                                {NO_OF_TILES && NO_OF_TILES.map(index => {
                                                    return <MenuItem value={index}>{index}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Starting Number</Box>
                                        <FormControl
                                            required
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <Select
                                                size="small"
                                                name="startingNumber"
                                                value={form.startingNumber}
                                                onChange={handleOnChangeCreateDistrict}
                                            >
                                                {STARTING_NUMBER && STARTING_NUMBER.map(index => {
                                                    return <MenuItem value={index}>{index}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box className="fieldParent">
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Order</Box>
                                        <FormControl
                                            required
                                            error={emptyError.Order}
                                            sx={{ width: "100%" }}
                                        >
                                            <RadioGroup
                                                row
                                                name="order"
                                                value={order}
                                                onChange={(e) => {
                                                    setOrder(e.target.value)
                                                }}
                                                sx={{ width: "100%" }}
                                            >
                                                <FormControlLabel
                                                    sx={{ flexGrow: 1 }}
                                                    value="ru"
                                                    control={<Radio />}
                                                    name="Order"
                                                    label={
                                                        <Box className="imgBoxLayout">
                                                            <img
                                                                src={Images.ruB}
                                                                alt="ru"
                                                                className="imgStyleLayout"
                                                            />
                                                        </Box>
                                                    }
                                                />
                                                <FormControlLabel
                                                    sx={{ flexGrow: 1 }}
                                                    value="ur"
                                                    control={<Radio />}
                                                    name="Order"
                                                    label={
                                                        <Box className="imgBoxLayout">
                                                            <img
                                                                src={Images.urB}
                                                                alt="ur"
                                                                className="imgStyleLayout"
                                                            />
                                                        </Box>
                                                    }
                                                />
                                                <FormControlLabel
                                                    sx={{ flexGrow: 1 }}
                                                    value="rd"
                                                    control={<Radio />}
                                                    name="Order"
                                                    label={
                                                        <Box className="imgBoxLayout">
                                                            <img
                                                                src={Images.rdB}
                                                                alt="rd"
                                                                className="imgStyleLayout"
                                                            />
                                                        </Box>
                                                    }
                                                />
                                                <FormControlLabel
                                                    sx={{ flexGrow: 1 }}
                                                    value="lu"
                                                    control={<Radio />}
                                                    name="Order"
                                                    label={
                                                        <Box className="imgBoxLayout">
                                                            <img
                                                                src={Images.luB}
                                                                alt="lu"
                                                                className="imgStyleLayout"
                                                            />
                                                        </Box>
                                                    }
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Layout</Box>
                                        <FormControl
                                            required
                                            sx={{ width: "100%" }}
                                        >
                                            <RadioGroup
                                                row
                                                name="layout"
                                                value={layout}
                                                onChange={(e) => {
                                                    setLayout(e.target.value)
                                                }}
                                                sx={{ width: "100%" }}
                                            >
                                                <FormControlLabel
                                                    sx={{ flexGrow: 1 }}
                                                    value="normal"
                                                    control={<Radio />}
                                                    name="Layout"
                                                    label={
                                                        <Box className="imgBoxLayout">
                                                            <img
                                                                src={Images.basicB}
                                                                alt="normal"
                                                                className="imgStyleLayout"
                                                            />
                                                        </Box>
                                                    }
                                                />
                                                <FormControlLabel
                                                    sx={{ flexGrow: 1 }}
                                                    value="zigzag"
                                                    control={<Radio />}
                                                    name="Layout"
                                                    label={
                                                        <Box className="imgBoxLayout">
                                                            <img
                                                                src={Images.zigzagB}
                                                                alt="zigzag"
                                                                className="imgStyleLayout"
                                                            />
                                                        </Box>
                                                    }
                                                />
                                                <FormControlLabel
                                                    sx={{ flexGrow: 1 }}
                                                    value="spiral"
                                                    control={<Radio />}
                                                    name="Layout"
                                                    label={
                                                        <Box className="imgBoxLayout">
                                                            <img
                                                                src={Images.spiralB}
                                                                alt="spiral"
                                                                className="imgStyleLayout"
                                                            />
                                                        </Box>
                                                    }
                                                />
                                            </RadioGroup>
                                            {emptyError.Layout ? <FormHelperText>{SELECT_EMPTY}</FormHelperText> : <></>}
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box className="fieldParent">
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Min Green</Box>
                                        <TextField
                                            required
                                            variant="outlined"
                                            sx={{
                                                '& label.Mui-focused': {
                                                    color: "#15135E",
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#15135E',
                                                },
                                            }}
                                            size="small"
                                            name="minGreen"
                                            label=""
                                            type="number"
                                            className="fieldStyle"
                                            value={form.minGreen}
                                            onChange={handleOnChangeCreateDistrict}
                                            onBlur={(e) => {
                                                let greenRed = Number(form.maxRed) + Number(form.minGreen);
                                                let noOfTiles = Number(form.noOfTiles);
                                                if (e.target.value === "" || noOfTiles < greenRed) {
                                                    setForm({
                                                        ...form,
                                                        maxRed: Number(form.noOfTiles) * 2 / 10,
                                                        minGreen: Number(form.noOfTiles) * 2 / 10
                                                    })
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                let charCode = (e.which) ? e.which : e.keyCode;
                                                if (charCode === 37 || charCode === 39) {
                                                    return;
                                                } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                                    e.preventDefault()
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Max Red</Box>
                                        <TextField
                                            required
                                            variant="outlined"
                                            sx={{
                                                '& label.Mui-focused': {
                                                    color: "#15135E",
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#15135E',
                                                },
                                            }}
                                            size="small"
                                            name="maxRed"
                                            label=""
                                            type="number"
                                            className="fieldStyle"
                                            value={form.maxRed}
                                            onChange={handleOnChangeCreateDistrict}
                                            onBlur={(e) => {
                                                let greenRed = Number(form.maxRed) + Number(form.minGreen);
                                                let noOfTiles = Number(form.noOfTiles);
                                                if (e.target.value === "" || noOfTiles < greenRed) {
                                                    setForm({
                                                        ...form,
                                                        maxRed: Number(form.noOfTiles) * 2 / 10,
                                                        minGreen: Number(form.noOfTiles) * 2 / 10
                                                    })
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                let charCode = (e.which) ? e.which : e.keyCode;
                                                if (charCode === 37 || charCode === 39) {
                                                    return;
                                                } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                                    e.preventDefault()
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Min Time(mins)</Box>
                                        <TextField
                                            required
                                            variant="outlined"
                                            sx={{
                                                '& label.Mui-focused': {
                                                    color: "#15135E",
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#15135E',
                                                },
                                            }}
                                            size="small"
                                            name="minTime"
                                            label=""
                                            type="number"
                                            className="fieldStyle"
                                            value={form.minTime}
                                            onChange={handleOnChangeCreateDistrict}
                                            onBlur={(e) => {
                                                if (e.target.value === "") {
                                                    setForm({
                                                        ...form,
                                                        minTime: 1,
                                                    })
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                let charCode = (e.which) ? e.which : e.keyCode;
                                                if (charCode === 37 || charCode === 39) {
                                                    return;
                                                } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                                    e.preventDefault()
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box className="fieldChild">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="showNumber"
                                                    checked={form.showNumber}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            setForm({
                                                                ...form,
                                                                showNumber: e.target.checked
                                                            });
                                                        } else {
                                                            setForm({
                                                                ...form,
                                                                showNumber: e.target.checked
                                                            });
                                                        }
                                                    }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label="Show Number"
                                        />
                                    </Box>
                                </Box>
                                <Box className="fieldParent">
                                    <Box className="fieldChild">
                                        <Box className="fieldLabel">Class</Box>
                                        <FormControl
                                            required
                                            fullWidth
                                            // error={emptyError.ClassID}
                                            variant="outlined"
                                        >
                                            <Select
                                                size="small"
                                                name="classId"
                                                label="Class"
                                                value={form.classId}
                                                onChange={handleOnChangeCreateDistrict}
                                                onBlur={_ => {
                                                    setForm({
                                                        ...form,
                                                        students: []
                                                    });
                                                }}
                                            >
                                                {classList && classList.map(item => {
                                                    return <MenuItem value={item.GroupID}>{item.Name}</MenuItem>
                                                })}
                                            </Select>
                                            {/* {emptyError.ClassID ? <FormHelperText>{CLASS_SELECTION_EMPTY}</FormHelperText> : <></>} */}
                                        </FormControl>
                                    </Box>
                                    <Box className="fieldChild">
                                    </Box>
                                    <Box className="fieldChild">
                                    </Box>
                                    <Box className="fieldChild">
                                    </Box>
                                </Box>
                                <Box className="fieldParentCURadioButton">
                                    <Box className="fieldChildCURadioButton">
                                        <Box>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                // justifyContent:"center",
                                                alignItems: "center"
                                            }}>
                                                <Typography sx={{
                                                    color: errorStudent ? "#f00" : "#00000099"
                                                }}>
                                                    Students *
                                                </Typography>
                                                {
                                                    studentList &&
                                                    studentList.length > 0 &&
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            sx={{ margin: 0 }}
                                                            control={
                                                                <Checkbox
                                                                    checked={isSelectAll}
                                                                    onChange={e => {
                                                                        setIsSelectAll(e.target.checked)
                                                                        setErrorStudent(false);
                                                                    }}
                                                                />}
                                                            label={isSelectAll ? "Deselect all" : "Select all"}
                                                        />
                                                    </FormGroup>

                                                }
                                            </Box>
                                            <Box
                                                style={{
                                                    maxHeight: '200px',
                                                    maxWidth: '300px',
                                                    overflow: 'auto',
                                                    borderBottom: "1px solid #80808038",
                                                    borderRight: "1px solid #80808038",
                                                    boxShadow: "rgb(0 0 0 / 12%) 0px -px 31px 3px"
                                                }}>

                                                <FormControl>
                                                    <FormGroup>
                                                        {studentList && studentList.length > 0 && studentList.map(item => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="success"
                                                                        checked={form.students.includes(item.StudentID)}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                let allGroupIdData = form.students;
                                                                                allGroupIdData.push(item.StudentID);
                                                                                let finalGroupId = allGroupIdData.filter(data => data)
                                                                                setForm({
                                                                                    ...form,
                                                                                    students: finalGroupId
                                                                                })
                                                                            } else {
                                                                                let allGroupIdData = form.students;
                                                                                let finalGroupId = allGroupIdData.filter(data => data !== item.StudentID)
                                                                                setForm({
                                                                                    ...form,
                                                                                    students: finalGroupId
                                                                                })
                                                                            }
                                                                            setErrorStudent(false);
                                                                        }}
                                                                    />
                                                                }
                                                                label={item.StudentName}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </FormControl>
                                            </Box>
                                            {
                                                errorStudent ||
                                                    (
                                                        studentList &&
                                                        studentList.length > 0 &&
                                                        form.students && form.students.length === 0
                                                    ) ?
                                                    <Typography className="errorMsg"> Select at least one.</Typography> :
                                                    null
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="createButtonParent" style={{ height: "auto", padding: "2%" }}>
                                    <Box className="createButtonChild">
                                        <CustomTableButton
                                            isInActive={true}
                                            buttonLabel={"Cancel"}
                                            onClickButton={() => {
                                                history.push("/teacher/teacher-assignments");
                                            }}
                                            buttonIcon={<Close size={16} />}
                                        />
                                    </Box>
                                    <Box className="createButtonChild">
                                        <CustomTableButton
                                            isInActive={false}
                                            buttonLabel={"Create"}
                                            onClickButton={handleCreateClick}
                                            buttonIcon={<Pen size={16} />}
                                        />
                                    </Box>
                                </Box>
                                <Box className="bottomSpace" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CreateAssignment;
