import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  COUNTRIES,
  COUNTRY_EMPTY,
  DESCRIPTION_EMPTY,
  DOMAIN_EMPTY,
  EMAIL_EMPTY,
  FIRST_NAME_EMPTY,
  LAST_NAME_EMPTY,
  LOCATION_EMPTY,
  PASSWORD_EMPTY,
  USER_NAME_EMPTY,
  EMAIL_INVALID,
  MAX_STUDENTS,
  DOMAIN_NAME_EXIST,
  DISTRICT_UPDATE_SUCCESS_MESSAGE,
  MAX_STUDENT_INVALID,
} from "../../constants";
import Creators from "../../redux/ducks/actions";
import "./ManageDistrict.css";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import { Close, Edit } from "@carbon/icons-react";

function UpdateDomain(props) {
  const dispatch = useDispatch(); // Dispatch
  const history = useHistory();
  const { userInfo } = useSelector(state => state.data);

  // Snack Message
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "update",
  });
  const { snackStatus, snackMessageFor } = snackbar;
  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  const url = useParams(); //URl

  const [form, setForm] = useState({
    domainName: "",
    description: "",
    location: "",
    country: "",
    userName: "",
    emailId: "",
    firstName: "",
    lastName: "",
    password: "",
    maxStudents: "",
    studentId: "",
  });
  const [emptyError, setEmptyError] = useState({
    domainName: false,
    description: false,
    location: false,
    country: false,
    userName: false,
    emailId: false,
    firstName: false,
    lastName: false,
    password: false,
    maxStudents: false,
    studentId: false,
  });

  const [countryData, setCountryData] = useState({
    code: "",
    label: "",
    phone: "",
  });

  const [emailValidation, setEmailValidation] = useState("");

  //   update Button
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateClick = () => {
    setUpdateLoading(true);
    const checkEmptyValue = { ...emptyError };
    let submit = true;
    // Checking empty data
    for (let items in form) {
      if (form[items] === "") {
        submit = false;
        checkEmptyValue[items] = true;
      }
    }
    if (emailValidation !== "") {
      submit = false;
    }

    if (form.maxStudents === "0") {
      submit = false;
      form.maxStudents = "";
      if (form.maxStudents === "") {
        handleSnackbarClick("maxStudentInvalid");
        setEmptyError({
          ...emptyError,
          maxStudents: true
        })
      }
    }

    if (!submit) {
      setEmptyError(checkEmptyValue);
      setUpdateLoading(false);
    } else {
      dispatch(
        Creators.updateDistrictById(form, (response) => {
          if (response.MessageID === 2) {
            // Domain name already exist Error
            setUpdateLoading(false);
            handleSnackbarClick("duplicateError");
            return null;
          } else if (response.MessageID === 0) {
            // Domain Update Success Message
            handleSnackbarClick("update");
            setTimeout(() => {
              setUpdateLoading(false);
              history.push("/admin/admin-manage-district");
            }, 1000);
          } else {
            console.error(response, "response");
          }
        })
      );
    }
  };

  // handle update Domain
  const handleOnChangeUpdateDistrict = (e) => {
    if (e.target.id === "maxStudents" && e.target.value < 0) {
      e.preventDefault()
    } else {
      if (e.target.value !== "") {
        setEmptyError((state) => ({
          ...state,
          [e.target.id]: false,
        }));
      }
      setForm((state) => ({
        ...state,
        [e.target.id]: e.target.value,
      }));
      if (e.target.id === "emailId" && e.target.value !== "") {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
          setEmailValidation("");
          setForm((state) => ({
            ...state,
            [e.target.id]: e.target.value,
          }));
        } else {
          setEmptyError((state) => ({
            ...state,
            [e.target.id]: true,
          }));

          setEmailValidation(EMAIL_INVALID);
          return null;
        }
      }
    }
  };

  //   setting data in field
  useEffect(() => {
    dispatch(
      Creators.getDistrictByID(Number(url.id), (response) => {
        let rowData = response;
        const getCountry = COUNTRIES.filter(
          (item) => item.label.toLowerCase() === rowData.Country.toLowerCase()
        );
        const rowCountry = getCountry[0];
        setCountryData((state) => ({
          ...state,
          code: rowCountry ? rowCountry.code : "",
          label: rowCountry ? rowCountry.label : "",
          phone: rowCountry ? rowCountry.phone : "",
        }));

        setForm((state) => ({
          ...state,
          domainId: rowData.ID,
          domainName: rowData.Name,
          description: rowData.Desc,
          location: rowData.Location,
          country: rowCountry ? rowCountry.label : "",
          userName: rowData.LoginName,
          emailId: rowData.EmailID,
          firstName: rowData.FirstName,
          lastName: rowData.LastName,
          password: rowData.Password,
          maxStudents: rowData.MaxStudents,
          studentId: rowData.StudentID,
        }));
      })
    );
  }, [url, dispatch]);


  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <>
      {/* Action Status */}
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar
          key="SnackBar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackStatus}
          onClose={handleSnackbarClose}
        >
          {snackMessageFor === "duplicateError" ? (
            <Alert
              variant="filled"
              severity="warning"
              sx={{
                "&.MuiPaper-root": {
                  background: "#ffc107",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {DOMAIN_NAME_EXIST}
            </Alert>
          ) : snackMessageFor === "maxStudentInvalid" ? (
            <Alert
              variant="filled"
              severity="warning"
              sx={{
                "&.MuiPaper-root": {
                  background: "#ffc107",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {MAX_STUDENT_INVALID}
            </Alert>
          ) : snackMessageFor === "update" ? (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                "&.MuiPaper-root": {
                  background: "#00c853",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {DISTRICT_UPDATE_SUCCESS_MESSAGE}
            </Alert>
          ) : null}
        </Snackbar>
      </Stack>
      <Box className='districtStyle'>
        <CustomHeader
          hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
          licenseStatus={licenseStatus}
          needBreadCrumb={true}
          previousPageLabel={"Manage District"}
          currentPageLabel={"Edit District"}
          onClickPrevPageLabel={() => { history.push("/admin/admin-manage-district") }}
        />
        <Box className="noHeaderButton" />
        <Box className='nsMainContent'>
          <Box className="nsMainHeader">
            <Box className="nsMainTextHeader">
              Edit District
            </Box>
            <Box className="customHeaderButton" />
          </Box>
          <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
            <Box className="createDomainParent">
              <Box className="LessonBotContent">
                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">District Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.domainName ? "#f00" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="domainName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.domainName}
                      onChange={handleOnChangeUpdateDistrict}
                      error={emptyError.domainName}
                      helperText={emptyError.domainName ? DOMAIN_EMPTY : ""}
                      disabled={true}
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Description</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        width: "100%",
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.description ? "" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="description"
                      label=""
                      multiline
                      rows={1}
                      value={form.description}
                      onChange={handleOnChangeUpdateDistrict}
                      error={emptyError.description}
                      helperText={emptyError.description ? DESCRIPTION_EMPTY : ""}
                    />
                  </Box>
                </Box>
                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Country</Box>
                    <Autocomplete
                      size="small"
                      id="country"
                      options={COUNTRIES}
                      value={countryData}
                      onChange={(_, y) => setCountryData(y)}
                      getOptionLabel={(option) => option.label}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          const present = COUNTRIES.some(
                            (item) => item.label === e.target.value
                          );
                          if (present) {
                            setEmptyError((state) => ({
                              ...state,
                              country: false,
                            }));
                            setForm((state) => ({
                              ...state,
                              country: e.target.value,
                            }));
                          } else {
                            setForm((state) => ({
                              ...state,
                              country: "",
                            }));
                          }
                        } else {
                          setForm((state) => ({
                            ...state,
                            country: "",
                          }));
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          required
                          variant="outlined"
                          sx={{
                            '& label.Mui-focused': {
                              color: "#2d8f3c",
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: emptyError.country ? "" : '#2d8f3c',
                            },
                          }}
                          size="small"
                          {...params}
                          label=""
                          value={form.country}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          error={emptyError.country}
                          helperText={emptyError.country ? COUNTRY_EMPTY : ""}
                        />
                      )}
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Location</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.location ? "" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="location"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.location}
                      onChange={handleOnChangeUpdateDistrict}
                      error={emptyError.location}
                      helperText={emptyError.location ? LOCATION_EMPTY : ""}
                    />
                  </Box>
                </Box>
                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">User Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.userName ? "" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="userName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.userName}
                      onChange={handleOnChangeUpdateDistrict}
                      error={emptyError.userName}
                      helperText={emptyError.userName ? USER_NAME_EMPTY : ""}
                      disabled={true}
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Password</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.password ? "" : '#2d8f3c',
                        },
                      }}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      size="small"
                      id="password"
                      label=""
                      type="text"
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.password}
                      onChange={handleOnChangeUpdateDistrict}
                      error={emptyError.password}
                      helperText={emptyError.password ? PASSWORD_EMPTY : ""}
                    />
                  </Box>
                </Box>
                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Email</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.emailId ? "" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="emailId"
                      label=""
                      type="email"
                      className="fieldStyle"
                      value={form.emailId}
                      onChange={handleOnChangeUpdateDistrict}
                      error={emptyError.emailId}
                      helperText={
                        emptyError.emailId
                          ? emailValidation !== ""
                            ? EMAIL_INVALID
                            : EMAIL_EMPTY
                          : ""
                      }
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">First Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.firstName ? "" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="firstName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.firstName}
                      onChange={handleOnChangeUpdateDistrict}
                      error={emptyError.firstName}
                      helperText={emptyError.firstName ? FIRST_NAME_EMPTY : ""}
                    />
                  </Box>
                </Box>
                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Last Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.lastName ? "" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="lastName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.lastName}
                      onChange={handleOnChangeUpdateDistrict}
                      error={emptyError.lastName}
                      helperText={emptyError.lastName ? LAST_NAME_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Max Students</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.maxStudents ? "" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="maxStudents"
                      label=""
                      type="number"
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.maxStudents}
                      onKeyDown={(e) => {
                        let charCode = (e.which) ? e.which : e.keyCode;
                        if (charCode === 37 || charCode === 39) {
                          return;
                        } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault()
                        }
                      }}
                      onChange={handleOnChangeUpdateDistrict}
                      error={emptyError.maxStudents}
                      helperText={emptyError.maxStudents ? MAX_STUDENTS : ""}
                    />
                  </Box>

                </Box>
                <Box className="createButtonParent" style={{ height: "auto", padding: "2%" }}>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={true}
                      buttonLabel={"Cancel"}
                      onClickButton={() => {
                        history.push("/admin/admin-manage-district");
                      }}
                      buttonIcon={<Close size={16} />}
                    />
                  </Box>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={false}
                      buttonLabel={"Update"}
                      onClickButton={handleUpdateClick}
                      buttonIcon={<Edit size={16} />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default UpdateDomain;
