import React, { useEffect, useState } from "react";
import "./WeeklyReports.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    FormControl,
    TextField,
    IconButton,
    Tooltip,
    Backdrop,
    Divider,
    Select,
    MenuItem,
} from '@mui/material';
import Logo from "../../constants/images";
import PieChartIcon from '@mui/icons-material/PieChart';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useParams } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import Creators from "../../redux/ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import NSLineChart from "./NSLineChart";
import NSBarChart from "./NSBarChart";
import { jsPDF } from "jspdf";
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import html2canvas from 'html2canvas';
import CustomHeader from "../../Components/CustomHeader";
import { formatDateToday } from "../../constants";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import { Download, PenFountain, User } from "@carbon/icons-react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

export default function WeeklyReports(params) {
    const history = useHistory(); //history
    const dispatch = useDispatch();
    const location = useLocation();
    const [districtProductData, setDistrictProductData] = useState([]);
    const [schoolLeaderBoard, setSchoolLeaderBoard] = useState({});
    const [districtDescription, setDistrictDescription] = useState("");

    // Report Date
    const getFromDate = () => {
        let todaysDate = new Date();
        let date = todaysDate.getDate();
        let month = todaysDate.getMonth();
        let year = todaysDate.getFullYear();
        month = month + 1;
        return (year - 1)
            + "-"
            + ((month).toString()?.length === 1 ? (0 + month.toString()) : month)
            + "-"
            + ((date).toString()?.length === 1 ? (0 + date.toString()) : date)
    }
    const getToDate = () => {
        let todaysDate = new Date();
        let date = todaysDate.getDate();
        let month = todaysDate.getMonth();
        let year = todaysDate.getFullYear();
        month = month + 1;
        return (year)
            + "-"
            + ((month).toString()?.length === 1 ? (0 + month.toString()) : month)
            + "-"
            + ((date).toString()?.length === 1 ? (0 + date.toString()) : date)
    }
    const [minDate, setMinDate] = React.useState("");
    const [maxDate, setMaxDate] = React.useState("");

    const [weeklyData, setWeeklyData] = React.useState({
        fromDate: getFromDate(),
        toDate: getToDate(),
    });

    // Loading
    const [loading, setLoading] = React.useState(false);

    const [barChartData, setBarChartData] = React.useState({
        chartTitle: ["Minutes Played", "Problems Solved", "Games Completed", "Levels Mastered"],
        labelNames: ["Current Week", "Last Week", "Cumulative"],
        labelValues: [[0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]],
    });

    const [lineChartData, setLineChartData] = React.useState({
        chartTitle: ["Minutes Played", "Problems Solved", "Games Completed", "Levels Mastered"],
        labelNames: ["Current Week", "Last Week", "Cumulative"],
        labelValues: [
            [[0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]],
            [[0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]],
            [[0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]],
        ]
    });

    const url = useParams();
    const { userInfo, districtDashboardData, classUsageData, listAllClasses, schoolUsageData } = useSelector((state) => state.data);
    const mdTheme = createTheme();
    const [currentClassName, setClassName] = useState([]);
    const [classId, setClassId] = useState("");

    // Initial Call
    useEffect(async () => {
        await getWeeklyReport();
        // Setting min and max date
        let currentDate = new Date();
        setMaxDate(currentDate)
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let date = currentDate.getDate();
        var prevDate = new Date(year, month, date);
        prevDate.setFullYear(prevDate.getFullYear() - 1);
        setMinDate(prevDate);

        // services
        dispatch(Creators.getDistrictLicenseList((response) => {
            setDistrictProductData(response);
        }));
        dispatch(Creators.getDistrictByID(userInfo.DomainID, (response) => {
            setDistrictDescription(response.Desc);
        }));
        if (userInfo && userInfo.UserType === "GroupAdmin") {
            if (listAllClasses.length > 0) {
                setClassId(listAllClasses[0].GroupID);
                setClassName(listAllClasses[0].Name);
            } else {
                setClassId(-1);
                setClassName("");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSchoolList = async (event) => {
        if (userInfo && userInfo.UserType === "GroupAdmin") {
            setClassId(event.target.value);
            let classDetail = listAllClasses.length > 0 && listAllClasses.filter(data => data.GroupID === Number(event.target.value))[0];
            setClassName(classDetail.Name);
            let weeklyReportData = {
                schoolId: event.target.value,
                fromDate: weeklyData.fromDate,
                toDate: weeklyData.toDate,
                classname: ""
            }
            await dispatch(Creators.classListTeacher(userInfo.LoginID, (response) => {
                if (response?.length > 0) {
                    response.map(data => {
                        if (Number(data.GroupID) === Number(classId)) {
                            weeklyReportData.classname = data.Name
                        }
                        return null;
                    })

                }
                dispatch(Creators.schoolWeeklyReport(weeklyReportData, (response) => {
                    if (response) {
                        // Bar Chart data set up
                        let chartData = [];
                        let minutesPlayed = [];
                        let problemsSolved = [];
                        let gameMastered = [];
                        let levelsMastered = [];
                        minutesPlayed.push(response.CurrentWeek.MinutesPlayed)
                        minutesPlayed.push(response.LastWeek.MinutesPlayed)
                        minutesPlayed.push(response.Cumulative.MinutesPlayed)
                        problemsSolved.push(response.CurrentWeek.ProblemsCompleted)
                        problemsSolved.push(response.LastWeek.ProblemsCompleted)
                        problemsSolved.push(response.Cumulative.ProblemsCompleted)
                        gameMastered.push(response.CurrentWeek.LevelsAttempted)
                        gameMastered.push(response.LastWeek.LevelsAttempted)
                        gameMastered.push(response.Cumulative.LevelsAttempted)
                        levelsMastered.push(response.CurrentWeek.LevelsMastered)
                        levelsMastered.push(response.LastWeek.LevelsMastered)
                        levelsMastered.push(response.Cumulative.LevelsMastered)
                        chartData.push(minutesPlayed);
                        chartData.push(problemsSolved);
                        chartData.push(gameMastered);
                        chartData.push(levelsMastered);
                        setBarChartData({
                            ...barChartData,
                            labelValues: chartData,
                        })
                        // Line Chart data set up
                        let lineData = [];
                        let MPData = [];
                        let PSData = [];
                        let GCData = [];
                        let LMData = [];
                        // Minutes Played
                        MPData.push(response.StudentCurrentWeekMP);
                        MPData.push(response.StudentLastWeekMP);
                        MPData.push(response.StudentCumulativeMP);

                        // Problem Solved
                        PSData.push(response.StudentCurrentPS);
                        PSData.push(response.StudentLastWeekPS);
                        PSData.push(response.StudentCumulativePS);

                        // Game Completed
                        GCData.push(response.StudentCurrentGC);
                        GCData.push(response.StudentLastWeekGC);
                        GCData.push(response.StudentCumulativeGC);

                        // Levels Mastered
                        LMData.push(response.StudentCurrentLM);
                        LMData.push(response.StudentLastWeekLM);
                        LMData.push(response.StudentCumulativeLM);

                        lineData.push(MPData);
                        lineData.push(PSData);
                        lineData.push(GCData);
                        lineData.push(LMData);
                        setLineChartData({
                            ...lineChartData,
                            labelValues: lineData,
                            xAxisLabel: response.StudentList,
                        })
                        setLoading(false);
                    } else {
                        console.error(response, "response");
                        setLoading(false);
                    }
                }))
            }))
            // listAllClasses

        }
    };

    const setLicenseDate = (date) => {
        setWeeklyData({
            ...weeklyData,
            fromDate: date
        })
    }

    useEffect(() => {
        const result = districtProductData?.filter(data => data.DistrictID === userInfo.DomainID);
        if (result?.length !== 0) {
            const { SubscriptionDate } = result[0];
            const Date = SubscriptionDate.split(" ")[0];
            const getDate = Date.split("/");
            const purchaseFromDate = getDate[2] + "-" + getDate[0] + "-" + getDate[1]
            if (purchaseFromDate !== weeklyData.fromDate) {
                setLicenseDate(purchaseFromDate);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [districtProductData])

    const handleFromDate = (date) => {
        setMinDate(date)
        try {

            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const finaldate = `${year}-${month}-${day}`;

            setWeeklyData({
                ...weeklyData,
                fromDate: finaldate
            });
        }
        catch (err) {
            console.error(err, "error")
        }
    }

    const handleToDate = (date) => {
        // Get the current date
        const currentDate = new Date();

        // Check if the selected date is greater than the current date
        if (
            date.getFullYear() > currentDate.getFullYear() ||
            (date.getFullYear() === currentDate.getFullYear() &&
                date.getMonth() > currentDate.getMonth()) ||
            (date.getFullYear() === currentDate.getFullYear() &&
                date.getMonth() === currentDate.getMonth() &&
                date.getDate() > currentDate.getDate())
        ) {
            // If the selected date is in the future, set it to the current date
            date = currentDate;
        }

        setMaxDate(date);

        try {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const finaldate = `${year}-${month}-${day}`;

            setWeeklyData({
                ...weeklyData,
                toDate: finaldate,
            });
        } catch (err) {
            console.error(err, 'error');
        }
    };

    useEffect(() => {
        getWeeklyReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weeklyData.fromDate, weeklyData.toDate])

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        margin: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }));

    // Get Chart data
    const getWeeklyReport = () => {
        setLoading(true);
        if (userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "restrictedschooladmin" || userInfo.UserType === "SuperGroupAdmin")) {
            // School, Restricted School Admin and Principal
            let weeklyReportData = {
                superGroupId: userInfo.SuperGroupID,
                fromDate: weeklyData.fromDate,
                toDate: weeklyData.toDate,
            }
            dispatch(Creators.classWeeklyReport(weeklyReportData, (response) => {
                if (response) {
                    // Bar Chart data set up
                    let chartData = [];
                    let minutesPlayed = [];
                    let problemsSolved = [];
                    let gameMastered = [];
                    let levelsMastered = [];
                    minutesPlayed.push(response.CurrentWeek.MinutesPlayed)
                    minutesPlayed.push(response.LastWeek.MinutesPlayed)
                    minutesPlayed.push(response.Cumulative.MinutesPlayed)
                    problemsSolved.push(response.CurrentWeek.ProblemsCompleted)
                    problemsSolved.push(response.LastWeek.ProblemsCompleted)
                    problemsSolved.push(response.Cumulative.ProblemsCompleted)
                    gameMastered.push(response.CurrentWeek.LevelsAttempted)
                    gameMastered.push(response.LastWeek.LevelsAttempted)
                    gameMastered.push(response.Cumulative.LevelsAttempted)
                    levelsMastered.push(response.CurrentWeek.LevelsMastered)
                    levelsMastered.push(response.LastWeek.LevelsMastered)
                    levelsMastered.push(response.Cumulative.LevelsMastered)
                    chartData.push(minutesPlayed);
                    chartData.push(problemsSolved);
                    chartData.push(gameMastered);
                    chartData.push(levelsMastered);
                    setBarChartData({
                        ...barChartData,
                        labelValues: chartData,
                    })
                    // Line Chart data set up
                    let lineData = [];
                    let MPData = [];
                    let PSData = [];
                    let GCData = [];
                    let LMData = [];
                    // Minutes Played
                    MPData.push(response.ClassCurrentWeekMP);
                    MPData.push(response.ClassLastWeekMP);
                    MPData.push(response.ClassCumulativeMP);

                    // Problem Solved
                    PSData.push(response.ClassCurrentPS);
                    PSData.push(response.ClassLastWeekPS);
                    PSData.push(response.ClassCumulativePS);

                    // Game Completed
                    GCData.push(response.ClassCurrentGC);
                    GCData.push(response.ClassLastWeekGC);
                    GCData.push(response.ClassCumulativeGC);

                    // Levels Mastered
                    LMData.push(response.ClassCurrentLM);
                    LMData.push(response.ClassLastWeekLM);
                    LMData.push(response.ClassCumulativeLM);

                    lineData.push(MPData);
                    lineData.push(PSData);
                    lineData.push(GCData);
                    lineData.push(LMData);
                    setLineChartData({
                        ...lineChartData,
                        labelValues: lineData,
                        xAxisLabel: response.ClassList,
                    })
                    setLoading(false);
                } else {
                    console.error(response, "response");
                    setLoading(false);
                }
            }))
        } else if (userInfo && userInfo.UserType === "GroupAdmin") {
            // Teacher
            let weeklyReportData = {
                schoolId: classId,
                fromDate: weeklyData.fromDate,
                toDate: weeklyData.toDate,
                classname: ""
            }
            if (classId !== "") {
                dispatch(Creators.classListTeacher(userInfo.LoginID, (response) => {
                    if (response?.length > 0) {
                        response.map(data => {
                            if (Number(data.GroupID) === Number(classId)) {
                                weeklyReportData.classname = data.Name
                            }
                            return null;
                        })
                    }
                    dispatch(Creators.schoolWeeklyReport(weeklyReportData, (response) => {
                        if (response) {
                            // Bar Chart data set up
                            let chartData = [];
                            let minutesPlayed = [];
                            let problemsSolved = [];
                            let gameMastered = [];
                            let levelsMastered = [];
                            minutesPlayed.push(response.CurrentWeek.MinutesPlayed)
                            minutesPlayed.push(response.LastWeek.MinutesPlayed)
                            minutesPlayed.push(response.Cumulative.MinutesPlayed)
                            problemsSolved.push(response.CurrentWeek.ProblemsCompleted)
                            problemsSolved.push(response.LastWeek.ProblemsCompleted)
                            problemsSolved.push(response.Cumulative.ProblemsCompleted)
                            gameMastered.push(response.CurrentWeek.LevelsAttempted)
                            gameMastered.push(response.LastWeek.LevelsAttempted)
                            gameMastered.push(response.Cumulative.LevelsAttempted)
                            levelsMastered.push(response.CurrentWeek.LevelsMastered)
                            levelsMastered.push(response.LastWeek.LevelsMastered)
                            levelsMastered.push(response.Cumulative.LevelsMastered)
                            chartData.push(minutesPlayed);
                            chartData.push(problemsSolved);
                            chartData.push(gameMastered);
                            chartData.push(levelsMastered);
                            setBarChartData({
                                ...barChartData,
                                labelValues: chartData,
                            })
                            // Line Chart data set up
                            let lineData = [];
                            let MPData = [];
                            let PSData = [];
                            let GCData = [];
                            let LMData = [];
                            // Minutes Played
                            MPData.push(response.StudentCurrentWeekMP);
                            MPData.push(response.StudentLastWeekMP);
                            MPData.push(response.StudentCumulativeMP);

                            // Problem Solved
                            PSData.push(response.StudentCurrentPS);
                            PSData.push(response.StudentLastWeekPS);
                            PSData.push(response.StudentCumulativePS);

                            // Game Completed
                            GCData.push(response.StudentCurrentGC);
                            GCData.push(response.StudentLastWeekGC);
                            GCData.push(response.StudentCumulativeGC);

                            // Levels Mastered
                            LMData.push(response.StudentCurrentLM);
                            LMData.push(response.StudentLastWeekLM);
                            LMData.push(response.StudentCumulativeLM);

                            lineData.push(MPData);
                            lineData.push(PSData);
                            lineData.push(GCData);
                            lineData.push(LMData);
                            setLineChartData({
                                ...lineChartData,
                                labelValues: lineData,
                                xAxisLabel: response.StudentList,
                            })
                            setLoading(false);
                        } else {
                            console.error(response, "response");
                            setLoading(false);
                        }
                    }))
                }))
                // listAllClasses

            }
        } else if (userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin")) {
            // District Admin and Restricted District Admin
            let weeklyReportData = {
                districtId: userInfo.DomainID,
                fromDate: weeklyData.fromDate,
                toDate: weeklyData.toDate,
            }
            dispatch(Creators.districtWeeklyReport(weeklyReportData, (response) => {
                if (response && response !== null) {
                    // console.log("District", response);
                    // Bar Chart data set up
                    let chartData = [];
                    let minutesPlayed = [];
                    let problemsSolved = [];
                    let gameMastered = [];
                    let levelsMastered = [];
                    minutesPlayed.push(response?.CurrentWeek?.MinutesPlayed)
                    minutesPlayed.push(response?.LastWeek?.MinutesPlayed)
                    minutesPlayed.push(response?.Cumulative?.MinutesPlayed)
                    problemsSolved.push(response?.CurrentWeek?.ProblemsCompleted)
                    problemsSolved.push(response?.LastWeek?.ProblemsCompleted)
                    problemsSolved.push(response?.Cumulative?.ProblemsCompleted)
                    gameMastered.push(response?.CurrentWeek?.LevelsAttempted)
                    gameMastered.push(response?.LastWeek?.LevelsAttempted)
                    gameMastered.push(response?.Cumulative?.LevelsAttempted)
                    levelsMastered.push(response?.CurrentWeek?.LevelsMastered)
                    levelsMastered.push(response?.LastWeek?.LevelsMastered)
                    levelsMastered.push(response?.Cumulative?.LevelsMastered)
                    chartData.push(minutesPlayed);
                    chartData.push(problemsSolved);
                    chartData.push(gameMastered);
                    chartData.push(levelsMastered);
                    setBarChartData({
                        ...barChartData,
                        labelValues: chartData,
                    })
                    // Line Chart data set up
                    let lineData = [];
                    let MPData = [];
                    let PSData = [];
                    let GCData = [];
                    let LMData = [];
                    // Minutes Played
                    MPData.push(response?.SchoolCurrentWeekMP);
                    MPData.push(response?.SchoolLastWeekMP);
                    MPData.push(response?.SchoolCumulativeMP);

                    // Problem Solved
                    PSData.push(response?.SchoolCurrentPS);
                    PSData.push(response?.SchoolLastWeekPS);
                    PSData.push(response?.SchoolCumulativePS);

                    // Game Completed
                    GCData.push(response?.SchoolCurrentGC);
                    GCData.push(response?.SchoolLastWeekGC);
                    GCData.push(response?.SchoolCumulativeGC);

                    // Levels Mastered
                    LMData.push(response?.SchoolCurrentLM);
                    LMData.push(response?.SchoolLastWeekLM);
                    LMData.push(response?.SchoolCumulativeLM);

                    lineData.push(MPData);
                    lineData.push(PSData);
                    lineData.push(GCData);
                    lineData.push(LMData);
                    setLineChartData({
                        ...lineChartData,
                        labelValues: lineData,
                        xAxisLabel: response.SchoolList,
                    })
                    // set School Leaderboard
                    function sortDesc(a, b) {
                        if (a.data > b.data) {
                            return -1;
                        }
                        if (a.data < b.data) {
                            return 1;
                        }
                        return 0;
                    }

                    const SMP = response?.SchoolList?.map((schoolName, index) => {
                        return {
                            schoolName,
                            data: response?.SchoolCumulativeMP[index]
                        }
                    });
                    const SPS = response?.SchoolList?.map((schoolName, index) => {
                        return {
                            schoolName,
                            data: response?.SchoolCumulativePS[index]
                        }
                    });
                    const SGC = response?.SchoolList?.map((schoolName, index) => {
                        return {
                            schoolName,
                            data: response?.SchoolCumulativeGC[index]
                        }
                    });
                    const SLM = response?.SchoolList?.map((schoolName, index) => {
                        return {
                            schoolName,
                            data: response?.SchoolCumulativeLM[index]
                        }
                    });
                    const MPSorted = SMP?.sort(sortDesc);
                    const PSSorted = SPS?.sort(sortDesc);
                    const GCSorted = SGC?.sort(sortDesc);
                    const LMSorted = SLM?.sort(sortDesc);
                    if (response?.SchoolList?.length > 10) {
                        setSchoolLeaderBoard({
                            schoolList: response?.SchoolList?.slice(0, 10),
                            minutesPlayed: MPSorted?.slice(0, 10),
                            problemsSolved: PSSorted?.slice(0, 10),
                            gamesCompleted: GCSorted?.slice(0, 10),
                            levelsMastered: LMSorted?.slice(0, 10),
                        })
                    } else {
                        setSchoolLeaderBoard({
                            schoolList: response.SchoolList,
                            minutesPlayed: MPSorted,
                            problemsSolved: PSSorted,
                            gamesCompleted: GCSorted,
                            levelsMastered: LMSorted,
                        })
                    }

                    setLoading(false);
                } else {
                    console.error(response, "response");
                    setLoading(false);
                }
            }))
        }
    }

    const generate = async () => {
        setLoading(true);
        // bar chart
        var newCanvas = document.querySelector('#PdfReport0');
        var newCanvasImgmp = newCanvas.toDataURL("image/png", 1.0);
        var newCanvasps = document.querySelector('#PdfReport1');
        var newCanvasImgps = newCanvasps.toDataURL("image/png", 1.0);
        var newCanvasgc = document.querySelector('#PdfReport2');
        var newCanvasImggc = newCanvasgc.toDataURL("image/png", 1.0);
        var newCanvaslm = document.querySelector('#PdfReport3');
        var newCanvasImglm = newCanvaslm.toDataURL("image/png", 1.0);

        let canvasImageMP;
        let canvasImagePS;
        let canvasImageLM;
        let canvasImageGC;
        if (userInfo?.UserType === "DistrictAdmin" || userInfo?.UserType === "restricteddistrictadmin") {
            // school leaderboard
            const MPdata = html2canvas(document.querySelector("#PdfReportSLMP"));
            const canvasMP = await MPdata.then((canvas => {
                return canvas;
            }));
            canvasImageMP = canvasMP.toDataURL("image/png", 1.0);
            const PSdata = html2canvas(document.querySelector("#PdfReportSLPS"));
            const canvasPS = await PSdata.then((canvas => {
                return canvas;
            }));
            canvasImagePS = canvasPS.toDataURL("image/png", 1.0);
            const GCdata = html2canvas(document.querySelector("#PdfReportSLGC"));
            const canvasGC = await GCdata.then((canvas => {
                return canvas;
            }));
            canvasImageGC = canvasGC.toDataURL("image/png", 1.0);
            const LMdata = html2canvas(document.querySelector("#PdfReportSLLM"));
            const canvasLM = await LMdata.then((canvas => {
                return canvas;
            }));
            canvasImageLM = canvasLM.toDataURL("image/png", 1.0);
        }

        // Line chart
        var newCanvas1 = document.querySelector('#PdfReport20');
        var newCanvasImg1 = newCanvas1.toDataURL("image/png", 1.0);
        var newCanvas2 = document.querySelector('#PdfReport21');
        var newCanvasImg2 = newCanvas2.toDataURL("image/png", 1.0);
        var newCanvas3 = document.querySelector('#PdfReport22');
        var newCanvasImg3 = newCanvas3.toDataURL("image/png", 1.0);
        var newCanvas4 = document.querySelector('#PdfReport23');
        var newCanvasImg4 = newCanvas4.toDataURL("image/png", 1.0);

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;

        var doc = new jsPDF('l', 'pt', "a4", "true");
        // width=840 and height=600
        doc.setFontSize(18);
        let chartTile = "";
        if (userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "restricteddistrictadmin" || userInfo.UserType === "restrictedschooladmin" || userInfo.UserType === "SuperGroupAdmin")) {
            chartTile = (districtDescription).trim()
        } else if (userInfo && userInfo.UserType === "GroupAdmin") {
            chartTile = (classUsageData.className).trim()
        }
        if (userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "restrictedschooladmin" || userInfo.UserType === "SuperGroupAdmin")) {
            doc.text(chartTile + " Weekly Report", 320, 20);
        } else {
            doc.text(chartTile + " Weekly Report", 320, 20);
        }
        doc.setFontSize(14);
        const newFromDate = weeklyData.fromDate.split('-').reverse().map((data, index) => {
            if (index === 2) {
                return data;
            }
            return (data.padStart(2, '0'))
        }).join('-');
        const newToDate = weeklyData.toDate.split('-').reverse().map((data, index) => {
            if (index === 2) {
                return data;
            }
            return (data.padStart(2, '0'))
        }).join('-');
        doc.text('From Date: ' + newFromDate + "- To Date: " + newToDate, 30, 50);
        doc.text('Report Date: ' + today, 650, 50);

        doc.addImage(newCanvasImgmp, 'JPEG', 10, 70, 400, 200, undefined, 'FAST');
        doc.addImage(newCanvasImgps, 'JPEG', 415, 70, 400, 200, undefined, 'FAST');
        doc.addImage(newCanvasImggc, 'JPEG', 10, 320, 400, 200, undefined, 'FAST');
        doc.addImage(newCanvasImglm, 'JPEG', 415, 320, 400, 200, undefined, 'FAST');

        if (userInfo?.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin") {
            if (schoolLeaderBoard?.schoolList?.length > 7) {
                doc.addPage();
                doc.setFontSize(18);
                const TableSize = (schoolLeaderBoard?.schoolList?.length + 1) * 24;
                doc.text(`${districtDescription} School Leaderboard`, 290, 30);
                doc.addImage(canvasImageMP, 'JPEG', 104, 50, 264, TableSize, undefined, 'FAST');
                doc.addImage(canvasImagePS, 'JPEG', 472, 50, 264, TableSize, undefined, 'FAST');
                doc.addImage(canvasImageGC, 'JPEG', 104, 318, 264, TableSize, undefined, 'FAST');
                doc.addImage(canvasImageLM, 'JPEG', 472, 318, 264, TableSize, undefined, 'FAST');
            } else {
                doc.addPage();
                doc.setFontSize(18);
                const TableSize = !(schoolLeaderBoard?.schoolList?.length) ? 24 : (schoolLeaderBoard?.schoolList?.length + 1) * 24;
                const customYAxis = 50 + TableSize + 24;
                doc.text(`${districtDescription} School Leaderboard`, 290, 30);
                doc.addImage(canvasImageMP, 'JPEG', 104, 50, 264, TableSize, undefined, 'FAST');
                doc.addImage(canvasImagePS, 'JPEG', 472, 50, 264, TableSize, undefined, 'FAST');
                doc.addImage(canvasImageGC, 'JPEG', 104, customYAxis, 264, TableSize, undefined, 'FAST');
                doc.addImage(canvasImageLM, 'JPEG', 472, customYAxis, 264, TableSize, undefined, 'FAST');
            }
        }
        doc.addPage();
        doc.setFontSize(18);

        if (userInfo.UserType === "DistrictAdmin" ||
            userInfo.UserType === "restricteddistrictadmin") {
            doc.text(`${districtDescription} School Usage Report`, 290, 30);
        } else if (userInfo.UserType === "GroupAdmin") {
            doc.text(`${classUsageData.className} Student Usage Report`, 290, 30);
        } else {
            doc.text(`${(districtDescription).trim()} Class Usage Report`, 290, 30);
        }
        // doc.text(chartTile + " Weekly Report - Minutes Played details", 280, 50);
        doc.addImage(newCanvasImg1, 'JPEG', 136, 50, 520, 260, undefined, 'FAST');
        // doc.text(chartTile + " Weekly Report - Problems solved details", 280, 300);
        doc.addImage(newCanvasImg2, 'JPEG', 136, 330, 520, 260, undefined, 'FAST');

        doc.addPage();

        // doc.text(chartTile + " Weekly Report - Games Completed details", 280, 20);
        doc.addImage(newCanvasImg3, 'JPEG', 136, 30, 520, 260, undefined, 'FAST');
        // doc.text(chartTile + " Weekly Report - Levels Mastered details", 280, 300);
        doc.addImage(newCanvasImg4, 'JPEG', 136, 320, 520, 260, undefined, 'FAST');

        if (userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin")) {
            doc.save('DistrictReports.pdf');
        } else if (userInfo && (userInfo.UserType === "Supergrouptechadmin" || userInfo.UserType === "restrictedschooladmin" || userInfo.UserType === "SuperGroupAdmin")) {
            doc.save('SchoolReports.pdf');
        } else {
            doc.save('TeacherReports.pdf');
        }
        setLoading(false);
    }

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    const welcomCardData = []

    return (
        <ThemeProvider theme={mdTheme}>
            <Box className='districtStyle'>
                <Backdrop
                    sx={{
                        background: "rgb(0 0 0 / 30%)",
                        color: '#fff',
                        zIndex: "99999999"
                    }}
                    open={loading}
                >
                    <CircularProgress style={{ color: "#008000" }} />
                </Backdrop>
                {
                    userInfo &&
                        userInfo.UserType === "GroupAdmin" &&
                        location.pathname &&
                        location.pathname.split("/") &&
                        location.pathname.split("/").length > 3 &&
                        location.pathname.split("/")[2] === "teacher-dashboard" ? (
                        <CustomHeader
                            licenseStatus={licenseStatus}
                            needBreadCrumb={true}
                            previousPageLabel={"Dashboard"}
                            previousPageLabel1={"Classes"}
                            currentPageLabel={"Weekly Reports"}
                            onClickPrevPageLabel={() => { history.push("/teacher/teacher-dashboard") }}
                            onClickPrevPageLabel1={() => { history.push(`/teacher/teacher-dashboard/class-details/${url.id}`) }}
                        />
                    ) : (
                        <CustomHeader
                            licenseStatus={licenseStatus}
                            headerLabel={"Weekly Reports"}
                        />
                    )
                }

                {
                    (userInfo && userInfo.UserType === "GroupAdmin") ? (
                        <CustomWelcomeCard
                            isSelectCard={true}
                            selectComponent={(
                                <Box className='weeklyReportWrapper'>
                                    <Box className="WRClassName">Select Class</Box>
                                    <FormControl size="small" variant="standard" className="SchoolListSelect">
                                        <Select
                                            labelId="SchoolList"
                                            variant="standard"
                                            value={classId}
                                            onChange={handleSchoolList}
                                            label="Class List"
                                            color="info"
                                        >
                                            {
                                                listAllClasses.map((data, index) => {
                                                    return (
                                                        <MenuItem key={`class-list-${index}`} value={data.GroupID}>
                                                            {data.Name}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}
                            welcomCardData={[{
                                label: "Total Students",
                                icon: <User className="welcomeCardIcons" />,
                                value: lineChartData && Array.isArray(lineChartData.xAxisLabel) ? lineChartData.xAxisLabel.length : 0
                            }]}
                        />
                    ) :
                        (userInfo && (
                            userInfo.UserType === "SuperGroupAdmin" ||
                            userInfo.UserType === "restrictedschooladmin"
                        )) ?
                            <Box className="noHeaderButton" />
                            :
                            (userInfo && (
                                userInfo.UserType === "Supergrouptechadmin"
                            )) ? (
                                <CustomWelcomeCard
                                    welcomCardData={welcomCardData}
                                    cardLabel={"School Name"}
                                    cardValue={`${userInfo && userInfo.SuperGroupName}`}
                                />
                            ) :
                                (
                                    userInfo.UserType === "DistrictAdmin" ||
                                    userInfo.UserType === "restricteddistrictadmin"
                                ) ? (
                                    <CustomWelcomeCard
                                        welcomCardData={welcomCardData}
                                        cardLabel={"District Name"}
                                        cardValue={`${districtDashboardData && districtDashboardData.District ? districtDashboardData.District : ""}`}
                                    />
                                )
                                    : (
                                        <CustomWelcomeCard
                                            welcomCardData={welcomCardData}
                                            cardLabel={"Class Name"}
                                            cardValue={`${classUsageData.className}`}
                                        />
                                    )
                }

                <Box id="PDFContent" className="nsMainContent">
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            {/* {
                                isSchoolLeaderboard ? "Student Leaderboard" : "Class Leaderboard"
                            } */}
                            {/* </Box> */}
                            {/* <Box className='WeeklyReportSubTextTitle'> */}
                            {
                                (userInfo && (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin")) ?
                                    `${districtDescription} Weekly Report` :
                                    userInfo && (userInfo.UserType === "Supergrouptechadmin" ||
                                        userInfo.UserType === "restrictedschooladmin" ||
                                        userInfo.UserType === "SuperGroupAdmin") ?
                                        `${(districtDescription).trim()} Weekly Report` :
                                        userInfo && userInfo.UserType === "GroupAdmin" ?
                                            `${currentClassName} Weekly Report` :
                                            ""
                            }
                        </Box>
                        <Box className='WeeklyReportFromToDate'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From Date"
                                    // format="MM/dd/yyyy"
                                    inputFormat="dd/MM/yyyy"
                                    value={weeklyData.fromDate}
                                    maxDate={maxDate}
                                    onChange={handleFromDate}
                                    onBlur={() => {
                                        getWeeklyReport();
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            className='fromDate'
                                            required
                                            onKeyDown={e => e.preventDefault()}
                                            {...params}
                                            variant="standard"
                                        />
                                    }
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To Date"
                                    inputFormat="dd/MM/yyyy"
                                    // format="MM/dd/yyyy"
                                    value={weeklyData.toDate}
                                    minDate={minDate}
                                    onChange={handleToDate}
                                    onBlur={() => {
                                        getWeeklyReport();
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            className='toDate'
                                            required
                                            onKeyDown={e => e.preventDefault()}
                                            {...params}
                                            variant="standard"
                                        />
                                    }
                                />
                            </LocalizationProvider>
                            <Tooltip
                                title="Download Report"
                                arrow
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            background: "#0e1318",
                                        },
                                    },
                                }}
                                onClick={() => {
                                    generate();
                                }}
                            >
                                <IconButton
                                    id={`download-button`}
                                >
                                    <Download size={32} className="WRIconOnly" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={{ padding: "2% 6%" }}>
                        <Grid container spacing={0} sx={{ width: "100%", justifyContent: "center" }}>
                            {
                                barChartData?.labelValues.map((data, index) => {
                                    return <Grid key={`weeklyReport-${index}`} item xs={12} sm={12} md={12} lg={6} sx={{
                                        cursor: "pointer",
                                        "& .MuiPaper-root": {
                                            boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 33%), 0px 1px 1px 0px rgb(0 0 0 / 33%), 0px 1px 3px 3px rgb(0 0 0 / 12%)"
                                        },
                                    }}>

                                        <Item>
                                            <NSBarChart
                                                titlePosition="bottom"
                                                title={barChartData.chartTitle[index]}
                                                dataSetName={barChartData.labelNames}
                                                dataSetData={data}
                                                id={`PdfReport${index}`}

                                            />
                                        </Item>
                                    </Grid>
                                })
                            }
                        </Grid>
                        <Divider sx={{ margin: "16px 0" }} />
                        {
                            (userInfo?.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin") ? (
                                <>
                                    <Grid container spacing={0} sx={{ width: "100%", justifyContent: "center" }}>
                                        <Box className='WeeklyReportSubText'>
                                            {`${districtDescription} School Leaderboard`}
                                        </Box>
                                    </Grid>
                                    <Grid container spacing={0} sx={{ width: "100%", justifyContent: "center", background: "#fff" }}>
                                        <Grid
                                            key={`schoolLeaderboard1`}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            sx={{
                                                cursor: "pointer",
                                                "& .MuiPaper-root": {
                                                    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 33%), 0px 1px 1px 0px rgb(0 0 0 / 33%), 0px 1px 3px 3px rgb(0 0 0 / 12%)"
                                                },
                                                background: "#fff"
                                            }}
                                            id="PdfReportSLMP"
                                        >
                                            {
                                                schoolLeaderBoard ?
                                                    (
                                                        <Item>
                                                            <table className="schoolLeaderboardTable">
                                                                <thead className="MP">
                                                                    <tr>
                                                                        <th colSpan={2}>Minutes Played</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        schoolLeaderBoard?.minutesPlayed?.map((value, index) => {
                                                                            return (
                                                                                <tr key={`${value.schoolName}+${index}+trmp`}>
                                                                                    <td>{value.schoolName}</td>
                                                                                    <td>{value.data}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Item>
                                                    )
                                                    : null
                                            }

                                        </Grid>
                                        <Grid
                                            key={`schoolLeaderboard2`}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            sx={{
                                                cursor: "pointer",
                                                "& .MuiPaper-root": {
                                                    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 33%), 0px 1px 1px 0px rgb(0 0 0 / 33%), 0px 1px 3px 3px rgb(0 0 0 / 12%)"
                                                },
                                            }}
                                            id="PdfReportSLPS"
                                        >
                                            {
                                                schoolLeaderBoard ?
                                                    (
                                                        <Item>
                                                            <table className="schoolLeaderboardTable">
                                                                <thead className="PS">
                                                                    <tr>
                                                                        <th colSpan={2}>Problems Solved</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        schoolLeaderBoard?.problemsSolved?.map((value, index) => {
                                                                            return (
                                                                                <tr key={`${value.schoolName}+${index}+trps`}>
                                                                                    <td>{value.schoolName}</td>
                                                                                    <td>{value.data}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Item>
                                                    )
                                                    : null
                                            }
                                        </Grid>
                                        <Grid
                                            key={`schoolLeaderboard3`}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            sx={{
                                                cursor: "pointer",
                                                "& .MuiPaper-root": {
                                                    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 33%), 0px 1px 1px 0px rgb(0 0 0 / 33%), 0px 1px 3px 3px rgb(0 0 0 / 12%)"
                                                },
                                            }}
                                            id="PdfReportSLGC"
                                        >
                                            {
                                                schoolLeaderBoard ?
                                                    (
                                                        <Item>
                                                            <table className="schoolLeaderboardTable">
                                                                <thead className="GC">
                                                                    <tr>
                                                                        <th colSpan={2}>Games Completed</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        schoolLeaderBoard?.gamesCompleted?.map((value, index) => {
                                                                            return (
                                                                                <tr key={`${value.schoolName}+${index}+trgc`}>
                                                                                    <td>{value.schoolName}</td>
                                                                                    <td>{value.data}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Item>
                                                    )
                                                    : null
                                            }
                                        </Grid>
                                        <Grid
                                            key={`schoolLeaderboard4`}
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={6}
                                            sx={{
                                                cursor: "pointer",
                                                "& .MuiPaper-root": {
                                                    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 33%), 0px 1px 1px 0px rgb(0 0 0 / 33%), 0px 1px 3px 3px rgb(0 0 0 / 12%)"
                                                },
                                            }}
                                            id="PdfReportSLLM"
                                        >
                                            {
                                                schoolLeaderBoard ?
                                                    (
                                                        <Item>
                                                            <table className="schoolLeaderboardTable">
                                                                <thead className="LM">
                                                                    <tr>
                                                                        <th colSpan={2}>Levels Mastered</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        schoolLeaderBoard?.levelsMastered?.map((value, index) => {
                                                                            return (
                                                                                <tr key={`${value.schoolName}+${index}+trlm`}>
                                                                                    <td>{value.schoolName}</td>
                                                                                    <td>{value.data}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Item>
                                                    )
                                                    : null
                                            }
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ margin: "16px 0" }} />
                                </>
                            ) : null
                        }
                        <Box className='WeeklyReportSubText' sx={{ marginBottom: "8px" }}>
                            {
                                (
                                    userInfo &&
                                    (userInfo.UserType === "DistrictAdmin" || userInfo.UserType === "restricteddistrictadmin")
                                ) ? `${districtDescription} School Usage Report` :
                                    (
                                        userInfo.UserType === "Supergrouptechadmin" ||
                                        userInfo.UserType === "restrictedschooladmin" ||
                                        userInfo.UserType === "SuperGroupAdmin"
                                    ) ? `${(districtDescription).trim()} Class Usage Report` :
                                        userInfo.UserType === "GroupAdmin" ? `${classUsageData.className} Student Usage Report` :
                                            ""
                            }
                        </Box>
                        <Grid container spacing={0} sx={{ width: "100%", justifyContent: "center" }}>
                            {
                                lineChartData?.labelValues.map((data, index) => {
                                    return (
                                        <Grid key={`weeklyReport1${index}`} item xs={12} sm={12} md={12} lg={10} sx={{
                                            cursor: "pointer",
                                            "& .MuiPaper-root": {
                                                boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 33%), 0px 1px 1px 0px rgb(0 0 0 / 33%), 0px 1px 3px 3px rgb(0 0 0 / 12%)"
                                            },
                                        }}>
                                            <Item>
                                                <NSLineChart
                                                    titlePosition="bottom"
                                                    title={lineChartData.chartTitle[index]}
                                                    dataSetName={lineChartData.labelNames}
                                                    dataSetData={data}
                                                    xAxisLabel={lineChartData.xAxisLabel ? lineChartData.xAxisLabel : []}
                                                    id={`PdfReport2${index}`}
                                                />
                                            </Item>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}