import * as React from 'react';
import { Button } from '@carbon/react';
import { Alarm } from '@carbon/icons-react';

export default function CustomTableButton({
    id,
    buttonLabel,
    onClickButton,
    buttonIcon,
    isInActive
}) {

    return (
        <button
            id={id}
            className={isInActive ? 'customTableButtonInActive' : 'customTableButton'}
            onClick={onClickButton}
        >
            {buttonIcon}
            {buttonLabel}
        </button>
    );
} 