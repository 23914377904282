// import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import {
  GridActionsCellItem,
} from "@mui/x-data-grid";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ManageDistrict.css";
import { useSelector, useDispatch } from "react-redux";
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { makeStyles } from "@material-ui/core";
import Images from "../../constants/images";
import allActions from "../../redux/ducks/actions";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Tooltip,
  Stack,
  Alert,
  Snackbar,
  Backdrop,
  Switch,
} from '@mui/material';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CircularProgress from '@mui/material/CircularProgress';
import { DEACTIVATED, ACTIVATED } from "../../constants";
import { Edit, OrderDetails } from '@carbon/icons-react';
import CustomTableButton from '../../Components/CustomTableButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


// Modal
const useStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: "blur(4px)",
    backgroundColor: "rgba(30,41,51,.45)",
  },
}));

function DistrictTable(props) {
  const Btncolor = "#2d8f3c";
  const editAction = "#b58119";
  const history = useHistory();
  const { listAllDistrict } = useSelector((state) => state.data);
  const [tableRow, setTableRow] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  // Loading
  const [loading, setLoading] = React.useState(false);

  // Snack Message
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "update",
  });
  const { snackStatus, snackMessageFor } = snackbar;
  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  useEffect(() => {
    setTableRow(listAllDistrict);
  }, [listAllDistrict]);

  // Active or Deactive Modal
  const [openDialog, setOpenDialog] = React.useState(false);
  const [status, setStatus] = React.useState(0);
  const [rowId, setRowId] = React.useState(0);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const columns = [
    "District Id",
    "District Name",
    "Admin Name",
    "Total Schools",
    "Total Students",
    "Total Teachers",
    "Source",
    {
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box className='districtAction'>
              <Tooltip
                title="Edit District"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value.id;
                  if (value.source === "ClassLink") {
                    history.push(`/admin/admin-manage-district/classlink-update/${rowId}`);
                  } else {
                    history.push(`/admin/admin-manage-district/update/${rowId}`);
                  }
                }}
              >
                <Box className='customActionIcon'>
                  <Edit size={16} color='#FFF' />
                </Box>
              </Tooltip>

              <Tooltip title={value.status === 1 ? "Active" : "Inactive"}>
                <Switch
                  checked={value.status === 1 ? true : false}
                  color="info"
                  onClick={() => {
                    let rowId = value.id;
                    setStatus(value.status)
                    setRowId(rowId)
                    handleDialogOpen();
                  }}
                />
              </Tooltip>

              <Tooltip
                title="Details"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let rowId = value.id;
                  history.push(`/admin/admin-manage-district/details/${rowId}`);
                }}
              >
                <Box className='customActionIcon'>
                  <OrderDetails size={16} color='#FFF' />
                </Box>
              </Tooltip>
            </Box>
          )
        }
      }
    }
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar
          key="SnackBar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackStatus}
          onClose={handleSnackbarClose}
        >
          <Alert
            variant="filled"
            severity="success"
            sx={{
              "&.MuiPaper-root": {
                background: "#00c853",
                color: "#fff",
              },
            }}
            action={
              <CloseRoundedIcon
                sx={{ cursor: "pointer", color: "#fff" }}
                onClick={() => handleSnackbarClose()}
                color="inherit"
                size="small"
              />
            }
          >
            {
              snackMessageFor === "activated"
                ? ACTIVATED
                : snackMessageFor === "deactivated"
                  ? DEACTIVATED
                  : null
            }
          </Alert>

        </Snackbar>
      </Stack>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{
          style: {
            padding: ".7%",
          },
        }}
      >
        <DialogTitle>
          <Box className="AssignmentDeleteHeader">
            {status ? "Deactivate District" : "Activate District"}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="deleteContent">
            {status ? "Do you want to Deactivate District?" : "Do you want to Activate District?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomTableButton
            isInActive={true}
            buttonLabel={"Cancel"}
            onClickButton={handleDialogClose}
          />
          <CustomTableButton
            isInActive={false}
            buttonLabel={"ok"}
            onClickButton={() => {
              handleDialogClose();
              let setStatus = status === 1 ? 0 : 1;
              setLoading(true);
              props.handleLoading(loading)
              dispatch(allActions.activeAndDeactive(rowId, setStatus, (response) => {
                if (response.MessageID === 0) {
                  setLoading(false);
                  props.handleLoading(loading)
                  setStatus === 1
                    ? handleSnackbarClick("activated")
                    : handleSnackbarClick("deactivated")
                } else {
                  console.error(response, "response");
                }
              }));
            }}
          />
        </DialogActions>
      </Dialog>
      <MUIDataTable
        title="Districts"
        data={tableRow && Array.isArray(tableRow) && tableRow.map(data => {
          const TableValue = {
            id: data.ID,
            source: data.Source,
            status: data.Status,
          }
          return [
            data.Name,
            data.Desc,
            data.AdminName,
            data.TotalSchools,
            data.TotalStudents,
            data.TotalTeachers,
            data.Source,
            TableValue,
          ]
        })}
        columns={columns}
        options={options}
      />
    </React.Fragment>

  );
}
export default DistrictTable;
