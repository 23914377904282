import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import allActions from "../../redux/ducks/actions";
import {
  Box,
  Breadcrumbs,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import StoreIcon from '@mui/icons-material/Store';
import "../ManageDistrict/ManageDistrict.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomHeader from "../../Components/CustomHeader";

export default function MUPurchaseHistory() {

  const dispatch = useDispatch(); // Dispatch
  const { userInfo } = useSelector(state => state.data);
  const history = useHistory(); //History
  const url = useParams();

  const [purchaseHistory, setPurchaseHistory] = useState([]);

  useEffect(() => {
    dispatch(allActions.getUserDistrictProductHistory(Number(url.id), (response) => {
      setPurchaseHistory(response)
    }
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    "Product ID",
    "Start Date",
    "End Date",
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };



  const [licenseStatus, setLicenseStatus] = React.useState(false);
  React.useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <Box className='districtStyle'>

      <CustomHeader
        hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
        licenseStatus={licenseStatus}
        needBreadCrumb={true}
        previousPageLabel={"Manage User License"}
        currentPageLabel={"User License Purchase History"}
        onClickPrevPageLabel={() => { history.push("/admin/admin-manage-user-license") }}
      />

      <Box className="noHeaderButton" />

      <Box className="nsMainContent">
        <MUIDataTable
          title="Purchase History"
          data={purchaseHistory && purchaseHistory.map(data => {
            return [
              data.ProductID,
              data.SubscriptionDate,
              data.ExpirationDate,
            ]
          })}
          columns={columns}
          options={options}
        />
      </Box>

    </Box>
  )
}