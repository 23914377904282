import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Breadcrumbs,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
  FormControl,
  FormLabel,
  Radio,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Switch,
  Backdrop,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from "react-redux";
import {
  COUNTRIES,
  COUNTRY_EMPTY,
  AGE_EMPTY,
  EMAIL_EMPTY,
  FIRST_NAME_EMPTY,
  LAST_NAME_EMPTY,
  PASSWORD_EMPTY,
  USER_NAME_EMPTY,
  USER_TYPE_EMPTY,
  EMAIL_INVALID,
  CLASS_NAME_EXIST,
  USER_UPDATED_SUCCESS,
} from "../../constants";
import Creators from "../../redux/ducks/actions";
// import "./ManageClasses.css";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import { Close, Edit } from "@carbon/icons-react";

function UpdateUsers(props) {
  const dispatch = useDispatch(); // Dispatch
  const history = useHistory();
  const { userInfo, schoolData } = useSelector(state => state.data); //state data
  const [classError, setClassError] = useState(false);

  // Snack Message
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "update",
  });
  const { snackStatus, snackMessageFor } = snackbar;
  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  const url = useParams(); //URl
  const location = useLocation(); //URl

  const [form, setForm] = useState({
    userType: "",
    classesList: "",
    age: "",
    firstName: "",
    lastName: "",
    loginName: "",
    emailId: "",
    password: "",
    isActive: "",
  });
  const [emptyError, setEmptyError] = useState({
    userType: false,
    classesList: false,
    age: false,
    firstName: false,
    lastName: false,
    loginName: false,
    emailId: false,
    password: false,
    isActive: false,
  });
  const [countryData, setCountryData] = useState({
    code: "",
    label: "",
    phone: "",
  });
  const [emailValidation, setEmailValidation] = useState("");
  const [isRestrictedAdmin, setIsRestrictedAdmin] = useState(false);

  // Loading
  const [loading, setLoading] = React.useState(false);

  //   update Button
  const handleUpdateClick = () => {
    setLoading(true)
    setClassError(false);
    const checkEmptyValue = { ...emptyError };
    let submit = true;
    // Checking empty data
    for (let items in form) {
      if (form[items] === "") {
        submit = false;
        checkEmptyValue[items] = true;
      }
    }

    if (emailValidation !== "") {
      submit = false;
    }

    if (!submit) {
      setEmptyError(checkEmptyValue);
      setLoading(false)
    } else {
      form.country = countryData.label;
      if (
        form.userType === "Teacher" &&
        form?.classesList?.filter(data => data.Active === 1).length === 0
      ) {
        setClassError(true);
        return setLoading(false)
      }
      dispatch(
        Creators.updateRestrictedAdminUsersById(form, (response) => {
          if (response.MessageID === 1 || response.MessageID === 2) {
            // Class name already exist Error
            setLoading(false)
            handleSnackbarClick("duplicateError");
            return null;
          } else if (response.MessageID === 0) {
            // Class Update Success Message
            setLoading(false)
            handleSnackbarClick("update");
            if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
              setTimeout(() => {
                history.push("/school/school-user-logins");
              }, 1000);
            } else {
              setTimeout(() => {
                history.push("/district/district-user-logins");
              }, 1000);
            }
          } else if (response.MessageID === 3) {
            setLoading(false)
            handleSnackbarClick("exception");
          } else {
            setLoading(false)
            console.error(response, "response");
          }
        })
      );
    }
  };

  // handle update Domain
  const handleOnChangeUpdateUsers = (e) => {
    if (e.target.id === "age" && e.target.value < 0) {
      e.preventDefault()
    } else {
      if (e.target.value !== "") {
        setEmptyError((state) => ({
          ...state,
          [e.target.id]: false,
        }));
      }

      setForm((state) => ({
        ...state,
        [e.target.id]: e.target.value,
      }));

      if (e.target.id === "emailId" && e.target.value !== "") {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
          setEmailValidation("");
          setForm((state) => ({
            ...state,
            [e.target.id]: e.target.value,
          }));
        } else {
          setEmptyError((state) => ({
            ...state,
            [e.target.id]: true,
          }));

          setEmailValidation(EMAIL_INVALID);
          return null;
        }
      }
    }
  };

  //   setting data in field
  useEffect(() => {
    dispatch(
      Creators.getRestrictedAdminUsersUsersById(url.id, (response) => {
        let rowData = response;
        const getCountry = COUNTRIES.filter(
          (item) => item.label.toLowerCase() === rowData.Country.toLowerCase()
        );
        const rowCountry = getCountry[0];
        setCountryData((state) => ({
          ...state,
          code: rowCountry ? rowCountry.code : "",
          label: rowCountry ? rowCountry.label : "",
          phone: rowCountry ? rowCountry.phone : "",
        }));

        if (rowData.UserType === "DistrictAdmin") {
          rowData.UserType = "District Admin"
        } else if (rowData.UserType === "GroupAdmin") {
          rowData.UserType = "Teacher"
        } else if (rowData.UserType === "Supergrouptechadmin") {
          rowData.UserType = "School Admin"
        } else if (rowData.UserType === "SuperGroupAdmin") {
          rowData.UserType = "Principal"
        } else if (rowData.UserType === "restricteddistrictadmin") {
          rowData.UserType = "Restricted District Admin"
        } else if (rowData.UserType === "restrictedschooladmin") {
          rowData.UserType = "Restricted School Admin"
        }

        setForm((state) => ({
          ...state,
          userType: rowData.UserType,
          classesList: rowData.Classes,
          age: rowData.Age,
          firstName: rowData.FName,
          lastName: rowData.LName,
          loginName: rowData.LoginName,
          emailId: rowData.EmailID,
          password: rowData.Pwd,
          isActive: rowData.ActiveFlag,
          id: url.id,
        }));
      })
    );
  }, [url, dispatch]);

  // user activation validation
  useEffect(() => {
    let path = location.pathname.split("/");
    if (path.includes("restricted-admin")) {
      setIsRestrictedAdmin(true)
    } else {
      setIsRestrictedAdmin(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])


  return (
    <>
      {/* Action Status */}
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar
          key="SnackBar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackStatus}
          onClose={handleSnackbarClose}
        >
          {snackMessageFor === "duplicateError" ? (
            <Alert
              variant="filled"
              severity="warning"
              sx={{
                "&.MuiPaper-root": {
                  background: "#ffc107",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {CLASS_NAME_EXIST}
            </Alert>
          ) : (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                "&.MuiPaper-root": {
                  background: "#00c853",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {snackMessageFor === "update"
                ? USER_UPDATED_SUCCESS
                : snackMessageFor === "exception"
                  ? "Exception"
                  : ""}
            </Alert>
          )}
        </Snackbar>
      </Stack>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>
      <Box className='districtStyle'>
        <CustomHeader
          licenseStatus={licenseStatus}
          needBreadCrumb={true}
          previousPageLabel={"User Logins"}
          currentPageLabel={"Edit User"}
          onClickPrevPageLabel={() => {
            const navigateTo = userInfo && userInfo.UserType === "Supergrouptechadmin" ? "/school/school-user-logins" : "/district/district-user-logins"
            history.push(navigateTo)
          }}
        />
        <Box className="noHeaderButton" />
        <Box className='nsMainContent'>
          <Box className="nsMainHeader">
            <Box className="nsMainTextHeader">
              Edit User
            </Box>
            <Box className="customHeaderButton" />
          </Box>
          <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
            <Box className="createDomainParent">
              <Box className="LessonBotContent">
                <Box className="fieldParentCU">
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">User Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="loginName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.loginName}
                      onChange={handleOnChangeUpdateUsers}
                      error={emptyError.loginName}
                      helperText={emptyError.loginName ? USER_NAME_EMPTY : ""}
                      disabled={true}
                    />
                  </Box>
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Password</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      size="small"
                      id="password"
                      label=""
                      type="text"
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.password}
                      onChange={handleOnChangeUpdateUsers}
                      error={emptyError.password}
                      helperText={emptyError.password ? PASSWORD_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Email</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="emailId"
                      label=""
                      type="email"
                      className="fieldStyle"
                      value={form.emailId}
                      onChange={handleOnChangeUpdateUsers}
                      error={emptyError.emailId}
                      helperText={
                        emptyError.emailId
                          ? emailValidation !== ""
                            ? EMAIL_INVALID
                            : EMAIL_EMPTY
                          : ""
                      }
                    />
                  </Box>
                </Box>
                <Box className="fieldParentCU">
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">First Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="firstName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.firstName}
                      onChange={handleOnChangeUpdateUsers}
                      error={emptyError.firstName}
                      helperText={emptyError.firstName ? FIRST_NAME_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Last Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="lastName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.lastName}
                      onChange={handleOnChangeUpdateUsers}
                      error={emptyError.lastName}
                      helperText={emptyError.lastName ? LAST_NAME_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Age</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="age"
                      label=""
                      type="number"
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.age}
                      onKeyDown={(e) => {
                        let charCode = (e.which) ? e.which : e.keyCode;
                        if (charCode === 37 || charCode === 39) {
                          return;
                        } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault()
                        }
                      }}
                      onChange={handleOnChangeUpdateUsers}
                      error={emptyError.age}
                      helperText={emptyError.age ? AGE_EMPTY : ""}
                    />
                  </Box>
                </Box>
                <Box className="fieldParentCU">
                  <Box className="fieldChildCU">
                    <Box className="fieldLabel">Country</Box>
                    <Autocomplete
                      size="small"
                      id="country"
                      options={COUNTRIES}
                      value={countryData}
                      onChange={(_, y) => setCountryData(y)}
                      getOptionLabel={(option) => option.label}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          const present = COUNTRIES.some(
                            (item) => item.label === e.target.value
                          );
                          if (present) {
                            setEmptyError((state) => ({
                              ...state,
                              country: false,
                            }));
                            setForm((state) => ({
                              ...state,
                              country: e.target.value,
                            }));
                          } else {
                            setForm((state) => ({
                              ...state,
                              country: "",
                            }));
                          }
                        } else {
                          setForm((state) => ({
                            ...state,
                            country: "",
                          }));
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          required
                          variant="outlined"
                          sx={{
                            '& label.Mui-focused': {
                              color: "#2d8f3c",
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#2d8f3c',
                            },
                          }}
                          size="small"
                          {...params}
                          label=""
                          value={form.country}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          error={emptyError.country}
                          helperText={emptyError.country ? COUNTRY_EMPTY : ""}
                        />
                      )}
                    />
                  </Box>

                  {userInfo && (
                    userInfo.UserType === "DistrictAdmin" ||
                    userInfo.UserType === "Supergrouptechadmin"
                  ) ?
                    <Box className="fieldChildCU">
                      <Box className="fieldLabel">User Type</Box>
                      <TextField
                        required
                        variant="outlined"
                        sx={{
                          '& label.Mui-focused': {
                            color: "#2d8f3c",
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#2d8f3c',
                          },
                        }}
                        size="small"
                        id="userType"
                        label=""
                        type="text"
                        className="fieldStyle"
                        autoComplete="off"
                        value={form.userType}
                        onChange={handleOnChangeUpdateUsers}
                        error={emptyError.userType}
                        helperText={emptyError.userType ? USER_TYPE_EMPTY : ""}
                        disabled={true}
                      />
                    </Box> : null
                  }

                  {
                    userInfo?.UserType === "DistrictAdmin" ?
                      <Box className="fieldChildCU">
                        <Box className="fieldLabel">School Name</Box>
                        <TextField
                          required
                          variant="outlined"
                          sx={{
                            '& label.Mui-focused': {
                              color: "#2d8f3c",
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: '#2d8f3c',
                            },
                          }}
                          size="small"
                          id="schoolName"
                          label=""
                          type="text"
                          className="fieldStyle"
                          autoComplete="off"
                          value={schoolData?.label ?? ""}
                          onChange={handleOnChangeUpdateUsers}
                          disabled={true}
                        />
                      </Box> : null
                  }
                  {console.log(userInfo, "userInfo")}
                  {
                    isRestrictedAdmin ? <Box className="fieldChildCU"></Box> :
                      <Box className="fieldChildCU">
                        <FormControl component="fieldset" variant="outlined">
                          <FormControlLabel
                            label="Status *"
                            labelPlacement="top"
                            control={
                              <Switch
                                checked={form.isActive}
                                color="success"
                                onClick={(e) => {
                                  setForm((state) => ({
                                    ...state,
                                    isActive: e.target.checked ? true : false,
                                  }));
                                }}
                              />
                            }
                          />
                        </FormControl>
                      </Box>
                  }
                </Box>
                {userInfo && userInfo.UserType === "Supergrouptechadmin" ? null :
                  <Box className="fieldParentCURadioButton">
                    <Box className="fieldChildCURadioButton">
                      {form.userType === "Student" ?
                        <React.Fragment>
                          <FormLabel
                            id="Class"
                            color="success"
                            sx={{
                              width: "100%"
                            }}
                          >
                            Class *
                          </FormLabel>
                          {
                            form.classesList &&
                            form.classesList.length > 0 &&
                            form.classesList.map(item =>
                              <FormControlLabel
                                key={`${item.ClassName}-tem`}
                                label={item.ClassName}
                                className="checkRadioStyle"
                                sx={{
                                  margin: 0,
                                  // width: "20%",
                                }}
                                control={
                                  <Radio
                                    color="success"
                                    checked={item.Active === 0 ? false : true}
                                    onChange={(e) => {
                                      const newState = form.classesList.map(datas => {
                                        if (item.GroupID === datas.GroupID) {
                                          return {
                                            ...datas,
                                            Active: e.target.checked ? 1 : 0
                                          };
                                        } else {
                                          return {
                                            ...datas,
                                            Active: 0
                                          };
                                        }
                                      });
                                      setForm((state) => ({
                                        ...state,
                                        classesList: newState
                                      }));
                                    }}
                                  />
                                }
                              />)
                          }
                        </React.Fragment>
                        : form.userType === "Teacher" ?
                          <React.Fragment>
                            <FormLabel
                              id="Class"
                              color="success"
                              sx={{
                                width: "100%"
                              }}
                            >
                              Class *
                            </FormLabel>
                            {
                              form.classesList &&
                              form.classesList.length > 0 &&
                              form.classesList.map(item =>
                                <FormControlLabel
                                  label={item.ClassName}
                                  className="checkRadioStyle"
                                  sx={{
                                    margin: 0,
                                    // width: "20%",
                                  }}
                                  control={
                                    <Checkbox
                                      checked={item.Active === 0 ? false : true}
                                      onChange={(e) => {
                                        const newState = form.classesList.map(datas => {
                                          if (item.GroupID === datas.GroupID) {
                                            return {
                                              ...datas,
                                              Active: e.target.checked ? 1 : 0
                                            };
                                          }
                                          return datas;
                                        });
                                        setForm((state) => ({
                                          ...state,
                                          classesList: newState
                                        }));
                                      }}
                                    />
                                  }
                                />)
                            }
                            <Box color={"red"} sx={{ display: classError ? "block" : "none" }}>
                              Atleast one class should be selected.
                            </Box>
                          </React.Fragment>
                          : null
                      }
                    </Box>
                  </Box>
                }
                <Box className="createButtonParent" style={{ marginRight: "3%" }}>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={true}
                      buttonLabel={"Cancel"}
                      onClickButton={() => {
                        if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                          history.push("/school/school-user-logins");
                        } else {
                          history.push("/district/district-user-logins");
                        }
                      }}
                      buttonIcon={<Close size={16} />}
                    />
                  </Box>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={false}
                      buttonLabel={"Update"}
                      onClickButton={handleUpdateClick}
                      buttonIcon={<Edit size={16} />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default UpdateUsers;
