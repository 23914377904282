import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
//Icons
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import PieChartIcon from '@mui/icons-material/PieChart';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarIcon from '@mui/icons-material/Star';
import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Menu,
    MenuItem,
    Dialog,
    IconButton,
    Tooltip,
    DialogContent,
    DialogActions,
    DialogTitle,
    Grid,
    Drawer,
    Badge,
} from "@mui/material";
import Logout from '@mui/icons-material/Logout';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import allActions from "../redux/ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import Images from "../constants/images";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from "react-router-dom";
import { MENU } from "../constants";
import ROUTES from "../routes";
// import Dashboard from '../Dashboard/Dashboard';
import "./LayoutStyle.css";
import CustomBrandName from './CustomBrandName';
import CustomMenuList from './CustomMenuList';

// route config
const getUserRoutes = (routes) => {
    return routes.map((prop, key) => {
        if (prop.layout === "/district") {
            return (
                <Route
                    exact
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                />
            );
        } else {
            return null;
        }
    });
};

const drawerWidth = 280;


const MDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            overflow: "auto",
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

export default function DistrictLayout() {
    const { userInfo, districtDashboardData, notificationLength } = useSelector((state) => state.data);
    const [licenseDetails, setLicenseDetails] = React.useState(false);
    // Menu
    const history = useHistory(); //   HIstory
    const { url } = useRouteMatch(); //   Route
    const location = useLocation();
    const CurrentURL = location.pathname.split("/")[2];
    const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch(); // dispatch

    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openProfile = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const currentPath = location && location.pathname.split("/")[2];
        switch (currentPath) {
            case 'district-dashboard': document.title = "Number Sense - District Dashboard"
                break;
            case 'district-manage-schools': document.title = "Number Sense - Manage Schools"
                break;
            case 'district-manage-classes': document.title = "Number Sense - Manage Classes"
                break;
            case 'district-school-usage-data': document.title = "Number Sense - School Usage Data"
                break;
            case 'district-weekly-report': document.title = "Number Sense - Weekly Reports"
                break;
            case 'district-notification': document.title = "Number Sense - Notifications"
                break;
            case 'district-class-leaderboard': document.title = "Number Sense - Class Leaderboard"
                break;
            case 'district-student-leaderboard': document.title = "Number Sense - Student Leaderboard"
                break;
            case 'district-purchase-History': document.title = "Number Sense - Purchase History"
                break;
            case 'district-user-logins': document.title = "Number Sense - User Logins"
                break;
            default: document.title = "Number Sense - District Console";
                break;
        }
    }, [url, location])

    React.useEffect(() => {
        if (userInfo.UserType !== "DistrictAdmin" && userInfo.UserType !== "restricteddistrictadmin") {
            if (userInfo.UserType === "NSTechAdmin") {
                history.push(`/admin`);
            } else if (userInfo.UserType === "GroupAdmin") {
                history.push(`/teacher`);
            } else if (userInfo.UserType === "Supergrouptechadmin") {
                history.push(`/school`);
            } else {
                history.push(`/login`);
            }
        }
    })

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (districtDashboardData && districtDashboardData.length !== 0 && districtDashboardData.ExpirationDate) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = districtDashboardData.ExpirationDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
    }, [setLicenseStatus, districtDashboardData])

    const handleLicenseDetailsOpen = () => {
        setLicenseDetails(true);
    };

    const handleLicenseDetailsClose = () => {
        setLicenseDetails(false);
    };

    // 

    const [darwerLeft, setDarwerLeft] = React.useState(false);

    const toggleMobileDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDarwerLeft(open);
    };

    const DrawerListItems = () => (
        <List component="nav">
            {MENU.map((item, index) =>
                item.layout === url ? (
                    ((userInfo.UserType === "restricteddistrictadmin" && item.menuName === "User Logins") ||
                        (userInfo.UserType === "restricteddistrictadmin" && item.menuName === "Manage Schools") ||
                        (userInfo.UserType === "restricteddistrictadmin" && item.menuName === "Manage Classes"))
                        ? null :
                        <Tooltip
                            key={index}
                            title={open ? "" : item.menuName}
                            arrow
                            placement="right"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        background: "#0e1318",
                                    },
                                },
                            }}>
                            <ListItem
                                id={index}
                                key={index}
                                sx={{
                                    "&.MuiListItem-root": {
                                        background:
                                            item.menuURL === CurrentURL ? "#e2f6e5" : undefined,
                                        borderRadius: "12px",
                                    },
                                    "&.MuiListItem-root:hover": {
                                        // background: "#e2f6e5",
                                        transform: "scale(1.05)",
                                        boxShadow: "0px 15px 28px rgb(0 0 0 / 10%), 0px 5px 12px rgb(0 0 0 / 8%)",
                                        transition: "all 300ms ease",
                                    },
                                }}
                                button
                                onClick={() => history.push(item.layout + "/" + item.menuURL)}
                            >
                                <ListItemIcon>
                                    <Avatar
                                        sx={{
                                            overflow: "inherit",
                                            borderRadius: "0",
                                            background: "transparent",
                                            color: item.menuURL === CurrentURL ? "#0e1318" : "#000000",
                                        }}
                                    >
                                        {item.menuName === "Dashboard" ? (
                                            <DashboardRoundedIcon
                                                sx={{
                                                    color:
                                                        item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                }}
                                            />
                                        ) : item.menuName === "Manage Schools" ? (
                                            <SchoolRoundedIcon
                                                sx={{
                                                    color:
                                                        item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                }}
                                            />
                                        ) : item.menuName === "Manage Classes" ? (
                                            <ClassRoundedIcon
                                                sx={{
                                                    color:
                                                        item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                }}
                                            />
                                        ) : item.menuName === "School Usage Data" ? (
                                            <InsertChartRoundedIcon
                                                sx={{
                                                    color:
                                                        item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                }}
                                            />
                                        ) : item.menuName === "Weekly Reports" ? (
                                            <PieChartIcon
                                                sx={{
                                                    color:
                                                        item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                }}
                                            />
                                        ) : item.menuName === "Notifications" ?
                                            userInfo.ISNotification === 1 ? (
                                                <Badge badgeContent={notificationLength} color="error">
                                                    <NotificationsActiveRoundedIcon
                                                        sx={{
                                                            color:
                                                                item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                        }}
                                                    />
                                                </Badge>
                                            ) : (
                                                <NotificationsIcon
                                                    sx={{
                                                        color:
                                                            item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                    }}
                                                />
                                            )
                                            : item.menuName === "Class Leaderboard" ? (
                                                <StarIcon
                                                    sx={{
                                                        color:
                                                            item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                    }}
                                                />
                                            ) : item.menuName === "Student Leaderboard" ? (
                                                <LeaderboardRoundedIcon
                                                    sx={{
                                                        color:
                                                            item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                    }}
                                                />
                                            ) : item.menuName === "Purchase History" ? (
                                                <ShoppingBasketRoundedIcon
                                                    sx={{
                                                        color:
                                                            item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                    }}
                                                />
                                            ) : item.menuName === "User Logins" ? (
                                                <AccountCircleRoundedIcon
                                                    sx={{
                                                        color:
                                                            item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                                    }}
                                                />
                                            ) : null}
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.menuName}
                                    disableTypography
                                    sx={{
                                        color: item.menuURL === CurrentURL ? "#2d8f3c" : "#0e1318",
                                        fontWeight: item.menuURL === CurrentURL ? 700 : undefined,
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                ) : null
            )}
        </List>
    );

    const webView = "17rem";
    const mobileView = "4rem";

    const onClickMenuItem = (layout, menuURL) => {
        history.push(layout + "/" + menuURL)
    }

    const rdaMenu = ["User Logins", "Manage Schools", "Manage Classes"];

    const MenuList = userInfo && userInfo.UserType === "restricteddistrictadmin" ? MENU.filter(data => !rdaMenu.includes(data.menuName)) : MENU

    return (

        (<ThemeProvider theme={mdTheme}>
            {userInfo && districtDashboardData ?
                <Box className='layoutWrapper'>
                    <Box
                        className='layoutMenuList'
                        style={{
                            width: open ? webView : mobileView,
                        }}
                    >
                        <CustomBrandName
                            layoutName="District"
                            isMenuOpen={open}
                            onMenuToggle={toggleDrawer}
                        />
                        <CustomMenuList
                            isMenuOpen={open}
                            menuList={MenuList}
                            currentRole={url}
                            currentMenuURL={CurrentURL}
                            onMenuClick={onClickMenuItem}
                        />
                    </Box>
                    <Box className='layoutMainContent'
                        style={{
                            width: open ? `calc(100% - ${webView})` : `calc(100% - ${mobileView})`
                        }}
                    >
                        <Switch>
                            {getUserRoutes(ROUTES)}
                            <Redirect from="*" to="/district/district-dashboard" />
                        </Switch>
                    </Box>
                </Box>
                // <Box className='RootStyle'>
                //     <Box className='sb-topnav navbar navbar-expand' id="NavStyle">
                //         <Box className='NavBrandMenuStyle'>
                //             <Typography className='brandname navbar-brand'>
                //                 NumberSense District Console
                //             </Typography>
                //             <Box className='menuIconStyle'>
                //                 <MenuRoundedIcon onClick={toggleDrawer} className="menuStyle" />
                //             </Box>
                //             <Box className='MobileViewDraweer'>
                //                 <MenuRoundedIcon onClick={toggleMobileDrawer(true)} className="menuStyle" />
                //             </Box>
                //         </Box>
                //         <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                //             <Tooltip
                //                 title="Profile"
                //                 arrow
                //                 componentsProps={{
                //                     tooltip: {
                //                         sx: {
                //                             background: "#0e1318",
                //                         },
                //                     },
                //                 }}>
                //                 <IconButton
                //                     onClick={handleClick}
                //                     size="small"
                //                     sx={{ ml: 2 }}
                //                     aria-controls={openProfile ? 'account-menu' : undefined}
                //                     aria-haspopup="true"
                //                     aria-expanded={openProfile ? 'true' : undefined}
                //                 >
                //                     <Avatar sx={{ width: 32, height: 32, background: "#e0faef", color: "#00864e" }}>
                //                         {
                //                             userInfo &&
                //                             userInfo.FName &&
                //                             userInfo.FName !== "" &&
                //                             userInfo.FName.charAt(0)
                //                         }
                //                     </Avatar>
                //                 </IconButton>
                //             </Tooltip>
                //         </Box>
                //         <Dialog
                //             open={licenseDetails}
                //             keepMounted
                //             maxWidth={"xs"}
                //             onClose={handleLicenseDetailsClose}
                //         >
                //             <DialogTitle sx={{ background: "#2d8f3c", color: "#fff" }}>
                //                 <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                //                     <Box sx={{
                //                         background: "#fff",
                //                         display: "flex",
                //                         justifyContent: "center",
                //                         alignItems: "center",
                //                         borderRadius: "50%",
                //                         marginRight: "12px",
                //                     }}>
                //                         <img src={Images.smart_logo} alt="smart logo" />
                //                     </Box>
                //                     {"License Details"}
                //                 </Box>
                //             </DialogTitle>
                //             <DialogContent sx={{ padding: "20px!important" }}>
                //                 <Grid container spacing={2}>
                //                     <Grid item xs={6}>
                //                         <Typography component={'span'} variant={'body1'}>
                //                             Name
                //                         </Typography>
                //                     </Grid>
                //                     <Grid item xs={6}>
                //                         <Typography component={'b'} variant={'body1'} sx={{ color: "#000" }}>
                //                             : {districtDashboardData && districtDashboardData.ProductID}
                //                         </Typography>
                //                     </Grid>
                //                     <Grid item xs={6}>
                //                         <Typography component={'span'} variant={'body1'}>
                //                             Subscribed Date
                //                         </Typography>
                //                     </Grid>
                //                     <Grid item xs={6}>
                //                         <Typography component={'b'} variant={'body1'} sx={{ color: "#000" }}>
                //                             : {districtDashboardData && districtDashboardData.SubscriptionDate}
                //                         </Typography>
                //                     </Grid>
                //                     <Grid item xs={6}>
                //                         <Typography component={'span'} variant={'body1'}>
                //                             Expiry Date
                //                         </Typography>
                //                     </Grid>
                //                     <Grid item xs={6}>
                //                         <Typography component={'b'} variant={'body1'} sx={{ color: "#000" }}>
                //                             : {districtDashboardData && districtDashboardData.ExpirationDate}
                //                         </Typography>
                //                     </Grid>
                //                 </Grid>
                //             </DialogContent>
                //             <DialogActions>
                //                 <Button
                //                     variant="outlined"
                //                     color="error"
                //                     onClick={handleLicenseDetailsClose}
                //                     sx={{ margin: "8px" }}
                //                     startIcon={<CloseRoundedIcon />}
                //                 >
                //                     Close
                //                 </Button>
                //             </DialogActions>
                //         </Dialog>
                //         <Menu
                //             anchorEl={anchorEl}
                //             id="account-menu"
                //             open={openProfile}
                //             onClose={handleClose}
                //             onClick={handleClose}
                //             PaperProps={{
                //                 elevation: 0,
                //                 sx: {
                //                     overflow: 'visible',
                //                     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                //                     mt: 1.5,
                //                     '& .MuiAvatar-root': {
                //                         width: 32,
                //                         height: 32,
                //                         ml: -0.5,
                //                         mr: 1,
                //                     },
                //                     '&:before': {
                //                         content: '""',
                //                         display: 'block',
                //                         position: 'absolute',
                //                         top: 0,
                //                         right: 14,
                //                         width: 10,
                //                         height: 10,
                //                         bgcolor: 'background.paper',
                //                         transform: 'translateY(-50%) rotate(45deg)',
                //                         zIndex: 0,
                //                     },
                //                 },
                //             }}
                //             transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                //             anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                //         >
                //             <MenuItem>
                //                 <Avatar alt={userInfo.FName} src={Images.man} /> {userInfo.FName}
                //             </MenuItem>
                //             {/* <MenuItem>
                //             <Avatar /> My account
                //         </MenuItem> */}
                //             {/* <Divider />
                //         <MenuItem>
                //             <ListItemIcon>
                //                 <PersonAdd fontSize="small" />
                //             </ListItemIcon>
                //             Add another account
                //         </MenuItem> */}
                //             <MenuItem>
                //                 <ListItemIcon>
                //                     <Box className='statusColor' sx={{ background: licenseStatus ? "#1cce73" : "#ff1100" }} />
                //                 </ListItemIcon>
                //                 License Status
                //             </MenuItem>
                //             <MenuItem onClick={handleLicenseDetailsOpen}>
                //                 <ListItemIcon>
                //                     <BadgeRoundedIcon fontSize="small" />
                //                 </ListItemIcon>
                //                 License Details
                //             </MenuItem>
                //             {/* <MenuItem>
                //                 <ListItemIcon>
                //                     <Settings fontSize="small" />
                //                 </ListItemIcon>
                //                 Settings
                //             </MenuItem> */}
                //             <MenuItem onClick={() => {
                //                 dispatch(
                //                     allActions.userLogout(() => {
                //                         sessionStorage.Username = "";
                //                         sessionStorage.Password = "";
                //                         sessionStorage.userDomain = "";
                //                         sessionStorage.Type = "";
                //                         history.push("/");
                //                     })
                //                 );
                //             }}>
                //                 <ListItemIcon>
                //                     <Logout fontSize="small" />
                //                 </ListItemIcon>
                //                 Logout
                //             </MenuItem>
                //         </Menu>
                //     </Box>
                //     <Box className='SideMainStyle'>
                //         <React.Fragment key={"left"}>
                //             <Drawer
                //                 open={darwerLeft}
                //                 onClose={toggleMobileDrawer(false)}
                //             >
                //                 {DrawerListItems()}
                //             </Drawer>
                //         </React.Fragment>
                //         <Box className="menuList" sx={{ display: 'flex' }}>
                //             <MDrawer
                //                 variant="permanent"
                //                 open={open}
                //             // onMouseLeave={closeDrawer}
                //             >
                //                 {DrawerListItems()}
                //             </MDrawer>
                //         </Box>
                //         <Box className='rightMainMenu'>
                //             <Switch>
                //                 {getUserRoutes(ROUTES)}
                //                 <Redirect from="*" to="/district/district-dashboard" />
                //             </Switch>
                //         </Box>
                //     </Box>
                // </Box>
                : <div />}
        </ThemeProvider>)
    );
}