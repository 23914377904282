import React from 'react';
import {
    Chart as ChartJSBar,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import faker from 'faker';
import "./WeeklyReports.css";

export default function NSBarChart(params) {
    ChartJSBar.register(
        ChartDataLabels,
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    // let delayed;
    let labelNames = params.dataSetName;
    let labelValues = params.dataSetData;
    let dataLength = (Math.max(...labelValues)).toString().length;
    let backgroundColor = ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)", "rgba(160, 246, 163, 0.5)"];
    let borderColor = ["rgb(255, 99, 132)", "rgb(53, 162, 235)", "rgb(52, 235, 52)"];

    const data = {
        labels: [""],
        datasets: labelNames && labelNames.map((data, index) => {
            if (2 < index) {
                if (index % 3 === 0) {
                    index = 0
                } else if (index % 2 === 0) {
                    index = 1
                } else {
                    index = 2
                }
            }
            return {
                label: data,
                data: [labelValues[index]],
                borderColor: borderColor[index],
                backgroundColor: backgroundColor[index],
                borderWidth: 1,
            };
        }),
    };

    const options = {
        responsive: true,
        scales: {
            y: { // defining min and max so hiding the dataset does not change scale range
                min: 0,
                max: parseInt('1'.padEnd(dataLength + 1, '0')),
                ticks: {
                    stepSize: parseInt('1'.padEnd(dataLength, '0'))
                }
            }
        },
        // animation: {
        //     onComplete: () => {
        //         delayed = true;
        //     },
        //     delay: (context) => {
        //         let delay = 0;
        //         if (context.type === 'data' && context.mode === 'default' && !delayed) {
        //             delay = context.dataIndex * 300 + context.datasetIndex * 100;
        //         }
        //         return delay;
        //     },
        // },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: "#0e1318",
                    family: "sans-serif",
                }
            },
            title: {
                position: params.titlePosition ? params.titlePosition : 'left',
                display: params.title === "" ? false : true,
                text: params.title,
            },
            datalabels: {
                display: true,
                clip: false,
                align: 'end',
                anchor:'end',
                font: {
                    weight: 'bold',
                },
                color: '#0e1318',
            }
        },
    };

    return (
        <Bar
            id={params.id}
            options={options}
            data={data}
        />
    );
}