import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Stack,
    Breadcrumbs,
    Typography,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import allActions from "../../../redux/ducks/actions";
import { Link, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DashboardIcon from '@mui/icons-material/Dashboard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import CustomWelcomeCard from "../../../Components/CustomWelcomeCard";
import CustomHeader from "../../../Components/CustomHeader";
import { Backdrop } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function ClassDailySummary(props) {
    const dispatch = useDispatch(); //dispatch
    const history = useHistory(); //history
    const { userInfo, schoolUsageData } = useSelector((state) => state.data);
    const [className, setClassName] = useState("");
    const [loading, setLoading] = useState(false);

    const [tableRow, setTableRow] = useState([]);
    let url = useParams();

    useEffect(() => {
        setLoading(true);
        dispatch(allActions.classDailySummaryCS(Number(url.id), (response) => {
            setLoading(false);
            setTableRow(response);
        }));
        dispatch(allActions.getAllClassList(schoolUsageData.schoolId, (response) => {
            setLoading(false);
            if (response && response.length > 0) {
                response.map(data => {
                    if (data.ID === Number(url.id)) {
                        setClassName(data.Name)
                    }
                    return null;
                })
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        "Date",
        "Minutes Played",
        "Problems Solved",
        "Games Completed",
        "Levels Mastered",
    ];

    const options = {
        selectableRows: false,
        filter: false,
        download: false,
        print: false,
        rowsPerPage: 25,
    };


    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: "99999999"
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>

            <CustomHeader
                licenseStatus={licenseStatus}
                needBreadCrumb={true}
                previousPageLabel={`${schoolUsageData.schoolName} Dashboard`}
                previousPageLabel1={`Class Summary`}
                currentPageLabel={"Daily Summary"}
                onClickPrevPageLabel={() => {
                    const navigateTo = `/district/district-school-usage-data/school-details/${schoolUsageData.schoolId}`;
                    history.push(navigateTo);
                }}
                onClickPrevPageLabel1={() => {
                    const navigateTo = `/district/district-school-usage-data/class-summary/${schoolUsageData.schoolId}`;
                    history.push(navigateTo);
                }}
            />

            <CustomWelcomeCard
                welcomCardData={[]}
                cardLabel={"School Name"}
                cardValue={schoolUsageData.schoolName}
            />

            <Box className="nsMainContent">
                <MUIDataTable
                    title={className && className !== "" ? `${className} - Daily Summary` : "Daily Summary"}
                    data={tableRow ? tableRow.map(data => {
                        return [
                            data.date,
                            data.minutes,
                            data.problems,
                            data.games,
                            data.levels,
                        ]
                    }) : []}
                    columns={columns}
                    options={options}
                />
            </Box>
        </Box >
    );
}
export default ClassDailySummary;

