import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Stack,
  Typography,
  Tooltip,
  TextField,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import AllActions from "../../redux/ducks/actions";
import { useHistory } from "react-router-dom";
import BadgeIcon from '@mui/icons-material/Badge';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { GridActionsCellItem, } from "@mui/x-data-grid";
import { Backdrop } from "@mui/material";
import CustomHeader from "../../Components/CustomHeader";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import { Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import { ChartBar, ChartLine, User } from "@carbon/icons-react";

function StudentUsageDataList(props) {
  const dailySummaryIcon = "#f83e29";
  const activitySummaryIcon = "#2096f3";
  const history = useHistory();
  const dispatch = useDispatch(); //dispatch
  const { userInfo, listAllClasses } = useSelector((state) => state.data);
  const [tableRow, setTableRow] = useState([]);
  const [ClassNameOptions, setClassNameOptions] = useState({
    TotalStudents: 0
  });
  const defaultData = {
    options: [],
    getOptionLabel: (option) => option,
  };
  const [value, setValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [classData, setClassData] = React.useState({});

  useEffect(() => {
    if (listAllClasses && listAllClasses.length > 0) {
      setLoading(true)
      dispatch(AllActions.classListTeacher(userInfo.LoginID, (response) => {
        setLoading(false)
        return true;
      }));
      let defaultProps = {
        options: listAllClasses,
        getOptionLabel: (option) => option && option.Name && (option.Name).trim()
      };
      setClassNameOptions(defaultProps);
      setValue(listAllClasses[0]);
      setLoading(true)
      dispatch(AllActions.studentsSummaryCS(listAllClasses[0].GroupID, (response) => {
        setTableRow(response);
        setLoading(false)
      }));
      dispatch(AllActions.classUsageDataSchoolDashboard(listAllClasses[0].GroupID, (response) => {
        setClassData(response);
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (value) {
      dispatch(AllActions.studentsSummaryCS(value.GroupID, (response) => {
        setTableRow(response);
      }));

      dispatch(AllActions.classUsageDataSchoolDashboard(value.GroupID, (response) => {
        setClassData(response);
      }));
    }
  }, [value, dispatch, userInfo])

  const [licenseStatus, setLicenseStatus] = React.useState(false);
  React.useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])


  const columns = [
    "Student Name",
    "Games Completed",
    "Levels Mastered",
    "Minutes Played",
    "Problems Solved",
    {
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box className="actionWrapper">
              <Tooltip
                title="Student Daily Summary"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  dispatch(AllActions.setStudentName(value.name));
                  history.push(`/teacher/teacher-student-usage-data/student-daily-summary/${value.rowId}`);
                }}
              >
                <Box className='customActionIcon'>
                  <ChartBar size={16} color='#FFF' />
                </Box>
              </Tooltip>
              <Tooltip
                title="Student Activity Summary"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  dispatch(AllActions.setStudentName(value.name));
                  history.push(`/teacher/teacher-student-usage-data/student-details-summary/${value.rowId}`);
                }}
              >
                <Box className='customActionIcon'>
                  <ChartLine size={16} color='#FFF' />
                </Box>
              </Tooltip>
            </Box>
          )
        }
      }
    }
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };

  const welcomCardData = [
    {
      label: "Total Students",
      icon: <User className="welcomeCardIcons" />,
      value: classData && classData.TotalStudents ? classData.TotalStudents : 0
    },
  ]

  return (
    <Box className='districtStyle'>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: "99999999"
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>

      <CustomHeader
        licenseStatus={licenseStatus}
        headerLabel={"Student Usage Data"}
      />

      <CustomWelcomeCard
        isSelectCard={true}
        selectComponent={(
          <Box className='weeklyReportWrapper'>
            {
              listAllClasses && listAllClasses.length > 0 ?
                <Autocomplete
                  {...ClassNameOptions}
                  value={value}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      if (listAllClasses && listAllClasses.lenght > 0) {
                        setValue({
                          label: listAllClasses[0].Name,
                          id: listAllClasses[0].GroupID
                        });
                      }
                    } else {
                      setValue(newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Classes"
                      variant="standard"
                      className="WRClassName"
                    />
                  )}
                /> :
                <Autocomplete
                  {...defaultData}
                  id="disable-close-on-select"
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField {...params} label="Classes" variant="standard" />
                  )}
                />
            }
          </Box>
        )}
        welcomCardData={welcomCardData}
      />

      <Box className="nsMainContent">
        <MUIDataTable
          title={"Student Usage Data"}
          data={tableRow ? tableRow.map(data => {
            const studentDetails = {
              rowId: data.StudentID,
              name: data.StudentName,
            }
            return [
              data.StudentName,
              data.GamesCompleted,
              data.LevelsMastered,
              data.MinutesPlayed,
              data.ProblemsSolved,
              studentDetails
            ]
          }) : []}
          columns={columns}
          options={options}
        />
      </Box>
    </Box>

  );
}
export default StudentUsageDataList;



