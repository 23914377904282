import CreateIcon from "@mui/icons-material/Create";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Breadcrumbs,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DESCRIPTION_EMPTY,
  SCHOOL_NAME_EMPTY,
  SCHOOL_NAME_EXIST,
  SCHOOL_CREATE,
} from "../../constants";
import Creators from "../../redux/ducks/actions";
import "./ManageSchools.css";
import { useHistory } from "react-router-dom";
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import { Close, Pen } from "@carbon/icons-react";

function CreateSchools(props) {
  const dispatch = useDispatch(); //dispatch
  const { userInfo } = useSelector(state => state.data); //dispatch
  const history = useHistory(); //history
  // Snack Message
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "",
  });
  const { snackStatus, snackMessageFor } = snackbar;
  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  const [form, setForm] = useState({
    schoolName: "",
    description: "",
    domainid: userInfo.DomainID
  });
  const [emptyError, setEmptyError] = useState({
    schoolName: false,
    description: false,
    domainid: false,
  });
  //   create Button
  const [createLoading, setCreateLoading] = useState(false);
  const handleCreateClick = () => {
    setCreateLoading(true);
    const checkEmptyValue = { ...emptyError };
    let submit = true;
    // Checking empty data
    for (let items in form) {
      if (form[items] === "") {
        submit = false;
        checkEmptyValue[items] = true;
      }
    }
    if (!submit) {
      setEmptyError(checkEmptyValue);
      setCreateLoading(false);
    } else {
      setCreateLoading(false);
      dispatch(
        Creators.putSchools(form, (response) => {
          if (response.MessageID === 1) {
            // District Duplicate Error
            setCreateLoading(false);
            handleSnackbarClick("duplicateError");
            return null;
          } else if (response.MessageID === 0) {
            // District Success Message
            handleCancelButton();
            handleSnackbarClick("create");
            setTimeout(() => {
              setCreateLoading(false);
              history.push("/district/district-manage-schools");
            }, 1000);
          } else {
            console.error(response, "response");
          }
        })
      );
    }
  };

  // cancel Button
  const handleCancelButton = () => {
    setForm((state) => ({
      ...state,
      schoolName: "",
      description: "",
    }));
    setEmptyError((state) => ({
      ...state,
      schoolName: false,
      description: false,
    }));
  };
  // handle Create Schools
  const handleOnChangeCreateDistrict = (e) => {
    if (e.target.value !== "") {
      setEmptyError((state) => ({
        ...state,
        [e.target.id]: false,
      }));
    }

    setForm((state) => ({
      ...state,
      [e.target.id]: e.target.value,
    }));

  };


  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <>
      {/* Action Status */}
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar
          key="SnackBar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackStatus}
          onClose={handleSnackbarClose}
        >
          {snackMessageFor === "duplicateError" ? (
            <Alert
              variant="filled"
              severity="warning"
              sx={{
                "&.MuiPaper-root": {
                  background: "#ffc107",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {SCHOOL_NAME_EXIST}
            </Alert>
          ) : snackMessageFor === "create" ? (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                "&.MuiPaper-root": {
                  background: "#00c853",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {SCHOOL_CREATE}
            </Alert>
          ) : null}
        </Snackbar>
      </Stack>
      <Box className='districtStyle'>
        <CustomHeader
          licenseStatus={licenseStatus}
          needBreadCrumb={true}
          previousPageLabel={"Manage School"}
          currentPageLabel={"Create School"}
          onClickPrevPageLabel={() => { history.push("/district/district-manage-schools") }}
        />
        <Box className="noHeaderButton" />
        <Box className='nsMainContent'>
          <Box className="nsMainHeader">
            <Box className="nsMainTextHeader">
              Create School
            </Box>
            <Box className="customHeaderButton" />
          </Box>
          <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
            <Box className="createDomainParent">
              <Box className="LessonBotContent">
                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">School Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        width: "100%",
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="schoolName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.schoolName}
                      onChange={handleOnChangeCreateDistrict}
                      error={emptyError.schoolName}
                      helperText={emptyError.schoolName ? SCHOOL_NAME_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">School Description</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        width: "100%",
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="description"
                      label=""
                      multiline
                      rows={1}
                      value={form.description}
                      onChange={handleOnChangeCreateDistrict}
                      error={emptyError.description}
                      helperText={emptyError.description ? DESCRIPTION_EMPTY : ""}
                    />
                  </Box>
                </Box>
                <Box className="createButtonParent" style={{ height: "auto", padding: "2%" }}>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={true}
                      buttonLabel={"Cancel"}
                      onClickButton={() => {
                        history.push("/district/district-manage-schools");
                      }}
                      buttonIcon={<Close size={16} />}
                    />
                  </Box>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={false}
                      buttonLabel={"Create"}
                      onClickButton={handleCreateClick}
                      buttonIcon={<Pen size={16} />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default CreateSchools;
