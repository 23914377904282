import ApartmentIcon from '@mui/icons-material/Apartment';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import CreateIcon from '@mui/icons-material/Create';
import { Box, Typography } from '@mui/material';
import { User, Location, Enterprise, PenFountain, Education, Course, GroupPresentation } from '@carbon/icons-react';
import { FormatNumbers } from '../../constants';

export default function CardDistrict(props) {

    let cardColor, icon;

    switch (props.cardName) {
        case "Total Users":
            cardColor = "#8cc63e";
            icon = <User size={30} />
            break;
        case "Total Schools":
            cardColor = "#0093d1";
            icon = <Enterprise size={30} />
            break;
        case "Total Classes":
            cardColor = "#F75C03";
            icon = <Course size={30} />
            break;
        case "Total Teachers":
            cardColor = "#A45EEF";
            icon = <Education size={30} />
            break;
        case "Total Students":
            cardColor = "#8cc63e";
            icon = <GroupPresentation size={30} />
            break;
        default:
            icon = <Location size={30} />
            cardColor = "white";
    }
    return (
        <Box className="nsDBPhase1Card">
            <Box className='nsDBIconSec'>
                {icon}
                <Box className='nsDBIconText'>{FormatNumbers(props.data)}</Box>
            </Box>
            <Box className='nsDBCardText'>{props.cardName}</Box>
        </Box>
    )
}