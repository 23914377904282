/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography, Tooltip, Backdrop, Snackbar, Alert, Stack } from "@mui/material";
import "./DistrictDashboard.css";
import Logo from "../../constants/images";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import AllActions from "../../redux/ducks/actions";
// Icons
import SchoolIcon from "@mui/icons-material/School";
import ClassIcon from "@mui/icons-material/Class";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { CLASSLINK_UPDATE_START, CLASSLINK_UPDATE_SUCCESS, CLASSLINK_UPDATE_FAILURE, FormatNumbers } from "../../constants";
import CustomHeader from "../../Components/CustomHeader";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import CustomClassCard from "../../Components/CustomClassCard";
import CustomTableButton from "../../Components/CustomTableButton";
import { ChartBar, ChartLine, Report, List, IbmCloudMassDataMigration, IbmCloudDirectLink_1DedicatedHosting } from "@carbon/icons-react";
import { Grid, Column } from "@carbon/react";
import CardContainer from "../../Admin_Super/AdminDashboard/Reg&Sub_Section/CardContainer";
import Districtcard from "./Districtcard";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  margin: "10px",
}));
const mdTheme = createTheme();

export default function DistrictDashboard() {
  const dispatch = useDispatch();
  const history = useHistory(); //history
  const { districtDashboardData, userInfo, token } = useSelector((state) => state.data);
  const initialCall = async () => {
    try {
      // Dispatch the first API call
      dispatch(AllActions.getDistrictDashboardData(userInfo.DomainID));

      setTimeout(async () => {
        await new Promise((resolve) => {
          dispatch(
            AllActions.getDistrictByID(userInfo.DomainID, (response) => {
              if (response && response.ApplicationID) {

                setAppId(response.ApplicationID);
                setTokenId(response.AccessToken);
              }
              resolve(); // Resolve the promise after the callback completes
            })
          );

        });
        await new Promise((resolve) => {
          dispatch(AllActions.getAllSchoolList(userInfo.DomainID, (response) => {
            dispatch(AllActions.getAllClassLicense());
            resolve(); // Resolve the promise after the nested dispatch completes
          }));
        });
      }, 100);
      // Dispatch the second API call and handle the response


      // Dispatch the third API call and handle the nested API call

    } catch (error) {
      console.error("Error during initial API calls:", error);
    }
  };

  const [schoolData, setSchoolData] = React.useState();
  const [userData, setUserData] = React.useState();
  const [classData, setClassData] = React.useState();
  const [userMaping, setUserMaping] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [tokenId, setTokenId] = React.useState("");
  const [appId, setAppId] = React.useState("");
  // useEffect(() => {
  //   if (Object.keys(token).length > 0) {

  //     dispatch(AllActions.getAllSchoolList(userInfo.DomainID, (response) => {
  //       dispatch(AllActions.getAllClassLicense());
  //     }));

  //   }

  // }, [dispatch, token]);
  useEffect(() => {
    if (userData) {
      let result = classData && classData.classes && classData.classes.map((a) => a.sourcedId);
      let classuser = [];
      // eslint-disable-next-line array-callback-return
      result &&
        result.map((row, index) => {
          const token = tokenId;
          let headers = new Headers();
          headers.set("Authorization", `Bearer ${token}`);
          headers.set("Content-type", `application/json`);
          fetch(`https://oneroster-proxy.classlink.io/${appId}/ims/oneroster/v1p1/classes/${row}/students?limit=100000`, {
            method: "GET",
            headers: headers,
            //credentials: 'user:passwd'
          })
            .then((response) => response.json()) //2
            .then((data) => {
              fetch(`https://oneroster-proxy.classlink.io/${appId}/ims/oneroster/v1p1/classes/${row}/teachers?limit=100000`, {
                method: "GET",
                headers: headers,
              })
                .then((res) => res.json())
                .then((teacherData) => {
                  let users = data.users.map((a) => a.sourcedId);
                  teacherData.users.forEach((user) => {
                    users.push(user.sourcedId);
                  });
                  let classdata = {
                    classID: row,
                    Users: users,
                  };
                  classuser.push(classdata);
                  if (result.length - 1 == index) {
                    setUserMaping(classuser);
                  }
                });
            });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const failureMesssage = async () => {
    setLoading(false);
    handleSnackbarClick("failure");
    await initialCall();
  };

  useEffect(async () => {
    if (userMaping) {
      dispatch(
        AllActions.classlinkSchoolUpload(schoolData, (response) => {
          if (response && response.MessageID == 0) {
            dispatch(
              AllActions.classlinkClassUpload(classData, (response) => {
                if (response && response.MessageID == 0) {
                  dispatch(
                    AllActions.classlinkUserUpload(userData, async (response) => {
                      if (response && response.MessageID == 0) {
                        dispatch(
                          AllActions.classlinkUserMaping(userMaping, async (response) => {
                            if (response && response.MessageID == 0) {
                              setLoading(false);
                              handleSnackbarClick("success");
                              await initialCall();
                            } else {
                              failureMesssage();
                            }
                          })
                        );
                      } else {
                        failureMesssage();
                      }
                    })
                  );
                } else {
                  failureMesssage();
                }
              })
            );
          } else {
            failureMesssage();
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMaping]);

  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "update",
  });
  const { snackStatus, snackMessageFor } = snackbar;

  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };

  // useEffect(() => {
  //     let boxIndex = document.getElementById("HeaderBoxResponsiveIndex");
  //     if (snackbar.snackStatus) {
  //         boxIndex.style.zIndex = 1;
  //     } else {
  //         boxIndex.style.zIndex = 9999;
  //     }
  // }, [snackbar])

  const handleSnackbarClose = () => {
    if (snackMessageFor === "loading") {
      return null;
    } else {
      setSnackbar((state) => ({
        ...state,
        snackStatus: false,
        snackMessageFor: "",
      }));
    }
  };

  const addDataClick = () => {
    setLoading(true);
    handleSnackbarClick("loading");
    const token = tokenId;
    let headers = new Headers();
    //let url =`https://certs-nj-v2.oneroster.com/${appId}/ims/oneroster/v1p1/orgs`;
    let url = `https://oneroster-proxy.classlink.io/${appId}/ims/oneroster/v1p1/schools?limit=100000`;
    let url1 = `https://oneroster-proxy.classlink.io/${appId}/ims/oneroster/v1p1/classes?limit=100000`;
    let url2 = `https://oneroster-proxy.classlink.io/${appId}/ims/oneroster/v1p1/users?limit=100000`;
    //headers.append('Content-Type', 'text/json');
    // headers.set("Authorization", `Bearer ${token}`);
    // headers.set("Content-type", `application/json`);
    // fetch(url, {
    //   method: "GET",
    //   headers: headers,
    //   //credentials: 'user:passwd'
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setSchoolData(data);
    //     fetch(url1, {
    //       method: "GET",
    //       headers: headers,
    //       //credentials: 'user:passwd'
    //     })
    //       .then((response) => response.json())
    //       .then((data) => {
    //         setClassData(data);
    //         fetch(url2, {
    //           method: "GET",
    //           headers: headers,
    //           //credentials: 'user:passwd'
    //         })
    //           .then((response) => response.json())
    //           .then((data) => {
    //             setUserData(data);
    //           });
    //       });
    //   });
    const fetchClassLinkData = async () => {
      try {
        const response = await fetch('https://server.knomadixapp.com/Api/Numbersense/V1/ClassLinkData', {
          method: 'POST',
          headers: {
            'Accept': 'text/plain',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            DomainID: userInfo.DomainID,
            UserID: userInfo.UserID,
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        setLoading(false);
        handleSnackbarClick("success");

        const data = await response.text(); // If response is plain text
        console.log('Response:', data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the function when needed
    fetchClassLinkData();

  };

  useEffect(async () => {

    await initialCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    console.log(userInfo, "userInfo");
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, "0");
      let todayMonth = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split("/");
      let licenseDate = dateData[1];
      let licenseMonth = dateData[0];
      let licenseYear = dateData[2];
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {

        setLicenseStatus(false);
      }
    }
    console.log(userInfo.SourceID, "userInfo.SourceID", userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box className="districtStyle">
        <Backdrop
          sx={{
            background: "rgb(0 0 0 / 30%)",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress style={{ color: "#008000" }} />
        </Backdrop>
        <Stack spacing={2} sx={{ maxWidth: 1000 }}>
          <Snackbar key="SnackBar" anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackStatus} onClose={handleSnackbarClose}>
            {snackMessageFor === "success" ? (
              <Alert
                variant="filled"
                severity="success"
                sx={{
                  "&.MuiPaper-root": {
                    background: "#00c853",
                    color: "#fff",
                  },
                }}
                action={
                  <CloseRoundedIcon sx={{ cursor: "pointer", color: "#fff" }} onClick={() => handleSnackbarClose()} color="inherit" size="small" />
                }
              >
                {CLASSLINK_UPDATE_SUCCESS}
              </Alert>
            ) : snackMessageFor === "failure" ? (
              <Alert
                variant="filled"
                severity="error"
                action={
                  <CloseRoundedIcon sx={{ cursor: "pointer", color: "#fff" }} onClick={() => handleSnackbarClose()} color="inherit" size="small" />
                }
              >
                {CLASSLINK_UPDATE_FAILURE}
              </Alert>
            ) : (
              <Alert
                variant="filled"
                severity="info"
                sx={{
                  "&.MuiPaper-root": {
                    background: "#ffc107",
                    color: "#fff",
                  },
                }}
              >
                {snackMessageFor === "loading"
                  ? CLASSLINK_UPDATE_START
                  : snackMessageFor === "success"
                    ? CLASSLINK_UPDATE_SUCCESS
                    : snackMessageFor === "failure"
                      ? CLASSLINK_UPDATE_FAILURE
                      : ""}
              </Alert>
            )}
          </Snackbar>
        </Stack>
        <CustomHeader
          licenseStatus={licenseStatus}
          headerLabel={userInfo.UserType === "restricteddistrictadmin" ? "Restricted District Admin Dashboard" : "District Admin Dashboard"}
        />

        <CustomWelcomeCard welcomCardData={[]} cardLabel={"District Name"} cardValue={districtDashboardData && districtDashboardData.District} />

        <Box className="nsMainContent">
          <Box className="nsMainHeader">
            <Box className="nsMainTextHeader">District Dashboard</Box>
            <Box className="customHeaderButton">
              {userInfo.SourceID !== "" ? (
                districtDashboardData.TotalSchools > 0 ? (
                  <CustomTableButton
                    buttonLabel={"Sync Data"}
                    onClickButton={() => {
                      addDataClick();
                    }}
                    buttonIcon={<IbmCloudDirectLink_1DedicatedHosting size={16} />}
                  />
                ) : (
                  <CustomTableButton
                    buttonLabel={"Add Data"}
                    onClickButton={() => {
                      addDataClick();
                    }}
                    buttonIcon={<IbmCloudMassDataMigration size={16} />}
                  />
                )
              ) : null}

              <CustomTableButton
                buttonLabel={"Daily Summary"}
                onClickButton={() => {
                  history.push(`/district/district-dashboard/daily-summary/${userInfo.DomainID}`);
                }}
                buttonIcon={<ChartBar size={16} />}
              />
              <CustomTableButton
                buttonLabel={"Activity Summary"}
                onClickButton={() => {
                  history.push(`/district/district-dashboard/details-summary/${userInfo.DomainID}`);
                }}
                buttonIcon={<ChartLine size={16} />}
              />
            </Box>
          </Box>

          <Districtcard data={districtDashboardData} />

          <Box
            className="gridWrapper"
            style={{
              padding: "0.75rem",
            }}
          >
            <Grid>
              <Column sm={4} md={4} lg={4}>
                <CustomClassCard
                  cardLabel={FormatNumbers(districtDashboardData && districtDashboardData.Minutes)}
                  cardValue={"Minutes Played"}
                  backgroundColor={"linear-gradient(225deg, #D896FF 0%, #F57373 100%)"}
                />
              </Column>
              <Column sm={4} md={4} lg={4}>
                <CustomClassCard
                  cardLabel={FormatNumbers(districtDashboardData && districtDashboardData.ProblemsCompleted)}
                  cardValue={"Problems Solved"}
                  backgroundColor={
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.00) 96.41%), linear-gradient(132deg, #667EEA 6.78%, #764BA2 93.86%)"
                  }
                />
              </Column>
              <Column sm={4} md={4} lg={4}>
                <CustomClassCard
                  cardLabel={FormatNumbers(districtDashboardData && districtDashboardData.LevelsAttempted)}
                  cardValue={"Games Completed"}
                  backgroundColor={"linear-gradient(225deg, #41A7C4 0%, #3E72E8 100%)"}
                />
              </Column>
              <Column sm={4} md={4} lg={4}>
                <CustomClassCard
                  cardLabel={FormatNumbers(districtDashboardData && districtDashboardData.LevelsMastered)}
                  cardValue={"Levels Mastered"}
                  backgroundColor={"linear-gradient(225deg, #95CC4E 0%, #2ED8F7 100%)"}
                />
              </Column>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
