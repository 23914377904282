import React from "react";
import "./WeeklyReports.css";
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
// import faker from 'faker';

export default function NSLineChart(params) {
            // console.log(labelNames[index],"labelNames[index]");
            ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    // let delayed;
    let labelNames = ["Current Week", "Last Week", "Cumulative"];
    let newArray = [];
    if (params.dataSetData && params.dataSetData.length > 0) {
        for (let i = 0; i < params.dataSetData.length; i++) {
            if (!(params.dataSetData.includes(undefined) || params.dataSetData.includes(null))) {
                newArray.push(...params.dataSetData[i]);
            }
        }
    }
    let labelValues = newArray;
    let dataLength = labelValues.length > 0 ? (Math.max(...labelValues)).toString().length : 10;
    let backgroundColor = ["rgba(255, 99, 132, 0.5)", "rgba(53, 162, 235, 0.5)", "rgba(160, 246, 163, 0.5)", "rgba(250,243,208,0.5)"];
    let borderColor = ["rgb(255, 99, 132)", "rgb(53, 162, 235)", "rgb(52, 235, 52)", "rgb(235,202,52)"];

    const data = {
        labels: params.xAxisLabel,
        datasets: labelNames && labelNames.map((_, index) => {
            return {
                label: labelNames[index],
                data: params.dataSetData.length > 0 ? params.dataSetData[index] : [],
                borderColor: borderColor[index],
                backgroundColor: backgroundColor[index],
                borderWidth: 3,
            };
        }),
    };

    const options = {
        responsive: true,
        scales: {
            y: { // defining min and max so hiding the dataset does not change scale range
                min: 0,
                max: parseInt('1'.padEnd(dataLength + 1, '0')),
                ticks: {
                    stepSize: parseInt('1'.padEnd(dataLength, '0'))
                },
                title: {
                    display: true,
                    text: params.title,

                    font: {
                        size: 20
                    }
                }

            }
        },
        // animation: {
        //     onComplete: () => {
        //         delayed = true;
        //     },
        //     delay: (context) => {
        //         let delay = 0;
        //         if (context.type === 'data' && context.mode === 'default' && !delayed) {
        //             delay = context.dataIndex * 300 + context.datasetIndex * 100;
        //         }
        //         return delay;
        //     },
        // },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: "#0e1318",
                    family: "sans-serif",
                }
            },

            datalabels: {
                display: false,
                align: 'top',
                clip: true,
                font: {
                    weight: 'bold',
                },
                color: '#0e1318',
            }
        },
    };
    return (
        <Line
            id={params.id}
            options={options}
            data={data}
        />
    );
}
