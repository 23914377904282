import * as React from 'react';
import {
    Box,
} from '@mui/material';
import Logo from "../constants/images";

export default function CustomDashboardCard({
    index,
    onClickCard,
    teacherClass,
    TotalStudents,
    Minutes,
    ProblemsCompleted,
    LevelsAttempted,
    LevelsMastered,
}) {

    return (
        <Box className="customDBCard" onClick={onClickCard}>
            <Box className='DBTitle'>{teacherClass}</Box>
            <Box className='DBContent'>
                <Box className='DBImageWrapper'>
                    {
                        index % 2 === 0 ? <img alt="logo" src={Logo.DBImage} className="DBImage" /> :
                            index % 3 === 0 ? <img alt="logo" src={Logo.DBImage1} className="DBImage" /> :
                                <img alt="logo" src={Logo.DBImage2} className="DBImage" />
                    }

                </Box>
                <Box className='DBDetail'>
                    <Box className='DBStudentNameDetail'>
                        <Box className='DBStudentName'>Number of Students</Box>
                        <Box className='DBStudentValue'>{TotalStudents} students</Box>
                    </Box>
                    <Box className='DBStudentScoreDetail'>
                        <Box className='DBBoxTopWrapper'>
                            <Box>
                                <Box className='DBSSBoxLabel'>Minutes Played</Box>
                                <Box className='DBSSBoxValue'>{Minutes} minutes</Box>
                            </Box>
                            <Box>
                                <Box className='DBSSBoxLabel'>Problems Solved</Box>
                                <Box className='DBSSBoxValue'>{ProblemsCompleted} problems</Box>
                            </Box>
                        </Box>
                        <Box className='DBBoxBottomWrapper'>
                            <Box>
                                <Box className='DBSSBoxLabel'>Games Completed</Box>
                                <Box className='DBSSBoxValue'>{LevelsAttempted} games</Box>
                            </Box>
                            <Box>
                                <Box className='DBSSBoxLabel'>Levels Mastered</Box>
                                <Box className='DBSSBoxValue'>{LevelsMastered} levels</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
} 