import {
    Alert,
    Box,
    Typography,
    Button,
    Snackbar,
    Stack,
    Breadcrumbs,
    Tooltip,
    Checkbox,
    FormControlLabel,
    Autocomplete,
    TextField,
    Backdrop
} from "@mui/material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {
    SCHOOL_BULK_UPLOAD,
    SCHOOL_DUPLICATE_EXIST,
    NO_SCHOOL_NAME_FOUND,
    INVALID_DATA,
    NO_DATA,
    INVALID_COLUMN_DATA,
} from "../../constants";
import Logo from "../../constants/images";
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import XLSX from "xlsx";
import Creators from "../../redux/ducks/actions";
import { Close, Download, Upload } from "@carbon/icons-react";
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";

function UserLoginsSchoolBulkUpload() {
    const [xlsxfile, setxlsxFile] = React.useState(null);
    const [duplicateCheck, setDuplicateCheck] = React.useState(true);
    const [duplicateRowsInFile, setDuplicateRowsInFile] = React.useState("");
    const [fileData, setFileData] = React.useState(null);
    const history = useHistory();
    const dispatch = useDispatch(); // Dispatch
    const { userInfo, listAllSchools } = useSelector(state => state.data);
    const [checkBoxData, setCheckBoxData] = React.useState(false);
    // colors
    const Btncolor = "#2d8f3c";

    const [value, setValue] = React.useState(null);
    const defaultProps = {
        options: listAllSchools,
        getOptionLabel: (option) => option && option.Name && (option.Name).trim()
    };

    // Loading
    const [loading, setLoading] = React.useState(false);
    //   const handleLoadingClose = () => {
    //     setLoading(false);
    //   };

    React.useEffect(() => {
        setValue(listAllSchools[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Action Status
    const [snackbar, setSnackbar] = React.useState({
        snackStatus: false,
        snackMessageFor: "update",
    });
    const { snackStatus, snackMessageFor } = snackbar;

    const handleSnackbarClick = (msg) => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: true,
            snackMessageFor: msg,
        }));
    };

    const handleSnackbarClose = () => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: false,
            snackMessageFor: "",
        }));
    };

    React.useEffect(() => {
        if (fileData) {
            setDuplicateCheck(false);
        }
    }, [fileData])

    React.useEffect(() => {
        setTimeout(() => {
            let msgBox1 = document.getElementsByClassName("sc-hKMtZM");
            let msgBox2 = document.getElementsByClassName("cXBrtO");
            if (msgBox1 && (
                msgBox1[0].innerText !== "Upload or drop a file right here" ||
                msgBox1[0].innerText === "Uploaded Successfully!. Upload another?"
            )) {
                msgBox1[0].innerHTML = xlsxfile && xlsxfile.name + " - Uploaded Successfully!.<br/> Click or drop a file right here to replace file.";
            } else if (msgBox2 && (
                msgBox2[0].innerText !== "Upload or drop a file right here" ||
                msgBox2[0].innerText === "Uploaded Successfully!. Upload another?"
            )) {
                msgBox2[0].innerHTML = xlsxfile && xlsxfile.name + " - Uploaded Successfully!. <br/>Click or drop a file right here to replace file.";
            }
        }, 700)
    }, [xlsxfile])

    // For Image
    const fileTypes = ["XLSX"];

    // handle xsls file
    const handleChange = (file) => {
        /* Boilerplate to set up FileReader */
        setxlsxFile(file)
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            setFileData(data);
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    const uploadSchoolList = () => {
        setLoading(true);
        if (!fileData || fileData.length === 0) {
            setLoading(false);
            handleSnackbarClick("noFile");
            return null;
        }
        let isColumnMatch = 0;
        let columnNames = [
            "User Name",
            "First Name",
            "Last Name",
            "Email ID",
            "Country",
            "Age",
            "Password",
            "User Type",
            "Class Name",
            "",
            "Private"
        ];
        let allSchoolDetails = [];
        // Getting data from excel file
        for (let sample of fileData) {
            if (sample.length !== 0 && sample[0]) {
                let dataObject = {
                    LoginName: sample[0],
                    FName: sample[1],
                    LName: sample[2],
                    EmailID: sample[3],
                    Country: sample[4],
                    Age: sample[5],
                    Pwd: sample[6].toString(),
                    UserType: sample[7] === "Teacher" ? "Instructor" : sample[7],
                    ClassName: sample[8],
                    ImageID: "",
                    Source: "Private"
                }
                allSchoolDetails.push(dataObject);
            }
        }
        // checking column names
        if (allSchoolDetails && allSchoolDetails.length > 1) {
            const columnCheck = Object.values(allSchoolDetails[0])
            columnCheck.map(data => {
                if (!columnNames.includes(data)) {
                    isColumnMatch = 1;
                }
                return null
            })
        } else {
            isColumnMatch = 2;
        }
        if (isColumnMatch === 2) {
            setLoading(false);
            handleSnackbarClick("noData");
        } else if (isColumnMatch === 1) {
            setLoading(false);
            handleSnackbarClick("inValidColumnName");
        } else {
            // Removing headers and setting data only
            let userList = allSchoolDetails.filter((data, index) => index !== 0 && data);
            dispatch(
                Creators.userLoginSchoolsBulkUpload(
                    userInfo && userInfo.UserType === "Supergrouptechadmin" ? userInfo.SuperGroupID : value.ID,
                    checkBoxData ? 1 : 0,
                    userList,
                    (response) => {
                        if (response.MessageID === 4) {
                            setLoading(false);
                            let duplicateSchoolNames = "";
                            if (response.Messages.length > 0) {
                                response.Messages.map(data => {
                                    // eslint-disable-next-line no-useless-concat
                                    duplicateSchoolNames = duplicateSchoolNames + (`Row number ${data.RowNumber} has ${data.Message}` + "\n");
                                    return null;
                                })
                            }
                            setDuplicateRowsInFile(duplicateSchoolNames);
                            handleSnackbarClick("fileUploadError");
                            return null;
                        } else if (response.MessageID === 3) {
                            setLoading(false);
                            // Invalida data
                            handleSnackbarClick("inValidData");
                            return null;
                        } else if (response.MessageID === 1) {
                            setLoading(false);
                            // Invalida data
                            handleSnackbarClick("schoolListEmpty");
                            return null;
                        } else if (response.MessageID === 5) {
                            setLoading(false);
                            // Invalida data
                            handleSnackbarClick("duplicatedata");
                            return null;
                        } else if (response.MessageID === 0) {
                            setLoading(false);
                            // School Update Success Message
                            handleSnackbarClick("update");
                            if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                                setTimeout(() => {
                                    history.push("/school/school-user-logins");
                                }, 1000);
                            } else {
                                setTimeout(() => {
                                    history.push("/district/district-user-logins");
                                }, 1000);
                            }
                        } else {
                            setLoading(false);
                        }
                    })
            );
        }
    }


    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])


    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            <Stack spacing={2} sx={{ maxWidth: 1000 }}>
                <Snackbar
                    key="SnackBar"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackStatus}
                    onClose={handleSnackbarClose}
                >
                    {snackMessageFor === "update" ? (
                        <Alert
                            variant="filled"
                            severity="success"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#00c853",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {SCHOOL_BULK_UPLOAD}
                        </Alert>
                    ) : (
                        <Alert
                            variant="filled"
                            severity="warning"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#ffc107",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {snackMessageFor === "duplicateError"
                                ? SCHOOL_DUPLICATE_EXIST
                                : snackMessageFor === "noSchoolName"
                                    ? NO_SCHOOL_NAME_FOUND
                                    : snackMessageFor === "fileUploadError"
                                        ? duplicateRowsInFile
                                        : snackMessageFor === "inValidData"
                                            ? INVALID_DATA
                                            : snackMessageFor === "noData"
                                                ? NO_DATA
                                                : snackMessageFor === "inValidColumnName"
                                                    ? INVALID_COLUMN_DATA : snackMessageFor === "noFile" ? "Please upload a file" : snackMessageFor === "duplicatedata" ? "Duplicate data found"
                                                        : ""}
                        </Alert>

                    )}
                </Snackbar>
            </Stack>
            <CustomHeader
                licenseStatus={licenseStatus}
                needBreadCrumb={true}
                previousPageLabel={"User Logins"}
                currentPageLabel={"Bulk Upload School"}
                onClickPrevPageLabel={() => {
                    const navigateTo = userInfo && userInfo.UserType === "Supergrouptechadmin" ? "/school/school-user-logins" : "/district/district-user-logins"
                    history.push(navigateTo);
                }}
            />

            <Box className="customWelcomeCard">
                <Box className="customWelcomePhase1">
                    <Box className='weeklyReportWrapper'>
                        {/* <Box className="customWelcomePhase1">
                                <Box className="customWelcomeText1">School Name</Box>
                                <Box className="customWelcomeText2">{"schoolUsageData && schoolUsageData.schoolName"}</Box>
                            </Box> */}
                    </Box>
                </Box>
                <Box className="customWelcomePhase2">
                    {userInfo && userInfo.UserType === "DistrictAdmin" ? (
                        <a
                            className="anchorStyle"
                            style={{ textDecoration: "none" }}
                            href={require("./District_Logins/Sample_Bulkupload_User_School.xlsx")}
                            download="Sample_Bulkupload_User_School"
                        >
                            <Download size={16} />
                            Download Sample file
                        </a>
                    ) : (
                        <a
                            className="anchorStyle"
                            style={{ textDecoration: "none" }}
                            href={require("./Sample_Bulkupload_User_School.xlsx")}
                            download="Sample_Bulkupload_User_School"
                        >
                            <Download size={16} />
                            Download Sample file
                        </a>
                    )
                    }
                </Box>
            </Box>

            <Box className='nsMainContent'>
                <Box className="nsMainHeader">
                    <Box className="nsMainTextHeader">
                        Bulk Upload
                    </Box>
                    <Box className="customHeaderButton">
                    </Box>
                </Box>

                {userInfo && userInfo.UserType === "Supergrouptechadmin"
                    ? null
                    : (
                        <Box className="bulkUploadInputWrapper">
                            <Box className="bulkUploadInputBox">
                                <Autocomplete
                                    {...defaultProps}
                                    value={value}
                                    onChange={(event, newValue) => {
                                        if (!newValue) {
                                            setValue(listAllSchools[0]);
                                        } else {
                                            setValue(newValue);
                                        }
                                    }}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="School names"
                                            variant="standard"
                                            sx={{
                                                // width: "50%",
                                                '& label.Mui-focused': {
                                                    color: "#2d8f3c",
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: '#2d8f3c',
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    )
                }
                <Box className="gridWrapper bulkUploadHead fileuploadStyle" style={{ padding: "0.75rem" }}>
                    <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        className='bulkupLoadSchool'
                    />
                    <Box className="bulkUploadPhase2">
                        <Box>
                            <FormControlLabel
                                label={"Send Email to all"}
                                sx={{
                                    margin: 0,
                                    width: "100%",
                                }}
                                control={
                                    <Checkbox
                                        checked={checkBoxData}
                                        onChange={(e) => {
                                            setCheckBoxData(e.target.checked);
                                        }}
                                    />
                                }
                            />
                        </Box>
                        <Box className="createButtonParent">
                            <Box className="createButtonChild">
                                <CustomTableButton
                                    isInActive={true}
                                    buttonLabel={"Cancel"}
                                    onClickButton={() => {
                                        if (userInfo && userInfo.UserType === "Supergrouptechadmin") {
                                            history.push("/school/school-user-logins");
                                        } else {
                                            history.push("/district/district-user-logins");
                                        }
                                    }}
                                    buttonIcon={<Close size={16} />}
                                />
                            </Box>
                            <Box className="createButtonChild">
                                <CustomTableButton
                                    isInActive={false}
                                    disabled={duplicateCheck}
                                    buttonLabel={"Upload"}
                                    onClickButton={uploadSchoolList}
                                    buttonIcon={<Upload size={16} />}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default UserLoginsSchoolBulkUpload;