/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import "../ManageSchools/ManageSchools.css"
import {
    Stack,
    Typography,
    Box,
    Backdrop,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "../SchoolLeaderboard/Leaderboard.css";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Creators from "../../redux/ducks/actions";
import StarIcon from '@mui/icons-material/Star';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Slide from '@mui/material/Slide';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NSPieChart from '../WeeklyReports/NSPieChart';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClassLeaderboard() {

    const [districtProductData, setDistrictProductData] = useState([]);
    let iconCSS = { height: '5vh', width: '5vh', margin: "0% 1%", marginBottom: "3px" };

    const getFromDate = () => {
        let todaysDate = new Date();
        let date = todaysDate.getDate();
        let month = todaysDate.getMonth();
        let year = todaysDate.getFullYear();
        month = month + 1;
        return (year - 1)
            + "-"
            + ((month).toString().length === 1 ? (0 + month.toString()) : month)
            + "-"
            + ((date).toString().length === 1 ? (0 + date.toString()) : date)
    }
    const getToDate = () => {
        let todaysDate = new Date();
        let date = todaysDate.getDate();
        let month = todaysDate.getMonth();
        let year = todaysDate.getFullYear();
        month = month + 1;
        return (year)
            + "-"
            + ((month).toString().length === 1 ? (0 + month.toString()) : month)
            + "-"
            + ((date).toString().length === 1 ? (0 + date.toString()) : date)
    }

    const dispatch = useDispatch();
    const [dataResult, setDataResult] = useState("minutes")
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [fromDate, setFromDate] = React.useState(getFromDate());
    const [toDate, setToDate] = React.useState(getToDate());
    const [minDate, setMinDate] = React.useState("");
    const [maxDate, setMaxDate] = React.useState("");
    // const topData = leaderboardData.slice(0, 3);
    // const tableData = leaderboardData.slice(3, leaderboardData.length);
    const { userInfo } = useSelector(state => state.data);
    const [loading, setLoading] = React.useState(false);
    // Popup
    const [openPopup, setOpenPopup] = React.useState(false);
    const [popupData, setPopupData] = React.useState(false);

    const handleClickOpen = () => {
        setOpenPopup(true);
    };

    const handleClose = () => {
        setOpenPopup(false);
    };

    const handleFromDate = (date) => {
        setMinDate(date)
        try {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const finaldate = `${year}-${month}-${day}`;
            setFromDate(finaldate)
        }
        catch (err) {
            console.log(err, "error")
        }
    }

    const handleToDate = (date) => {
        // Get the current date
        const currentDate = new Date();

        // Check if the selected date is greater than the current date
        if (
            date.getFullYear() > currentDate.getFullYear() ||
            (date.getFullYear() === currentDate.getFullYear() &&
                date.getMonth() > currentDate.getMonth()) ||
            (date.getFullYear() === currentDate.getFullYear() &&
                date.getMonth() === currentDate.getMonth() &&
                date.getDate() > currentDate.getDate())
        ) {
            // If the selected date is in the future, set it to the current date
            date = currentDate;
        }
        setMaxDate(date)
        try {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const finaldate = `${year}-${month}-${day}`;
            setToDate(finaldate)
        }
        catch (err) {
            console.log(err, "error")
        }
    }

    const setDataType = (e) => {
        setDataResult(e.target.id);
    }

    const getDateLB = () => {
        setLoading(true);
        let form = {
            DistrictID: userInfo.DomainID,
            FromDate: fromDate,
            ToDate: toDate,
        }
        if (dataResult === "minutes") {
            dispatch(Creators.getClassLeaderboardData(
                form,
                (response) => {
                    setLoading(false);
                    if (response) {
                        setLeaderboardData(response.MinutesPlayed)
                    }
                }
            ));
        }
        else if (dataResult === "problems") {
            dispatch(Creators.getClassLeaderboardData(
                form,
                (response) => {
                    setLoading(false);
                    if (response) {
                        setLeaderboardData(response.ProblemsSolved)
                    }
                }
            ));
        }
        else if (dataResult === "levels") {
            dispatch(Creators.getClassLeaderboardData(form,
                (response) => {
                    setLoading(false);
                    if (response) {
                        setLeaderboardData(response.GamesCompleted)
                    }
                }
            ));
        }
    }

    React.useEffect(() => {
        // Setting min and max date
        let currentDate = new Date();
        setMaxDate(currentDate)
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let date = currentDate.getDate();
        var prevDate = new Date(year, month, date);
        prevDate.setFullYear(prevDate.getFullYear() - 1);
        setMinDate(prevDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        getDateLB();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate, fromDate, dataResult])

    useEffect(() => {
        dispatch(Creators.getDistrictLicenseList((response) => {
            setDistrictProductData(response);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setLicenseDate = (date) => {
        // setWeeklyData({
        //     ...weeklyData,
        //     fromDate: date
        // })
        setFromDate(date);
    }

    useEffect(() => {
        const result = districtProductData?.filter(data => data.DistrictID === userInfo.DomainID);
        if (result.length !== 0) {
            const { SubscriptionDate } = result[0];
            const Date = SubscriptionDate.split(" ")[0];
            const getDate = Date.split("/");
            const purchaseFromDate = getDate[2] + "-" + getDate[0] + "-" + getDate[1]
            if (purchaseFromDate !== fromDate) {
                setLicenseDate(purchaseFromDate);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [districtProductData])

    return (
        <React.Fragment>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            {/* Popup */}
            <Dialog
                open={openPopup}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                maxWidth={"md"}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Box>Details</Box>
                    <HighlightOffIcon
                        color='error'
                        onClick={handleClose}
                        sx={{ cursor: "pointer" }}
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography component={'span'} variant={'body1'}>
                                    School Name
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography component={'b'} variant={'body1'} sx={{ color: "#000" }}>
                                    : {popupData?.SchoolName}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography component={'span'} variant={'body1'}>
                                    Class Name
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography component={'b'} variant={'body1'} sx={{ color: "#000" }}>
                                    : {popupData?.ClassName}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography component={'span'} variant={'body1'}>
                                    Teacher Name
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography component={'b'} variant={'body1'} sx={{ color: "#000" }}>
                                    : {popupData?.TeachersName ? popupData?.TeachersName : ""}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography component={'span'} variant={'body1'}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography component={'b'} variant={'body1'} sx={{ color: "#000" }}>
                                    : {popupData &&
                                        (dataResult === "minutes" ? `${popupData?.CategoryTotal} Minutes Played` :
                                            dataResult === "problems" ? `${popupData?.CategoryTotal} Problems Solved` :
                                                dataResult === "levels" ? `${popupData?.CategoryTotal} Levels Mastered` :
                                                    null)
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        {popupData && popupData.Skills &&
                            (
                                <React.Fragment>
                                    <Box
                                        className='chartStylePieHead'
                                    >
                                        <Box className='chartStylePie'>
                                            <NSPieChart
                                                value={popupData.ConceptData}
                                                name={popupData.ConceptName}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className='ConceptTable'>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Skill</TableCell>
                                                    <TableCell align="left">Concept</TableCell>
                                                    <TableCell align="left">
                                                        {dataResult === "minutes" ? `Minutes Played` :
                                                            dataResult === "problems" ? `Problems Solved` :
                                                                dataResult === "levels" ? `Levels Mastered` :
                                                                    null}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {popupData.Skills.map((row) => (
                                                    <TableRow
                                                        key={row.SkillName}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="left">{row.SkillName}</TableCell>
                                                        <TableCell align="left">{row.ConceptName}</TableCell>
                                                        <TableCell align="left">{row.SkillValue}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </React.Fragment>
                            )
                        }
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <div className="ClassPage">
                <Box
                    className="HeaderBoxResponsive"
                    zIndex={loading ? 1 : 9999}
                >
                    <Stack direction="row" spacing={1}>
                        <StarIcon
                            sx={{
                                color: "#2d8f3c",
                                display: "flex",
                                alignSelf: "center",
                            }}
                        />
                        <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            sx={{
                                "&.MuiTypography-h6": {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontWeight: 700,
                                },
                            }}
                        >
                            Class Leaderboard
                        </Typography>
                    </Stack>
                </Box>
                <div className="dateContainer">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="From Date"
                            format="MM/dd/yyyy"
                            value={fromDate}
                            onChange={handleFromDate}
                            maxDate={maxDate}
                            renderInput={(params) =>
                                <TextField
                                    required
                                    sx={{ width: "90%" }}
                                    onKeyDown={e => e.preventDefault()}
                                    {...params}
                                    variant="standard"
                                />
                            }
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            className='toDate'
                            label="To Date"
                            format="MM/dd/yyyy"
                            value={toDate}
                            onChange={handleToDate}
                            minDate={minDate}
                            renderInput={(params) =>
                                <TextField
                                    required
                                    sx={{ width: "90%" }}
                                    onKeyDown={e => e.preventDefault()}
                                    {...params}
                                    variant="standard"
                                />
                            }
                        />
                    </LocalizationProvider>
                </div>
                <div className="lbSection">
                    <div className="wrapper">
                        <nav>
                            <input type="radio" name="tab" id="minutes" onClick={setDataType}></input>
                            <input type="radio" name="tab" id="problems" onClick={setDataType}></input>
                            <input type="radio" name="tab" id="levels" onClick={setDataType}></input>
                            <label
                                htmlFor="minutes"
                                className="minutes"
                                style={{
                                    color: dataResult === "minutes" ? "#ffffff" : "#000000"
                                }}
                            >
                                <AlarmOnIcon sx={iconCSS} />
                                Minutes Played
                            </label>
                            <label
                                htmlFor="problems"
                                className="problems"
                                style={{
                                    color: dataResult === "problems" ? "#ffffff" : "#000000"
                                }}
                            >
                                <SmartToyIcon sx={iconCSS} />
                                Problems Solved
                            </label>
                            <label
                                htmlFor="levels"
                                className="levels"
                                style={{
                                    color: dataResult === "levels" ? "#ffffff" : "#000000"
                                }}
                            >
                                <AutoGraphIcon sx={iconCSS} />
                                Levels Mastered
                            </label>
                            <div className="tab"></div>
                        </nav>
                    </div>
                    <div className="lb-bottom">
                        <div className="tableContainer">
                            {!loading && leaderboardData && leaderboardData.map((row, index) => (
                                <Box
                                    key={index + "lbd"}
                                    sx={{
                                        color:
                                            index === 0 ? "#ff9a00" :
                                                index === 1 ? "#8b8b8b" :
                                                    index === 2 ? "#964b00" :
                                                        "#000000",
                                        backgroundColor:
                                            index === 0 ? "#ffedd0" :
                                                index === 1 ? "#e8e8e8" :
                                                    index === 2 ? "#ffe1c4" :
                                                        "#ffffff",
                                    }}
                                    className="lbBox"
                                    onClick={() => {
                                        let form = {
                                            GroupID: row.GroupID,
                                            FromDate: fromDate,
                                            ToDate: toDate,
                                            Category:
                                                dataResult === "minutes" ? "mp" :
                                                    dataResult === "problems" ? `ps` :
                                                        dataResult === "levels" ? `lm` :
                                                            null
                                        }
                                        dispatch(Creators.classLbPopup(form, (response) => {
                                            handleClickOpen()
                                            setPopupData(response);
                                        }
                                        ));
                                    }}
                                >
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "10%",
                                    }}>
                                        {
                                            index === 0 ? <>
                                                <MilitaryTechIcon sx={{ color: "#ffb61c" }} />
                                                {index + 1}
                                            </> :
                                                index === 1 ? <>
                                                    <MilitaryTechIcon sx={{ color: "#b6b6b9" }} />
                                                    {index + 1}
                                                </> :
                                                    index === 2 ? <>
                                                        <MilitaryTechIcon sx={{ color: "#9b774b" }} />
                                                        {index + 1}
                                                    </> :
                                                        <>
                                                            <ArrowDropUpIcon sx={{ color: "#2d8f3c" }} />
                                                            {index + 1}
                                                        </>
                                        }
                                    </Box>
                                    <Box sx={{
                                        display: "flex",
                                        // justifyContent: "center",
                                        alignItems: "center",
                                        width: "55%"
                                    }}>
                                        {row?.TeachersName}
                                    </Box>
                                    <Box sx={{
                                        flexDirection: "column",
                                        width: "25%"
                                    }}>
                                        <Box>
                                            {row?.SchoolName}
                                        </Box>
                                        <Box sx={{ fontSize: "12px" }}>
                                            {row?.ClassName}
                                        </Box>
                                    </Box>
                                    <Box
                                        className='totalDetails'
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "10%"
                                        }}>
                                        <Box>
                                            {
                                                dataResult === "minutes" ? `${row?.MinutesPlayed}` :
                                                    dataResult === "problems" ? `${row?.ProblemsSolved}` :
                                                        dataResult === "levels" ? `${row?.GamesCompleted}` :
                                                            null
                                            }
                                        </Box>
                                        <Box>
                                            {
                                                dataResult === "minutes" ? ` Minutes` :
                                                    dataResult === "problems" ? ` Problems` :
                                                        dataResult === "levels" ? ` Levels` :
                                                            null
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                            {
                                !loading &&
                                    (leaderboardData === null ||
                                        (leaderboardData &&
                                            leaderboardData.length === 0)
                                    ) ?
                                    <Box sx={{ padding: "2%" }}>There is no activity for the selected date range.Please change the date range.</Box> :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <Box sx={{ height: "15px" }} />
            </div>
        </React.Fragment>
    )
}