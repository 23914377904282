import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Stack,
    Breadcrumbs,
    Typography,
    Tooltip,
    CircularProgress,
    Backdrop,
} from '@mui/material';
import AllActions from "../../redux/ducks/actions";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useParams } from "react-router-dom";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import CustomHeader from "../../Components/CustomHeader";

function SchoolClassDetailsSummary(props) {
    const dispatch = useDispatch(); //dispatch
    const history = useHistory(); //history
    const { userInfo, schoolUsageData } = useSelector((state) => state.data);
    const [tableRow, setTableRow] = useState([]);
    const { classUsageData } = useSelector((state) => state.data);
    const url = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(AllActions.schoolClassDetailsSummaryCS(Number(url.id), (response) => {
            setTableRow(response);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            name: "Skill",
            options: {
                filter: true,
            }
        },
        {
            name: "Games Completed",
            options: {
                filter: true,
            }
        },
        {
            name: "Beginner,Advanced,Sensei",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (value, tableMeta, updateValue) => {
                    return (
                        <th className="MuiTableCell-head MuiTableCell-root MuiTableCell-sizeMedium th-border-style">
                            <tr className="headerTrStyle">
                                <th colSpan={3}>Levels Mastered</th>
                            </tr>
                            <tr className="headerTrStyle">
                                <th className="headerThStyle">Beginner</th>
                                <th className="headerThStyle">Advanced</th>
                                <th className="headerThStyle">Sensei</th>
                            </tr>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box className="bodyBoxStyle">
                            <Box className="bodyinnerBoxStyle">{value.Beg}</Box>
                            <Box className="bodyinnerBoxStyle">{value.Adv}</Box>
                            <Box className="bodyinnerBoxStyle">{value.Sen}</Box>
                        </Box>
                    );
                }
            }
        },
    ];

    const options = {
        selectableRows: false,
        filter: false,
        download: false,
        print: false,
        rowsPerPage: 25,
    };


    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: "99999999"
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            <CustomHeader
                licenseStatus={licenseStatus}
                needBreadCrumb={true}
                previousPageLabel={`${classUsageData.className} Dashboard`}
                currentPageLabel={"Activity Summary"}
                onClickPrevPageLabel={() => { history.push(`/school/school-class-usage-data/class-details/${schoolUsageData.classId}`) }}
            />
            <CustomWelcomeCard
                welcomCardData={[]}
                cardLabel={"Class Name"}
                cardValue={`${classUsageData.className}`}
            />

            <Box className="nsMainContent">
                <MUIDataTable
                    title={`${classUsageData.className} - Activity Summary`}
                    data={tableRow ? tableRow.map(data => {
                        const levelsMastered = {
                            Beg: data.Beg,
                            Adv: data.Adv,
                            Sen: data.Sen,
                        }
                        return [
                            data.skill,
                            data.Games,
                            levelsMastered,
                        ]
                    }) : []}
                    columns={columns}
                    options={options}
                />
            </Box>
        </Box >
        // <Box sx={{
        //     width: "100%",
        //     overflow: 'auto',
        //     padding: "0 15px",
        // }}>
        //     <Box className="HeaderBoxResponsive">
        //         <Box className='HeaderContent'>
        //             <Stack direction="row" spacing={1}>
        //                 <QueryStatsIcon
        //                     sx={{
        //                         color: "#2d8f3c",
        //                         display: "flex",
        //                         alignSelf: "center",
        //                     }}
        //                 />
        //                 <Typography
        //                     variant="h6"
        //                     gutterBottom
        //                     component="div"
        //                     sx={{
        //                         "&.MuiTypography-h6": {
        //                             display: "flex",
        //                             alignItems: "center",
        //                             justifyContent: "center",
        //                             fontWeight: 700,
        //                         },
        //                     }}
        //                 >
        //                     Class - Activity Summary
        //                 </Typography>
        //             </Stack>
        //             <Breadcrumbs
        //                 aria-label="breadcrumb"
        //                 separator={<NavigateNextIcon fontSize="small" />}
        //             >
        //                 <Link
        //                     style={{
        //                         display: "flex",
        //                         alignItems: "center",
        //                         color: "inherit",
        //                         textDecoration: "none",
        //                         cursor: "pointer"
        //                     }}
        //                     to={}
        //                 >
        //                     <Tooltip title={`${classUsageData.className} Dashboard`}>
        //                         <DashboardIcon sx={{ mr: 0.5 }} fontSize="medium" />
        //                     </Tooltip>
        //                     <span className="BreadcrumbName">{classUsageData.className} Dashboard</span>
        //                 </Link>
        //                 <Typography sx={{ display: "flex", alignItems: "center" }}>
        //                     <Tooltip title="Activity Summary">
        //                         <QueryStatsIcon sx={{ mr: 0.5 }} fontSize="medium" />
        //                     </Tooltip>
        //                     <span className="BreadcrumbName">Activity Summary</span>
        //                 </Typography>
        //             </Breadcrumbs>
        //         </Box>
        //     </Box>
        //     <Box>

        //     </Box>
        //     <Box className="heightInc"></Box>
        // </Box >
    );
}
export default SchoolClassDetailsSummary;
