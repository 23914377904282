import "../ManageDistrict/ManageDistrict.css";
import {
    Box,
    Breadcrumbs,
    Typography,
    Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../redux/ducks/actions";
import CustomHeader from "../../Components/CustomHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function ViewAllNotifications() {

    const dispatch = useDispatch();
    const history = useHistory(); //History
    const { userInfo } = useSelector((state) => state.data);

    const [allNotifications, setAllNotifications] = useState([]);
    useEffect(() => {
        dispatch(allActions.getAllNotifications((response) => { setAllNotifications(response) }));
    }, [dispatch]);

    const columns = [
        "Notification message",
        "District name",
        "School name",
        "Created date",
        "Max allowed student",
        "Current no of student",
    ];

    const options = {
        selectableRows: false,
        filter: false,
        download: false,
        print: false,
        rowsPerPage: 25,
    };


    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])


    return (
        <Box className='districtStyle'>

            <CustomHeader
                hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
                licenseStatus={licenseStatus}
                needBreadCrumb={true}
                previousPageLabel={"Notifications"}
                currentPageLabel={"All Notifications"}
                onClickPrevPageLabel={() => { history.push("/admin/admin-notification") }}
            />

            <Box className="noHeaderButton" />

            <Box className="nsMainContent">
                <MUIDataTable
                    title={"All Notifications"}
                    data={allNotifications && allNotifications.map(data => {
                        return [
                            data.NotificationMessage,
                            data.DistrictName,
                            data.SchoolName,
                            data.CreatedDate,
                            data.MaxStudents,
                            data.NoOfStudents,
                        ]
                    })}
                    columns={columns}
                    options={options}
                />
            </Box>

        </Box>
    );
}