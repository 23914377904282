import React from 'react';
import PerformCard from './PerformCard';
import { Typography } from '@mui/material';
import { Column, Grid } from '@carbon/react';

export default function PerformContainer(props) {
    return (
        <React.Fragment>
            <Grid className='nsAdminPadding'>
                <Column lg={4} md={4} sm={4}>
                    <PerformCard cardName="Minutes Played" data={props.data.Minutes} styleName={"MinutesPlayedColor"} />
                </Column>
                <Column lg={4} md={4} sm={4}>
                    <PerformCard cardName="Problems Solved" data={props.data.ProblemsCompleted} styleName={"ProblemsSolvedColor"} />
                </Column>
                <Column lg={4} md={4} sm={4}>
                    <PerformCard cardName="Games Completed" data={props.data.LevelsAttempted} styleName={"GamesCompletedColor"} />
                </Column>
                <Column lg={4} md={4} sm={4}>
                    <PerformCard cardName="Levels Mastered" data={props.data.LevelsMastered} styleName={"LevelsMasteredColor"} />
                </Column>
            </Grid>
        </React.Fragment>
    )
}