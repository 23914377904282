import React, { useState, useEffect } from "react";
import "./TeacherClassUsageData.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Backdrop,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AllActions from "../../redux/ducks/actions";

// Icons
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ChartBar, ChartLine, Education, List, Report, User } from "@carbon/icons-react";
// import Creators from "../../redux/ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomHeader from "../../Components/CustomHeader";
import CustomWelcomeCard from "../../Components/CustomWelcomeCard";
import { FormatNumbers } from "../../constants";
import { Grid, Column } from "@carbon/react";
import CustomClassCard from "../../Components/CustomClassCard";
import CustomTableButton from "../../Components/CustomTableButton";
import CircularProgress from '@mui/material/CircularProgress';

export default function TeacherClassUsageDataDashboard() {
    const history = useHistory(); //history
    const dispatch = useDispatch();
    const [classUsageDashboardData, setClassUsageDashboardData] = useState([]);
    const [expirationDate, setExpirationDate] = useState("");
    const { userInfo } = useSelector(state => state.data); //state data
    const url = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(AllActions.classUsageDataSchoolDashboard(Number(url.id), (response) => {
            setClassUsageDashboardData(response);
            setExpirationDate(userInfo.LicenseExpiryDate)
            setLoading(false);
            // dispatch(Creators.setClassId(Number(url.id)));
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [licenseStatus, setLicenseStatus] = React.useState(false);
    React.useEffect(() => {
        if (expirationDate) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = expirationDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classUsageDashboardData, expirationDate])


    const mdTheme = createTheme();

    const welcomCardData = [
        {
            label: "Total Students",
            icon: <User className="welcomeCardIcons" />,
            value: classUsageDashboardData.TotalStudents
        },
        {
            label: "Total Teachers",
            icon: <Education className="welcomeCardIcons" />,
            value: classUsageDashboardData.TotalTeachers
        },
    ]

    return (
        <ThemeProvider theme={mdTheme}>
            <Box className='districtStyle'>
                <Backdrop
                    sx={{
                        background: "rgb(0 0 0 / 30%)",
                        color: '#fff',
                        zIndex: "99999999"
                    }}
                    open={loading}
                >
                    <CircularProgress style={{ color: "#008000" }} />
                </Backdrop>
                <CustomHeader
                    licenseStatus={licenseStatus}
                    needBreadCrumb={true}
                    previousPageLabel={"Dashboard"}
                    currentPageLabel={"Classes"}
                    onClickPrevPageLabel={() => { history.push("/teacher/teacher-dashboard") }}
                />
                <CustomWelcomeCard
                    welcomCardData={welcomCardData}
                    cardLabel={"Class Name"}
                    cardValue={`${classUsageDashboardData.ClassName}`}
                />

                <Box className='nsMainContent'>
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            Performance
                        </Box>
                        <Box className="customHeaderButton" id={`class-buttons-id`}>
                            <CustomTableButton
                                buttonLabel={"Reports"}
                                onClickButton={() => {
                                    history.push(`/teacher/teacher-dashboard/class-weekly-report/${Number(url.id)}`)
                                }}
                                buttonIcon={<Report size={16} />}
                            />
                            <CustomTableButton
                                buttonLabel={"Daily Summary"}
                                onClickButton={() => {
                                    history.push(`/teacher/teacher-dashboard/class-daily-summary/${Number(url.id)}`)
                                }}
                                buttonIcon={<ChartBar size={16} />}
                            />
                            <CustomTableButton
                                buttonLabel={"Activity  Summary"}
                                onClickButton={() => {
                                    history.push(`/teacher/teacher-dashboard/class-details-summary/${Number(url.id)}`)
                                }}
                                buttonIcon={<ChartLine size={16} />}
                            />
                            <CustomTableButton
                                buttonLabel={"Student List"}
                                onClickButton={() => {
                                    history.push(`/teacher/teacher-dashboard/student-list/${Number(url.id)}`)
                                }}
                                buttonIcon={<List size={16} />}
                            />
                        </Box>
                    </Box>

                    <Box
                        className="gridWrapper"
                        style={{
                            padding: "0.75rem"
                        }}
                    >
                        <Grid>
                            <Column sm={4} md={4} lg={4}>
                                <CustomClassCard
                                    cardLabel={FormatNumbers(classUsageDashboardData.Minutes)}
                                    cardValue={"Minutes Played"}
                                    backgroundColor={"linear-gradient(225deg, #D896FF 0%, #F57373 100%)"}
                                />
                            </Column>
                            <Column sm={4} md={4} lg={4}>
                                <CustomClassCard
                                    cardLabel={FormatNumbers(classUsageDashboardData.ProblemsCompleted)}
                                    cardValue={"Problems Solved"}
                                    backgroundColor={"linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.00) 96.41%), linear-gradient(132deg, #667EEA 6.78%, #764BA2 93.86%)"}
                                />
                            </Column>
                            <Column sm={4} md={4} lg={4}>
                                <CustomClassCard
                                    cardLabel={FormatNumbers(classUsageDashboardData.LevelsAttempted)}
                                    cardValue={"Games Completed"}
                                    backgroundColor={"linear-gradient(225deg, #41A7C4 0%, #3E72E8 100%)"}
                                />
                            </Column>
                            <Column sm={4} md={4} lg={4}>
                                <CustomClassCard
                                    cardLabel={FormatNumbers(classUsageDashboardData.LevelsMastered)}
                                    cardValue={"Levels Mastered"}
                                    backgroundColor={"linear-gradient(225deg, #95CC4E 0%, #2ED8F7 100%)"}
                                />
                            </Column>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    );
}