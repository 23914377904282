import { Checkbox } from "@carbon/react";
import PropTypes from "prop-types";

export default function CustomCheckBox({
    name,
    size,
    text,
    isChecked,
    onHandleClick,
    hideCheckBox,
    checkBoxType,
    id,
    className,
}) {
    if (checkBoxType === 'default') {
        return (
            <Checkbox
                id={name}
                className={className}
                name={name}
                checked={isChecked}
                labelText={text}
                onChange={(e) => onHandleClick(e.target.checked)}
            />
        )
    }
    return (
        <div className="remember-main" id={id}>
            <input
                id={name}
                name={name}
                checked={isChecked}
                style={{
                    display: hideCheckBox ? "none" : "",
                    fontSize: size === "large" ? "18px" :
                        size === "medium" ? "16px" : "14px",
                }}
                type="checkbox"
                className="custom-checkbox"
                onChange={(e) => onHandleClick(e.target.checked)}
            />
            <label
                htmlFor={name}
                className="custom-checkbox-label"
            >
                {text}
            </label>
        </div>
    );
}

CustomCheckBox.propTypes = {
    name: PropTypes.string,
    text: PropTypes.any,
    isChecked: PropTypes.bool,
    onHandleClick: PropTypes.func,
    hideCheckBox: PropTypes.bool,
}