import React, { useEffect, useState } from "react";
import Creators from "../../redux/ducks/actions";
import { useHistory, useParams } from "react-router-dom";
import { Stack, Box, Snackbar, Alert, Backdrop } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from "react-redux";
import './ManageDistrictLicense.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
    SUBSCRIPTION_SUCCESS,
} from "../../constants";
import CircularProgress from '@mui/material/CircularProgress';
import { Close, Edit } from "@carbon/icons-react";
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";

export default function MDLSubscribe() {
    const Btncolor = "#2d8f3c";
    const dispatch = useDispatch();
    const url = useParams();
    const history = useHistory();
    const [date, setDate] = useState(new Date());
    const { userInfo, listAllProducts } = useSelector((state) => state.data);
    // Loading
    const [loading, setLoading] = React.useState(false);

    // Action Status
    const [snackbar, setSnackbar] = React.useState({
        snackStatus: false,
        snackMessageFor: "update",
    });
    const { snackStatus, snackMessageFor } = snackbar;

    const handleSnackbarClick = (msg) => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: true,
            snackMessageFor: msg,
        }));
    };

    const handleSnackbarClose = () => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: false,
            snackMessageFor: "",
        }));
    };


    const [form, setForm] = useState({
        DistrictID: 0,
        ProductID: "",
        SubscriptionDate: "",
    });

    useEffect(() => {
        if (url) {
            const data = new Date();
            let newMonth = Number(data.getMonth()) + 1;
            let newDate = Number(data.getDate());
            let monthLength = newMonth.toString().length;
            let dateLength = newDate.toString().length;
            let date = dateLength === 1 ? "0" + newDate : newDate;
            let month = monthLength === 1 ? "0" + newMonth : newMonth;
            let year = data.getFullYear();
            console.log(listAllProducts, "listAllProducts")
            setForm({
                ...form,
                DistrictID: url.DistrictID,
                ProductID: listAllProducts && listAllProducts.length === 0 ? "" : listAllProducts.length > 5 ? listAllProducts.at(-7).ProductID : listAllProducts.at(2).ProductID,
                SubscriptionDate: month + "/" + date + "/" + year,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleUpdateClick = () => {
        setLoading(true)
        dispatch(
            Creators.manageDistrictLicenceSubscription(form, (response) => {
                if (response.MessageID === 0) {
                    // Course Update Success Message
                    handleSnackbarClick("create");
                    setLoading(false)
                    setTimeout(() => {
                        history.push(`/admin/admin-manage-district-license`);
                    }, 1000);
                } else if (response.MessageID === 1) {
                    // Course name already exist Error
                    console.error(response, "Already Created");
                    return null;
                } else if (response.MessageID === 2) {
                    // Invalid Data
                    console.error(response, "Invalid Data");
                } else if (response.MessageID === 3) {
                    // Empty field
                    console.error(response, "response");
                } else {
                    console.error(response, "response");
                }
            })
        );
    }

    const handleChange = (e) => {
        console.log(e.target.value, "value");
        console.log(e.target.name, "name");
        setForm((state) => ({
            ...state,
            [e.target.name]: e.target.value,
        }));
    };

    const handleTimeChange = (data) => {
        if (data) {
            let newMonth = Number(data.getMonth()) + 1;
            let newDate = Number(data.getDate());
            let monthLength = newMonth.toString().length;
            let dateLength = newDate.toString().length;
            let date = dateLength === 1 ? "0" + newDate : newDate;
            let month = monthLength === 1 ? "0" + newMonth : newMonth;
            let year = data.getFullYear();
            setForm((state) => ({
                ...state,
                SubscriptionDate: year + '-' + month + '-' + date,
            }));
            setDate(month + '/' + date + '/' + year)
        }
    }

    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])


    return (
        <React.Fragment>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            {/* Action Status */}
            <Stack spacing={2} sx={{ maxWidth: 1000 }}>
                <Snackbar
                    key="SnackBar"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackStatus}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        variant="filled"
                        severity="success"
                        sx={{
                            "&.MuiPaper-root": {
                                background: "#00c853",
                                color: "#fff",
                            },
                        }}
                        action={
                            <CloseRoundedIcon
                                sx={{ cursor: "pointer", color: "#fff" }}
                                onClick={() => handleSnackbarClose()}
                                color="inherit"
                                size="small"
                            />
                        }
                    >
                        {snackMessageFor === "create"
                            ? SUBSCRIPTION_SUCCESS
                            : ""}
                    </Alert>
                </Snackbar>
            </Stack>

            <Box className='districtStyle'>
                <CustomHeader
                    hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
                    licenseStatus={licenseStatus}
                    needBreadCrumb={true}
                    previousPageLabel={"Manage District License"}
                    currentPageLabel={"Create District Subscription"}
                    onClickPrevPageLabel={() => { history.push("/admin/admin-manage-district-license") }}
                />
                <Box className="noHeaderButton" />
                <Box className='nsMainContent'>
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            Create District Subscription
                        </Box>
                        <Box className="customHeaderButton" />
                    </Box>
                    <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
                        <Box className="contentSection">
                            <Box className="fieldParentCU">
                                <Box className="fieldChildCU">
                                    <Box className="fieldLabel">License</Box>
                                    <FormControl size="small" fullWidth sx={{ minWidth: 120, width: "90%" }}>
                                        {/* <InputLabel id="License-label">License</InputLabel> */}
                                        <Select
                                            variant="outlined"
                                            labelId="License-label"
                                            value={form.ProductID}
                                            label="License"
                                            onChange={(e) => handleChange(e)}
                                            name="ProductID"
                                        >
                                            {listAllProducts.map(index => {
                                                return (
                                                    <MenuItem key={`key-${index}`} value={index.ProductID}>
                                                        {index.ProductName} - ({index.Duration} Months)
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box className="fieldChildCU">
                                    <Box className="fieldLabel">Start Date</Box>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        sx={{ minWidth: 120, width: "90%" }}
                                    >
                                        <DatePicker
                                            minDate={new Date()}
                                            label=""
                                            inputFormat="dd/MM/yyyy"
                                            // format="MM/dd/yyyy"
                                            value={date}
                                            onChange={handleTimeChange}
                                            renderInput={(params) =>
                                                <TextField
                                                    size="small"
                                                    variant="outlined"
                                                    required
                                                    onKeyDown={e => e.preventDefault()}
                                                    {...params}
                                                />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="createButtonParent">
                            <Box className="createButtonChild">
                                <CustomTableButton
                                    isInActive={true}
                                    buttonLabel={"Cancel"}
                                    onClickButton={() => {
                                        history.push("/admin/admin-manage-district-license");
                                    }}
                                    buttonIcon={<Close size={16} />}
                                />
                            </Box>
                            <Box className="createButtonChild">
                                <CustomTableButton
                                    isInActive={false}
                                    buttonLabel={"Update"}
                                    onClickButton={handleUpdateClick}
                                    buttonIcon={<Edit size={16} />}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}