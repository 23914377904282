import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Breadcrumbs,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  Backdrop,
} from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DESCRIPTION_EMPTY,
  PRODUCT_NAME_EMPTY,
  PRODUCT_ID_EMPTY,
  PRICE_EMPTY,
  DURATION_EMPTY,
  PRODUCT_NAME_EXIST,
  PRODUCT_UPDATED,
  PRODUCT_ID_EXIST,
  MODES_EMPTY,
  DURATION_INVALID
} from "../../constants";
import Creators from "../../redux/ducks/actions";
import "./ManageProducts.css";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import { Close, Edit } from "@carbon/icons-react";

function UpdateProducts(props) {
  const dispatch = useDispatch(); // Dispatch
  const history = useHistory();
  const { userInfo } = useSelector(state => state.data);

  // Snack Message
  const [snackbar, setSnackbar] = React.useState({
    snackStatus: false,
    snackMessageFor: "update",
  });
  const { snackStatus, snackMessageFor } = snackbar;
  const handleSnackbarClick = (msg) => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: true,
      snackMessageFor: msg,
    }));
  };
  const handleSnackbarClose = () => {
    setSnackbar((state) => ({
      ...state,
      snackStatus: false,
      snackMessageFor: "",
    }));
  };

  const url = useParams(); //URl

  const [errorMode, setErrorMode] = useState(false);
  const [modes, setModes] = useState([
    {
      label: "Sample",
      value: "Trial",
      isChecked: false,
    },
    {
      label: "Numbers",
      value: "Numbers",
      isChecked: false,
    },
    {
      label: "Addition",
      value: "Addition",
      isChecked: false,
    },
    {
      label: "Subtraction",
      value: "Subtraction",
      isChecked: false,
    },
    {
      label: "Multiplication",
      value: "Multiplication",
      isChecked: false,
    },
    {
      label: "Division",
      value: "Division",
      isChecked: false,
    },
    {
      label: "Mixed Operations",
      value: "Mixed",
      isChecked: false,
    },
    {
      label: "Roman Numerals",
      value: "Roman",
      isChecked: false,
    },
    {
      label: "Pre Algebra",
      value: "Equations",
      isChecked: false,
    },
    {
      label: "Mashup",
      value: "Mashup",
      isChecked: false,
    },
  ]
  )
  const [form, setForm] = useState({
    ProductID: "",
    ProductName: "",
    ProductDesc: "",
    Price: "",
    Duration: "",
  });
  const [emptyError, setEmptyError] = useState({
    ProductID: false,
    ProductName: false,
    ProductDesc: false,
    Price: false,
    Duration: false,
  });

  // Loading
  const [loading, setLoading] = React.useState(false);

  //   update Button
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateClick = () => {
    setLoading(true);
    setUpdateLoading(true);
    const checkEmptyValue = { ...emptyError };
    let submit = true;
    // Checking empty data
    for (let items in form) {
      if (form[items] === "") {
        submit = false;
        checkEmptyValue[items] = true;
      }
    }
    // Modes validation
    let modeData = modes.some(data => data.isChecked === true)
    if (!modeData) {
      submit = false;
      setErrorMode(true)
    }
    if (form.Duration === "0") {
      submit = false;
      form.Duration = "";
      // handleSnackbarClick("duplicateError");
      // if (form.Duration === "") {
      handleSnackbarClick("durationInvalid");
      // setEmptyError({
      //   ...emptyError,
      //   Duration: true
      // })
      // }
    }
    if (!submit) {
      setEmptyError(checkEmptyValue);
      setLoading(false);
      setUpdateLoading(false);
    } else {
      let modesItem = [];
      modes.map(data => {
        if (data.isChecked === true) {
          modesItem.push(data.value)
        }
        return null;
      })
      form.Modes = modesItem.toString();
      dispatch(
        Creators.updateProductsById(form, (response) => {
          if (response.MessageID === 2) {
            // Product name already exist Error
            setUpdateLoading(false);
            setLoading(false);
            handleSnackbarClick("duplicateError");
            return null;
          } else if (response.MessageID === 0) {
            // Product Update Success Message
            handleSnackbarClick("update");
            setUpdateLoading(false);
            setLoading(false);
            setTimeout(() => {
              history.push("/admin/admin-manage-products");
            }, 1000);
          } else {
            setUpdateLoading(false);
            setLoading(false);
            console.error(response, "response");
          }
        })
      );
    }
  };

  // handle update Product
  const handleOnChangeUpdateProducts = (e) => {
    if ((e.target.id === "Price" || e.target.id === "Duration") && e.target.value < 0) {
      e.preventDefault()
    } else {
      if (e.target.value !== "") {
        setEmptyError((state) => ({
          ...state,
          [e.target.id]: false,
        }));
      }
      setForm((state) => ({
        ...state,
        [e.target.id]: e.target.value,
      }));
    }
  }


  const handleOnChangeCheckedBox = (e) => {
    console.log(e.target.value, 'e.target.value');
    // setModes
    setModes(prevState => {
      const newState = prevState.map(items => {
        if (items.value === e.target.value) {
          return { ...items, isChecked: e.target.checked };
        }
        return items;
      });

      return newState;
    });
    if (errorMode) {
      setErrorMode(false)
    }
  }

  useEffect(() => {
    dispatch(
      Creators.getProductsByID((url.id), (response) => {
        let rowData = response;
        setForm((state) => ({
          ...state,
          ProductID: rowData.ProductID,
          ProductName: rowData.ProductName,
          ProductDesc: rowData.ProductDesc,
          Price: rowData.Price,
          Duration: rowData.Duration,
        }));
        let modesData = rowData.Modes.split(',');
        setModes(prevState => {
          const newState = prevState.map(items => {
            if (modesData.includes(items.value)) {
              return { ...items, isChecked: true };
            }
            return items;
          });
          return newState;
        });
      })
    );
  }, [url, dispatch]);


  const [licenseStatus, setLicenseStatus] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>
      {/* Action Status */}
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar
          key="SnackBar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackStatus}
          onClose={handleSnackbarClose}
        >
          {snackMessageFor === "durationInvalid" || snackMessageFor === "duplicateError" || snackMessageFor === "duplicateId" ? (
            <Alert
              variant="filled"
              severity="warning"
              sx={{
                "&.MuiPaper-root": {
                  background: "#ffc107",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {snackMessageFor === "durationInvalid" ? DURATION_INVALID : snackMessageFor === "duplicateId" ? PRODUCT_ID_EXIST : PRODUCT_NAME_EXIST}
            </Alert>
          ) : (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                "&.MuiPaper-root": {
                  background: "#00c853",
                  color: "#fff",
                },
              }}
              action={
                <CloseRoundedIcon
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={() => handleSnackbarClose()}
                  color="inherit"
                  size="small"
                />
              }
            >
              {snackMessageFor === "update"
                ? PRODUCT_UPDATED
                : ""}
            </Alert>
          )}
        </Snackbar>
      </Stack>
      <Box className='districtStyle'>
        <CustomHeader
          hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
          licenseStatus={licenseStatus}
          needBreadCrumb={true}
          previousPageLabel={"Manage Product"}
          currentPageLabel={"Edit Product"}
          onClickPrevPageLabel={() => { history.push("/admin/admin-manage-products") }}
        />
        <Box className="noHeaderButton" />
        <Box className='nsMainContent'>
          <Box className="nsMainHeader">
            <Box className="nsMainTextHeader">
              Edit Product
            </Box>
            <Box className="customHeaderButton" />
          </Box>
          <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
            <Box className="createDomainParent">
              <Box className="LessonBotContent">
                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Product ID</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.ProductID ? "#f00" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="ProductID"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.ProductID}
                      onChange={handleOnChangeUpdateProducts}
                      disabled={true}
                      error={emptyError.ProductID}
                      helperText={emptyError.ProductID ? PRODUCT_ID_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Product Name</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.ProductName ? "#f00" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="ProductName"
                      label=""
                      type="text"
                      className="fieldStyle"
                      value={form.ProductName}
                      onChange={handleOnChangeUpdateProducts}
                      error={emptyError.ProductName}
                      helperText={emptyError.ProductName ? PRODUCT_NAME_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Price (USD)</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.Price ? "#f00" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="Price"
                      label=""
                      type="number"
                      onKeyDown={(e) => {
                        let charCode = (e.which) ? e.which : e.keyCode;
                        if (charCode === 37 || charCode === 39) {
                          return;
                        } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault()
                        }
                      }}
                      className="fieldStyle"
                      autoComplete="off"
                      value={form.Price}
                      onChange={handleOnChangeUpdateProducts}
                      error={emptyError.Price}
                      helperText={emptyError.Price ? PRICE_EMPTY : ""}
                    />
                  </Box>
                </Box>

                <Box className="fieldParent">
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Description</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        width: "100%",
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.ProductDesc ? "#f00" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="ProductDesc"
                      label=""
                      multiline
                      rows={1}
                      value={form.ProductDesc}
                      onChange={handleOnChangeUpdateProducts}
                      error={emptyError.ProductDesc}
                      helperText={emptyError.ProductDesc ? DESCRIPTION_EMPTY : ""}
                    />
                  </Box>
                  <Box className="fieldChild">
                    <Box className="fieldLabel">Duration(Months)</Box>
                    <TextField
                      required
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: "#2d8f3c",
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: emptyError.Duration ? "#f00" : '#2d8f3c',
                        },
                      }}
                      size="small"
                      id="Duration"
                      label=""
                      type="number"
                      onKeyDown={(e) => {
                        let charCode = (e.which) ? e.which : e.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault()
                        }
                      }}
                      className="fieldStyle"
                      value={form.Duration}
                      onChange={handleOnChangeUpdateProducts}
                      error={emptyError.Duration}
                      helperText={emptyError.Duration ? DURATION_EMPTY : ""}
                    />
                  </Box>

                </Box>

                <Box className="list">
                  <h6> List Of Concepts *</h6>
                  <Box
                    style={{
                      maxHeight: '200px',
                      maxWidth: '300px',
                      overflow: 'auto',
                      borderBottom: "1px solid #80808038",
                      borderRight: "1px solid #80808038",
                      boxShadow: "rgb(0 0 0 / 12%) 0px -px 31px 3px"
                    }}>
                    <FormControl id="Modes"
                      error={emptyError.Modes}
                      helperText={emptyError.Modes ? MODES_EMPTY : ""}
                    >
                      <FormGroup>
                        {modes && modes.map(data => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={data.isChecked}
                                value={data.value}
                                onChange={(e) => handleOnChangeCheckedBox(e)}
                              />
                            }
                            label={data.label}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Box>
                  {errorMode ? <Typography className="errorMsg"> Select at least one List Of Concepts.</Typography> : null}
                </Box>

                <Box className="createButtonParent" style={{ height: "10%" }}>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={true}
                      buttonLabel={"Cancel"}
                      onClickButton={() => {
                        history.push("/admin/admin-manage-products");
                      }}
                      buttonIcon={<Close size={16} />}
                    />
                  </Box>
                  <Box className="createButtonChild">
                    <CustomTableButton
                      isInActive={false}
                      buttonLabel={"Update"}
                      onClickButton={handleUpdateClick}
                      buttonIcon={<Edit size={16} />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );

};

//   setting data in field




export default UpdateProducts;


