import React, { useEffect, useState } from "react";
import Creators from "../../redux/ducks/actions";
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumbs, Stack, Typography, Box, Button, Backdrop, Snackbar, Alert, Autocomplete } from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSelector } from "react-redux";
import './ManageDistrictLicense.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch } from "react-redux";
import {
    INVALID_PRODUCT,
    MONTH_ISSUE,
    DISTRICT_LICENSE_UPDATE_SUCCESS_MESSAGE,
} from "../../constants";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CircularProgress from '@mui/material/CircularProgress';
import CustomHeader from "../../Components/CustomHeader";
import CustomTableButton from "../../Components/CustomTableButton";
import CancelIcon from '@mui/icons-material/Cancel';
import { Close, Edit } from "@carbon/icons-react";

export default function EditSubscription() {
    const Btncolor = "#2d8f3c";
    const dispatch = useDispatch();
    const url = useParams();
    const history = useHistory();
    // Loading
    const [loading, setLoading] = React.useState(false);
    const { userInfo } = useSelector(state => state.data);

    const { listAllProducts, listAllDistrictLicense } = useSelector((state) => state.data);

    const [form, setForm] = useState({
        DistrictID: 0,
        ProductID: "",
        SubscriptionDate: "",
    });

    let defaultProps = {
        options: listAllProducts,
        getOptionLabel: (option) => option.ProductName + " - (" + option.Duration + " Months)"
    };
    const [value, setValue] = React.useState(null);

    // Action Status
    const [snackbar, setSnackbar] = React.useState({
        snackStatus: false,
        snackMessageFor: "update",
    });
    const { snackStatus, snackMessageFor } = snackbar;

    const handleSnackbarClick = (msg) => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: true,
            snackMessageFor: msg,
        }));
    };

    const handleSnackbarClose = () => {
        setSnackbar((state) => ({
            ...state,
            snackStatus: false,
            snackMessageFor: "",
        }));
    };

    React.useEffect(() => {
        dispatch(Creators.getAllProductsList());
        dispatch(Creators.getDistrictLicenseList(response => { return null }));
        if (url) {
            listAllDistrictLicense.map(data => {
                if (Number(data.DistrictID) === Number(url.DistrictID)) {
                    setForm({
                        ...form,
                        DistrictID: data.DistrictID,
                        ProductID: data.ProductID,
                        SubscriptionDate: data.SubscriptionDate,
                    })
                    listAllProducts.map(item => {
                        if (item.ProductID === data.ProductID) {
                            setValue(item);
                        }
                        return null;
                    })
                }
                return null;
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleUpdateClick = () => {
        setLoading(true);
        form.ProductID = value.ProductID;
        dispatch(
            Creators.updateSubscription(form, (response) => {
                if (response && response.MessageID === 0) {
                    // Course Update Success Message
                    setLoading(false);
                    handleSnackbarClick("update");
                    setTimeout(() => {
                        history.push(`/admin/admin-manage-district-license`);
                    }, 1000);
                } else if (response && response.MessageID === 1) {
                    // Course name already exist Error
                    console.error(response, "Already Created");
                    setLoading(false);
                    return null;
                } else if (response && response.MessageID === 2) {
                    // Invalid Data
                    setLoading(false);
                    handleSnackbarClick("invalidData");
                    console.error(response, "Invalid Data");
                } else if (response && response.MessageID === 3) {
                    // Empty field
                    setLoading(false);
                    handleSnackbarClick("monthIssue");
                } else {
                    setLoading(false);
                    console.error(response, "response");
                }
            })
        );
    }

    const handleTimeChange = (data) => {
        if (data) {
            let monthLength = (data.getMonth()).toString().length;
            let dateLength = (data.getDate()).toString().length;
            let date = dateLength === 1 ? "0" + data.getDate() : data.getDate();
            let month = monthLength === 1 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1);
            let year = data.getFullYear();
            setForm((state) => ({
                ...state,
                SubscriptionDate: year + '/' + month + '/' + date,
            }));
        }
    }


    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    return (
        <React.Fragment>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            <Stack spacing={2} sx={{ maxWidth: 1000 }}>
                <Snackbar
                    key="SnackBar"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackStatus}
                    onClose={handleSnackbarClose}
                >
                    {snackMessageFor === "update" ? (
                        <Alert
                            variant="filled"
                            severity="success"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#00c853",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {DISTRICT_LICENSE_UPDATE_SUCCESS_MESSAGE}
                        </Alert>
                    ) : (
                        <Alert
                            variant="filled"
                            severity="warning"
                            sx={{
                                "&.MuiPaper-root": {
                                    background: "#ffc107",
                                    color: "#fff",
                                },
                            }}
                            action={
                                <CloseRoundedIcon
                                    sx={{ cursor: "pointer", color: "#fff" }}
                                    onClick={() => handleSnackbarClose()}
                                    color="inherit"
                                    size="small"
                                />
                            }
                        >
                            {
                                snackMessageFor === "invalidData" ? INVALID_PRODUCT :
                                    snackMessageFor === "monthIssue" ? MONTH_ISSUE :
                                        ""
                            }
                        </Alert>

                    )}
                </Snackbar>
            </Stack>
            <Box className='districtStyle'>
                <CustomHeader
                    hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
                    licenseStatus={licenseStatus}
                    needBreadCrumb={true}
                    previousPageLabel={"Manage District License"}
                    currentPageLabel={"Edit District Subscription"}
                    onClickPrevPageLabel={() => { history.push("/admin/admin-manage-district-license") }}
                />
                <Box className="noHeaderButton" />
                <Box className='nsMainContent'>
                    <Box className="nsMainHeader">
                        <Box className="nsMainTextHeader">
                            Edit District Subscription
                        </Box>
                        <Box className="customHeaderButton" />
                    </Box>
                    <Box className="gridWrapper" style={{ padding: "0 .75rem" }}>
                        <Box className="contentSection">
                            <Box className="fieldParentCU">
                                <Box className="fieldChildCU">
                                    <Box className="fieldLabel">License</Box>
                                    <Autocomplete
                                        {...defaultProps}
                                        size="small"
                                        value={value}
                                        onChange={(event, newValue) => {
                                            if (!newValue) {
                                                setValue(listAllProducts[0]);
                                            } else {
                                                setValue(newValue);
                                            }
                                        }}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                variant="outlined"
                                                sx={{
                                                    '& label.Mui-focused': {
                                                        color: "#2d8f3c",
                                                    },
                                                    '& .MuiInput-underline:after': {
                                                        borderBottomColor: '#2d8f3c',
                                                    },
                                                }}
                                                required
                                            />
                                        )}
                                    />
                                </Box>
                                <Box className="fieldChildCU">
                                    <Box className="fieldLabel">Start Date</Box>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            minDate={new Date()}
                                            label=""
                                            inputFormat="dd/MM/yyyy"
                                            // format="MM/dd/yyyy"
                                            value={form.SubscriptionDate}
                                            // onKeyDown={e => e.preventDefault()}
                                            onChange={handleTimeChange}
                                            sx={{
                                                width: "100%",
                                                '& label.Mui-focused': {
                                                    color: "#2d8f3c",
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderColor: '#2d8f3c',
                                                },
                                            }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    size="small"
                                                    sx={{ width: "100%" }}
                                                    onKeyDown={(evt) => evt && evt.preventDefault()}
                                                    required
                                                    variant="outlined"
                                                />}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="createButtonParent">
                            <Box className="createButtonChild">
                                <CustomTableButton
                                    isInActive={true}
                                    buttonLabel={"Cancel"}
                                    onClickButton={() => {
                                        history.push("/admin/admin-manage-district-license");
                                    }}
                                    buttonIcon={<Close size={16} />}
                                />
                            </Box>
                            <Box className="createButtonChild">
                                <CustomTableButton
                                    isInActive={false}
                                    buttonLabel={"Update"}
                                    onClickButton={handleUpdateClick}
                                    buttonIcon={<Edit size={16} />}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}