import * as React from 'react';
import {
  Box,
  Card,
  Button,
  CardContent,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Avatar,
  Input,
  InputLabel,
  FormControl,
  Backdrop,
} from '@mui/material';

import {
  USER_NAME_EMPTY,
  PASSWORD_EMPTY,
  DISTRICT_EMPTY
} from "../../constants";

// Icons
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../constants/images';
import "./LoginScreen.css"
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  connect,
  // useDispatch 
} from "react-redux";

import allActions from "../../redux/ducks/actions";
import {
  INCORRECT_CREDENTIALS,
  FIELD_EMPTY,
} from "../../constants";
import { Alert } from "@mui/material";

const theme = createTheme();

function ClasslinkLogin(props) {
  const history = useHistory();

  const { classlinkLogin } = props; //from dispatch



  // Login Info
  const [login, setLogin] = useState({
    userName: "",
    password: "",
    userDomain: "",
  });

  const [emptyError, setEmptyError] = useState({
    userName: false,
    password: false,
    userDomain: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  // remember me
  const [isRememberPwd, setisRememberPwd] = useState(false);
  // data not filled msg
  const [showEmptyAlert, setshowEmptyAlert] = useState(false);
  // Incorrect credential msg
  const [showAlert, setshowAlert] = useState(false);
  // Loader
  const [loading, setLoading] = React.useState(false);
  // const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    const parsed = window.location.search;
    const urlValue = new URLSearchParams(parsed);

    const code = urlValue.get("code");
    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Host: window.location.host,
        Accept: "*/*",
        'access-control-allow-credentials': true,
        'access-control-allow-origin': window.location.host,
        Connection: "keep-alive"
      },
      body: JSON.stringify({ client_secret: 'c17e529842662f637c8e57bfd763c22a', client_id: 'c16522536640340d58552285ff4e4407380e678fb456', code: code })
    };

    fetch('https://launchpad.classlink.com/oauth2/v2/token', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.access_token) {
          const requestOptions1 = {
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${data.access_token}`,
            }
          };
          fetch('https://nodeapi.classlink.com/v2/my/info', requestOptions1)
            .then(response => response.json())
            .then(data => {
              if (data.SourcedId) {
                classlinkLogin(
                  data.SourcedId,
                  (userData) => {
                    if (userData) {
                      if (userData.UserType === "DistrictAdmin") {
                        history.push("district");
                      } else if (userData.UserType === "GroupAdmin") {
                        history.push("teacher");
                      } else if (userData.UserType === "Supergrouptechadmin" || userData.UserType === "SuperGroupAdmin") {
                        history.push("school");
                      } else if (userData.UserType === "NSTechAdmin") {
                        history.push("admin");
                      } else {
                        history.push("login");
                      }
                    } else {
                      console.error(userData);
                      history.push("login");
                    }
                  }
                );
                setLoading(false);
              } else {
                setLoading(false);
              }
            })
        } else {
          console.error(data)
          setLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleChange = (e) => {
    const { id, value } = e.target;
    if (e.target.value !== "") {
      setEmptyError((state) => ({
        ...state,
        [e.target.id]: false,
      }));
    }
    setLogin((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setshowAlert(false);
    setshowEmptyAlert(false);
  };

  const handleLoginClick = (e) => {
    setLoading(true);
    e.preventDefault();
    const checkEmptyValue = { ...emptyError };
    let submit = true;
    // Checking empty data
    for (let items in login) {
      if (login[items] === "") {
        submit = false;
        checkEmptyValue[items] = true;
      }
    }
    if (!submit) {
      setshowEmptyAlert(true);
      setEmptyError(checkEmptyValue);
      setLoading(false);
    } else {

    }
  };

  function Copyright(props) {
    return (
      <Typography variant="body2" align="center" {...props}>
        2022-24 © Knomadix Corp.
      </Typography>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box id="layoutAuthentication">
        <Backdrop
          sx={{
            background: "rgb(0 0 0 / 30%)",
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
          open={loading}
        >
          <CircularProgress style={{ color: "#008000" }} />
        </Backdrop>
        <Box
          id="layoutAuthentication_content"
          sx={{
            backgroundImage: `url(${Logo.background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Box className='container'>
            <Box className='justifyRow'>
              <Box className='col-lg-5'>
                <Card className='shadow-lg border-0 rounded-lg mt-5'>
                  <CardContent>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                      <Avatar alt="NS Logo" src={Logo.logo} sx={{ width: "150px", height: "auto" }} variant="rounded" />
                    </Box>
                  </CardContent>
                  <CardContent>
                    <Box sx={{ display: 'flex', margin: "1%" }}>
                      <Typography variant="h5" fontWeight={600} sx={{ color: "#2d8f3c" }} >
                        Sign In
                      </Typography>
                    </Box>

                    {(showAlert || showEmptyAlert) && (
                      <Box className="text-center p-1 mb-1">
                        <Alert severity="error" variant="filled">
                          {showAlert
                            ? INCORRECT_CREDENTIALS
                            : FIELD_EMPTY}
                        </Alert>
                      </Box>
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <AccountCircleRoundedIcon fontSize="large" sx={{ color: "#2d8f3c" }} />
                      <TextField
                        variant='standard'
                        sx={{
                          width: "100%",
                          marginLeft: "2%",
                          '& label.Mui-focused': {
                            color: "#2d8f3c",
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: emptyError.userName ? "#d32f2f" : "#2d8f3c",
                          },
                        }}
                        required
                        id="userName"
                        label="LoginID"
                        name="userName"
                        type="text"
                        value={login.userName}
                        onChange={handleChange}
                        error={emptyError.userName}
                        helperText={emptyError.userName ? USER_NAME_EMPTY : ""}
                      />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: "3%" }}>
                      <LockRoundedIcon fontSize="large" sx={{ color: "#2d8f3c" }} />
                      <FormControl
                        sx={{
                          m: 1,
                          width: '100%',
                          '& label.Mui-focused': {
                            color: "#2d8f3c",
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: emptyError.password ? "#d32f2f" : "#2d8f3c",
                          },
                        }}
                        variant="standard"
                        required
                        error={emptyError.password}
                      >
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                        <Input
                          id="password"
                          type={showPassword ? 'text' : 'password'}
                          value={login.password}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end" onClick={() => {
                              setShowPassword(!showPassword);
                            }}>
                              <IconButton>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {emptyError.password ? <FormHelperText>{PASSWORD_EMPTY}</FormHelperText> : ""}
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: "5%" }}>
                      <PlaceRoundedIcon fontSize="large" sx={{ color: "#2d8f3c" }} />
                      <TextField
                        variant='standard'
                        sx={{
                          width: "100%",
                          marginLeft: "2%",
                          '& label.Mui-focused': {
                            color: "#2d8f3c",
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: emptyError.userDomain ? "#d32f2f" : "#2d8f3c",
                          },
                        }}
                        required
                        id="userDomain"
                        label="School District"
                        name="userDomain"
                        type="text"
                        value={login.userDomain}
                        onChange={handleChange}
                        error={emptyError.userDomain}
                        helperText={emptyError.userDomain ? DISTRICT_EMPTY : ""}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", marginTop: "5%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="remembrMe"
                            value="remember"
                            sx={{
                              color: "#2d8f3c",
                              '&.Mui-checked': {
                                color: "#2d8f3c",
                              },
                            }}
                          />
                        }
                        checked={
                          isRememberPwd === true ||
                            isRememberPwd === "true"
                            ? true
                            : false}
                        onChange={(e) => {
                          setisRememberPwd((state) => !state);
                        }}
                        label="Remember me"
                        margin="none"
                        htmlFor="remembrMe"
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          mt: 2, mb: 2,
                          color: "#fff",
                          backgroundColor: "#2d8f3c",
                          '&:hover': {
                            color: "#fff",
                            backgroundColor: "#1a6826",
                          },
                        }}
                        onClick={handleLoginClick}
                      >
                        Sign In
                      </Button>

                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", paddinBottom: '10px' }}>
                      <a href='https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcllogin&client_id=c16522536640340d58552285ff4e4407380e678fb456&response_type=code'>ClassLink Login</a>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                      <Copyright />
                    </Box>

                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );


}

const mapStateToProps = (state) => ({
  userInfo: state.data.userInfo,
  logInOutMessage: state.data.logInOutMessage,
});

const mapDispatchToProps = (dispatch) => ({
  classlinkLogin: (sourceid, successFn, failureFn) =>
    dispatch(
      allActions.classlinkLogin(sourceid, successFn, failureFn)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClasslinkLogin);
