import {
    Box,
    Typography,
    FormControl,
    Backdrop,
    Select,
    MenuItem,
    Paper,
    Button,
    Stack,
} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Creators from "../../redux/ducks/actions";
import Logo from "../../constants/images";
import "./SchoolUsageData.css";
import { useHistory } from "react-router-dom";
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import CircularProgress from '@mui/material/CircularProgress';

export default function SchoolUsageDataSchoolList() {
    const dispatch = useDispatch(); //dispatch
    const history = useHistory(); //history
    const { userInfo } = useSelector(state => state.data); //dispatch
    const [schoolList, setSchoolList] = useState([]);
    const [schoolId, setSchoolId] = useState(-1);
    const [loading, setLoading] = React.useState(false);

    const handleSchoolList = (event) => {
        setSchoolId(event.target.value);
    };

    useEffect(() => {
        setLoading(true);
        dispatch(Creators.schoolUsageDataSchoolList(userInfo.DomainID, (response) => {
            setSchoolList(response);
            setLoading(false);
            if (response.length > 0) {
                history.push(`district-school-usage-data/school-details/${response[0].ID}`)
            } else {
                history.push(`district-school-usage-data/school-details/-1`)
            }
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const mdTheme = createTheme();

    return (
        < ThemeProvider theme={mdTheme} >
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            {/* <Box className='districtStyle'>
                <Box className="HeaderBoxResponsive"
                    zIndex={loading ? 1 : 9999}
                >
                    <Stack direction="row" spacing={1} sx={{ justifyContent: "space-between" }}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            sx={{
                                "&.MuiTypography-h6": {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontWeight: 700,
                                },
                            }}
                        >
                            <InsertChartRoundedIcon
                                fontSize="large"
                                sx={{
                                    marginRight: "5px",
                                    color: "#2d8f3c",
                                    display: "flex",
                                    alignSelf: "center",
                                }}
                            />
                            School Usage Data
                        </Typography>
                        <Box>
                            <img alt="logo" src={Logo.logo} className="logoStyle" />
                        </Box>
                    </Stack>
                </Box>
                <Box className='schoolUsageListMain'>
                    <Paper
                        elevation={3}
                        className="schoolUsageListHead"
                    >
                        <Box className='SchoolListDetails'>
                            <Box sx={{ height: "40%" }}>
                                <Box className="numberStyle" sx={{ textTransform: "uppercase" }}>Select School</Box>
                                <Box className="LineStyle" sx={{ background: "#2d8f3c" }} />
                            </Box>
                            <FormControl variant="standard" className="SchoolListSelect">
                                <Select
                                    labelId="SchoolList"
                                    variant="standard"
                                    value={schoolId}
                                    onChange={handleSchoolList}
                                    label="School List"
                                    color="success"
                                >
                                    {schoolList.map((data, index) => {
                                        return (
                                            <MenuItem key={`school-list-${index}`} value={data.ID}>
                                                {data.Name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <Button
                                sx={{
                                    "&.MuiButton-root": {
                                        background: "#2d8f3c",
                                        color: "#fff",
                                    },
                                    "&:hover": {
                                        color: "#fff",
                                        boxShadow: "0px 0px 0px 0px #2d8f3c, 0px 0px 0px 0px #2d8f3c, 0px 0px 3px 1px #2d8f3c"
                                    }
                                }}
                                onClick={() => {
                                    history.push(`district-school-usage-data/school-details/${schoolId}`)
                                }}
                            >
                                Ok
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </Box> */}
        </ThemeProvider >
    );
}