import PropTypes from "prop-types";

export default function CustomText({ text, size, align, color, className }) {
    const fontSize = size === "large" ? "custom-text-lg" : size === "medium" ? "custom-text-md" : "custom-text-sm"
    return (
        <div className="custom-text-main">
            <span
                className={className + ' custom-text ' + fontSize}
                style={{
                    color: color ? color : "var(--secondary-color)",
                    textAlign: align,
                }}
            >
                {text}
            </span>
        </div >
    );
}
CustomText.propTypes = {
    text: PropTypes.string,
    size: PropTypes.string,
    align: PropTypes.string,
    color: PropTypes.string,
}