export const INITIAL_STATE = {
  userInfo: {},
  token: {},
  logInOutMessage: null,
  listAllDistrict: [],
  listAllProducts: [],
  listAllClassLicense: [],
  listAllUserLicense: [],
  districtDashboardData: [],
  listAllSchools: [],
  listAllClasses: [],
  listAllUsersList: [],
  listNewNotifications: [],
  listAllNotifications: [],
  schoolUsageData: {
    schoolName: "",
    schoolId: 0,
    classId: 0,
    studentId: 0,
  },
  teacherUsageData: {
    FirstName: "",
    ID: 0,
    LastName: "",
  },
  //classLicenseProductData: [],
  listAllDistrictLicense: [],
  schoolDashboardData: [],
  schoolActivityData: [],
  notificationLength: 0,
  classUsageData: {
    className: "",
    schoolId: 0,
  },
  teacherDashboardData: [],
  studentUsageData: {
    studentName: ""
  },
  schoolData: null,
  classSchoolData: null,
  assignmentClassId: -1,
  assignmentList: [],
  teacherStatus: false,
  teacherNumber: 0,
};
