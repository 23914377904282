import DocumentScannerRoundedIcon from '@mui/icons-material/DocumentScannerRounded';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
    Backdrop,
    Box,
    Breadcrumbs,
    CircularProgress,
    Stack,
    Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ManageClasses.css";
import allActions from "../../redux/ducks/actions";
import MUIDataTable from "mui-datatables";
import ClassIcon from '@mui/icons-material/Class';
import CustomHeader from '../../Components/CustomHeader';
import CustomWelcomeCard from '../../Components/CustomWelcomeCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function ClassDetails(props) {
    const dispatch = useDispatch(); //dispatch
    const history = useHistory(); //history
    const [loading, setLoading] = useState(false);
    const { userInfo, listAllClasses } = useSelector((state) => state.data);
    let url = useParams();
    const [tableRow, setTableRow] = useState([]);

    React.useEffect(() => {
        dispatch(allActions.getClassDetails(Number(url.id), (response) => {
            console.log("response", response);
            setTableRow(response);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        "Full Name",
        "Login Name",
        "User Type",
    ];

    const options = {
        selectableRows: false,
        filter: false,
        download: false,
        print: false,
        rowsPerPage: 25,
    };


    const [licenseStatus, setLicenseStatus] = useState(false);
    useEffect(() => {
        if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
            let today = new Date();
            let todayDate = String(today.getDate()).padStart(2, '0');
            let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let todayYear = today.getFullYear();
            let dateData = userInfo.LicenseExpiryDate.split('/');
            let licenseDate = dateData[1]
            let licenseMonth = dateData[0]
            let licenseYear = dateData[2]
            if (licenseYear > todayYear) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
                setLicenseStatus(true);
            } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
                setLicenseStatus(true);
            } else {
                setLicenseStatus(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo.LicenseExpiryDate])

    const welcomCardData = []

    return (
        <Box className='districtStyle'>
            <Backdrop
                sx={{
                    background: "rgb(0 0 0 / 30%)",
                    color: '#fff',
                    zIndex: "99999999"
                }}
                open={loading}
            >
                <CircularProgress style={{ color: "#008000" }} />
            </Backdrop>
            <CustomHeader
                licenseStatus={licenseStatus}
                needBreadCrumb={true}
                previousPageLabel={"Manage Classes"}
                currentPageLabel={"Class Details"}
                onClickPrevPageLabel={() => {
                    let navigateTo = userInfo && (
                        userInfo.UserType === "Supergrouptechadmin" ||
                        userInfo.UserType === "SuperGroupAdmin"
                    ) ? "/school/school-manage-classes" : "/district/district-manage-classes";
                    history.push(navigateTo);
                }}
            />
            <CustomWelcomeCard
                welcomCardData={welcomCardData}
                cardLabel={"Class Name"}
                cardValue={`${listAllClasses &&
                    listAllClasses.length > 0 &&
                    listAllClasses.find(data => data.ID === Number(url.id) && data).Name
                    }`}
            />

            <Box className="nsMainContent">
                <MUIDataTable
                    title={"Class Details"}
                    data={tableRow ? tableRow.map(data => {
                        return [
                            data.Name,
                            data.LoginName,
                            data.UserType,
                        ]
                    }) : []}
                    columns={columns}
                    options={options}
                />
            </Box>
        </Box >
    );
}
export default ClassDetails;
