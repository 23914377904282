import {
  GridActionsCellItem,
} from "@mui/x-data-grid";
import MUIDataTable from "mui-datatables";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip, Skeleton, Box } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import allActions from "../../redux/ducks/actions";
import { Edit, ShoppingCatalog } from "@carbon/icons-react";

function ManageUserLicenseTable() {

  const history = useHistory();
  const dispatch = useDispatch();
  const [userLicenseData, setUserLicenseData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true)
    dispatch(allActions.getUserLicenseList((response) => {
      console.log(response,"getUserLicenseList")
      setUserLicenseData(response)
      setLoading(false)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    "Login Name",
    "Full Name",
    "License Name",
    "Start Date",
    "End Date",
    {
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box className='districtAction'>
              <Tooltip
                title="Edit Subscription"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let UserID = value.userId;
                  let ProductID = value.productId;
                  history.push(`admin-manage-user-license/edit_subscription/${UserID}/${ProductID}`);
                }}
              >
                <Box className='customActionIcon'>
                  <Edit size={16} color='#FFF' />
                </Box>
              </Tooltip>

              <Tooltip
                title="Purchase History"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      background: "#0e1318",
                    },
                  },
                }}
                onClick={() => {
                  let userID = value.userId;
                  history.push(`admin-manage-user-license/user_license_history/${userID}`);
                }}
              >
                <Box className='customActionIcon'>
                  <ShoppingCatalog size={16} color='#FFF' />
                </Box>
              </Tooltip>

            </Box>
          )
        }
      }
    }
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 25,
  };
  return (
    <React.Fragment>
      {
        loading
          ? <Skeleton
            variant="rectangular"
            sx={{
              position: "relative",
              width: "100%",
              height: "100%"
            }}
          />
          : <MUIDataTable
            title="User License"
            data={userLicenseData && userLicenseData.map(data => {
              const TableValue = {
                userId: data.UserID,
                productId: data.ProductID,
              }
              return [
                data.LoginName,
                data.FullName,
                data.ProductID,
                data.SubscriptionDate,
                data.ExpirationDate,
                TableValue,
              ]
            })}
            columns={columns}
            options={options}
          />
      }
    </React.Fragment>
  )
}
export default ManageUserLicenseTable;
