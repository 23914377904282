import ClassLicenseTable from "./ClassLicenseTable"
import { Stack, Typography, Box } from "@mui/material";
import ClassRoundedIcon from '@mui/icons-material/ClassRounded';
import React from "react";
import CustomHeader from "../../Components/CustomHeader";
import { useSelector } from "react-redux";
export default function ManageClassLicense() {
  const { userInfo } = useSelector(state => state.data);


  const [licenseStatus, setLicenseStatus] = React.useState(false);
  React.useEffect(() => {
    if (userInfo && userInfo.LicenseExpiryDate && userInfo.LicenseExpiryDate !== undefined) {
      let today = new Date();
      let todayDate = String(today.getDate()).padStart(2, '0');
      let todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let todayYear = today.getFullYear();
      let dateData = userInfo.LicenseExpiryDate.split('/');
      let licenseDate = dateData[1]
      let licenseMonth = dateData[0]
      let licenseYear = dateData[2]
      if (licenseYear > todayYear) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth > todayMonth) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate > todayDate) {
        setLicenseStatus(true);
      } else if (licenseYear >= todayYear && licenseMonth >= todayMonth && licenseDate >= todayDate) {
        setLicenseStatus(true);
      } else {
        setLicenseStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.LicenseExpiryDate])

  return (
    <React.Fragment>
      <Box className='districtStyle'>
        <CustomHeader
          hidelicense={userInfo && userInfo.UserType === "NSTechAdmin" ? true : false}
          licenseStatus={licenseStatus}
          headerLabel={"Manage Class License"}
        />

        <Box className="noHeaderButton" />

        <Box className="nsMainContent">
          <ClassLicenseTable />
        </Box>
      </Box>
    </React.Fragment>
  )
}