import * as React from "react";
import {
  Box,
  Card,
  Button,
  CardContent,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Avatar,
  Input,
  InputLabel,
  FormControl,
  Backdrop,
} from "@mui/material";

import {
  USER_NAME_EMPTY,
  PASSWORD_EMPTY,
  DISTRICT_EMPTY,
  Incorrect_Credential,
  No_Internet_Connection,
  Fill_Required_Field,
  This_Field_Required,
} from "../../constants";

// Icons
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import PlaceRoundedIcon from "@mui/icons-material/PlaceRounded";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../constants/images";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Column, Form, Grid, Row } from "@carbon/react";
import "./LoginScreen.css";
import "./loginstyles.scss";

import allActions from "../../redux/ducks/actions";
import { INCORRECT_CREDENTIALS, FIELD_EMPTY } from "../../constants";
import { Alert } from "@mui/material";
import LoginCarousel from "./LoginCarousel";
import CustomText from "./CustomText";
import CustomButton from "./CustomButton";
import CustomCheckBox from "./CustomCheckbox";
import CustomInput from "./CustomInput";
import { SetSession } from "./SetSession";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// import { classLogin } from "../../redux/sagas/allSagaFunctions";

const theme = createTheme();

function LoginScreen(props) {
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);

  // remember me
  const [isRememberPwd, setisRememberPwd] = useState(false);
  // data not filled msg
  const [showEmptyAlert, setshowEmptyAlert] = useState(false);
  // Incorrect credential msg
  const [showAlert, setshowAlert] = useState(false);
  // Error msg  
  const [showError, setshowError] = useState(false);
  const location = useLocation();
  const [errorMessage, seterrorMessage] = useState(false);
  // Loader
  const [loading, setLoader] = React.useState(false);
  const dispatch = useDispatch();

  const { userLogin, classLogin } = props; //from dispatch
  // Login Info
  const [login, setLogin] = useState({
    userName: "",
    password: "",
    userDomain: "",
  });

  const [emptyError, setEmptyError] = useState({
    userName: false,
    password: false,
    userDomain: false,
  });

  const [loginForm, setLoginForm] = useState([
    {
      name: "userName",
      type: "text",
      label: "User Name",
      value: "",
      isRequired: true,
      error: false,
      errorMessage: "",
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      value: "",
      isRequired: true,
      error: false,
      errorMessage: "",
      showPassword: false,
    },
    {
      name: "domainName",
      type: "text",
      label: "Domain Name",
      value: "",
      isRequired: true,
      error: false,
      errorMessage: "",
    },
  ]);

  const getFormValues = () => {
    let setLoginValues = {};
    loginForm.map((formData) => {
      setLoginValues[formData.name] = formData.value;
      return null;
    });
    return setLoginValues;
  };

  const onHandleSubmitForm = (userName, password, domainName) => {
    setLoader(true);
    userLogin(
      userName,
      password,
      domainName,
      (userData) => {
        console.log(userData, 'userData');
        setLoader(false);
        SetSession(userName, password, domainName, isRememberPwd);
        if (isRememberPwd) {
          localStorage.Username = loginForm[0].value;
          localStorage.Password = loginForm[1].value;
          localStorage.userDomain = loginForm[2].value;
          localStorage.Type = "http://localhost:3000";
        } else {
          localStorage.Username = "";
          localStorage.Password = "";
          localStorage.userDomain = "";
          localStorage.Type = "";
        }
        localStorage.IsRememberPwd = isRememberPwd;
        sessionStorage.Username = login.userName;
        sessionStorage.Password = login.password;
        sessionStorage.userDomain = login.userDomain;
        sessionStorage.Type = "https://reactnsadmin.knomadixapp.com/";

        // 1.NSTechAdmin             - Super Admin                 // nstechadmin softech knomadix
        // 2.DistrictAdmin           - District Admin              // samchinnud 1234 riverdale
        // 3.Supergrouptechadmin     - School Admin                // samchinnusa 1234 riverdale
        // 4.SuperGroupAdmin         - Principal                   // samchinnup 1234 riverdale

        // 5.GroupAdmin              - Teacher                     // samchinnuT 1234 riverdale

        // 6.restricteddistrictadmin - Restricted District Admin   // RDA1 1234 riverdale
        // 7.restrictedschooladmin   - Restricted school admin     // RSA 1234 riverdale

        if (userData.UserType === "DistrictAdmin" || userData.UserType === "restricteddistrictadmin") {
          setLoader(false);
          window.location.href = '/district/district-dashboard'
        } else if (userData.UserType === "GroupAdmin") {
          setLoader(false);
          window.location.href = '/teacher/teacher-dashboard'

        } else if (
          userData.UserType === "Supergrouptechadmin" ||
          userData.UserType === "restrictedschooladmin" ||
          userData.UserType === "SuperGroupAdmin" || userData.UserType === "Principal"
        ) {
          setLoader(false);
          window.location.href = '/school/school-dashboard'
        } else if (userData.UserType === "NSTechAdmin") {
          setLoader(false);
          window.location.href = '/admin/admin-dashboard'
        } else {
          setLoader(false);
          history.push("login");
        }
      },
      (userData) => {
        if (userData.StatusCode) {
          setshowAlert(true);
          setLoader(false);
        }
        else {
          setLoader(false);
          history.push("login?messageID=6");
        }
      }
    );
  };

  const handleLoginClick = () => {
    const result = loginForm.map((data) => {
      if (data.value === "" && data.isRequired) {
        setLoginForm((current) =>
          current.map((obj) => {
            if (obj.name === data.name)
              return {
                ...obj,
                error: true,
                errorMessage: This_Field_Required,
              };
            return obj;
          })
        );
        return false;
      } else if (data.error) {
        return false;
      } else {
        return true;
      }
    });
    const submitForm = !result.includes(false);
    if (submitForm) {
      const { userName, password, domainName } = getFormValues();
      onHandleSubmitForm(userName, password, domainName);
    } else {
      // alert(Fill_Required_Field);
    }
  };

  // Prevent navigating back
  useEffect(() => {
    var pathName = window.location.pathname;
    // Normal Login
    if (pathName == "/login") {
      const params = new URLSearchParams(window.location.search);
      const showalert = params.get("showalert");
      if (showalert) {
        setshowAlert(true);
      }
      const onBackButtonEvent = (event) => {
        event.preventDefault();
        history.push("/");
      };
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);
      return () => {
        window.removeEventListener("popstate", onBackButtonEvent);
      };
    }
    // classLink Login
    else if (pathName == "/classlinklogin") {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      if (code === null) {
        window.location.href = "/login";
      } else if (code) {
        setLoader(true);
        classLogin(
          code,
          (userData) => {
            if (userData.UserType === "DistrictAdmin" || userData.UserType === "restricteddistrictadmin") {
              history.push("district");
            } else if (userData.UserType === "GroupAdmin") {
              history.push("teacher");
            } else if (
              userData.UserType === "Supergrouptechadmin" ||
              userData.UserType === "restrictedschooladmin" ||
              userData.UserType === "SuperGroupAdmin"
            ) {
              history.push("school");
            } else if (userData.UserType === "NSTechAdmin") {
              history.push("admin");
            } else if (userData.UserType === "Student") {
              window.location.href = "https://numbersense.app/?sourceid=hgh";
            }
            else {
              history.push("login");
            }
          },
          (response) => {
            if (response?.UserType === "student") {
              window.location.href = `https://numbersense.app/?sourceid=${response.SourceID}`;
            } else {
              window.location.href = "/login?showalert=true";
            }
          }
        );
      }
    }
  }, [history]);
  useEffect(() => {
    handleErrorMessage();
  }, [location, history]);
  useEffect(() => {
    var pathName = window.location.pathname;
    // Normal Login
    if (pathName === "/login") {
      document.title = "Number Sense - Login";
      if (localStorage.IsRememberPwd === "true" || localStorage.IsRememberPwd === true) {
        setLoginForm((current) =>
          current.map((obj) => {
            if (obj.name === "userName")
              return {
                ...obj,
                value: localStorage.Username,
              };
            else if (obj.name === "password")
              return {
                ...obj,
                value: localStorage.Password,
              };
            else if (obj.name === "domainName")
              return {
                ...obj,
                value: localStorage.userDomain,
              };
            return obj;
          })
        );
        setisRememberPwd(localStorage.IsRememberPwd);
      }
      if (sessionStorage.Username !== "" && sessionStorage.Password !== "" && sessionStorage.userDomain !== "") {
        // userLogin(
        //   sessionStorage.Username,
        //   sessionStorage.Password,
        //   sessionStorage.userDomain,
        //   (userData) => {
        //     if (userData.UserType === "DistrictAdmin" || userData.UserType === "restricteddistrictadmin") {
        //       history.push("district");
        //     } else if (userData.UserType === "GroupAdmin") {
        //       history.push("teacher");
        //     } else if (
        //       userData.UserType === "Supergrouptechadmin" ||
        //       userData.UserType === "restrictedschooladmin" ||
        //       userData.UserType === "SuperGroupAdmin"
        //     ) {
        //       history.push("school");
        //     } else if (userData.UserType === "NSTechAdmin") {
        //       history.push("admin");
        //     } else {
        //       history.push("login");
        //     }
        //   },
        //   (response) => {
        //     console.error(response);
        //   }
        // );
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (e.target.value !== "") {
      setEmptyError((state) => ({
        ...state,
        [e.target.id]: false,
      }));
    }
    setLogin((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setshowAlert(false);
    setshowEmptyAlert(false);
  };

  function Copyright(props) {
    return (
      <Typography variant="body2" align="center" {...props}>
        2022-24 © Knomadix Corp.
      </Typography>
    );
  }
  const handleErrorMessage = () => {
    const queryParams = new URLSearchParams(location.search);
    const messageID = queryParams.get("messageID");

    // Define messages for different messageIDs
    const messages = {
      6: "Something went wrong, please login again.",
      7: "Session Expired, please login again.",
      // Add more message mappings as needed
    };

    if (messageID && messages[messageID]) {
      setshowError(true);
      seterrorMessage(messages[messageID]);
    } else {
      setshowError(false);
      seterrorMessage("");
    }
    setTimeout(() => { seterrorMessage(false); setshowError(false) }, 5000);
    // setTimeout(() => {

    //   history.push("login");
    // }, 1000);
  };
  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{
          background: "rgb(0 0 0 / 30%)",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress style={{ color: "#008000" }} />
      </Backdrop>
      <Grid condensed className="loginMain">
        <Column lg={8} md={4} sm={0}>
          <LoginCarousel />
          {/* <CustomCarousel /> */}
        </Column>
        <Column
          lg={8}
          md={4}
          sm={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="RightFrame1"
        >
          <section className="LoginFrame">
            <div>
              <CustomText className={"LoginTitle1"} text={"Welcome back!"} />
              <CustomText className={"LoginTitle2"} text={"Let’s get started!"} />
            </div>
            <div
              className="w-100 LoginFormInputs"
              onClick={() => {
                setshowAlert(false);
              }}
            >
              {showAlert || showEmptyAlert ? (
                <Box className="text-center p-1 mb-1">
                  <Alert severity="error" variant="filled">
                    {showAlert ? INCORRECT_CREDENTIALS : FIELD_EMPTY}
                  </Alert>
                </Box>
              ) : null}
              {showError ? (
                <Box className="text-center p-1 mb-1">
                  <Alert severity="error" variant="filled">
                    {errorMessage}
                  </Alert>
                </Box>
              ) : null}
              {loginForm.map((data, index) => {
                return (
                  <CustomInput
                    hideLabel={true}
                    key={data + `login` + +index}
                    name={data.name}
                    type={data.type}
                    label={data.label}
                    value={data.value}
                    onHandleChange={setLoginForm}
                    isRequired={data.isRequired}
                    error={data.error}
                    errorMessage={data.errorMessage}
                    showPassword={data.showPassword}
                  />
                );
              })}
            </div>
            <div className="w-100">
              <div>
                <CustomButton className={"loginButtonStyle"} text="Sign In" size={"md"} onHandleClick={handleLoginClick} />
              </div>
              <div className="LoginRememberFrame">
                <CustomCheckBox
                  checkBoxType="default"
                  name="rememberMe"
                  text="Remember me"
                  isChecked={isRememberPwd}
                  onHandleClick={(value) => setisRememberPwd(value)}
                />
                <div className="LoginForgetFrame">Forgot Password?</div>
              </div>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", paddinBottom: "10px", marginTop: "20px" }}>
                <a href="https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Fnsadmin.knomadixapp.com%2Fclasslinklogin&client_id=c1652253664034ce801a0b6d838d49020207cb233d46&response_type=code">
                  ClassLink Login
                </a>
              </Box>
            </div>
          </section>
          <span className="LoginTerms">By tapping on the button above you agree to the Terms of Service and Privacy Policy.</span>
        </Column>
      </Grid>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.data.userInfo,
  logInOutMessage: state.data.logInOutMessage,
});

const mapDispatchToProps = (dispatch) => ({
  userLogin: (username, password, domain, successFn, failureFn) => dispatch(allActions.userLogin(username, password, domain, successFn, failureFn)),
  classLogin: (code, successFn, failureFn) => dispatch(allActions.classLogin(code, successFn, failureFn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
