// NumberSense Messages

import { Box, Building, ChartLineSmooth, Course, LicenseGlobal, LicenseMaintenance, LicenseThirdParty, Location, Notebook, Notification, RecentlyViewed, ShoppingCatalog, TableSplit, Task, Trophy, UserAdmin, Workspace } from "@carbon/icons-react";
import { useDispatch } from "react-redux";
import allActions from "../redux/ducks/actions"
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const This_Field_Required = "This field is required.";
export const minRequired = (data) => {
    return data.lenght > 1 ? `Minimum length ${data} characters are required.` :
        `Minimum length ${data} character is required.`;
}
export const maxRequired = (data) => {
    return `Maximum length of ${data} is exceeded.`;
}
export const Invalid_Email = "Enter a valid email.";
export const Incorrect_Credential = "Please enter valid login credentials and try again.";
export const No_Internet_Connection = "Check your internet connection and try again.";
export const Fill_Required_Field = "Please complete the necessary fields.";

// Empty messages
export const FIELD_EMPTY = "All fields are required.";
export const DOMAIN_EMPTY = "District Name is required.";
export const DESCRIPTION_EMPTY = "Description is required.";
export const COUNTRY_EMPTY = "Country selection is required.";
export const LOCATION_EMPTY = "Location Name is required.";
export const USER_NAME_EMPTY = "User Name is required.";
export const PASSWORD_EMPTY = "Password is required.";
export const FIRST_NAME_EMPTY = "First Name is required.";
export const LAST_NAME_EMPTY = "Last Name is required.";
export const EMAIL_EMPTY = "Email is required.";
export const MAX_STUDENTS = "Max Student is required.";

export const PRODUCT_NAME_EMPTY = "Product Name is required.";
export const PRODUCT_ID_EMPTY = "ProductID is required.";
export const PRICE_EMPTY = " Price is required.";
export const DURATION_EMPTY = "Duration is required.";
export const MODES_EMPTY = "Modes is required.";
export const AGE_EMPTY = "Age is required.";
export const USER_TYPE_EMPTY = "User Type is required.";
export const DISTRICT_EMPTY = "District Field is required.";
export const CLASS_EMPTY = "Select Class.";
export const SCHOOL_NAME_EMPTY = "School Name is required."
export const ASSIGNMENT_NAME_EMPTY = "Assignment Name is required.";
export const TARGET_TIME_EMPTY = "Target Time selection is required.";
export const CLASS_SELECTION_EMPTY = "Class selection is required.";
export const CONCEPT_SELECTION_EMPTY = "Concept selection is required.";
export const SKILL_SELECTION_EMPTY = "Skill selection is required.";
export const NO_TILES_EMPTY = "Number of tiles selection is required.";
export const STARTING_NUMBER_EMPTY = "Starting number is required.";
export const MIN_GREEN_EMPTY = "Min Gren is required.";
export const MAX_RED_EMPTY = "Min Red is required.";
export const MIN_TIME_EMPTY = "Min Time is required.";
export const SELECT_EMPTY = "Please select 1 of the options.";
export const SCHOOL_LIST_EMPTY = "School List is empty.";
export const CLASS_LIST_EMPTY = "Class List is empty.";
export const CLASS_NAME_EMPTY = "Class Name is required.";

// Exist message
export const PRODUCT_NAME_EXIST = "Product name already exist.";
export const DOMAIN_NAME_EXIST = "District name already exist!";
export const PRODUCT_ID_EXIST = "Product Id already exist.";
export const USER_NAME_EXIST = "User name already exist.";
export const SCHOOL_DUPLICATE_EXIST = "Duplicate school.";
export const SCHOOL_NAME_EXIST = "School name already exist.";
export const CLASS_DUPLICATE_EXIST = "Duplicate class.";
export const CLASS_NAME_EXIST = "Class name already exist.";

// Invalid message
export const EMAIL_INVALID = "Enter a valid email.";
export const PASSWORD_INVALID = "Password should be of minimum 8 characters length.";
export const VALID_AGE = "Enter a valid age.";
export const MAX_STUDENTS_VALIDATION = "Enter a valid Number in Max Student Field.";
export const DURATION_INVALID = "Duration(Months) should be above 0.";
export const MAX_STUDENT_INVALID = "Max Student should be above 0.";

// Number Sense Messages

// Login
export const INCORRECT_CREDENTIALS = "Please enter valid login credentials and try again.";
export const LOGIN_SUCCESS_MESSAGE = "Logged in successfully.";
export const LOGIN_FAILED_MESSAGE = "Log in failed.";
export const LOGOUT_SUCCESS_MESSAGE = "Logout successfully.";

// Manage District
export const DISTRICT_CREATE_SUCCESS_MESSAGE = "District added successfully.";
export const DISTRICT_UPDATE_SUCCESS_MESSAGE = "District updated successfully.";
export const DISTRICT_DELETE_SUCCESS_MESSAGE = "District deleted successfully.";
export const ACTIVATED = "District has been activated successfully.";
export const DEACTIVATED = "District has been deactivated successfully.";

// Manage Product
export const PRODUCT_CREATED = "Product created successfully.";
export const PRODUCT_UPDATED = "Product updated successfully.";
export const DELETE_PRODUCT = "Product deleted successfully.";

// Manage School
export const SCHOOL_CREATE = "School added successfully.";
export const SCHOOL_UPDATE = "School updated successfully.";

// ManageClasses
export const CLASS_ADD = "Class added successfully.";
export const CLASS_UPDATE = "Class updated successfully.";
export const CLASS_NAME_DUPLICATE = "Duplicate class."
export const CLASS_TEACHER_TO_CLASS_MAPPING = "Teacher assigned to class successfully.";

// License
export const DISTRICT_LICENSE_UPDATE_SUCCESS_MESSAGE = "District License updated successfully.";
export const CLASS_LICENSE_UPDATE_SUCCESS = "Class License updated successfully.";


// UserLogins
export const USER_CREATED_SUCCESS = "User created successfully.";
export const USER_UPDATED_SUCCESS = "User updated successfully.";

// Notification
export const NOTIFICATION_DELETED_SUCCESS = "Notification deleted successfully.";

// Bulk uploads
export const SCHOOL_BULK_UPLOAD = "School uploaded successfully.";
export const CLASS_BULK_UPLOAD = "Class uploaded successfully.";
export const NO_SCHOOL_NAME_FOUND = "School Name List not found in the file. Please add and upload again.";
export const SCHOOL_NAME_UPLOAD_SUCCESS = "School Names Uploaded successfully.";
export const NO_CLASS_NAME_FOUND = "Class Name List not found in the file. Please add and upload again.";
export const SUBSCRIPTION_SUCCESS = "License Subscribed successfully.";
export const SUBSCRIPTION_UPDATED_SUCCESS = "User License updated successfully.";
export const INVALID_DATA = "Invalid Data in the Uploaded Excel File.";
export const NO_DATA = "The Uploaded excel file has no data.";
export const INVALID_COLUMN_DATA = "Invalid Column Names in the Excel File.";
export const INVALID_PRODUCT = "Invalid License Name. Please choose another Name.";
export const MONTH_ISSUE = "Cannot subscribe to current month.";
export const INVALID_CLASS_NAME = "Invalid Class Name.";

//Claslink
export const CLASSLINK_UPDATE_START = "Adding data.Please wait.";
export const CLASSLINK_UPDATE_SUCCESS = "Data Added succesfully.";
export const CLASSLINK_UPDATE_FAILURE = "Data adding failed.Please try again.";

// Assignment
export const ADD_ASSIGNMENT_MESSAGE = "Assignment added successfully."
export const DELETE_ASSIGNMENT_MESSAGE = "Assignment deactivated successfully."
export const NO_OF_TILES = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
export const STARTING_NUMBER = [1, 11, 21, 31, 41, 51, 61, 71, 81, 91, 101];
export const TIME_FORMAT = [
    "12:00", "12:15", "12:30", "12:45",
    "1:00", "1:15", "1:30", "1:45",
    "2:00", "2:15", "2:30", "2:45",
    "3:00", "3:15", "3:30", "3:45",
    "4:00", "4:15", "4:30", "4:45",
    "5:00", "5:15", "5:30", "5:45",
    "6:00", "6:15", "6:30", "6:45",
    "7:00", "7:15", "7:30", "7:45",
    "8:00", "8:15", "8:30", "8:45",
    "9:00", "9:15", "9:30", "9:45",
    "10:00", "10:15", "10:30", "10:45",
    "11:00", "11:15", "11:30", "11:45"
];

export const MENU = [
    // Admin Layout Menu
    {
        menuName: "Dashboard",
        menuURL: "admin-dashboard",
        layout: "/admin",
        icon: <TableSplit size={24} />
    },
    {
        menuName: "Manage Districts",
        menuURL: "admin-manage-district",
        layout: "/admin",
        icon: <Location size={24} />
    },
    {
        menuName: "Manage Products",
        menuURL: "admin-manage-products",
        layout: "/admin",
        icon: <ShoppingCatalog size={24} />
    },
    {
        menuName: "Manage District License",
        menuURL: "admin-manage-district-license",
        layout: "/admin",
        icon: <LicenseGlobal size={24} />
    },
    {
        menuName: "Manage Class License",
        menuURL: "admin-manage-class-license",
        layout: "/admin",
        icon: <LicenseMaintenance size={24} />
    },
    {
        menuName: "Manage User License",
        menuURL: "admin-manage-user-license",
        layout: "/admin",
        icon: <LicenseThirdParty size={24} />
    },
    {
        menuName: "Notifications",
        menuURL: "admin-notification",
        layout: "/admin",
        icon: <Notification size={24} />
    },


    // District Layout Menu
    {
        menuName: "Dashboard",
        menuURL: "district-dashboard",
        layout: "/district",
        icon: <TableSplit size={24} />
    },
    {
        menuName: "Manage Schools",
        menuURL: "district-manage-schools",
        layout: "/district",
        icon: <Building size={24} />
    },
    {
        menuName: "Manage Classes",
        menuURL: "district-manage-classes",
        layout: "/district",
        icon: <Course size={24} />
    },
    {
        menuName: "School Usage Data",
        menuURL: "district-school-usage-data",
        layout: "/district",
        icon: <Notebook size={24} />
    },
    {
        menuName: "Weekly Reports",
        menuURL: "district-weekly-report",
        layout: "/district",
        icon: <ChartLineSmooth size={24} />
    },
    {
        menuName: "Notifications",
        menuURL: "district-notification",
        layout: "/district",
        icon: <Notification size={24} />
    },
    // {
    //     menuName: "Class Leaderboard",
    //     menuURL: "district-class-leaderboard",
    //     layout: "/district",
    //     icon: <TableSplit size={24} />
    // },
    {
        menuName: "Leaderboard",
        menuURL: "district-student-leaderboard",
        layout: "/district",
        icon: <Trophy size={24} />
    },
    {
        menuName: "Purchase History",
        menuURL: "district-purchase-History",
        layout: "/district",
        icon: <RecentlyViewed size={24} />
    },
    {
        menuName: "User Logins",
        menuURL: "district-user-logins",
        layout: "/district",
        icon: <UserAdmin size={24} />
    },

    // School Layout Menu
    {
        menuName: "Dashboard",
        menuURL: "school-dashboard",
        layout: "/school",
        icon: <TableSplit size={24} />
    },
    {
        menuName: "Manage Classes",
        menuURL: "school-manage-classes",
        layout: "/school",
        icon: <Course size={24} />
    },
    {
        menuName: "Class Usage Data",
        menuURL: "school-class-usage-data",
        layout: "/school",
        icon: <Notebook size={24} />
    },
    {
        menuName: "Teacher Usage Data",
        menuURL: "school-teacher-usage-data",
        layout: "/school",
        icon: <Notebook size={24} />
    },
    {
        menuName: "Weekly Reports",
        menuURL: "school-weekly-report",
        layout: "/school",
        icon: <ChartLineSmooth size={24} />
    },
    // {
    //     menuName: "Class Leaderboard",
    //     menuURL: "school-class-leaderboard",
    //     layout: "/school",
    //     icon: <Trophy size={24} />
    // },
    {
        menuName: "Leaderboard",
        menuURL: "school-student-leaderboard",
        layout: "/school",
        icon: <Trophy size={24} />
    },
    {
        menuName: "User Logins",
        menuURL: "school-user-logins",
        layout: "/school",
        icon: <UserAdmin size={24} />
    },

    // Teacher Layout Menu
    {
        menuName: "Dashboard",
        menuURL: "teacher-dashboard",
        layout: "/teacher",
        icon: <TableSplit size={24} />
    },
    {
        menuName: "Student Usage Data",
        menuURL: "teacher-student-usage-data",
        layout: "/teacher",
        icon: <Notebook size={24} />
    },
    {
        menuName: "Weekly Reports",
        menuURL: "teacher-weekly-report",
        layout: "/teacher",
        icon: <ChartLineSmooth size={24} />
    },
    // {
    //     menuName: "Class Leaderboard",
    //     menuURL: "teacher-class-leaderboard",
    //     layout: "/teacher",
    //     icon: <Trophy size={24} />
    // },
    {
        menuName: "Leaderboard",
        menuURL: "teacher-leaderboard",
        layout: "/teacher",
        icon: <Trophy size={24} />
    },
    {
        menuName: "Assignments",
        menuURL: "teacher-assignments",
        layout: "/teacher",
        icon: <Task size={24} />
    },

];

export const COUNTRIES = [
    { code: "AD", label: "Andorra", phone: "376" },
    {
        code: "AE",
        label: "United Arab Emirates",
        phone: "971",
    },
    { code: "AF", label: "Afghanistan", phone: "93" },
    {
        code: "AG",
        label: "Antigua and Barbuda",
        phone: "1-268",
    },
    { code: "AI", label: "Anguilla", phone: "1-264" },
    { code: "AL", label: "Albania", phone: "355" },
    { code: "AM", label: "Armenia", phone: "374" },
    { code: "AO", label: "Angola", phone: "244" },
    { code: "AQ", label: "Antarctica", phone: "672" },
    { code: "AR", label: "Argentina", phone: "54" },
    { code: "AS", label: "American Samoa", phone: "1-684" },
    { code: "AT", label: "Austria", phone: "43" },
    {
        code: "AU",
        label: "Australia",
        phone: "61",
        suggested: true,
    },
    { code: "AW", label: "Aruba", phone: "297" },
    { code: "AX", label: "Alland Islands", phone: "358" },
    { code: "AZ", label: "Azerbaijan", phone: "994" },
    {
        code: "BA",
        label: "Bosnia and Herzegovina",
        phone: "387",
    },
    { code: "BB", label: "Barbados", phone: "1-246" },
    { code: "BD", label: "Bangladesh", phone: "880" },
    { code: "BE", label: "Belgium", phone: "32" },
    { code: "BF", label: "Burkina Faso", phone: "226" },
    { code: "BG", label: "Bulgaria", phone: "359" },
    { code: "BH", label: "Bahrain", phone: "973" },
    { code: "BI", label: "Burundi", phone: "257" },
    { code: "BJ", label: "Benin", phone: "229" },
    { code: "BL", label: "Saint Barthelemy", phone: "590" },
    { code: "BM", label: "Bermuda", phone: "1-441" },
    { code: "BN", label: "Brunei Darussalam", phone: "673" },
    { code: "BO", label: "Bolivia", phone: "591" },
    { code: "BR", label: "Brazil", phone: "55" },
    { code: "BS", label: "Bahamas", phone: "1-242" },
    { code: "BT", label: "Bhutan", phone: "975" },
    { code: "BV", label: "Bouvet Island", phone: "47" },
    { code: "BW", label: "Botswana", phone: "267" },
    { code: "BY", label: "Belarus", phone: "375" },
    { code: "BZ", label: "Belize", phone: "501" },
    {
        code: "CA",
        label: "Canada",
        phone: "1",
        suggested: true,
    },
    {
        code: "CC",
        label: "Cocos (Keeling) Islands",
        phone: "61",
    },
    {
        code: "CD",
        label: "Congo, Democratic Republic of the",
        phone: "243",
    },
    {
        code: "CF",
        label: "Central African Republic",
        phone: "236",
    },
    {
        code: "CG",
        label: "Congo, Republic of the",
        phone: "242",
    },
    { code: "CH", label: "Switzerland", phone: "41" },
    { code: "CI", label: "Cote d'Ivoire", phone: "225" },
    { code: "CK", label: "Cook Islands", phone: "682" },
    { code: "CL", label: "Chile", phone: "56" },
    { code: "CM", label: "Cameroon", phone: "237" },
    { code: "CN", label: "China", phone: "86" },
    { code: "CO", label: "Colombia", phone: "57" },
    { code: "CR", label: "Costa Rica", phone: "506" },
    { code: "CU", label: "Cuba", phone: "53" },
    { code: "CV", label: "Cape Verde", phone: "238" },
    { code: "CW", label: "Curacao", phone: "599" },
    { code: "CX", label: "Christmas Island", phone: "61" },
    { code: "CY", label: "Cyprus", phone: "357" },
    { code: "CZ", label: "Czech Republic", phone: "420" },
    {
        code: "DE",
        label: "Germany",
        phone: "49",
        suggested: true,
    },
    { code: "DJ", label: "Djibouti", phone: "253" },
    { code: "DK", label: "Denmark", phone: "45" },
    { code: "DM", label: "Dominica", phone: "1-767" },
    {
        code: "DO",
        label: "Dominican Republic",
        phone: "1-809",
    },
    { code: "DZ", label: "Algeria", phone: "213" },
    { code: "EC", label: "Ecuador", phone: "593" },
    { code: "EE", label: "Estonia", phone: "372" },
    { code: "EG", label: "Egypt", phone: "20" },
    { code: "EH", label: "Western Sahara", phone: "212" },
    { code: "ER", label: "Eritrea", phone: "291" },
    { code: "ES", label: "Spain", phone: "34" },
    { code: "ET", label: "Ethiopia", phone: "251" },
    { code: "FI", label: "Finland", phone: "358" },
    { code: "FJ", label: "Fiji", phone: "679" },
    {
        code: "FK",
        label: "Falkland Islands (Malvinas)",
        phone: "500",
    },
    {
        code: "FM",
        label: "Micronesia, Federated States of",
        phone: "691",
    },
    { code: "FO", label: "Faroe Islands", phone: "298" },
    {
        code: "FR",
        label: "France",
        phone: "33",
        suggested: true,
    },
    { code: "GA", label: "Gabon", phone: "241" },
    { code: "GB", label: "United Kingdom", phone: "44" },
    { code: "GD", label: "Grenada", phone: "1-473" },
    { code: "GE", label: "Georgia", phone: "995" },
    { code: "GF", label: "French Guiana", phone: "594" },
    { code: "GG", label: "Guernsey", phone: "44" },
    { code: "GH", label: "Ghana", phone: "233" },
    { code: "GI", label: "Gibraltar", phone: "350" },
    { code: "GL", label: "Greenland", phone: "299" },
    { code: "GM", label: "Gambia", phone: "220" },
    { code: "GN", label: "Guinea", phone: "224" },
    { code: "GP", label: "Guadeloupe", phone: "590" },
    { code: "GQ", label: "Equatorial Guinea", phone: "240" },
    { code: "GR", label: "Greece", phone: "30" },
    {
        code: "GS",
        label: "South Georgia and the South Sandwich Islands",
        phone: "500",
    },
    { code: "GT", label: "Guatemala", phone: "502" },
    { code: "GU", label: "Guam", phone: "1-671" },
    { code: "GW", label: "Guinea-Bissau", phone: "245" },
    { code: "GY", label: "Guyana", phone: "592" },
    { code: "HK", label: "Hong Kong", phone: "852" },
    {
        code: "HM",
        label: "Heard Island and McDonald Islands",
        phone: "672",
    },
    { code: "HN", label: "Honduras", phone: "504" },
    { code: "HR", label: "Croatia", phone: "385" },
    { code: "HT", label: "Haiti", phone: "509" },
    { code: "HU", label: "Hungary", phone: "36" },
    { code: "ID", label: "Indonesia", phone: "62" },
    { code: "IE", label: "Ireland", phone: "353" },
    { code: "IL", label: "Israel", phone: "972" },
    { code: "IM", label: "Isle of Man", phone: "44" },
    { code: "IN", label: "India", phone: "91" },
    {
        code: "IO",
        label: "British Indian Ocean Territory",
        phone: "246",
    },
    { code: "IQ", label: "Iraq", phone: "964" },
    {
        code: "IR",
        label: "Iran, Islamic Republic of",
        phone: "98",
    },
    { code: "IS", label: "Iceland", phone: "354" },
    { code: "IT", label: "Italy", phone: "39" },
    { code: "JE", label: "Jersey", phone: "44" },
    { code: "JM", label: "Jamaica", phone: "1-876" },
    { code: "JO", label: "Jordan", phone: "962" },
    {
        code: "JP",
        label: "Japan",
        phone: "81",
        suggested: true,
    },
    { code: "KE", label: "Kenya", phone: "254" },
    { code: "KG", label: "Kyrgyzstan", phone: "996" },
    { code: "KH", label: "Cambodia", phone: "855" },
    { code: "KI", label: "Kiribati", phone: "686" },
    { code: "KM", label: "Comoros", phone: "269" },
    {
        code: "KN",
        label: "Saint Kitts and Nevis",
        phone: "1-869",
    },
    {
        code: "KP",
        label: "Korea, Democratic People's Republic of",
        phone: "850",
    },
    { code: "KR", label: "Korea, Republic of", phone: "82" },
    { code: "KW", label: "Kuwait", phone: "965" },
    { code: "KY", label: "Cayman Islands", phone: "1-345" },
    { code: "KZ", label: "Kazakhstan", phone: "7" },
    {
        code: "LA",
        label: "Lao People's Democratic Republic",
        phone: "856",
    },
    { code: "LB", label: "Lebanon", phone: "961" },
    { code: "LC", label: "Saint Lucia", phone: "1-758" },
    { code: "LI", label: "Liechtenstein", phone: "423" },
    { code: "LK", label: "Sri Lanka", phone: "94" },
    { code: "LR", label: "Liberia", phone: "231" },
    { code: "LS", label: "Lesotho", phone: "266" },
    { code: "LT", label: "Lithuania", phone: "370" },
    { code: "LU", label: "Luxembourg", phone: "352" },
    { code: "LV", label: "Latvia", phone: "371" },
    { code: "LY", label: "Libya", phone: "218" },
    { code: "MA", label: "Morocco", phone: "212" },
    { code: "MC", label: "Monaco", phone: "377" },
    {
        code: "MD",
        label: "Moldova, Republic of",
        phone: "373",
    },
    { code: "ME", label: "Montenegro", phone: "382" },
    {
        code: "MF",
        label: "Saint Martin (French part)",
        phone: "590",
    },
    { code: "MG", label: "Madagascar", phone: "261" },
    { code: "MH", label: "Marshall Islands", phone: "692" },
    {
        code: "MK",
        label: "Macedonia, the Former Yugoslav Republic of",
        phone: "389",
    },
    { code: "ML", label: "Mali", phone: "223" },
    { code: "MM", label: "Myanmar", phone: "95" },
    { code: "MN", label: "Mongolia", phone: "976" },
    { code: "MO", label: "Macao", phone: "853" },
    {
        code: "MP",
        label: "Northern Mariana Islands",
        phone: "1-670",
    },
    { code: "MQ", label: "Martinique", phone: "596" },
    { code: "MR", label: "Mauritania", phone: "222" },
    { code: "MS", label: "Montserrat", phone: "1-664" },
    { code: "MT", label: "Malta", phone: "356" },
    { code: "MU", label: "Mauritius", phone: "230" },
    { code: "MV", label: "Maldives", phone: "960" },
    { code: "MW", label: "Malawi", phone: "265" },
    { code: "MX", label: "Mexico", phone: "52" },
    { code: "MY", label: "Malaysia", phone: "60" },
    { code: "MZ", label: "Mozambique", phone: "258" },
    { code: "NA", label: "Namibia", phone: "264" },
    { code: "NC", label: "New Caledonia", phone: "687" },
    { code: "NE", label: "Niger", phone: "227" },
    { code: "NF", label: "Norfolk Island", phone: "672" },
    { code: "NG", label: "Nigeria", phone: "234" },
    { code: "NI", label: "Nicaragua", phone: "505" },
    { code: "NL", label: "Netherlands", phone: "31" },
    { code: "NO", label: "Norway", phone: "47" },
    { code: "NP", label: "Nepal", phone: "977" },
    { code: "NR", label: "Nauru", phone: "674" },
    { code: "NU", label: "Niue", phone: "683" },
    { code: "NZ", label: "New Zealand", phone: "64" },
    { code: "OM", label: "Oman", phone: "968" },
    { code: "PA", label: "Panama", phone: "507" },
    { code: "PE", label: "Peru", phone: "51" },
    { code: "PF", label: "French Polynesia", phone: "689" },
    { code: "PG", label: "Papua New Guinea", phone: "675" },
    { code: "PH", label: "Philippines", phone: "63" },
    { code: "PK", label: "Pakistan", phone: "92" },
    { code: "PL", label: "Poland", phone: "48" },
    {
        code: "PM",
        label: "Saint Pierre and Miquelon",
        phone: "508",
    },
    { code: "PN", label: "Pitcairn", phone: "870" },
    { code: "PR", label: "Puerto Rico", phone: "1" },
    {
        code: "PS",
        label: "Palestine, State of",
        phone: "970",
    },
    { code: "PT", label: "Portugal", phone: "351" },
    { code: "PW", label: "Palau", phone: "680" },
    { code: "PY", label: "Paraguay", phone: "595" },
    { code: "QA", label: "Qatar", phone: "974" },
    { code: "RE", label: "Reunion", phone: "262" },
    { code: "RO", label: "Romania", phone: "40" },
    { code: "RS", label: "Serbia", phone: "381" },
    { code: "RU", label: "Russian Federation", phone: "7" },
    { code: "RW", label: "Rwanda", phone: "250" },
    { code: "SA", label: "Saudi Arabia", phone: "966" },
    { code: "SB", label: "Solomon Islands", phone: "677" },
    { code: "SC", label: "Seychelles", phone: "248" },
    { code: "SD", label: "Sudan", phone: "249" },
    { code: "SE", label: "Sweden", phone: "46" },
    { code: "SG", label: "Singapore", phone: "65" },
    { code: "SH", label: "Saint Helena", phone: "290" },
    { code: "SI", label: "Slovenia", phone: "386" },
    {
        code: "SJ",
        label: "Svalbard and Jan Mayen",
        phone: "47",
    },
    { code: "SK", label: "Slovakia", phone: "421" },
    { code: "SL", label: "Sierra Leone", phone: "232" },
    { code: "SM", label: "San Marino", phone: "378" },
    { code: "SN", label: "Senegal", phone: "221" },
    { code: "SO", label: "Somalia", phone: "252" },
    { code: "SR", label: "Suriname", phone: "597" },
    { code: "SS", label: "South Sudan", phone: "211" },
    {
        code: "ST",
        label: "Sao Tome and Principe",
        phone: "239",
    },
    { code: "SV", label: "El Salvador", phone: "503" },
    {
        code: "SX",
        label: "Sint Maarten (Dutch part)",
        phone: "1-721",
    },
    {
        code: "SY",
        label: "Syrian Arab Republic",
        phone: "963",
    },
    { code: "SZ", label: "Swaziland", phone: "268" },
    {
        code: "TC",
        label: "Turks and Caicos Islands",
        phone: "1-649",
    },
    { code: "TD", label: "Chad", phone: "235" },
    {
        code: "TF",
        label: "French Southern Territories",
        phone: "262",
    },
    { code: "TG", label: "Togo", phone: "228" },
    { code: "TH", label: "Thailand", phone: "66" },
    { code: "TJ", label: "Tajikistan", phone: "992" },
    { code: "TK", label: "Tokelau", phone: "690" },
    { code: "TL", label: "Timor-Leste", phone: "670" },
    { code: "TM", label: "Turkmenistan", phone: "993" },
    { code: "TN", label: "Tunisia", phone: "216" },
    { code: "TO", label: "Tonga", phone: "676" },
    { code: "TR", label: "Turkey", phone: "90" },
    {
        code: "TT",
        label: "Trinidad and Tobago",
        phone: "1-868",
    },
    { code: "TV", label: "Tuvalu", phone: "688" },
    {
        code: "TW",
        label: "Taiwan, Province of China",
        phone: "886",
    },
    {
        code: "TZ",
        label: "United Republic of Tanzania",
        phone: "255",
    },
    { code: "UA", label: "Ukraine", phone: "380" },
    { code: "UG", label: "Uganda", phone: "256" },
    {
        code: "US",
        label: "United States",
        phone: "1",
        suggested: true,
    },
    { code: "UY", label: "Uruguay", phone: "598" },
    { code: "UZ", label: "Uzbekistan", phone: "998" },
    {
        code: "VA",
        label: "Holy See (Vatican City State)",
        phone: "379",
    },
    {
        code: "VC",
        label: "Saint Vincent and the Grenadines",
        phone: "1-784",
    },
    { code: "VE", label: "Venezuela", phone: "58" },
    {
        code: "VG",
        label: "British Virgin Islands",
        phone: "1-284",
    },
    {
        code: "VI",
        label: "US Virgin Islands",
        phone: "1-340",
    },
    { code: "VN", label: "Vietnam", phone: "84" },
    { code: "VU", label: "Vanuatu", phone: "678" },
    { code: "WF", label: "Wallis and Futuna", phone: "681" },
    { code: "WS", label: "Samoa", phone: "685" },
    { code: "XK", label: "Kosovo", phone: "383" },
    { code: "YE", label: "Yemen", phone: "967" },
    { code: "YT", label: "Mayotte", phone: "262" },
    { code: "ZA", label: "South Africa", phone: "27" },
    { code: "ZM", label: "Zambia", phone: "260" },
    { code: "ZW", label: "Zimbabwe", phone: "263" },
];

export function formatDateToday() {
    const date = new Date();
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

export function FormatNumbers(num) {
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + 'G';
    } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + 'k';
    } else if (num === undefined) {
        return 0;
    } else {
        return num.toString();
    }
}
