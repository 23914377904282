import * as React from 'react';
import {
    Box,
} from '@mui/material';
import { Alarm } from '@carbon/icons-react';

export default function CustomClassCard({
    cardLabel,
    cardValue,
    backgroundColor,
}) {

    return (
        <Box className="customClassCard" style={{
            background: backgroundColor
        }}>
            <Box className='customClassCardIconWrapper'>
                <Alarm size={32} className='customClassCardIcon' />
            </Box>
            <Box className='customClassCardDivider' />
            <Box className="customClassCardContent">
                <Box className="customClassCardContentText1">{cardLabel}</Box>
                <Box className="customClassCardContentText2">{cardValue}</Box>
            </Box>
        </Box>
    );
} 